import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Modal,
    Table,
    message,
    Badge,
    Button,
    Menu,
    Tooltip,
    Typography,
    Alert,
    Switch,
} from "antd";
import {BarChartOutlined, DeploymentUnitOutlined} from "@ant-design/icons";
import PolicySelect from "../Health/PolicySelect";
import {exe} from "../../Lib/Dal";
import FacultativeEditorForm from "./FacultativeEditorForm";
import ReCessionGraph from "../Re/ReCessionGraph";
import {round2} from "../../Lib/Helpers";

const FacultativeEditor = (props) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [selected,setSelected]=useState(undefined);
    const [t]=useTranslation();
    const [splitWarning,setSplitWarning]=useState(false);
    const [createContract,setCreateContract]=useState(false);
    const [graphVisible,setGraphVisible]=useState(false);
    
    //props
    const visible=props.visible;
    const policyId=props.policyId;
    const onCancel=props.onCancel;
    
    useEffect(()=>{
        load();
    },[visible])
    
    const load=()=>{
        setLoading(true);
        exe("GetPolicyFac",{policyId:policyId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData&&r.outData.FacLines)
            }else message.error(r.msg);
        })
        
    }
    const onSave=values=>{
        console.log(values);
        values.split=round2(values.insuredSum/values.insuredSumUncovered*100);
        const newData=data.map(p=>p.cessionId===values.cessionId&&p.cessionPartId===values.cessionPartId?values:p);
        //validating
        const splitSum=newData.reduce((ac,c)=>ac+=c.split,0);
        const unique = [...new Set(newData.map(p=>p.coverageCode))];
        setSplitWarning(splitSum!==unique.length*100);
        //setting
        setData(newData);
        setSelected(undefined);
    }
    const onOk=()=>{
        setLoading(true);
        //replace temp ids starting with "temp" with 0
        const newData=data.map(p=>({...p,cessionPartId:p.cessionPartId.toString().startsWith("temp")?0:p.cessionPartId}));
        
        exe("PutPolicyFac",{FacLines:newData,createContract:createContract,policyId:policyId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Facultative marked as valid"));
                onCancel();
            }else message.error(r.msg);
        })
    }
    const onSplit=r=>{
        const editedRecord={...r};
        //editedRecord.insuredSum=editedRecord.insuredSum/2;
        //editedRecord.premium=editedRecord.premium/2;
        const newData=data.map(p=>p.cessionId===editedRecord.cessionId&&p.cessionPartId===editedRecord.cessionPartId?editedRecord:p);
        const newRecord={...editedRecord};
        newRecord.cessionPartId="temp"+Date.now(); //temporal id, converted to 0 before save
        newRecord.dirty=true;
        newData.push(newRecord);
        //validating
        const splitSum=newData.reduce((ac,c)=>ac+=c.split,0);
        const unique = [...new Set(newData.map(p=>p.coverageCode))];
        setSplitWarning(splitSum!==unique.length*100);
        //setting
        setData(newData);
    }
const onRemove=r=>{
        if(r.cessionPartId!==0){
            const newData=data.map(p=>p.cessionId===r.cessionId&&p.cessionPartId===r.cessionPartId? {...r,cessionPartId:r.cessionPartId*-1}:p);
            setData(newData);
        }else{
            const newData=data.filter(p=>!(p.cessionId===r.cessionId&&p.cessionPartId===0&&p.contactId===r.contactId));
            setData(newData);
        }
}
const distributeNow=()=>{
        setLoading(true);
        exe("ComputePolicyRe",{policyId:policyId,now:true}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Distributed"));
                load();
            }else message.error(r.msg);
        }
    )
}
    return (
        <div>
            <Modal title={t("Facultative Editor")} visible={visible} onCancel={onCancel} width={900} onOk={onOk} okText={t("Validate and Save")} okButtonProps={{disabled:selected||data.length==0}}>
                {splitWarning&&<Alert
                        message={t("Warning")}
                        description={t("Split % must sum 100% for each coverage")}
                        type="warning"
                        showIcon
                    />}
                <Button type={"link"} icon={<BarChartOutlined />} style={{float:"right"}} onClick={() => setGraphVisible(true)}>{t("Cession Graph")}</Button>
                {!selected&&<div>
                    {!policyId&&<div>{t("Policy")}:<PolicySelect /></div>}
                    <Button type={"link"} icon={<LegacyIcon type={"insurance"} />} onClick={() => (window.location.hash = "#/cessions?policyId=" + policyId)} disabled={!policyId}>
                        {t("Open All Cessions")}
                    </Button>
                    <Button type={"link"} icon={<DeploymentUnitOutlined />} onClick={distributeNow} disabled={!policyId}>
                        {t("Distribute Now")}
                    </Button>
                    <span>{t("Create Contract")}:</span><Switch style={{marginLeft:3}} size={"small"} checked={createContract} onChange={v=>setCreateContract(v)} />
                    <Table dataSource={data}>
                        <Table.Column title={t("Valid")} dataIndex="err" render={(v, r) => v?<Badge status={"error"}/>:<Badge status={"success"}/> } />
                        <Table.Column title={t("Coverage")} dataIndex={"coverageCode"} />  
                        <Table.Column title={t("Type")} dataIndex={"movementType"} />  
                        <Table.Column title={t("Reinsurer")} dataIndex={"reinsurer"} render={(v,r)=><Typography.Text delete={r.cessionPartId<0} mark={r.cessionPartId==0}>{v}</Typography.Text>}  />  
                        <Table.Column title={t("Split %")} dataIndex={"split"} />  
                        <Table.Column title={t("Ceded Insured Sum")} dataIndex={"insuredSum"} />  
                        <Table.Column title={t("Ceded Premium")} dataIndex={"premium"} />  
                        <Table.Column title={t("Commission")} dataIndex={"commission"} />  
                        <Table.Column title={t("Tax")} dataIndex={"tax"} />  
                        <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div style={{whiteSpace:"nowrap"}}>
                            <Tooltip title={t("Edit")}>
                                <Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)}/>
                            </Tooltip>
                            <Tooltip title={t("Duplicate")}>
                                <Button type={"link"} icon={<LegacyIcon type={"fork"} />} onClick={()=>onSplit(r)}/>
                            </Tooltip>
                            <Tooltip title={t("Remove")}>
                                <Button type={"link"} icon={<LegacyIcon type={"delete"} />} onClick={()=>onRemove(r)}/>
                            </Tooltip>
                        </div>} />  
                    </Table>
                </div>}
                {selected&&<FacultativeEditorForm record={selected} onCancel={()=>setSelected(undefined)} onSave={onSave} />}
                {/*<ParticipantDetail selected={selected} contractId={selected&&selected.contractId} visible={selected} onSave={onSave} onCancel={()=>setSelected(undefined)} />*/}
                <Modal title={t("Cession Graph")} visible={graphVisible} onCancel={()=>setGraphVisible(false)} width={900} onOk={()=>setGraphVisible(false)}>
                    <ReCessionGraph policyId={policyId} />
                </Modal>
            </Modal>
        </div>
    );
};

export default FacultativeEditor;