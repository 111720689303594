import React from 'react';
import { Select} from "antd";
import {useTranslation} from "react-i18next";

const IndexationOptionSelect = (props) => {
    const [t] = useTranslation();
    const data=[
        {id:0,name:t("No")},
        {id:1,name:t("Yes")},
        {id:2,name:t("Limited")},
        {id:3,name:t("Frequency")},
        {id:4,name:t("Insured Sum")},
        {id:5,name:t("Premium")},
        {id:6,name:t("Insured Sum and Premium")},
    ]
    
    
    
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} >
              {data.map(p=><Select.Option value={p.id} key={p.id}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default IndexationOptionSelect;