import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Table,
  Typography,
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Space,
  Tabs,
  Statistic,
  Progress,
  Tooltip,
  Tag,
  Empty,
  Form,
  Radio
} from 'antd';
import {
  DownloadOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  BarChartOutlined,
  PieChartOutlined,
  LineChartOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { exe } from "../../Lib/Dal";
import BudgetActualUpdate from './BudgetActualUpdate';

const { Title, Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const BudgetComparison = ({ budget, onRefresh }) => {
  const [t] = useTranslation();
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('summary');
  const [comparisonData, setComparisonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [actualUpdateVisible, setActualUpdateVisible] = useState(false);
  const [selectedBudgetLine, setSelectedBudgetLine] = useState(null);
  
  // Load scenarios when budget changes
  useEffect(() => {
    if (budget && budget.id) {
      fetchScenarios(budget.id);
    } else {
      // Reset scenarios when budget is cleared
      setScenarios([]);
      setSelectedScenario(null);
    }
  }, [budget]);
  
  // Load comparison data when budget or scenario changes
  useEffect(() => {
    if (budget && budget.id) {
      fetchComparisonData(budget.id, selectedScenario);
    } else {
      // Clear comparison data when budget is cleared
      setComparisonData([]);
    }
  }, [budget, selectedScenario]);
  
  const fetchScenarios = async (budgetId) => {
    try {
      setLoading(true);
      
      const result = await exe('RepoBudgetScenario', {
        operation: 'GET',
        filter: '',
        size: 0
      });
      
      if (result.ok) {
        // Filter scenarios in JavaScript
        const filteredScenarios = (result.outData || []).filter(s => s.budgetId === budgetId);
        setScenarios(filteredScenarios);
        
        // If no scenario is selected and there's a default scenario, select it
        const defaultScenario = filteredScenarios.find(s => s.isDefault);
        if (!selectedScenario && defaultScenario) {
          setSelectedScenario(defaultScenario.id);
        } else if (!selectedScenario && filteredScenarios.length > 0) {
          // Either select the default scenario or the first scenario
          const scenarioToSelect = defaultScenario ? defaultScenario.id : filteredScenarios[0].id;
          setSelectedScenario(scenarioToSelect);
        }
      } else {
        console.error('Failed to load scenarios:', result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading scenarios:', error);
      setLoading(false);
    }
  };
  
  const fetchComparisonData = async (budgetId, scenarioId) => {
    try {
      setLoading(true);
      
      const result = await exe('GetBudgetComparison', {
        budgetId: budgetId,
        scenarioId: scenarioId || 0  // If scenarioId is null or undefined, use 0
      });
      
      if (result.ok) {
        setComparisonData(result.outData || []);
      } else {
        console.error('Failed to load comparison data:', result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading comparison data:', error);
      setLoading(false);
    }
  };
  
  const handleScenarioChange = (scenarioId) => {
    setSelectedScenario(scenarioId);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const calculateTotals = () => {
    if (!comparisonData.length) return { budget: 0, actual: 0, variance: 0, percentageUsed: 0 };
    
    const totals = comparisonData.reduce((acc, item) => {
      acc.budget += item.BudgetTotal || 0;
      acc.actual += item.ActualTotal || 0;
      return acc;
    }, { budget: 0, actual: 0 });
    
    totals.variance = totals.actual - totals.budget;
    totals.percentageUsed = totals.budget !== 0 ? (totals.actual / totals.budget) * 100 : 0;
    
    return totals;
  };

  const renderVariance = (variance, percentVariance, type) => {
    // Round percentage to 1 decimal place
    const roundedPercentage = Math.round(percentVariance * 10) / 10;
    
    if (variance === 0) {
      return <Text>$0</Text>;
    }
    
    // For expenses, overspent is bad (negative variance is good)
    // For income, more than expected is good (positive variance is good)
    const isNegative = type === 'expense' ? variance > 0 : variance < 0;
    const color = isNegative ? 'red' : 'green';
    const icon = isNegative ? <ArrowDownOutlined /> : <ArrowUpOutlined />;
    
    return (
      <Text type={color} style={{ display: 'flex', alignItems: 'center' }}>
        {icon} ${Math.abs(variance).toLocaleString()} ({Math.abs(roundedPercentage)}%)
      </Text>
    );
  };

  // Open the actual update modal for a specific budget line
  const handleUpdateActual = (record) => {
    // Set the budget line to be updated
    setSelectedBudgetLine(record.LineId);
    
    // Show the modal
    setActualUpdateVisible(true);
  };
  
  // Handle successful update from the modal
  const handleActualUpdateSuccess = () => {
    // Close the modal
    setActualUpdateVisible(false);
    
    // Refresh the data
    if (budget && budget.id) {
      fetchComparisonData(budget.id, selectedScenario);
    }
    
    // Notify parent if needed
    if (onRefresh) {
      onRefresh();
    }
  };

  const columns = [
    {
      title: t('Category/Item'),
      dataIndex: 'Name',
      key: 'name',
      fixed: 'left',
      width: 250,
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Text strong>{text}</Text>
          <Text type="secondary">{record.Category}</Text>
        </Space>
      ),
    },
    {
      title: t('Budgeted'),
      dataIndex: 'BudgetTotal',
      key: 'budgeted',
      width: 130,
      render: (value) => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Actual'),
      dataIndex: 'ActualTotal',
      key: 'actual',
      width: 130,
      render: (value) => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Variance'),
      key: 'variance',
      width: 150,
      render: (_, record) => renderVariance(
        record.VarianceTotal || 0, 
        record.PercentageVariance || 0,
        'expense' // This is a simplification, should be based on the item type
      ),
    },
    {
      title: t('% of Budget Used'),
      dataIndex: 'PercentageUsed',
      key: 'percentUsed',
      width: 180,
      render: (value) => {
        // Ensure value is a number and round to 1 decimal place
        const percentage = typeof value === 'number' ? Math.round(value * 10) / 10 : 0;
        
        // Determine color based on percentage used
        let color = 'normal';
        if (percentage > 100) {
          color = 'exception';
        } else if (percentage > 90) {
          color = 'warning';
        } else if (percentage > 0) {
          color = 'success';
        }
        
        return (
          <Progress 
            percent={percentage} 
            size="small" 
            status={color}
            format={percent => `${percent.toFixed(1)}%`}
          />
        );
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleUpdateActual(record)}
            title={t('Update Actual Values')}
          />
        </Space>
      ),
    }
  ];

  const monthlyColumns = [
    {
      title: t('January'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetJan',
          key: 'jan_budget', 
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualJan',
          key: 'jan_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('February'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetFeb',
          key: 'feb_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualFeb',
          key: 'feb_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('March'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetMar',
          key: 'mar_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualMar',
          key: 'mar_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('April'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetApr',
          key: 'apr_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualApr',
          key: 'apr_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('May'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetMay',
          key: 'may_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualMay',
          key: 'may_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('June'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetJun',
          key: 'jun_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualJun',
          key: 'jun_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('July'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetJul',
          key: 'jul_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualJul',
          key: 'jul_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('August'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetAug',
          key: 'aug_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualAug',
          key: 'aug_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('September'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetSep',
          key: 'sep_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualSep',
          key: 'sep_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('October'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetOct',
          key: 'oct_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualOct',
          key: 'oct_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('November'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetNov',
          key: 'nov_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualNov',
          key: 'nov_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
    {
      title: t('December'),
      children: [
        {
          title: t('Budget'),
          dataIndex: 'BudgetDec',
          key: 'dec_budget',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
        {
          title: t('Actual'),
          dataIndex: 'ActualDec',
          key: 'dec_actual',
          width: 100,
          render: value => `$${(value || 0).toLocaleString()}`,
        },
      ]
    },
  ];

  const allColumns = [...columns, ...monthlyColumns];
  
  const totals = calculateTotals();
  
  return (
    <div>
      <Card title={t("Variance Analysis")}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form layout="horizontal">
            <Row gutter={[16, 16]} align="middle">
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label={t('Scenario')}>
                  <Select 
                    style={{ width: '100%' }}
                    placeholder={t('Select scenario')}
                    value={selectedScenario}
                    onChange={handleScenarioChange}
                    loading={loading}
                    disabled={!budget || scenarios.length === 0}
                  >
                    <Option value={null}>{t('Base Budget (No Scenario)')}</Option>
                    {scenarios.map(scenario => (
                      <Option key={scenario.id} value={scenario.id}>
                        {scenario.name} {scenario.isDefault && `(${t('Default')})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={12} md={8} lg={6}>
                <Form.Item label={t('View Type')}>
                  <Radio.Group 
                    value={selectedPeriod}
                    onChange={e => handlePeriodChange(e.target.value)}
                    disabled={!budget}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="summary">{t('Summary')}</Radio.Button>
                    <Radio.Button value="monthly">{t('Monthly')}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={12} md={8} lg={6}>
                <Button 
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => setActualUpdateVisible(true)}
                  disabled={!budget}
                >
                  {t('Update Actual Values')}
                </Button>
              </Col>
            
            </Row>
          </Form>
          
          {budget && (
            <>
              {selectedPeriod === 'summary' && (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={6}>
                    <Card>
                      <Statistic 
                        title={t('Budgeted Amount')}
                        value={totals.budget}
                        precision={2}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Card>
                      <Statistic 
                        title={t('Actual Spent')}
                        value={totals.actual}
                        precision={2}
                        prefix="$"
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Card>
                      <Statistic 
                        title={t('Variance')}
                        value={totals.variance}
                        precision={2}
                        prefix="$"
                        valueStyle={{ color: totals.variance > 0 ? '#cf1322' : '#3f8600' }}
                      />
                    </Card>
                  </Col>
                  <Col xs={24} sm={12} md={6}>
                    <Card>
                      <Statistic 
                        title={t('Budget Used')}
                        value={totals.percentageUsed}
                        precision={1}
                        suffix="%"
                        valueStyle={{ 
                          color: totals.percentageUsed > 100 ? '#cf1322' : 
                                totals.percentageUsed > 90 ? '#faad14' : '#3f8600' 
                        }}
                      />
                      <Progress 
                        percent={totals.percentageUsed} 
                        status={
                          totals.percentageUsed > 100 ? 'exception' : 
                          totals.percentageUsed > 90 ? 'warning' : 'success'
                        }
                        showInfo={false}
                        style={{ marginTop: 8 }}
                      />
                    </Card>
                  </Col>
                </Row>
              )}
              
              <Table 
                dataSource={comparisonData}
                columns={selectedPeriod === 'monthly' ? allColumns : columns}
                rowKey="LineId"
                loading={loading}
                scroll={{ x: 'max-content' }}
              />
            </>
          )}
          
          {!budget && (
            <Empty 
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('Please select a budget to view comparison data')}
            />
          )}
        </Space>
      </Card>
      
      {/* Budget Actual Update Modal */}
      <BudgetActualUpdate
        visible={actualUpdateVisible}
        onCancel={() => setActualUpdateVisible(false)}
        onSuccess={handleActualUpdateSuccess}
        budgetId={budget?.id}
        scenarioId={selectedScenario}
        budgetLineId={selectedBudgetLine}
      />
    </div>
  );
};

export default BudgetComparison; 