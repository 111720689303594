import React, { useEffect, useState } from 'react';
import Viewer from "bpmn-js/lib/NavigatedViewer";
import { DeleteOutlined, HeatMapOutlined, MinusCircleOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import h337 from 'heatmap.js/build/heatmap.js';
import { exe } from '../../Lib/Dal';
import { Button, message } from 'antd';

const VisorInstances = (props) => {
  const [currentViewer, setCurrentViewer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [instances, setInstances] = useState([]);

  useEffect(() => {
    if (props.definition) {
      load();
      removeHeatMap();
    }
  }, [props.definition]);

  const load = () => {
    console.log("load", props.definition);
    const bpmnXML = props.definition?.xml;
    if (!bpmnXML) return;

    //clearing previous instance
    if (currentViewer) {
      currentViewer.destroy();
    }
    const viewer = new Viewer({ container: "#canvas" });
    setCurrentViewer(viewer);

    // Add direct mouse event listeners to the canvas
    const canvasElement = document.querySelector("#canvas");
    canvasElement.addEventListener('mousedown', removeHeatMap);

    viewer.importXML(bpmnXML, (err) => {
      if (err) {
        console.log(err);
      } else {


        var canvas = viewer.get("canvas");
        canvas.zoom("fit-viewport");

        var a = document.getElementsByClassName("bjs-powered-by")[0];
        if (a && a.parentNode) a.parentNode.removeChild(a);

        loadInstances(viewer);

      }
    });
  }

  const loadInstances = (viewer) => {
    setLoading(true);
    exe("GetProcesses", { filter: `definitionId=${props.definition.id}`, include: ["Pasos"] }).then(r => {
      setLoading(false);
      if (r.ok) {
        setInstances(r.outData);
        props.setInstances(r.outData);
        createOverlays(viewer, r.outData);
      } else {
        message.error(r.msg);
      }
    });
  }
  const onElementClick = (instance) => {
    console.log("element", instance);
    props.setFilterByEstadoId(instance.estadoId);
  }
  const createOverlays = (viewer, instances) => {
    //grouing instances by estadoId and counting them eg {estadoId:"ABC",count:3}
    const grouped = instances.reduce((acc, instance) => {
      const found = acc.find((el) => el.estadoId === instance.estadoId);
      if (found) {
        found.count += 1;
      } else {
        acc.push({ estadoId: instance.estadoId, count: 1, id: instance.id });
      }
      return acc;
    }, []);
    //creating an overlay for each instance on the current step
    grouped.forEach((instance) => {
      const overlays = viewer.get("overlays");
      const newOverlay = {
        position: {
          top: 5,
          left: 5,
        },
        html: `<div>
            <div id="${instance.id}" style="cursor: pointer; background-color: rgb(135, 208, 104); opacity: 0.9; font-size: 12px; border-radius: 5px; width: 30px; height: 25px; line-height: 25px; text-align: center; color: #fff;">${instance.count}</div>
            </div>`,
      }
      //adding the overlay to the viewer in try catch because sometimes the element is not found when user cahnges the process
      try {
        overlays.add(instance.estadoId, newOverlay);
        //ataching a click event to the overlay
        document.getElementById(instance.id).addEventListener("click", () => onElementClick(instance));
      } catch (error) {
        console.log("error", error, instance.estadoId);
      }
    });


  }
  const removeHeatMap = () => {
    console.log("removeHeatMap");
    //searching for heatmap-canvas class and removing it
    const canvas = document.querySelector("#canvas");
    if (canvas) {
      const heatmap = canvas.querySelector(".heatmap-canvas");
      if (heatmap) {
        canvas.removeChild(heatmap);
      }
    }

  }

  const addHeatMap = () => {
    //if there is a heatmap already, toggle
    const heatmap = document.querySelector(".heatmap-canvas");
    if (heatmap) {
      removeHeatMap();
      return;
    }

    // Get current viewport scale from the viewer
    const elementRegistry = currentViewer.get('elementRegistry');
    const canvas = currentViewer.get('canvas');

    const grouped = instances.reduce((acc, instance) => {
      const found = acc.find((el) => el.estadoId === instance.estadoId);
      if (found) {
        found.count += 1;
      } else {
        acc.push({ estadoId: instance.estadoId, count: 1, id: instance.id });
      }
      return acc;
    }, []);

    const heatmapInstance = h337.create({ container: document.querySelector("#canvas"), });


    const points = [];
    const max = Math.max(...grouped.map(g => g.count));

    //datapoints on tasks
    elementRegistry.forEach(element => {
      console.log("element",element.type);
      if (element.type == "bpmn:Task") {
        const stat = grouped.find(s => s.estadoId == element.id);
        if (stat) {
          const position = canvas.getAbsoluteBBox(element);
          points.push({
            x: Math.round(position.x + position.width / 2),
            y: Math.round(position.y + position.height / 2),
            value: 1 + stat.count,
            radius: Math.round(position.width)
          });
        }
      } else if (element.type == "bpmn:SequenceFlow") {
          const position = canvas.getAbsoluteBBox(element);
          points.push({
            x: Math.round(position.x + position.width / 2),
            y: Math.round(position.y + position.height / 2),
            value: max*0.1 ,
            radius: Math.round(position.width)
          });
      } else if (element.type == "bpmn:ExclusiveGateway") {
          const position = canvas.getAbsoluteBBox(element);
          points.push({
            x: Math.round(position.x + position.width / 2),
            y: Math.round(position.y + position.height / 2),
            value: max*0.1,
            radius: Math.round(position.width)
          });
      }
    });


    const data = {
      max: max,
      data: points
    };
    console.log("data points", data);
    heatmapInstance.setData(data);
  }

  const handleZoomIn = () => {
    if (currentViewer) {
      const canvas = currentViewer.get('canvas');
      canvas.zoom(canvas.zoom() + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (currentViewer) {
      const canvas = currentViewer.get('canvas');
      canvas.zoom(canvas.zoom() - 0.1);
    }
  };

  return (
    <div>
      <Button type="link" icon={<HeatMapOutlined />} onClick={() => addHeatMap(currentViewer, instances)} disabled={!props.definition} />
      <Button type="link" icon={<ZoomInOutlined />} onClick={handleZoomIn} disabled={!props.definition} />
      <Button type="link" icon={<ZoomOutOutlined />} onClick={handleZoomOut} disabled={!props.definition} />
      <div
        id="canvas"
        style={{
          height: props.fullScreen ? "100vh" : "400px",
        }}
      />
    </div>);
};

export default VisorInstances;