import { CheckOutlined, CloseOutlined, EditOutlined, SwitchOutlined } from '@ant-design/icons';
import { Button, Input, Typography, InputNumber, Switch, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import yaml from "js-yaml";
const EditableFormField = (props) => {
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState(props.value);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if(props.dirty) {
      setDirty(true);
    }
  }, [props.dirty]);

  const onChange = (v) => {
    setValue(v);
  };
  const onSubmitChange = () => {
    console.log("submitting", value);
    if(props.type=="yaml"){
      props.onChange(toJson(value));
      console.log("submitting json", toJson(value));
    }else{
      props.onChange(value);
    }
    setEditable(false);
    setDirty(true);
  };
  const onCancelChange = (e) => {
    setEditable(false);
  };
  const toYaml = (value) => {
    if(!value) return "";
    //converting json to yaml
    try{
      return yaml.safeDump(JSON.parse(value));
    }catch(error){
      return value;
    }
  }
  const toJson = (value) => {
    //converting yaml to json
    try{
      return JSON.stringify(yaml.safeLoad(value));
    }catch(error){
      return value;
    }
  }
  return (
    <div className="editable-cell">
      {editable ? (<>
        {(!props.type||props.type=="string")&&<Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onPressEnter={(v) => onSubmitChange()}
          autoFocus
          style={{ minWidth: 200,width: "50%"}}
          onBlur={(e) => onCancelChange(e)}
          suffix={
            <div>
              <Button
                type="link"
                icon={<CheckOutlined />}
                onMouseDown={(e) => {
                  onSubmitChange();
                }}
              />
            </div>
          }
        />}
        {props.type=="number"&&<span><InputNumber
          value={value}
          onChange={(v) => onChange(v)}
          onPressEnter={(v) => onSubmitChange()}
          autoFocus
          style={{ minWidth: 200, maxWidth: 800, width:200 }}
        />
        <Button type="link" icon={<CheckOutlined />} onClick={() =>onSubmitChange()}/>
        <Button type="link" icon={<CloseOutlined />} onClick={() =>onCancelChange()}/>
        </span>}
        {props.type=="boolean"&&<span>
          <Switch
            checked={value}
            onChange={(v) => onChange(v)}
          />
          <Button type="link" icon={<CheckOutlined />} onClick={() =>onSubmitChange()}/>
          <Button type="link" icon={<CloseOutlined />} onClick={() =>onCancelChange()}/>
        </span>}
        {props.type=="string[]"&&<span>
          <Select mode="tags" value={typeof value=="string"?JSON.parse(value):value} onChange={(v) => onChange(v)} style={{minWidth:200,maxWidth:800,width:value?value.length*10:200}} />
          <Button type="link" icon={<CheckOutlined />} onClick={() =>onSubmitChange()}/>
          <Button type="link" icon={<CloseOutlined />} onClick={() =>onCancelChange()}/>
        </span>}
        {props.type=="number[]"&&<span>
          <Select mode="tags" value={typeof value=="string"?((JSON.parse(value)||[]).map(p=>+p).filter(p=>!isNaN(p))):((value||[]).map(p=>+p).filter(p=>!isNaN(p)))} onChange={(v) => onChange(v.map(p=>+p).filter(p=>!isNaN(p)))} style={{minWidth:200,maxWidth:800,width:value?value.length*10:200}} />
          <Button type="link" icon={<CheckOutlined />} onClick={() =>onSubmitChange()}/>
          <Button type="link" icon={<CloseOutlined />} onClick={() =>onCancelChange()}/>
        </span>}
        {props.type=="yaml"&&<span>
          <Input.TextArea value={toYaml(value)} onChange={(e) => onChange(e.target.value)} autoFocus autoSize={{ minRows: 3, maxRows: 10 }} style={{
            fontFamily: 'Consolas, Monaco, "Courier New", monospace',
            backgroundColor: '#f6f8fa',
            padding: '12px',
            borderRadius: '4px',
            fontSize: '14px',
            lineHeight: '1.5',
            tabSize: 2,
            border: '1px solid #d1d5da',
            boxShadow: 'inset 0 1px 2px rgba(27,31,35,0.075)',
            whiteSpace: 'pre',
            overflowX: 'auto'
  }}/>
          <Button type="link" icon={<CheckOutlined />} onClick={() =>onSubmitChange()}/>
          <Button type="link" icon={<CloseOutlined />} onClick={() =>onCancelChange()}/>
        </span>}
      </>
      ) : (
        <div style={{ ...{ paddingRight: 24, ...props.style } }}>
          {(!props.type||props.type=="string"||props.type=="number")&&<Typography.Text code={!dirty} mark={dirty}>
            {props.value}
          </Typography.Text>}
          {props.type=="boolean"&&<span><Switch disabled checked={value}/></span>}
          {props.type=="string[]"&&<span>{(() => {
            try {
              const arrayValue = typeof value=="string" ? JSON.parse(value) : value || [];
              return arrayValue.map(p => <Tag key={p}>{p.toString()}</Tag>);
            } catch(error) {
              console.error("Error parsing string[] value:", error);
              return [];
            }
          })()}</span>}
          {props.type=="number[]"&&<span>{(() => {
            try {
              const arrayValue = typeof value=="string" ? JSON.parse(value) : value || [];
              return arrayValue.map(p => <Tag key={p}>{p}</Tag>);
            } catch(error) {
              console.error("Error parsing number[] value:", error);
              return [];
            }
          })()}</span>}
          
          {props.type=="yaml"&&<span><Input.TextArea value={toYaml(value)} disabled style={{fontFamily: 'Consolas, Monaco, "Courier New", monospace', fontSize:10, maxWidth:800,width:800,minHeight:400,maxHeight:800}} autoSize={{ minRows: 3, maxRows: 10 }} /></span>}
          {!props.readOnly && <Button type="link" icon={<EditOutlined />} style={{ marginLeft: 5 }} onClick={() => setEditable(true)} />}
        </div>
      )}
    </div>
  );
};

export default EditableFormField;
