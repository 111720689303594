import { Switchboard } from './Switchboard';
import { getGuestTokenRefreshTiming } from './guestTokenRefresh';

const IFRAME_COMMS_MESSAGE_TYPE = "__embedded_comms__";
const DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY = {
  visible: "show_filters",
  expanded: "expand_filters",
};


async function embedDashboard({
  id,
  supersetDomain,
  mountPoint,
  fetchGuestToken,
  dashboardUiConfig,
  debug = false,
  iframeTitle = "Embedded Dashboard",
  iframeSandboxExtras = []
}) {
  function log(...info) {
    if (debug) {
      console.debug(`[superset-embedded-sdk][dashboard ${id}]`, ...info);
    }
  }

  log('embedding');

  if (supersetDomain.endsWith("/")) {
    supersetDomain = supersetDomain.slice(0, -1);
  }

  function calculateConfig() {
    let configNumber = 0;
    if (dashboardUiConfig) {
      if (dashboardUiConfig.hideTitle) configNumber += 1;
      if (dashboardUiConfig.hideTab) configNumber += 2;
      if (dashboardUiConfig.hideChartControls) configNumber += 8;
    }
    return configNumber;
  }

  async function mountIframe() {
    return new Promise(resolve => {
      const iframe = document.createElement('iframe');
      const dashboardConfigUrlParams = dashboardUiConfig ? { uiConfig: `${calculateConfig()}` } : {};
      const filterConfig = dashboardUiConfig?.filters || {};
      const filterConfigKeys = Object.keys(filterConfig);
      const filterConfigUrlParams = Object.fromEntries(
        filterConfigKeys.map(key => [DASHBOARD_UI_FILTER_CONFIG_URL_PARAM_KEY[key], filterConfig[key]])
      );

      const urlParams = { ...dashboardConfigUrlParams, ...filterConfigUrlParams, ...dashboardUiConfig?.urlParams };
      const urlParamsString = Object.keys(urlParams).length ? '?' + new URLSearchParams(urlParams).toString() : '';

      iframe.sandbox.add("allow-same-origin");
      iframe.sandbox.add("allow-scripts");
      iframe.sandbox.add("allow-presentation");
      iframe.sandbox.add("allow-downloads");
      iframe.sandbox.add("allow-forms");
      iframe.sandbox.add("allow-popups");
      iframeSandboxExtras.forEach(key => iframe.sandbox.add(key));

      iframe.addEventListener('load', () => {
        const commsChannel = new MessageChannel();
        const ourPort = commsChannel.port1;
        const theirPort = commsChannel.port2;

        iframe.contentWindow.postMessage(
          { type: IFRAME_COMMS_MESSAGE_TYPE, handshake: "port transfer" },
          supersetDomain,
          [theirPort]
        );
        log('sent message channel to the iframe');

        resolve(new Switchboard({ port: ourPort, name: 'superset-embedded-sdk', debug }));
      });
      
      iframe.src = `${supersetDomain}/embedded/${id}${urlParamsString}`;
      iframe.title = iframeTitle;
      mountPoint.replaceChildren(iframe);
      log('placed the iframe');
    });
  }

  const [guestToken, ourPort] = await Promise.all([
    fetchGuestToken(),
    mountIframe(),
  ]);

  ourPort.emit('guestToken', { guestToken });
  log('sent guest token');

  async function refreshGuestToken() {
    const newGuestToken = await fetchGuestToken();
    ourPort.emit('guestToken', { guestToken: newGuestToken });
    setTimeout(refreshGuestToken, getGuestTokenRefreshTiming(newGuestToken));
  }

  setTimeout(refreshGuestToken, getGuestTokenRefreshTiming(guestToken));

  function unmount() {
    log('unmounting');
    mountPoint.replaceChildren();
  }

  function getScrollSize() {
    return ourPort.get('getScrollSize');
  }

  function getDashboardPermalink(anchor) {
    return ourPort.get('getDashboardPermalink', { anchor });
  }

  function getActiveTabs() {
    return ourPort.get('getActiveTabs');
  }

  return {
    getScrollSize,
    unmount,
    getDashboardPermalink,
    getActiveTabs,
  };
}

export { embedDashboard };
