import React, { useEffect, useState } from "react";
import { CheckOutlined,DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, Select, message, Tag, Row, Col, Dropdown, Menu } from "antd";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import { exe } from "../../Lib/Dal";
import useUpdateEffect, { round2 } from "../../Lib/Helpers";
import moment from "moment";
import PolicySelect from "../Health/PolicySelect";
import InstallmentPremiumAllocation from "./InstallmentPremiumAllocation";
import SupplementaryPremiumAllocation from "./SupplementaryPremiumAllocation";
import Section from "../Shared/Section";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const AllocationTask = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const selectedTransfers=props.selectedTransfers||[];
  const selectedPremiums=props.selectedPremiums||[];
  const selectedOtherReceivables=props.selectedOtherReceivables||[];

  useEffect(() => {
    sumSelectedTransfers(selectedTransfers);
    sumSelectedPremiums(selectedPremiums);
  }, [selectedTransfers?.length,selectedPremiums?.length,selectedOtherReceivables?.length]);

  useUpdateEffect(() => onNewAllocationTask(), [props.doSave]);
  useUpdateEffect(() => reset(), [props.doReset]);
  
  const reset=()=>{
    console.log("Resetting allocation task");
    setSelectedPolicy(null);
    sumSelectedTransfers([]);
    sumSelectedPremiums([]);
    props.form.resetFields();
  }

  const sumSelectedTransfers = (selected) => {
    if (selected.length === 0) {
      props.form.setFieldsValue({ currency: undefined, transferAmount: 0 });
    } else {
      const sumTransfers = selected.reduce((a, c) => a + c.amount, 0);
      props.form.setFieldsValue({ currency: selected[0].currency, transferAmount: round2(sumTransfers) });
    }
    const installmentPremiums = props.form.getFieldValue("InstallmentPremiums");
    const supplementaryPremiums = props.form.getFieldValue("SupplementaryPremiums");
    computeTotals(installmentPremiums, supplementaryPremiums);
  };
  const sumSelectedPremiums = (selected) => {
    if (selected.length === 0) {
      props.form.setFieldsValue({ premiumAmount: 0 });
      //checking credits
      const hasCredits = selectedOtherReceivables.some((r) => r.type === "C");
        if (hasCredits) {
          const transferedAmount = round2(props.form.getFieldValue("transferAmount"))||0;
          const supplementaryPremiums=selectedOtherReceivables.filter(q=>q.type==="C").map(r=>({
            lifePolicyId:undefined,
            accountId: r.reference.split("-")[2],//account is the third number in the reference (creditId-installmentNum-accountId),
            creditId: r.entityId,
            destination: "ACCOUNT",
            moneyInAmount: round2(transferedAmount),
            currency: r.currency,
            compensationAmount: 0,
            transitAmount: 0
          }));
          props.form.setFieldsValue({SupplementaryPremiums:supplementaryPremiums});
          computeTotals([], supplementaryPremiums);
        }
    } else {
      const sumPremiums = round2(selected.reduce((a, c) => a + c.pendingAmount, 0));
      const transferedAmount = round2(props.form.getFieldValue("transferAmount"))||0;

      //default
      const dif = round2(transferedAmount - sumPremiums);
      let supplementaryPremiums = [];
      let installmentPremiums = [];
      if (dif > 0) {
        installmentPremiums = selected.map((p) => ({
          lifePolicyId: p.lifePolicyId,
          payPlanId: p.id,
          dueAmount: round2(p.pendingAmount),
          moneyInAmount: round2(p.pendingAmount),
          currency: p.currency,
          transitAmount: 0,
          compensationAmount: 0,
        }));
        //by default assigning exces to transit, unless there are credits to be paid among otherReceivablees (specific accounts defined)
        const hasCredits = selectedOtherReceivables.some((r) => r.type === "C");
        if (hasCredits) {
          supplementaryPremiums=selectedOtherReceivables.filter(q=>q.type==="C").map(r=>({
            lifePolicyId:undefined,
            accountId: r.reference.split("-")[2],//account is the third number in the reference (creditId-installmentNum-accountId),
            creditId: r.entityId,
            destination: "ACCOUNT",
            moneyInAmount: round2(transferedAmount - sumPremiums),
            currency: r.currency,
            compensationAmount: 0,
            transitAmount: 0
          }))
        } else{
          supplementaryPremiums = [
            {
              lifePolicyId: selected[0].lifePolicyId,
              destination: "TRANSIT",
              moneyInAmount: round2(transferedAmount - sumPremiums),
              currency: selected[0].currency,
              compensationAmount: 0,
              transitAmount: 0,
            },
          ];
        }
      

      } else if (dif == 0) {
        installmentPremiums = selected.map((p) => ({
          lifePolicyId: p.lifePolicyId,
          payPlanId: p.id,
          dueAmount: round2(p.pendingAmount),
          moneyInAmount: round2(p.pendingAmount),
          currency: p.currency,
          compensationAmount: 0,
          transitAmount: 0,
        }));
      } else {
        const qPremiums = installmentPremiums.length > 0 ? installmentPremiums.length : selected.length;
        //borrowing from transit in order
        // installmentPremiums = selected.map((p) => ({
        //   lifePolicyId: p.lifePolicyId,
        //   payPlanId: p.id,
        //   dueAmount: round2(p.pendingAmount),
        //   moneyInAmount: round2(transferedAmount / qPremiums),
        //   transitAmount: round2((-1 * dif) / qPremiums),
        //   compensationAmount: 0,
        //   currency: p.currency,
        // }));
        //in order
        let available=transferedAmount;
        selected.forEach(p=>{
          installmentPremiums.push({
            lifePolicyId: p.lifePolicyId,
            payPlanId: p.id,
            dueAmount: round2(p.pendingAmount),
            moneyInAmount: round2(available>=p.pendingAmount?p.pendingAmount:available),
            transitAmount: round2(available>=p.pendingAmount?0:p.pendingAmount-available),
            compensationAmount: 0,
            currency: p.currency,
          });
          available=Math.max(available-p.pendingAmount,0);
        })
      }
      props.form.setFieldsValue({
        InstallmentPremiums: installmentPremiums,
        SupplementaryPremiums: supplementaryPremiums,
        currency: selected[0].currency,
      });
      computeTotals(installmentPremiums, supplementaryPremiums);
    }
  };

  const computeTotals = (installmentPremiums = [], supplementaryPremiums = []) => {
    let currency = installmentPremiums[0] ? installmentPremiums[0].currency : undefined;
    if (!currency) currency = supplementaryPremiums[0] ? supplementaryPremiums[0].currency : undefined;
    if (!currency) currency = selectedTransfers&&selectedTransfers[0] ? selectedTransfers[0].currency : undefined;
    const transferedAmount = round2(props.form.getFieldValue("transferAmount"));
    const fromTransitAmount = round2(installmentPremiums.reduce((sum, x) => sum + x.transitAmount, 0));
    const compensationAmount = round2(installmentPremiums.reduce((sum, x) => sum + x.compensationAmount, 0));

    let dueAmount = round2(installmentPremiums.reduce((sum, x) => sum + x.dueAmount, 0));
    if (isNaN(dueAmount)) dueAmount = 0;
    const premiumMoneyInAmount = round2(installmentPremiums.reduce((sum, x) => sum + x.moneyInAmount, 0));

    const supplementaryAmount = round2(supplementaryPremiums.reduce((sum, x) => sum + x.moneyInAmount, 0));
    const toDuePremiums = round2(premiumMoneyInAmount + fromTransitAmount + compensationAmount);

    props.form.setFieldsValue({
      currency: currency,
      fromTransitAmount: fromTransitAmount,
      premiumAmount: premiumMoneyInAmount,
      supplementaryAmount: supplementaryAmount,
      compensationAmount: compensationAmount,
      differenceAmount: round2(transferedAmount - premiumMoneyInAmount - supplementaryAmount),
      premiumDifferenceAmount: round2(toDuePremiums - dueAmount),
    });
  };

  const onNewAllocationTask = (draft) => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if ((values.InstallmentPremiums || []).some((p) => p.moneyInAmount == 0 && p.transitAmount == 0&&p.compensationAmount==0)) {
        message.error(t("Premiums must have at least a money source greater than zero"));
        return;
      }
      if ((values.SupplementaryPremiums || []).some((p) => p.moneyInAmount == 0 && p.transitAmount == 0)) {
        message.error(t("Supplementary premiums must have at least a money source greater than zero"));
        return;
      }
      if ((values.InstallmentPremiums || []).length == 0 && (values.SupplementaryPremiums || []).length == 0) {
        message.error(t("Please select installment or supplementary premiums"));
        return;
      }
      if ((values.InstallmentPremiums || []).some((p) => !p.lifePolicyId)) {
        message.error(t("Please select policy on all installment premium lines"));
        return;
      }
      if ((values.SupplementaryPremiums || []).some((p) => (!p.lifePolicyId&&!p.accountId) || !p.destination)) {
        message.error(t("Please insert all fields of supplementary premium lines"));
        return;
      }
      //if some SupplementaryPremiums are ACCOUNT, we set their lifePolicyId to 0
      values.SupplementaryPremiums=values.SupplementaryPremiums.map(p=>({...p,lifePolicyId:p.destination==="ACCOUNT"?0:p.lifePolicyId}));
      
      if(props.workspaceId) values.transferWorkspaceId=props.workspaceId; //if deon from transfer workspace
      const policyId = props.form.getFieldValue("policyId");
      values.Transfers = selectedTransfers;
      values.Premiums = selectedPremiums;
      setLoading(true);
      props.setLoading(true);
      exe("DoPaymentAllocation", { entity: values, draft: draft, policyId: policyId }).then((r) => {
        setLoading(false);
        props.setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          if (draft) {
            props.onDraftAllocation(r.outData);
          } else {
            props.onAllocation();
          }
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onDraftAllocationTask = () => {
    onNewAllocationTask(true);
  };
  const onOptionPayNextInstallment=()=>{
    //removes supplementary amounts ant pays next installment
    const supAmount=(props.form.getFieldValue("SupplementaryPremiums")||[]).reduce((p,c)=>c.moneyInAmount+p,0);
    if(supAmount<=0){
      message.error(t("Not enough supplementary amount"));
      return;
    }
    const premiums=props.form.getFieldValue("InstallmentPremiums");
    if(premiums.length===0){
      message.error(t("Please select at least a main installment"));
      return;
    }
    const installment=premiums[premiums.length-1];
    //getting next
    setLoading(true);
    exe("GetNextInstallment",{payPlanId:installment.payPlanId}).then(r=>{
      setLoading(false);
      if(r.ok){
        const nextInstallment=r.outData;
        const newInstallmentPremiumAllocation={
          lifePolicyId:nextInstallment.lifePolicyId,
          payPlanId:nextInstallment.id,
          dueAmount:nextInstallment.pendingAmount,
          moneyInAmount:supAmount,
          transitAmount:0,
          compensationType:"PARTIAL",
          compensationAmount:nextInstallment.pendingAmount-supAmount,
          currency:nextInstallment.currency
        }
        console.log("adding",newInstallmentPremiumAllocation)
        props.form.setFieldsValue({SupplementaryPremiums:[],InstallmentPremiums:[...premiums,newInstallmentPremiumAllocation]})
      }else message.error(r.msg)
    })
  }
    const completeWithCommission=()=>{
      const premiums=props.form.getFieldValue("InstallmentPremiums");
      //getting available commissions for the selected premiums, using distinct policy ids
      setLoading(true);
      exe("GetAvailableCommission",{policyIds:[...new Set(premiums.map(p=>p.lifePolicyId))],currency:props.form.getFieldValue("currency")}).then(r=>{
        setLoading(false);
        if(r.ok){
          const availableCommission=r.outData.commission;
          console.log(premiums,"Premiums",availableCommission,"Available Commission");
          //completing premiums with available commission
          const newPremiums=premiums.map(p=>{
            return {...p,
              compensationType:(p.dueAmount-p.moneyInAmount)>0?"COMMISSION":p.compensationType,
              compensationAmount:p.dueAmount-p.moneyInAmount,
              transitAmount:+0,
              moneyInAmount:p.moneyInAmount?p.moneyInAmount:+0,
            }
          });
          
          //setting new premiums
          props.form.setFieldsValue({InstallmentPremiums:newPremiums});

          //computing totals
          computeTotals(newPremiums,props.form.getFieldValue("SupplementaryPremiums"));
          
          //checking if there is enough commission by adding compensation amounts
          const totalCompensation=newPremiums.reduce((p,c)=>c.compensationAmount+p,0);
          if(totalCompensation>availableCommission){
            message.warn(t("Available commission -> {{commission}} is not enough to complete the allocation", {commission:availableCommission}));
            return;
          }
          
        }else message.error(r.msg)
      }
      )
    }

  const field = props.form.getFieldDecorator;
  const currency = props.form.getFieldValue("currency") || undefined;
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };
  return (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={18}>
            <Section text={t("Installment Premiums")}>
              {field("InstallmentPremiums")(
                <InstallmentPremiumAllocation currency={currency} onChange={(v) => computeTotals(v, props.form.getFieldValue("SupplementaryPremiums"))} completeWithCommission={completeWithCommission} />
              )}
            </Section>
            <Section text={t("Supplementary Premiums")}>
              {field("SupplementaryPremiums")(
                <SupplementaryPremiumAllocation currency={currency} onChange={(v) => computeTotals(props.form.getFieldValue("InstallmentPremiums"), v)} 
                                                onPayNextInstallment={onOptionPayNextInstallment} differenceAmount={props.form.getFieldValue("differenceAmount")} />
              )}
            </Section>
          </Col>
          <Col span={6}>
            <Section text={t("Summary")}>
              <div style={{ width: 300 }}>
                <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
                <Form.Item label={t("Transaction Date")}>{field("transactionDate", { initialValue: new moment() })(<DatePickerW disabledDate={disabledDate} />)}</Form.Item>

                <Form.Item label={t("From Money In")}>{field("transferAmount")(<Money currency={currency} disabled />)}</Form.Item>
                <Form.Item label={t("From Transit")}>{field("fromTransitAmount")(<Money currency={currency} disabled />)}</Form.Item>
                <Form.Item label={t("From Other Sources")}>
                  {field("compensationAmount", { initialValue: 0 })(<Money currency={currency} disabled />)}
                </Form.Item>

                <Form.Item label={t("To Installment Premiums")}>{field("premiumAmount")(<Money currency={currency} disabled />)}</Form.Item>
                <Form.Item label={t("To Supplementary Premiums")}>{field("supplementaryAmount")(<Money currency={currency} disabled />)}</Form.Item>

                <Form.Item label={t("Allocation Difference")}>{field("differenceAmount")(<Money currency={currency} disabled />)}</Form.Item>
                <Form.Item label={t("Premium Difference")}>{field("premiumDifferenceAmount")(<Money currency={currency} disabled />)}</Form.Item>
              </div>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => onDraftAllocationTask()}
                loading={loading}
                disabled={
                  (!props.form.getFieldValue("InstallmentPremiums") && !props.form.getFieldValue("SupplementaryPremiums")) ||
                  props.form.getFieldValue("differenceAmount") !== 0 ||
                  props.form.getFieldValue("premiumDifferenceAmount") !== 0
                }>
                {t("Compute")}
              </Button>
            </Section>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(AllocationTask);
