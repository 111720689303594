import { Dropdown, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getQueryParam, setQueryParam } from "../../Lib/Dal";
const EnvironmentLocationSelect = () => {
    const [currentEnvironment,setCurrentEnvironment]=useState("main");
    const BACKUPURL = "https://sisos-api-iconx.axxis-systems.net";
    const [t]=useTranslation();

    useEffect(()=>{
      const currentEnvironment=checkCurrentEnvironment();
      setCurrentEnvironment(currentEnvironment);
        
      //check if the url contains a environment parameter
      const backup = getQueryParam("backup");
      if(backup==="true"){
        if(!hasBackupServer){
          message.error(t("This environment does not have a backup server"));
          //removing the environment parameter from the url
          setQueryParam("backup",false,{remove:true});
        }else{
          localStorage.sis11api = BACKUPURL;
          setQueryParam("backup",false,{remove:true});
          if(currentEnvironment!=="backup") window.location.reload();
        }
      }
    },[window.location.hash])
    const hasBackupServer = window.location.href.includes("axxis-systems.net");

    const items=[
        {
          key: "main",
          label: t("Primary Location"),
        },
        {
          key: "backup",
          label: t("Backup Location"),
          disabled: !hasBackupServer,
        }
      ]
      const checkCurrentEnvironment=()=>{
        if(localStorage.sis11api) return "backup";
        else return "main";
      }
      const onClick = (key) => {
        switch(key){
            case "main":
                localStorage.removeItem("sis11api");
                break;
            case "backup":
                localStorage.sis11api = BACKUPURL;
                break;
        }
        //checking if reload is needed
        if(currentEnvironment!==key) window.location.reload();
      }
    return <div>
               
    <Dropdown
    menu={{
    items,
    selectable: true,
    selectedKeys: [currentEnvironment],
    onClick: ({ key }) => {
      onClick(key);
    },
    }}>
    <Typography.Link>
      <Space>
        {t("Environment Location")}
        <DownOutlined />
      </Space>
    </Typography.Link>
  </Dropdown>
    </div>
}
export default EnvironmentLocationSelect;