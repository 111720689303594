/**
 * Mock services for bank reconciliation
 * These functions simulate backend functionality
 */

import { exe } from '../../Lib/Dal';

// Mock internal transactions for matching against imported data
const mockInternalTransactions = [
  { 
    id: 'int-001', 
    date: '2023-05-01', 
    description: 'Supplier Payment - ABC Corp', 
    amount: -1250.75, 
    type: 'Outgoing', 
    reference: 'INV-2023-001' 
  },
  { 
    id: 'int-002', 
    date: '2023-05-01', 
    description: 'Monthly Subscription', 
    amount: -49.99, 
    type: 'Outgoing', 
    reference: 'SUB-2023-MAY' 
  },
  { 
    id: 'int-003', 
    date: '2023-05-02', 
    description: 'Customer Payment - XYZ Ltd', 
    amount: 2500.00, 
    type: 'Incoming', 
    reference: 'CUS-2023-005' 
  },
  { 
    id: 'int-004', 
    date: '2023-05-03', 
    description: 'Service Fee', 
    amount: -125.00, 
    type: 'Outgoing', 
    reference: 'FEE-2023-MAY' 
  },
  { 
    id: 'int-005', 
    date: '2023-05-04', 
    description: 'Customer Payment - ABC Services', 
    amount: 1750.50, 
    type: 'Incoming', 
    reference: 'CUS-2023-006' 
  },
  { 
    id: 'int-006', 
    date: '2023-05-05', 
    description: 'Office Supplies', 
    amount: -89.99, 
    type: 'Outgoing', 
    reference: 'EXP-2023-010' 
  },
  { 
    id: 'int-007', 
    date: '2023-05-06', 
    description: 'Customer Payment - DEF Inc', 
    amount: 3200.00, 
    type: 'Incoming', 
    reference: 'CUS-2023-007' 
  },
  { 
    id: 'int-008', 
    date: '2023-05-07', 
    description: 'Insurance Premium', 
    amount: -450.00, 
    type: 'Outgoing', 
    reference: 'INS-2023-MAY' 
  },
  { 
    id: 'int-009', 
    date: '2023-05-08', 
    description: 'Customer Payment - GHI Consultants', 
    amount: 1200.00, 
    type: 'Incoming', 
    reference: 'CUS-2023-008' 
  },
  { 
    id: 'int-010', 
    date: '2023-05-09', 
    description: 'Utility Bill', 
    amount: -175.25, 
    type: 'Outgoing', 
    reference: 'UTIL-2023-MAY' 
  }
];

// Mock storage for saved reconciliations
let savedReconciliations = [
  {
    id: 'rec-001',
    name: 'May 2023 Bank Reconciliation',
    date: '2023-05-15',
    status: 'Executed',
    transactionCount: 47,
    matchedCount: 42,
    unmatchedCount: 5,
    totalAmount: 12750.85,
    bankAccount: 'Business Checking Account',
    createdBy: 'John Smith',
    executedDate: '2023-05-16'
  },
  {
    id: 'rec-002',
    name: 'June 2023 Bank Reconciliation',
    date: '2023-06-14',
    status: 'Saved',
    transactionCount: 53,
    matchedCount: 48,
    unmatchedCount: 5,
    totalAmount: 15420.30,
    bankAccount: 'Business Checking Account',
    createdBy: 'Maria Rodriguez',
    executedDate: null
  },
  {
    id: 'rec-003',
    name: 'Quarterly Account Review',
    date: '2023-07-02',
    status: 'Executed',
    transactionCount: 125,
    matchedCount: 120,
    unmatchedCount: 5,
    totalAmount: 42850.75,
    bankAccount: 'Business Savings Account',
    createdBy: 'John Smith',
    executedDate: '2023-07-05'
  }
];

// Function to generate a simple ID
const generateId = () => {
  return 'txn-' + Math.random().toString(36).substr(2, 9);
};

// Function to generate a reconciliation ID
const generateReconciliationId = () => {
  return 'rec-' + Math.random().toString(36).substr(2, 9);
};

// Simple similarity function for descriptions (for demo purposes)
const calculateSimilarity = (str1, str2) => {
  const s1 = str1.toLowerCase();
  const s2 = str2.toLowerCase();
  
  // Very simple similarity check - in a real app, you would use a better algorithm
  const commonChars = s1.split('').filter(char => s2.includes(char)).length;
  const avgLength = (s1.length + s2.length) / 2;
  
  return Math.floor((commonChars / avgLength) * 100);
};

// Mock reconciliation service
export const reconciliationService = {
  /**
   * Get all reconciliations
   */
  getAllReconciliations: () => {
    return new Promise((resolve, reject) => {
      exe('RepoReconciliation', { 
        operation: 'GET',
        include: ['Account']  // Include Account data
      }).then(response => {
        if (response.ok) {
          // Ensure each reconciliation has the account data
          const reconciliations = response.outData.map(rec => ({
            ...rec,
            account: rec.Account || null,  // Map Account to account for consistency
            bankAccount: rec.Account?.name || ''  // Use Account name as bankAccount
          }));
          resolve(reconciliations);
        } else {
          reject(response.msg);
        }
      });
    });
  },

  /**
   * Get reconciliation by ID
   */
  getReconciliationById: (id) => {
    return new Promise((resolve, reject) => {
      exe('RepoReconciliation', { 
        operation: 'GET',
        filter: `id=${id}`,
        include: ['Account']
      }).then(response => {
        if (response.ok && response.outData && response.outData.length > 0) {
          const reconciliation = response.outData[0];
          // Parse the jData if it exists
          if (reconciliation.jData) {
            try {
              const parsedData = JSON.parse(reconciliation.jData);
              // Ensure we have the correct data structure
              reconciliation.importedData = parsedData.importedData || [];
              reconciliation.reconciliationResults = {
                matched: (parsedData.matched || []).map(item => ({
                  ...item,
                  key: item.key || item.id || `matched-${item.date}-${item.amount}`
                })),
                unmatched: (parsedData.unmatched || []).map(item => ({
                  ...item,
                  key: item.key || item.id || `unmatched-${item.date}-${item.amount}`
                }))
              };
            } catch (error) {
              console.error('Error parsing jData:', error);
              // Initialize with empty arrays if parsing fails
              reconciliation.importedData = [];
              reconciliation.reconciliationResults = { matched: [], unmatched: [] };
            }
          } else {
            // Initialize with empty arrays if no jData
            reconciliation.importedData = [];
            reconciliation.reconciliationResults = { matched: [], unmatched: [] };
          }
          resolve(reconciliation);
        } else {
          reject(response.msg || 'Reconciliation not found');
        }
      });
    });
  },

  /**
   * Save reconciliation
   */
  saveReconciliation: (reconciliationData) => {
    // Prepare the data structure according to the C# model
    const { name, bankAccount, importedData, reconciliationResults } = reconciliationData;
    
    // Create serialized data for jData field
    const jData = JSON.stringify({
      importedData: importedData,
      matched: reconciliationResults.matched,
      unmatched: reconciliationResults.unmatched
    });
    
    // Calculate total amount from matched transactions only
    const totalAmount = reconciliationResults.matched.reduce(
      (sum, item) => sum + (parseFloat(item.amount) || 0), 
      0
    );
    
    const entity = {
      id: 0, // New record
      name: name,
      status: 0, // 0 = pending
      accountId: bankAccount,
      transactionCount: importedData.length, // Total number of transactions from imported data
      matchedCount: reconciliationResults.matched.length,
      unmatchedCount: reconciliationResults.unmatched.length,
      totalAmount: totalAmount, // Use the calculated total from matched transactions
      createdBy: 'Current User',
      createdDate: new Date().toISOString(),
      jData: jData
    };
    
    return new Promise((resolve, reject) => {
      exe('RepoReconciliation', { 
        operation: 'ADD',
        entity: entity
      }).then(response => {
        if (response.ok && response.outData && response.outData.length > 0) {
          const savedRec = response.outData[0];
          // Add the parsed data back to the object
          savedRec.importedData = importedData;
          savedRec.reconciliationResults = reconciliationResults;
          resolve(savedRec);
        } else {
          reject(response.msg || 'Failed to save reconciliation');
        }
      });
    });
  },

  /**
   * Execute reconciliation
   */
  executeReconciliation: (id) => {
    return new Promise((resolve, reject) => {
      // Simply call the DoReconciliation command with only the reconciliation ID
      // It will read matched rows from the saved serialized data in the database
      exe('DoReconciliation', {
        reconciliationId: id
      }).then(execResponse => {
        if (execResponse.ok) {
          // Update reconciliation status
          exe('RepoReconciliation', { 
            operation: 'GET',
            filter: `id=${id}`,
            include: ['Account']
          }).then(getResponse => {
            if (getResponse.ok && getResponse.outData && getResponse.outData.length > 0) {
              const reconciliation = getResponse.outData[0];
              const updatedEntity = {
                ...reconciliation,
                status: 1, // 1 = executed
                executedDate: new Date().toISOString()
              };
              
              exe('RepoReconciliation', { 
                operation: 'UPDATE',
                entity: updatedEntity
              }).then(updateResponse => {
                if (updateResponse.ok && updateResponse.outData && updateResponse.outData.length > 0) {
                  const executedRec = updateResponse.outData[0];
                  
                  // Add the parsed data back to the object with consistent structure
                  if (reconciliation.jData) {
                    try {
                      const parsedData = JSON.parse(reconciliation.jData);
                      executedRec.importedData = parsedData.importedData || [];
                      executedRec.reconciliationResults = {
                        matched: (parsedData.matched || []).map(item => ({
                          ...item,
                          key: item.key || item.id || `matched-${item.date}-${item.amount}`
                        })),
                        unmatched: (parsedData.unmatched || []).map(item => ({
                          ...item,
                          key: item.key || item.id || `unmatched-${item.date}-${item.amount}`
                        }))
                      };
                    } catch (error) {
                      console.error('Error parsing jData:', error);
                      // Initialize with empty arrays if parsing fails
                      executedRec.importedData = [];
                      executedRec.reconciliationResults = { matched: [], unmatched: [] };
                    }
                  } else {
                    // Initialize with empty arrays if no jData
                    executedRec.importedData = [];
                    executedRec.reconciliationResults = { matched: [], unmatched: [] };
                  }
                  
                  resolve(executedRec);
                } else {
                  reject(updateResponse.msg || 'Failed to update reconciliation status');
                }
              });
            } else {
              reject(getResponse.msg || 'Failed to retrieve reconciliation after execution');
            }
          });
        } else {
          reject(execResponse.msg || 'Failed to execute reconciliation');
        }
      });
    });
  },

  /**
   * Delete reconciliation
   */
  deleteReconciliation: (id) => {
    return new Promise((resolve, reject) => {
      // First get the reconciliation
      exe('RepoReconciliation', { 
        operation: 'GET',
        filter: `id=${id}`
      }).then(response => {
        if (response.ok && response.outData && response.outData.length > 0) {
          const reconciliation = response.outData[0];
          
          // Then delete it
          exe('RepoReconciliation', { 
            operation: 'DELETE',
            entity: reconciliation
          }).then(deleteResponse => {
            if (deleteResponse.ok) {
              resolve(deleteResponse);
            } else {
              reject(deleteResponse.msg || 'Failed to delete reconciliation');
            }
          });
        } else {
          reject(response.msg || 'Reconciliation not found');
        }
      });
    });
  },

  /**
   * Process imported data and identify matches
   */
  processReconciliation: (importedData) => {
    // Map imported data to match AccountMov structure
    const mappedData = importedData.map((item, index) => ({
      id: `txn-${Math.random().toString(36).substring(2, 10)}`,
      date: item.date,
      transaction: item.transaction || item.description || '', // Support both field names
      amount: parseFloat(item.amount) || 0,
      // We're removing transactionCode/type requirement
    }));
    
    // Simple matching algorithm based ONLY on date and amount
    const matched = [];
    const unmatched = [];
    
    // Check each mapped transaction
    mappedData.forEach(transaction => {
      // A transaction is considered matched if it has valid date and amount
      if (
        transaction.date && 
        transaction.amount !== undefined && 
        transaction.amount !== null && 
        !isNaN(transaction.amount)
      ) {
        matched.push(transaction);
      } else {
        unmatched.push(transaction);
      }
    });
    
    return {
      matched: matched,
      unmatched: unmatched
    };
  },

  /**
   * Manual match function
   */
  manualMatch: (selectedItems, unmatched) => {
    const matchedItems = unmatched.filter(item => selectedItems[item.id]);
    return {
      matchedCount: matchedItems.length,
      matchedItems: matchedItems
    };
  },
  
  /**
   * Get system-maintained account data
   * This would be replaced with an API call in a real application
   */
  getAccountData: () => {
    return mockInternalTransactions;
  }
}; 