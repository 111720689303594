import { Modal, Progress, Space, Timeline } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
const ProgressWindow=props=>{
    const [data,setData]=useState([]);
    const [visible,setVisible]=useState(false)
    const [percent,setPercent]=useState(0)
    const [t]=useTranslation();
    useEffect(()=>{
        load();
    },[]);
    const load=()=>{
        console.log("Loading progress window");
        window.progressES = new EventSource(config.ssApiUrl + "/stream?session="+encodeURIComponent(localStorage.token));
        window.progressES.addEventListener("progress",ev=>{
            //setData(current=>[...current,ev.data])
            const info=JSON.parse(ev.data);
            if(info.msg==="Command Start") {
                setPercent(0);
                setVisible(true);}
            else if(info.msg==="Command End"){
                setPercent(100);
                setVisible(false);
            } 
            else {
                if(info.percent!=null)
                    setPercent(info.percent);
                else
                    setPercent(p=>p+1);
                setData([...data,info.msg]) //only current message
            }
        })
    }
    return <Modal visible={visible} title={<Space><LoadingOutlined/>{t("Command Progress")}</Space>} footer={null} onCancel={()=>setVisible(false)} mask={false} forceRender zIndex={9999} >
        <Progress percent={percent} status="active" />
        <pre style={{ whiteSpace: 'pre-wrap' }}>{data.join("\n")}</pre>
        {/* <Timeline>{data.map((d,i)=><Timeline.Item key={i}>{d}</Timeline.Item>)}</Timeline> */}
    </Modal>

}
export default ProgressWindow;