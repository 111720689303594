import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { safeValue } from '../../Lib/Dal';

const InputChangeCode = (props) => {
    const { t } = useTranslation();
    const [hasChangeCode, setHasChangeCode] = useState(false);

    useEffect(()=>{
        if(props.common.config){
            const changeCode = safeValue("Main.changeCode", props.common.config, false);
            setHasChangeCode(changeCode);
        }
    },[props.common]);
    return (
        <Input value={props.value} onChange={props.onChange} placeholder={hasChangeCode ? t("Auto generated code on save") : t("Optional manual code")} disabled={hasChangeCode} />
    );
};

export default InputChangeCode;