import React, { useState, useEffect } from "react";
import { CheckCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Steps,
  message,
  Result,
  Collapse,
  Popconfirm,
  Select,
  Radio,
  Alert,
} from "antd";
import Section from "../Shared/Section";
import Billing from "./Bill";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import Bill from "./Bill";
import { exe, safeParse } from "../../Lib/Dal";
import moment from "moment";
import { load } from "js-yaml";
import MultiContactSelect from "../Contact/MultiContactSelect";
import BillUnique from "./BillUnique";
import InvestmentPlanSelect from "../InvestmentPlans/InvestmentPlanSelect";
import { formatDate } from "../../Lib/Helpers";
import DetailViewer from "./DetailViewer";
import { useTranslation } from "react-i18next";
import CommonChangeSection from "./CommonChangeSection";
import DatePickerW from "../Shared/DatePickerW";
import InputChangeCode from "./InputChangeCode";
const ChangePaidUp = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => setValues(props.value), props.value);
  useEffect(() => {if(props.common.customEffect!==undefined) props.common.customEffect(setStep)}, [props.common.customEffectDeps]);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  const currentInvestment = props.currentInvestment;
  const policyId = props.policyId;
  const Accounts = props.Accounts || [];

  const onQuote = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        setLoading(true);
        exe("ChangePaidUp", {
          ...values, policyId: policyId,
          ...values,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        if (operation == "UPDATE") {
          onUpdate(values);
          return;
        }
        setLoading(true);
        exe("ChangePaidUp", {
          ...values, policyId: policyId,
          operation: "ADD",
          ...values,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
            setResult({ status: "success", title: t("Successfully Saved Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onUpdate = (values) => {
    setLoading(true);
    exe("ChangePaidUp", { Entity: values, operation: "UPDATE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData);
        setStep(step + 1);
        setResult({ status: "success", title: t("Successfully Updated Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ExeChangePaidUp", { changeId: values.id, operation: "EXECUTE" }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setValues(r.outData);
          setResult({
            status: "success",
            title: r.outData.status == 1 ? t("Successfully Executed Policy Change") : t("Policy Change Scheduled"),
            subTitle:
              t("Change number") +
              ":" +
              r.outData.id +
              (r.outData.status == 1
                ? t(". Please refresh policy or click on 'Change List'")
                : t("Execution date") + ":" + formatDate(r.outData.effectiveDate)),
          });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const setValues = (r) => {
    props.form.setFieldsValue(r);
    if (r.status == 0) {
      setResult({ status: "info", title: t("Saved Policy Change Ready To Be Executed"), subTitle: t("Change number") + ":" + r.id });
    } else if (r.status == 1 || r.status == 3) {
      setResult({ status: "info", title: t("Policy Change Executed"), subTitle: t("Change Execution Date") + ":" + r.executionDate });
      setReadOnly(true);
    }
  };

  const netTotalSurrenderValue = props.form.getFieldValue("netTotalSurrenderValue");

  return (
    <div style={{ marginTop: 10 }}>
      <Form layout="vertical">
        <Steps current={step} onChange={(v) => setStep(v)} style={{ margin: 5, marginBottom: 15 }}>
          <Steps.Step title={t("Change")} description={t("Change options")} />
          <Steps.Step title={t("Quote")} description={t("Cost of changes")} />
          <Steps.Step title={t("Result")} description={t("Execution")} />
        </Steps>
        <Row gutter={16} hidden={step !== 0}>
          <Col span={12}>
            <Section text={t("Change Options")}>
              {!netTotalSurrenderValue && <Alert message={t("Please quote to calculate values")} type="info" showIcon />}
              {/* CHANGE OPTIONS ***********************************************************************/}
              {/* <Form.Item label="Net total surrender value">{field("netTotalSurrenderValue", {})(<Money disabled currency={props.currency} />)}</Form.Item>
              <Form.Item label="Reduced sum insured">{field("reducedSum", {})(<Money disabled currency={props.currency} />)}</Form.Item> */}
              <Form.Item label={t("Effective Date")}>{field("effectiveDate", { initialValue: moment() })(<DatePickerW />)}</Form.Item>
             <CommonChangeSection t={t} form={props.form} common={props.common} change={props.value}  /> 
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
            <Button style={{ marginTop: 5 }} type="primary" onClick={onQuote} loading={loading} disabled={readOnly}>
              {t("Quote")}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 1}>
          <Col span={16}>
            <Collapse defaultActiveKey={["calculationDetail"]}>
              <Collapse.Panel header={t("Detail")} key="calculationDetail">
                <Form.Item>{field("jDetail", {})(<DetailViewer />)}</Form.Item>
              </Collapse.Panel>
              <Collapse.Panel header={t("Change Bill")} key="changeBill">
                <Form.Item>{field("BillUnique", {})(<BillUnique currency={currency} />)}</Form.Item>
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col span={8}>
            <Section text={t("Options")}>
              <Form.Item label={t("Id")}>{field("id", {})(<Input disabled />)}</Form.Item>
              <Form.Item label={t("Code")}>{field("code", {})(<InputChangeCode common={props.common} />)}</Form.Item>
              <Form.Item label={t("Note")}>
                {field("note", {})(<Input.TextArea rows={4} placeholder={t("Optional printable change note or remark")} />)}
              </Form.Item>
              <div style={{ visibility: "hidden" }}>{field("lifePolicyId", {})(<Input disabled />)}</div>
              <div style={{ marginTop: 20 }}>
                <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={() => setStep(step - 1)}>
                  {t("Back")}
                </Button>
                <Button type="primary" icon={<SaveOutlined />} style={{ marginRight: 5 }} onClick={onSave} loading={loading} disabled={readOnly}>
                  {t("Save")}
                </Button>
              </div>
            </Section>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 2}>
          <Col span={24}>
            <Result
              {...result}
              extra={[
                <Button key="console" onClick={() => props.reload()}>
                  {readOnly ? t("Change List") : t("Execute later")}
                </Button>,
                <Popconfirm
                  title={t("Are you sure you want to execute this policy change?")}
                  onConfirm={onExecute}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  disabled={readOnly}>
                  <Button icon={<CheckCircleOutlined />} type="primary" style={{ marginRight: 5 }} disabled={readOnly} loading={loading}>
                    {t("Execute")}
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ChangePaidUp);
