import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Form, 
  Input, 
  Button, 
  Select, 
  DatePicker, 
  Divider, 
  Row, 
  Col, 
  InputNumber, 
  Switch,
  Typography
} from 'antd';
import { 
  UserOutlined, 
  SearchOutlined, 
  TagsOutlined, 
  TeamOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';
import UserSelect from '../Bpm/UserSelect';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

// Mock data for dropdown options
const mockStages = [
  { id: 1, name: 'Lead' },
  { id: 2, name: 'Qualified' },
  { id: 3, name: 'Proposal' },
  { id: 4, name: 'Negotiation' },
  { id: 5, name: 'Closed Won' },
  { id: 6, name: 'Closed Lost' }
];

const mockUsers = [
  { id: 1, name: 'Sarah' },
  { id: 2, name: 'Mike' },
  { id: 3, name: 'Lisa' }
];

const mockTags = [
  'VIP', 'Tech', 'Retail', 'Manufacturing', 'Enterprise', 
  'Financial', 'Consulting', 'Healthcare', 'Education'
];

const FunnelFilter = ({ visible, onClose, onSearch }) => {
  const [form] = Form.useForm();
  const [t] = useTranslation();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // If needed, load stages and tags for filter options when modal opens
    if (visible) {
      loadFilterOptions();
    }
  }, [visible]);

  const loadFilterOptions = () => {
    setLoading(true);
    
    // Load all stages for filter options
    exe("RepoFunnelStage", {
      operation: "GET",
    }).then(r => {
      if (r.ok && r.outData) {
        setStages(r.outData);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
    
    // Load unique tags from contacts
    exe("RepoFunnelContact", {
      operation: "GET",
    }).then(r => {
      if (r.ok && r.outData) {
        // Extract all tags from contacts
        const allTags = new Set();
        r.outData.forEach(contact => {
          if (contact.tags) {
            contact.tags.split(',').filter(Boolean).forEach(tag => {
              allTags.add(tag.trim());
            });
          }
        });
        setTags(Array.from(allTags));
      }
    });
  };

  const handleSearch = () => {
    form.validateFields()
      .then(values => {
        onSearch(values);
        onClose();
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={
        <div>
          <SearchOutlined style={{ marginRight: 8 }} />
          {t('Filter Pipeline')}
        </div>
      }
      open={visible}
      onCancel={onClose}
      width={700}
      footer={[
        <Button key="reset" onClick={handleReset}>
          {t('Reset')}
        </Button>,
        <Button key="cancel" onClick={onClose}>
          {t('Cancel')}
        </Button>,
        <Button key="search" type="primary" onClick={handleSearch} icon={<SearchOutlined />}>
          {t('Apply Filter')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          showClosedDeals: true
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="searchText" label={t('Search Text')}>
              <Input 
                prefix={<SearchOutlined />} 
                placeholder={t('Search by name, company, email or phone')}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="stages" label={t('Stages')}>
              <Select
                mode="multiple"
                placeholder={t('Select stages')}
                allowClear
              >
                {stages.map(stage => (
                  <Option key={stage.id} value={stage.id}>
                    {stage.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="assignedTo" label={t('Assigned To')}>
              <UserSelect 
                filterByGroups={true}
                value={form.getFieldValue('assignedTo')}
                onChange={value => form.setFieldsValue({ assignedTo: value })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="tags" label={t('Tags')}>
              <Select
                mode="multiple"
                placeholder={t('Select tags')}
                allowClear
              >
                {tags.map(tag => (
                  <Option key={tag} value={tag}>
                    {tag}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="createdDateRange" label={t('Created Date')}>
              <RangePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Divider>
          <a onClick={() => setAdvancedMode(!advancedMode)}>
            {advancedMode ? t('Basic Filter') : t('Advanced Filter')}
          </a>
        </Divider>

        {advancedMode && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="valueRange" label={t('Deal Value Range')}>
                  <Input.Group compact>
                    <Form.Item name={['valueRange', 'min']} noStyle>
                      <InputNumber
                        style={{ width: '45%' }}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder={t('Min')}
                      />
                    </Form.Item>
                    <Input
                      style={{ width: '10%' }}
                      placeholder="~"
                      disabled
                    />
                    <Form.Item name={['valueRange', 'max']} noStyle>
                      <InputNumber
                        style={{ width: '45%' }}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder={t('Max')}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="probabilityRange" label={t('Probability Range')}>
                  <Input.Group compact>
                    <Form.Item name={['probabilityRange', 'min']} noStyle>
                      <InputNumber
                        style={{ width: '45%' }}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        placeholder={t('Min')}
                      />
                    </Form.Item>
                    <Input
                      style={{ width: '10%' }}
                      placeholder="~"
                      disabled
                    />
                    <Form.Item name={['probabilityRange', 'max']} noStyle>
                      <InputNumber
                        style={{ width: '45%' }}
                        min={0}
                        max={100}
                        formatter={value => `${value}%`}
                        parser={value => value.replace('%', '')}
                        placeholder={t('Max')}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="lastActivityRange" label={t('Last Activity Date')}>
                  <RangePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="showClosedDeals" label={t('Show Closed Deals')} valuePropName="checked">
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default FunnelFilter; 