import React from 'react';
import {Tooltip} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

const LongTextColumn = (props) => {
    return (
        <div style={{display:"inline-block",whiteSpace:"pre-line", overflowWrap:"break-word"}}>
            <Tooltip title={props.value} overlayStyle={{maxWidth: "50%", whiteSpace:"pre-line"}} trigger={props.trigger} >
                <Paragraph style={{fontSize:props.fontSize}}  ellipsis={{ rows: props.rows, expandable:props.expandable, symbol: props.symbol}}>
                    {props.value}
                </Paragraph>
            </Tooltip>
        </div>
    );
};

export default LongTextColumn;