import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Tabs, Menu, Modal, Table, Button, message, Tag, Tooltip } from 'antd';
import { AppstoreOutlined, FilterOutlined, FolderOpenOutlined, MinusSquareOutlined, PlusSquareOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import VisorCalor from './VisorCalor';
import DefaultPage from './Shared/DefaultPage';
import { exe } from '../Lib/Dal';
import VisorInstances from './Bpm/VisorInstances';
import { formatDate, formatDateFromNow, formatString } from '../Lib/Helpers';
import { Link } from 'react-router-dom';
import DraggableModal from './Common/DraggableModal';

const { TabPane } = Tabs;
const { Column } = Table;

const ProcessAnalysis = (props) => {
  const [t] = useTranslation()
  const [loadVisible, setLoadVisible] = useState(false);
  const [xml, setXml] = useState(null);
  const [stats, setStats] = useState(null);
  const [selected, setSelected] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [instances, setInstances] = useState([]);
  const [filterByEstadoId, setFilterByEstadoId] = useState(undefined);
  const [hideTabs, setHideTabs] = useState(false);

  useEffect(() => {
    getDefinitions();
  }, []);

  useEffect(() => {
    //whem a definition is selectd, change the url
    if (selected.length > 0) {
      props.history.push(`/processAnalysis/${selected[0].id}`);
    } else {
      props.history.push(`/processAnalysis`);
    }
  }, [selected]);

  const getDefinitions = () => {
    exe("GetDefinitions", { getInstances: true }).then((r) => {
      if (r.ok) {
        setDefinitions(r.outData);
        //if a definiitonId is passed in the url, select it
        if (props.match.params.definitionId) {
          const def = r.outData.find((d) => d.id == +props.match.params.definitionId);
          if (def) setSelected([def]);
        }

      } else message.error(r.msg);
    });
  }
  const showTotal = (total) => formatString(t("Total {0} items"), total);
  return (
    <div>
      <DefaultPage
        title={t("Process Analysis")}
        icon="video-camera"
        loading={false}
        extra={
          <div>
            <Button type="primary" icon={<FolderOpenOutlined />} onClick={() => setLoadVisible(true)}>{t("Open")}</Button>
          </div>
        }>

        <Row>
          <Col span={24}>
            <Card
              title={selected.length > 0 ? `${t("Process Analysis")} - ${selected[0].name}` : t("Process Analysis")}
              size="small"
              extra={<div>
                <Button type="link" icon={hideTabs ? <MinusSquareOutlined /> : <PlusSquareOutlined />} onClick={() => setHideTabs(!hideTabs)} />
              </div>}
            >

              <VisorInstances definition={selected[0]} setInstances={setInstances} setFilterByEstadoId={setFilterByEstadoId} fullScreen={hideTabs} />
              
              <Tabs defaultActiveKey="1" hidden={hideTabs} >
                <TabPane tab={t("Instances")} key="sla">
                  <Button type='link' disabled={!filterByEstadoId} icon={<FilterOutlined />} onClick={() => setFilterByEstadoId(null)}>{filterByEstadoId ? t("All") : t("Click graph to filter")}</Button>
                  <Table dataSource={instances.filter(p => filterByEstadoId ? p.estadoId == filterByEstadoId : true)} rowKey="id" size="small" pagination={{ showTotal: showTotal }} >
                    <Column title={t("Id")} dataIndex="id" width={90} render={v => <Link to={`/activity/${v}`}>{v}</Link>} sorter={(a, b) => a.id - b.id} />
                    <Column title={t("User")} dataIndex="usuario" render={v => <Tag>{v}</Tag>} sorter={(a, b) => a.usuario.localeCompare(b.usuario)} />
                    <Column title={t("Started")} dataIndex="fInicio" render={v => formatDate(v)} sorter={(a, b) => new Date(a.fInicio) - new Date(b.fInicio)} />
                    <Column title={t("Expires")} dataIndex="dueDate" render={(v, r) => r.sla ? formatDateFromNow(v) : ""} sorter={(a, b) => new Date(a.dueDate) - new Date(b.dueDate)} />
                    <Column title={t("In Status")} dataIndex="fEstado" sorter={(a, b) => new Date(a.fEstado) - new Date(b.fEstado)} render={v => <Tooltip title={formatDate(v)}>{formatDateFromNow(v)}</Tooltip>} />
                    <Column title={t("Responsible")} dataIndex="responsable" render={v => <Tag>{v}</Tag>} sorter={(a, b) => (a.responsable || " ").localeCompare((b.responsable || " "))} />
                    <Column title={t("Ended")} dataIndex="fFin" render={v => v ? formatDate(v) : ""} sorter={(a, b) => new Date(a.fFin) - new Date(b.fFin)} />
                    <Column title={t("Status")} dataIndex="estado" render={v => <Tag color="#87d068">{v}</Tag>} sorter={(a, b) => a.estado.localeCompare(b.estado)} />
                  </Table>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
        <Row>
        </Row>


        <DraggableModal
          title={t("Open process")}
          visible={loadVisible}
          onOk={() => setLoadVisible(false)}
          onCancel={() => setLoadVisible(false)}
        >
          <Table 
            rowKey="id" 
            dataSource={definitions}
            scroll={{ y: 400 }}
            onRow={record => ({
              onClick: () => {
                setSelected([record]); 
                setFilterByEstadoId(undefined);
              }
            })}
            rowSelection={{
              selectedRowKeys: selected.map((r) => r.id),
              onSelect: (record, selected, selectedRows) => { 
                setSelected([record]); 
                setFilterByEstadoId(undefined); 
              },
            }}
          >
            <Column title={t("Id")} dataIndex="id" width={90} />
            <Column title={t("Name")} dataIndex="name" />
            <Column title={t("Instances")} dataIndex="instanceCount" />
          </Table>
        </DraggableModal>
      </DefaultPage>
    </div>
  );
};

export default ProcessAnalysis;