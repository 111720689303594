import React, {useState} from 'react';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, message, Drawer, Checkbox, InputNumber, Input, DatePicker } from "antd";
import Currency from "../Shared/Currency";
import {useTranslation} from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {exe} from "../../Lib/Dal";
import CreditProductSelect from '../Credit/CreditProductSelect';
import PolicySelect from '../Health/PolicySelect';
import StatusSelect from '../Shared/StatusSelect';

const OtherReceivablesFilter = (props) => {
    const [t]=useTranslation();
    const [loading,setLoading]=useState();
    
    const field=props.form.getFieldDecorator;
    
    const onFilter=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            setLoading(true);
            exe("GetOtherReceivables",values).then(r=>{
                setLoading(false);
                if(r.ok){
                    props.onData(r.outData.results)
                }else message.error(r.msg);
            })
        })
    }
    const values=props.form.getFieldsValue();
    return (
        <div>
            <Drawer
                title={t("Filter")}
                visible={props.visible}
                width={512}
                onClose={props.onClose}>
                <div style={{marginBottom: "15px", marginTop: "-15px"}}>
                    <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                        {t("Reset")}
                    </Button>
                </div>
                <Form layout="vertical">
                    
                    <Form.Item label={t("Contact")} >{field("contactId")(<SimpleContactSelect/>)}</Form.Item>  
                    <Form.Item label={t("Currency")} style={{flex: 1, marginRight: 5}}>{field("currency")(<Currency/>)}</Form.Item>
                    <Form.Item label={t("Include Credits")} style={{flex: 1, marginRight: 5}}>{field("includeCredits",{valuePropName:"checked"})(<Checkbox/>)}</Form.Item>
                    {values.includeCredits&&<div>
                            <Form.Item label={t("Id")}>{field("crId")(<InputNumber/>)}</Form.Item>
                            <Form.Item label={t("Code")}>{field("crCode")(<Input/>)}</Form.Item>
                            <Form.Item label={t("Policy")}>{field("crPolicyId")(<PolicySelect/>)}</Form.Item>
                                <div style={{ display: "flex" }}>
                                    <Form.Item label={t("Due Month")} style={{ flex: 1, marginRight: 5 }}>{field("crDueMonth")(<DatePicker.MonthPicker style={{ width: "100%" }} />)}</Form.Item>
                                    <Form.Item label={t("Creation Month")} style={{ flex: 1 }}>{field("crCreationMonth")(<DatePicker.MonthPicker style={{ width: "100%" }} />)}</Form.Item>
                                </div>
                                 <div style={{ display: "flex" }}>
                                    <Form.Item label={t("Min Due Days")} >
                                        {field("crDueDaysMin")(<InputNumber style={{ width: "100%" }} />)}
                                    </Form.Item>
                                    <Form.Item label={t("Max Due Days")} style={{ marginLeft: 10 }}>
                                        {field("crDueDaysMax")(<InputNumber style={{ width: "100%" }} />)}
                                    </Form.Item>
                                </div>
                            <Form.Item label={t("Product")}>{field("crProduct")(<CreditProductSelect />)}</Form.Item>
                            <Form.Item label={t("Status")}>{field("crStatus")(<StatusSelect />)}</Form.Item>
                            <Form.Item label={t("External Id")}>{field("crExternalId")(<Input />)}</Form.Item>
                        </div>}
                  
                </Form>
                <Button type="primary" icon={<SearchOutlined />} onClick={onFilter} loading={loading}>
                    {t("Search")}
                </Button>
              
            </Drawer>
        </div>
    );
};

export default Form.create()(OtherReceivablesFilter);