import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select, Switch, InputNumber, DatePicker } from "antd";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";
import { UndoOutlined } from '@ant-design/icons';

const FormItem = Form.Item;
const Option = Select.Option;

class ActividadBuscar extends React.Component {
  state = {
    usuarios: [],
  };
  componentDidMount() {
    exe("GetUsers").then((r) => this.setState({ usuarios: r.outData }));
    exe("RepoUsrGroup", { operation: "GET" }).then((r) => this.setState({ grupos: r.outData }));
    exe("GetDistinctValues", { table: "proceso",column:"estado" }).then((r) => this.setState({ estados: r.outData.map(p=>p.estado) }));
    exe("GetDistinctValues", { table: "ProcesoEtiqueta",column:"nombre" }).then((r) => this.setState({ etiquetas: r.outData.map(p=>p.nombre) }));
    exe("DoQuery", { sql: "SELECT id,name from Definition" }).then((r) => this.setState({ procesos: r.outData }));

    if(this.props.definitionId){
      this.props.form.setFieldsValue({proceso:+this.props.definitionId});
      console.log("definitionId",+this.props.definitionId);
    }
      
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //let filtro=Object.keys(values).filter(k=>values[k]).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(values[k])}`);
        //filtro=filtro.join(';');
        console.log(values);
        this.props.onSearch(values);
      }
    });
  };
  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const usuarios = this.state.usuarios && this.state.usuarios.map((u) => <Option value={u.email}>{u.nombre}</Option>);
    const etiquetas = this.state.etiquetas && this.state.etiquetas.map((u) => <Option value={u}>{u}</Option>);
    const estados = this.state.estados && this.state.estados.map((u) => <Option value={u}>{u}</Option>);
    const procesos = this.state.procesos && this.state.procesos.map((u) => <Option value={u.id} key={u.id}>{u.name}</Option>);
    const grupos = this.state.grupos && this.state.grupos.map((u) => <Option value={u.id}>{u.name}</Option>);

    return (
      <Form style={{ marginBottom: 10 }} onSubmit={this.handleSubmit} layout="vertical">
          <div style={{marginTop:-12,display:"flex",flexDirection:"row-reverse" }}>
              <Button icon={<UndoOutlined />} size="small" onClick={() => this.props.form.resetFields()}>
                  {t("Reset")}
              </Button>
          </div>
        <FormItem label={t("ID")}>{getFieldDecorator("id")(<InputNumber />)}</FormItem>
        <FormItem label={t("Created")}>{getFieldDecorator("created")(<DatePicker.RangePicker />)}</FormItem>
        <FormItem label={t("In status")}>{getFieldDecorator("inStatus")(<DatePicker.RangePicker />)}</FormItem>
        <FormItem label={t("Process")}>
          {getFieldDecorator("proceso")(
            <Select allowClear={true} placeholder={t("Process")} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
              {procesos}
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Responsible")}>
          {getFieldDecorator("responsable")(
            <Select allowClear={true} placeholder={t("Responsible")} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
              {usuarios}
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Group")}>
          {getFieldDecorator("groups")(
            <Select mode="multiple" allowClear={true} placeholder={t("Group")} optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
              {grupos}
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Status")}>
          {getFieldDecorator("estado")(
            <Select mode="multiple" allowClear={true} showArrow={true} placeholder={t("Status")} optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
              {estados}
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Tags")}>
          {getFieldDecorator("etiquetas")(
            <Select mode="multiple" allowClear={true} showArrow={true} placeholder={t("Tags")} optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
              {etiquetas}
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Completed")}>{getFieldDecorator("finalizado",{valuePropName:"checked"})(<Switch />)}</FormItem>
        <FormItem label={t("Expired")}>{getFieldDecorator("vencido",{valuePropName:"checked"})(<Switch />)}</FormItem>
        <FormItem label={t("Events")}>
          {getFieldDecorator("eventsDisabled")(
            <Select allowClear>
              <Select.Option value={true}>{t("Events disabled")}</Select.Option>
              <Select.Option value={false}>{t("Events enabled")}</Select.Option>
            </Select>
          )}
        </FormItem>
        <FormItem label={t("Expiration Range")}>{getFieldDecorator("expirationRange")(<DatePicker.RangePicker />)}</FormItem>
        <Button type="primary" htmlType="submit">
          {t("Find")}
        </Button>
      </Form>
    );
  }
}
const wrapped = Form.create()(ActividadBuscar);

export default withTranslation()(wrapped);
