import React, { useEffect, useState } from 'react';
import CodeEditorW from '../Shared/CodeEditorW';
import { Button, Modal, message } from 'antd';
import { exe } from '../../Lib/Dal';
import { SaveOutlined } from '@ant-design/icons';
const ChainPeek = (props) => {
   const [chain,setChain]=useState(null);
   const [code,setCode]=useState("");
   const [visible,setVisible]=useState(false);
   const [loading,setLoading]=useState(false);

   useEffect(()=>{
    loadCode(props.chainName);
},[props.chainName]);

   const loadCode=(chainName)=>{
        if(!chainName) return;
        exe("RepoChain",{operation:"GET",filter:`name='${chainName}'`}).then(r=>{
            if(r.ok){
                if(r.total>0){
                    setChain(r.outData[0]);
                    setCode(r.outData[0].code);
                    setVisible(true);
                }else{
                    //if not found, create a new one
                    exe("RepoChain",{operation:"ADD",entity:{name:chainName}}).then(r=>{
                        if(r.ok){
                            message.success("Chain "+chainName+" created");
                            setChain(r.outData[0]);
                            setCode("");
                            setVisible(true);
                        }else {
                            message.error(r.msg);
                        }
                    })
                }
            }else {
                message.error(r.msg);
            }
        })
    }
    const onCancel=()=>{
        setVisible(false);
        props.onCancel&&props.onCancel();
    }
    const onSave=()=>{
        setLoading(true);
        chain.code=code;
        exe("RepoChain",{operation:"UPDATE",entity:chain}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success("Saved");
            }else {
                message.error(r.msg);
            }
        })
    }
    const onOk=()=>{
        onSave();
        onCancel();
    }

  

    return (
        <div>
            <Modal open={visible} onCancel={onCancel} onOk={onOk} title={props.chainName} width={1000} height={800} destroyOnClose={true} bodyStyle={{paddingTop:0}} okText="Save" >
                <Button type='link' icon={<SaveOutlined />} onClick={onSave} loading={loading} />
                <CodeEditorW javascript value={code} onChange={(v) => setCode(v)} divId="chainPeek" />
            </Modal>
        </div>
    );
};

export default ChainPeek;