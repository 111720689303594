import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Progress, Typography, Space, Divider, Select } from 'antd';
import { 
  BarChartOutlined, 
  DollarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { formatMoney, getConfigProfile, round2 } from '../../Lib/Helpers';
import { useTranslation } from 'react-i18next';
import { safeGet } from '../../Lib/Dal';
const { Text, Title } = Typography;

const StatCard = ({ title, value, icon, color }) => (
  <Card style={{ marginBottom: 16 }}>
    <Row gutter={16} align="middle">
      <Col span={16}>
        <Text type="secondary">{title}</Text>
        <div style={{ fontSize: 24, fontWeight: 'bold' }}>{value}</div>
      </Col>
      <Col span={8} style={{ textAlign: 'center' }}>
        <div style={{ 
          color: color, 
          background: `${color}22`, 
          padding: '15px',
          borderRadius: '50%',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {icon}
        </div>
      </Col>
    </Row>
  </Card>
);

const FixedAssetsStats = ({ stats, loading }) => {
  const {t} = useTranslation();
  const [defaultCurrency, setDefaultCurrency] = useState('USD');
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  
  useEffect(() => {
    getConfigProfile().then((profile) => {
      const currency = safeGet(['Main', 'defaultCurrency'], profile, 'USD');
      setDefaultCurrency(currency);
      setSelectedCurrency(currency);
      console.log(profile,"profile");
    });
  }, []);

  if (loading) {
    return <div style={{ textAlign: 'center', margin: '16px 0' }}>{t('Loading statistics...')}</div>;
  }


  // Calculate depreciation percentage with better error handling
  let percentValue = 0;
  if (stats.totalPurchaseValue && stats.totalPurchaseValue > 0) {
    // Make sure we don't get a negative or NaN value
    const depreciation = Math.max(0, stats.totalPurchaseValue - (stats.totalBookValue || 0));
    percentValue = Math.min(100, round2((depreciation / stats.totalPurchaseValue) * 100));

  }

  // Main currency (we'll use the default currency)
  const mainCurrency = defaultCurrency;

  // Currency data and calculations
  const availableCurrencies = stats.currencies ? 
    [mainCurrency, ...Object.keys(stats.currencies).filter(c => c !== mainCurrency)] : 
    [mainCurrency];

  // Get purchase value and book value for selected currency
  const getCurrencyValues = (currency) => {
    if (currency === mainCurrency) {
      return {
        purchaseValue: stats.totalPurchaseValue || 0,
        bookValue: stats.totalBookValue || 0
      };
    } else if (stats.currencies && stats.currencies[currency]) {
      // Get the purchase value for the selected currency
      const purchaseValue = stats.currencies[currency] || 0;
      
      // Calculate the book value for this currency
      // If we have the actual book value per currency in stats, use that instead
      let bookValue = 0;
      if (stats.bookValueByCurrency && stats.bookValueByCurrency[currency]) {
        bookValue = stats.bookValueByCurrency[currency];
      } else {
        // Estimate book value using the same depreciation ratio as main currency
        const mainDepreciationRatio = stats.totalBookValue && stats.totalPurchaseValue ? 
          stats.totalBookValue / stats.totalPurchaseValue : 1;
        bookValue = purchaseValue * mainDepreciationRatio;
      }
      
      return {
        purchaseValue,
        bookValue: bookValue
      };
    }
    return { purchaseValue: 0, bookValue: 0 };
  };

  // This will now get values based on the currently selected currency
  const currencyValues = getCurrencyValues(selectedCurrency);
  
  // Calculate depreciation percentage for selected currency
  let currencyPercentValue = 0;
  if (currencyValues.purchaseValue > 0) {
    const depreciation = Math.max(0, currencyValues.purchaseValue - currencyValues.bookValue);
    currencyPercentValue = Math.min(100, round2((depreciation / currencyValues.purchaseValue) * 100));
  }
  const formatCurrency=formatMoney;
  return (
    <div style={{ marginTop: 24 }}>
      <Row gutter={16}>
        <Col xs={24} sm={12} md={6}>
          <StatCard 
            title={t('Total Assets')} 
            value={stats.totalAssets || 0} 
            icon={<BarChartOutlined style={{ fontSize: 24 }} />} 
            color="#1890ff"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard 
            title={t('Total Purchase Value')} 
            value={formatCurrency(stats.totalPurchaseValue, mainCurrency)} 
            icon={<DollarOutlined style={{ fontSize: 24 }} />} 
            color="#52c41a"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard 
            title={t('Active Assets')} 
            value={stats.activeAssets || 0} 
            icon={<CheckCircleOutlined style={{ fontSize: 24 }} />} 
            color="#13c2c2"
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StatCard 
            title={t('Inactive Assets')} 
            value={stats.inactiveAssets || 0} 
            icon={<CloseCircleOutlined style={{ fontSize: 24 }} />} 
            color="#f5222d"
          />
        </Col>
      </Row>
      
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col xs={24} md={12}>
          <Card>
            <Row justify="space-between" align="middle">
              <Col>
                <Title level={5}>{t("Asset Value Distribution")}</Title>
              </Col>
              <Col>
                <Select
                  value={selectedCurrency}
                  onChange={setSelectedCurrency}
                  style={{ width: 120 }}
                >
                  {availableCurrencies.map(currency => (
                    <Select.Option key={currency} value={currency}>
                      {currency}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
              <Col>
                <Text type="secondary">Original Purchase Value:</Text>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                  {formatCurrency(currencyValues.purchaseValue, selectedCurrency)}
                </div>
              </Col>
              <Col>
                <Text style={{ margin: '0 16px' }}>vs</Text>
              </Col>
              <Col>
                <Text type="secondary">Current Book Value:</Text>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                  {formatCurrency(currencyValues.bookValue, selectedCurrency)}
                </div>
              </Col>
            </Row>
            <div style={{ marginTop: 16 }}>
              <Progress 
                percent={currencyPercentValue} 
                strokeColor="#ff4d4f" 
                size="large"
              />
              <div style={{ marginTop: 8 }}>
                <Text type="secondary" style={{ fontSize: 12 }}>
                  Total depreciation percentage
                </Text>
              </div>
            </div>
          </Card>
        </Col>
        
        {stats.currencies && Object.keys(stats.currencies).length > 0 && (
          <Col xs={24} md={12}>
            <Card>
              <Title level={5}>Asset Values by Currency</Title>
              {Object.entries(stats.currencies).map(([currency, value]) => (
                <Row key={currency} justify="space-between" style={{ marginBottom: 8 }}>
                  <Col>
                    <Text strong>{currency}</Text>
                  </Col>
                  <Col>
                    <Text>{formatCurrency(value, currency)}</Text>
                  </Col>
                </Row>
              ))}
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default FixedAssetsStats; 