import React, { useEffect,useRef,useState } from "react";
import {basicSetup, EditorView} from "codemirror"
import {keymap} from "@codemirror/view"
import {Decoration, ViewPlugin, WidgetType, hoverTooltip} from "@codemirror/view"
import {indentWithTab} from "@codemirror/commands"
import {EditorState, StateField, StateEffect} from "@codemirror/state"
import {javascript,javascriptLanguage} from "@codemirror/lang-javascript"
import * as yamlMode from '@codemirror/legacy-modes/mode/yaml'; //yaml support
import { StreamLanguage, LanguageSupport } from "@codemirror/language" //yaml support
import { exe } from 'Lib/Dal';
import { autocompletion } from "@codemirror/autocomplete";
import ChainPeek from "../Bpm/ChainPeek";
import getCache from "../../Lib/Cache";

const CodeEditorW=(props)=> {
    const [editor,setEditor]=useState();
    const [selectedChain,setSelectedChain]=useState();
    const commandList=useRef([]);
  
    const divId = props.divId ? props.divId : "myCodeMirror"; 

    useEffect(()=>loadCommands(),[]);

    const loadCommands=()=>{
        getCache("GetCommands").then(r=>{
            commandList.current=r.outData;
        })
    }

    const sisosEngineCompletions=[
        {label:"doCmd",type:"function",detail:"(SISos Engine)",info:"Executes a SISos Command given a dto eg doCmd({\"cmd\":\"command_name\",\"data\":{\"arg1\":\"val1\",\"arg2\":\"val2\"}})", apply: (view, completion, from, to) => {
            view.dispatch({
                changes: {from, to, insert: "doCmd(dto)"},
                selection: {anchor: from + 6, head: from + 9}  // Position cursor at "dto"
            });
            return true;
        }},
        {label:"doCmd (with example)",type:"function",detail:"(SISos Engine)",info:"Executes a SISos Command with example DTO", apply: (view, completion, from, to) => {
            view.dispatch({
                changes: {from, to, insert: "doCmd({\"cmd\":\"command_name\",\"data\":{\"arg1\":\"val1\",\"arg2\":\"val2\"}})"},
                selection: {anchor: from + 14, head: from + 26}  // Position cursor at "command_name"
            });
            return true;
        }},
        {label:"log",type:"function",detail:"(SISos Engine)",info:"Logs a message to the returned command log array", apply: (view, completion, from, to) => {
            view.dispatch({
                changes: {from, to, insert: "log(message)"},
                selection: {anchor: from + 4, head: from + 11}  // Position cursor at "message"
            });
            return true;
        }},
        {label:"getScalar",type:"function",detail:"(SISos Engine)",info:"Returns a scalar value from the DB", apply: (view, completion, from, to) => {
            view.dispatch({
                changes: {from, to, insert: "getScalar(query)"},
                selection: {anchor: from + 10, head: from + 15}  // Position cursor at "query"
            });
            return true;
        }},
        {label:"exe",type:"function",detail:"(SISos Engine)",info:"Executes a SISos Command (cmd,args) when not in block mode", apply: (view, completion, from, to) => {
            view.dispatch({
                changes: {from, to, insert: "exe(cmd,args)"},
                selection: {anchor: from + 4, head: from + 12}  // Position cursor at "cmd,args"
            });
            return true;
        }}
    ]

    const myCompletions= async(context)=> {
        let word = context.matchBefore(/\w*/);
      
        if (word === null || (word.from == word.to && !context.explicit)) return null;
        
        // Only apply uppercase filter for command list items, not for engine completions
        if(word.text.length > 0) {
            // Always include sisosEngineCompletions
            const engineCompletions = sisosEngineCompletions.filter(item => 
                item.label.toLowerCase().startsWith(word.text.toLowerCase())
            );
            
            // Only include command list items if they start with uppercase
            const commandCompletions = word.text[0] === word.text[0].toUpperCase() 
                ? commandList.current.map(p => ({label: p.name, type: "variable", info: "(SISos Command)"}))
                : [];
                
            return {
                from: word.from,
                options: [...engineCompletions, ...commandCompletions]
            };
        }
        
        return {
          from: word.from,
          options: sisosEngineCompletions.concat(commandList.current.map(p=>({label:p.name, type:"variable", info:"(SISos Command)"})))
        };
    }

    // Chain word detection and decoration
    const createChainHighlighter = (onChainClick) => {
        const chainMark = Decoration.mark({
            class: "cm-chain-highlight",
            attributes: { style: "text-decoration: underline; cursor: pointer;" }
        });
        
        return ViewPlugin.fromClass(class {
            decorations;
            
            constructor(view) {
                this.decorations = this.getDecorations(view);
            }
            
            update(update) {
                if (update.docChanged || update.viewportChanged)
                    this.decorations = this.getDecorations(update.view);
            }
            
            getDecorations(view) {
                const decorations = [];
                const chainRegex = /\bchain\b/gi;
                
                for (const { from, to } of view.visibleRanges) {
                    const text = view.state.doc.sliceString(from, to);
                    let match;
                    
                    while ((match = chainRegex.exec(text)) !== null) {
                        const start = from + match.index;
                        const end = start + match[0].length;
                        decorations.push(chainMark.range(start, end));
                    }
                }
                
                return Decoration.set(decorations);
            }
        }, {
            decorations: v => v.decorations,
            eventHandlers: {
                click: (e, view) => {
                    const pos = view.posAtCoords({ x: e.clientX, y: e.clientY });
                    if (pos === null) return false;
                    
                    // Get the word at the current position
                    const line = view.state.doc.lineAt(pos);
                    const lineText = line.text;
                    
                    // Find word boundaries
                    let start = pos, end = pos;
                    
                    // Move start to beginning of word
                    while (start > line.from && /\w/.test(lineText[start - line.from - 1])) {
                        start--;
                    }
                    
                    // Move end to end of word
                    while (end < line.to && /\w/.test(lineText[end - line.from])) {
                        end++;
                    }
                    
                    const word = view.state.doc.sliceString(start, end);
                    
                    // Check if the clicked word is "chain"
                    if (word.toLowerCase() === "chain") {
                        // Look for the text that follows the "chain" keyword
                        let followingTextStart = end;
                        let followingText = "";
                        
                        // Check for both space-separated chains and colon/quote patterns
                        const restOfLine = lineText.substring(end - line.from);
                        
                        // Pattern 1: chain followed by space then identifier
                        const spacePattern = /^\s+([\w.-]+)/;
                        const spaceMatch = restOfLine.match(spacePattern);
                        
                        // Pattern 2: chain:'identifier' or chain:"identifier" or chain(`identifier`)
                        const quotesPattern = /^(?:\s*[:(`'"]\s*['"`]?)([^'"`)\s]+)['"`)]?/;
                        const quotesMatch = restOfLine.match(quotesPattern);
                        
                        if (quotesMatch) {
                            followingText = quotesMatch[1];
                        } else if (spaceMatch) {
                            followingText = spaceMatch[1];
                        }
                        
                        console.log(`Text following "chain": "${followingText}"`);
                        
                        if (followingText) {
                            setSelectedChain(followingText);
                            
                            if (onChainClick) {
                                onChainClick(word, start, end, followingText);
                                return true;
                            }
                        }
                    }
                    
                    return false;
                }
            }
        });
    };

    useEffect(()=>{
        //if value is set via formField
        if(props.formField&&editor&&props.value&&editor.state.doc.toString()===""){
            editor.dispatch({
                changes: {from: 0, to: editor.state.doc.length, insert: props.value}
              })
        }
    },[props.value])

    useEffect(()=>{
        if(!editor){
            const view = new EditorView({
                state: EditorState.create({
                    doc: props.value,
                    extensions: [
                        basicSetup,
                        keymap.of([
                            { key: "Ctrl-1", run: ()=>{if (props.saveHotKey) props.saveHotKey()} },
                            indentWithTab
                          ]),
                          props.javascript?javascriptLanguage.data.of({autocomplete: myCompletions}):autocompletion({
                            override: [myCompletions],activateOnTyping:false
                          }),
                        props.javascript?javascript({jsx:true}):new LanguageSupport(StreamLanguage.define(yamlMode.yaml)),
                        EditorView.updateListener.of(e=> {
                            props.onChange&&props.onChange(e.state.doc.toString());
                        }),
                        // Add chain highlighting
                        createChainHighlighter((word, from, to, followingText) => {
                            if (props.onChainClick) {
                                props.onChainClick(word, from, to, followingText);
                            } else {
                                console.log(`Chain clicked at positions ${from}-${to}, following text: "${followingText}"`);
                            }
                        })
                    ]
                }),
                parent: document.getElementById(divId)
            })
            
            //back compatibility
            window.global[divId]=view; 
            view.setValue=v=>view.dispatch({
                changes: {from: 0, to: view.state.doc.length, insert: v}
              });
            view.getValue=()=>view.state.doc.toString();
            
            setEditor(view);
            //fix line numbering height in modals
            setTimeout(()=>view.requestMeasure(),400); //todo: explore this npm install react-intersection-observer --save
          
        }else{
            //not used. Dependency not set
            if(props.value){
                editor.dispatch({
                    changes: {from: 0, to: editor.state.doc.length, insert: props.value}
                  })
            }
        }

    },[])
   
  return <div>
    <style>
      {`
        .cm-chain-highlight {
          text-decoration: underline;
          cursor: pointer;
        }
      `}
    </style>
    <div id={divId} className="CodeMirror" dir="ltr"></div>
    <ChainPeek chainName={selectedChain} onCancel={()=>setSelectedChain(null)} />
  </div>
  ;
}
export default CodeEditorW