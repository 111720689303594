import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Table,
  Tabs,
  Button,
  Modal,
  Space,
  Typography,
  Tooltip,
  Tag,
  Divider,
  message,
  Badge,
  Input,
  DatePicker,
  Form,
  Select,
  Row,
  Col,
  InputNumber
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  UploadOutlined,
  DownloadOutlined,
  FileDoneOutlined,
  LinkOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  SaveOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { exe } from "../../Lib/Dal";
import BudgetLineForm from './BudgetLineForm';
import ScenarioManager from './ScenarioManager';
import BudgetCategorySetup from './BudgetCategorySetup';
import BudgetComparison from './BudgetComparison';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const BudgetDetail = ({ budget, onBack, onSave, loading: parentLoading }) => {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState('info');
  const [loading, setLoading] = useState(false);
  const [budgetLines, setBudgetLines] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedBudgetLine, setSelectedBudgetLine] = useState(null);
  const [isLineModalVisible, setIsLineModalVisible] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  
  // Load budget lines and categories
  useEffect(() => {
    if (!budget) return;
    
    // Set form values for budget details
    form.setFieldsValue({
      name: budget.name,
      description: budget.description,
      year: budget.year,
      status: budget.status,
      owner: budget.owner
    });
    
    
    // Load categories
    fetchCategories();
    
    // Load budget lines
    fetchBudgetLines();
    
  }, [budget, form]);

  const fetchCategories = async () => {
        // Filter active categories in JavaScript instead and sort by order
        const activeCategories = budget?.Categories?.filter(cat => cat.isActive) || [];
        const sortedCategories = [...activeCategories].sort((a, b) => (a.order || 0) - (b.order || 0));
        setCategories(sortedCategories);
  };

  const fetchBudgetLines = async () => {
        // Filter the budget lines for the current budget and where scenarioId is null or 0 in JavaScript
        const filteredLines = (budget?.BudgetLines || [])
          .filter(line => line.budgetId === budget.id && !line.scenarioId);
        setBudgetLines(filteredLines);
  };
  
  const handleSaveBudget = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      // Determine if this is a new budget or an existing one
      // Using explicit comparison to 0 since budget?.id could be 0 for new budgets
      const operation = budget?.id && budget.id !== 0 ? 'UPDATE' : 'ADD';
      
      const saveResult = await exe('RepoBudget', {
        operation: operation,
        entity: {
          ...budget,
          ...values
        }
      });
      
      if (saveResult.ok) {
        message.success(t(operation === 'ADD' ? 'Budget created successfully' : 'Budget updated successfully'));
        
        // If it's a new budget, create default categories
        if (operation === 'ADD' && saveResult.outData && saveResult.outData.length > 0) {
          const savedBudget = saveResult.outData[0];
          await createDefaultCategories(savedBudget.id);
        }
        
        // Properly handle the response
        if (onSave && saveResult.outData && saveResult.outData.length > 0) {
          // Call onSave with the saved budget data and exit the function
          onSave(saveResult.outData[0]);
          setLoading(false);
          return; // Important: exit the function to prevent further processing
        }
      } else {
        message.error(t('Failed to save budget: ') + saveResult.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Validation or save error:', error);
      setLoading(false);
    }
  };
  
  // Create default budget categories for a new budget
  const createDefaultCategories = async (budgetId) => {
    try {
      // Define default categories
      const defaultCategories = [
        { 
          name: 'Revenue', 
          description: 'All income sources',
          categoryType: 'income',
          isActive: true,
          order: 1,
          budgetId: budgetId
        },
        { 
          name: 'Operational Expenses', 
          description: 'Day-to-day operational costs',
          categoryType: 'expense',
          isActive: true,
          order: 2,
          budgetId: budgetId
        },
        { 
          name: 'Personnel', 
          description: 'All employee-related costs',
          categoryType: 'expense',
          isActive: true,
          order: 3,
          budgetId: budgetId
        }
      ];

      exe('RepoBudgetCategory', {
        operation: 'MERGE',
        bulkJson: JSON.stringify(defaultCategories)
      }).then(result => {
        if (result.ok) {
          console.log('Default categories created successfully');
          setCategories(defaultCategories);
        } else {
          console.error('Error creating default categories:', result.msg);
        }
      });
      
    } catch (error) {
      console.error('Error creating default categories:', error);
    }
  };
  
  const handleAddBudgetLine = () => {
    setSelectedBudgetLine(null);
    setIsLineModalVisible(true);
  };
  
  const handleEditBudgetLine = (budgetLine) => {
    setSelectedBudgetLine(budgetLine);
    setIsLineModalVisible(true);
  };
  
  const handleDeleteBudgetLine = async (lineId) => {
    try {
      setLoading(true);
      
      const result = await exe('RepoBudgetLine', {
        operation: 'DELETE',
        entity: { id: lineId }
      });
      
      if (result.ok) {
        setBudgetLines(budgetLines.filter(line => line.id !== lineId));
        message.success(t('Budget line deleted'));
      } else {
        message.error(t('Failed to delete budget line: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error deleting budget line:', error);
      message.error(t('Failed to delete budget line'));
      setLoading(false);
    }
  };
  
  const handleLineFormSubmit = async (values) => {
    try {
      setLoading(true);
      
      if (selectedBudgetLine) {
        // Update existing line
        const result = await exe('RepoBudgetLine', {
          operation: 'UPDATE',
          entity: {
            ...selectedBudgetLine,
            ...values,
            budgetId: budget.id,
            Category: null// otherwise it will try to insert the category object
          }
        });
        
        if (result.ok) {
          const updatedLine = result.outData[0];
          setBudgetLines(budgetLines.map(line => line.id === selectedBudgetLine.id ? updatedLine : line));
          message.success(t('Budget line updated'));
        } else {
          message.error(t('Failed to update budget line: ') + result.msg);
        }
      } else {
        // Add new line
        // Find max order within the same scenario
        const maxOrder = budgetLines.length > 0 
          ? Math.max(...budgetLines.map(line => line.order || 0))
          : 0;
        const result = await exe('RepoBudgetLine', {
          operation: 'ADD',
          entity: {
            ...values,
            budgetId: budget.id,
            isActive: true,
            order: maxOrder + 1,
            Category: null// otherwise it will try to insert the category object
          }
        });
        
        if (result.ok) {
          const updatedLine = result.outData[0];
          setBudgetLines([...budgetLines, updatedLine]);
          message.success(t('Budget line added'));
        } else {
          message.error(t('Failed to add budget line: ') + result.msg);
        }
      }
      
      setLoading(false);
      setIsLineModalVisible(false);
    } catch (error) {
      console.error('Error saving budget line:', error);
      message.error(t('Failed to save budget line'));
      setLoading(false);
    }
  };
  
  const handleLineModalCancel = () => {
    setIsLineModalVisible(false);
  };
  
  const handleOpenCategoryModal = () => {
    setIsCategoryModalVisible(true);
  };
  
  const handleSaveCategories = async (updatedCategories) => {
    try {
      setLoading(true);

      
      // Use MERGE operation to bulk update/insert categories
      const result = await exe('RepoBudgetCategory', {
        operation: 'MERGE',
        bulkJson: JSON.stringify(updatedCategories.map(cat => ({
          ...cat,
          id:cat.id<0?0:cat.id,
          budgetId: budget.id
        })))
      });
      
      if (result.ok) {
        setCategories(updatedCategories);
        setIsCategoryModalVisible(false);
        message.success(t('Categories updated'));
      } else {
        message.error(t('Failed to update categories: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error saving categories:', error);
      message.error(t('Failed to save categories'));
      setLoading(false);
    }
  };
  
  const handleCancelCategoryModal = () => {
    setIsCategoryModalVisible(false);
  };
  
  const getCategoryNameById = (categoryId) => {
    const category = categories.find(c => c.id === categoryId);
    return category ? category.name : '';
  };
  
  const getMonthTotal = (month) => {
    return budgetLines.reduce((total, line) => total + (line[month] || 0), 0);
  };
  
  const handleSearch = (value) => {
    setSearchText(value);
  };
  
  // Move budget line up (decrease order within its category)
  const moveLineUp = async (line) => {
    try {
      setLoading(true);
      
      const result = await exe('UpdateBudgetLineOrder', {
        lineId: line.id,
        direction: 'up'
      });
      
      if (result.ok && result.outData) {
        // Only update the lines that were returned from the command, 
        // preserving all other budget lines
        const updatedLinesMap = {};
        result.outData.forEach(updatedLine => {
          updatedLinesMap[updatedLine.id] = updatedLine;
        });
        
        // Create a new array with updated lines, preserving the Category property
        const newBudgetLines = budgetLines.map(line => {
          if (updatedLinesMap[line.id]) {
            // Preserve the Category property from the original line
            return {
              ...updatedLinesMap[line.id],
              Category: line.Category
            };
          }
          return line;
        });
        
        setBudgetLines(newBudgetLines);
        message.success(t('Budget line moved up'));
      } else {
        message.error(result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error moving budget line:', error);
      message.error(t('Failed to move budget line'));
      setLoading(false);
    }
  };
  
  // Move budget line down (increase order within its category)
  const moveLineDown = async (line) => {
    try {
      setLoading(true);
      
      const result = await exe('UpdateBudgetLineOrder', {
        lineId: line.id,
        direction: 'down'
      });
      
      if (result.ok && result.outData) {
        // Only update the lines that were returned from the command, 
        // preserving all other budget lines
        const updatedLinesMap = {};
        result.outData.forEach(updatedLine => {
          updatedLinesMap[updatedLine.id] = updatedLine;
        });
        
        // Create a new array with updated lines, preserving the Category property
        const newBudgetLines = budgetLines.map(line => {
          if (updatedLinesMap[line.id]) {
            // Preserve the Category property from the original line
            return {
              ...updatedLinesMap[line.id],
              Category: line.Category
            };
          }
          return line;
        });
        
        setBudgetLines(newBudgetLines);
        message.success(t('Budget line moved down'));
      } else {
        message.error(result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error moving budget line:', error);
      message.error(t('Failed to move budget line'));
      setLoading(false);
    }
  };
  
  // Filter budget lines by search text
  const getFilteredBudgetLines = () => {
    if (!searchText) {
      // Return all budget lines sorted by category order and then line order within each category
      return [...budgetLines].sort((a, b) => {
        // First sort by category order
        const categoryOrderDiff = (a.Category?.order || 0) - (b.Category?.order || 0);
        if (categoryOrderDiff !== 0) return categoryOrderDiff;
        
        // Then by line order within category
        return (a.order || 0) - (b.order || 0);
      });
    }
    
    return budgetLines.filter(line => 
      (line.name || '').toLowerCase().includes(searchText.toLowerCase()) ||
      (line.description || '').toLowerCase().includes(searchText.toLowerCase()) ||
      (line.Category?.name || '').toLowerCase().includes(searchText.toLowerCase())
    );
  };
  
  const calculateAnnualTotal = (line) => {
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    return months.reduce((total, month) => total + (line[month] || 0), 0);
  };
  
  const calculateGrandTotal = () => {
    return budgetLines.reduce((total, line) => total + (line.annualTotal || calculateAnnualTotal(line)), 0);
  };
  
  const columns = [
    {
      title: t('Budget Line'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Text strong>{text}</Text>
          <Text type="secondary">{record.categoryId?getCategoryNameById(record.categoryId):''}</Text>
          {record.mappingType && (
            <Tag color="blue" icon={<LinkOutlined />}>
              {record.mappingId || ''}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'lineType',
      key: 'lineType',
      width: 120,
      render: (lineType) => {
        let color = lineType === 'income' ? 'green' : 'volcano';
        return <Tag color={color}>{lineType === 'income' ? t('Income') : t('Expense')}</Tag>;
      },
    },
    {
      title: t('Jan'),
      dataIndex: 'jan',
      key: 'jan',
      width: 100,
      render: (value) => value ? `$${value.toLocaleString()}` : '-',
    },
    {
      title: t('Feb'),
      dataIndex: 'feb',
      key: 'feb',
      width: 100,
      render: (value) => value ? `$${value.toLocaleString()}` : '-',
    },
    {
      title: t('Mar'),
      dataIndex: 'mar',
      key: 'mar',
      width: 100,
      render: (value) => value ? `$${value.toLocaleString()}` : '-',
    },
    {
      title: t('Q1'),
      key: 'q1',
      width: 120,
      render: (_, record) => {
        const total = (record.jan || 0) + (record.feb || 0) + (record.mar || 0);
        return `$${total.toLocaleString()}`;
      },
    },
    {
      title: t('Annual'),
      key: 'annual',
      width: 150,
      render: (_, record) => {
        const total = record.annualTotal || calculateAnnualTotal(record);
        return <Text strong>{`$${total.toLocaleString()}`}</Text>;
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      width: 180,
      render: (_, record) => (
        <Space size="small">
          <Tooltip title={t('Move Up')}>
            <Button 
              type="text" 
              icon={<ArrowUpOutlined />} 
              onClick={() => moveLineUp(record)} 
            />
          </Tooltip>
          <Tooltip title={t('Move Down')}>
            <Button 
              type="text" 
              icon={<ArrowDownOutlined />} 
              onClick={() => moveLineDown(record)} 
            />
          </Tooltip>
          <Tooltip title={t('Edit')}>
            <Button 
              type="text" 
              icon={<EditOutlined />} 
              onClick={() => handleEditBudgetLine(record)} 
            />
          </Tooltip>
          <Tooltip title={t('Delete')}>
            <Button 
              type="text" 
              danger
              icon={<DeleteOutlined />} 
              onClick={() => handleDeleteBudgetLine(record.id)} 
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  
  // Add this function to handle when a new scenario is created
  const handleScenarioCreated = (scenarioId) => {
    // If the active tab isn't scenarios already, switch to it
    if (activeTab !== 'variance') {
      setActiveTab('variance');
      message.info(t('Scenario created. Switched to Variance Analysis tab.'));
    }
  };

  const handleRefreshScenarios = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Title level={4} style={{ margin: 0 }}>
          {budget?.name || t('New Budget')} {budget?.year ? `(${budget.year})` : ''}
        </Title>
      </div>
      
      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab} 
        items={[
          {
            key: 'info',
            label: t('Info'),
            children: (
              <div>
                <Card>
                  <Form
                    form={form}
                    layout="vertical"
                  >
                    <Row gutter={16}>
                      <Col span={16}>
                        <Form.Item
                          name="name"
                          label={t("Budget Name")}
                          rules={[{ required: true, message: t('Please enter a budget name') }]}
                        >
                          <Input placeholder={t("Enter budget name")} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="year"
                          label={t("Year")}
                          rules={[{ required: true, message: t('Please select a year') }]}
                        >
                          <InputNumber 
                            style={{ width: '100%' }} 
                            min={2000} 
                            max={2100} 
                            placeholder={t("Select year")} 
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="owner"
                          label={t("Owner")}
                        >
                          <Input placeholder={t("Budget owner")} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="status"
                          label={t("Status")}
                          rules={[{ required: true, message: t('Please select a status') }]}
                        >
                          <Select placeholder={t("Select status")}>
                            <Option value="draft">{t("Draft")}</Option>
                            <Option value="active">{t("Active")}</Option>
                            <Option value="closed">{t("Closed")}</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      name="description"
                      label={t("Description")}
                    >
                      <TextArea 
                        rows={4}
                        placeholder={t("Enter budget description")}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button 
                        type="primary" 
                        onClick={handleSaveBudget} 
                        icon={<SaveOutlined />}
                        loading={loading || parentLoading}
                      >
                        {t("Save Budget")}
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            )
          },
          {
            key: 'categories',
            label: t('Categories & Lines'),
            children: (
              <div>
                <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                  <Space>
                    <Button 
                      type="primary" 
                      icon={<PlusOutlined />} 
                      onClick={handleAddBudgetLine}
                    >
                      {t('Add Budget Line')}
                    </Button>
                    <Button 
                      type="default" 
                      icon={<SettingOutlined />} 
                      onClick={handleOpenCategoryModal}
                    >
                      {t('Manage Categories')}
                    </Button>
                  </Space>
                  <Space>
                    <Search
                      placeholder={t('Search budget lines')}
                      allowClear
                      onChange={e => handleSearch(e.target.value)}
                      style={{ width: 300 }}
                    />
                   
                  </Space>
                </div>
                
                <Card>
                  <Table 
                    columns={columns}
                    dataSource={getFilteredBudgetLines()}
                    rowKey="id"
                    loading={loading || parentLoading}
                    pagination={false}
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <Text strong>{t('Total')}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Text strong>${getMonthTotal('jan').toLocaleString()}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Text strong>${getMonthTotal('feb').toLocaleString()}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Text strong>${getMonthTotal('mar').toLocaleString()}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Text strong>${(getMonthTotal('jan') + getMonthTotal('feb') + getMonthTotal('mar')).toLocaleString()}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          <Text strong>${calculateGrandTotal().toLocaleString()}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} />
                      </Table.Summary.Row>
                    )}
                  />
                </Card>
              </div>
            )
          },
          {
            key: 'scenarios',
            label: t('Scenarios'),
            children: (
              budget && budget.id ? (
                <ScenarioManager
                  budgetId={budget.id}
                  loading={loading}
                  onRefresh={handleRefreshScenarios}
                  budget={budget}
                />
              ) : (
                <Card>
                  <div style={{ textAlign: 'center', padding: 20 }}>
                    <Text>{t('Please save the budget first to manage scenarios')}</Text>
                  </div>
                </Card>
              )
            )
          },
          {
            key: 'variance',
            label: t('Variance Analysis'),
            children: (
              <BudgetComparison
                budget={budget}
                onRefresh={handleScenarioCreated}
              />
            )
          }
        ]}
      />
      
      {/* Budget Line Form Modal */}
      <Modal
        title={selectedBudgetLine ? t('Edit Budget Line') : t('Add Budget Line')}
        open={isLineModalVisible}
        onCancel={handleLineModalCancel}
        footer={null}
        width={800}
      >
        <BudgetLineForm 
          initialValues={selectedBudgetLine}
          categories={categories}
          onSave={handleLineFormSubmit}
          onCancel={handleLineModalCancel}
          budgetId={budget?.id}
        />
      </Modal>
      
      {/* Category Management Modal */}
      <Modal
        title={t('Manage Budget Categories')}
        open={isCategoryModalVisible}
        onCancel={handleCancelCategoryModal}
        footer={null}
        width={900}
      >
        <BudgetCategorySetup 
          initialCategories={categories}
          onSave={handleSaveCategories}
          onCancel={handleCancelCategoryModal}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default BudgetDetail; 