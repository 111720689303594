import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

const HandleContext = React.createContext();

const Handle = ({ nodeId, handleId, type, style }) => {
  const { registerHandle, onHandleMouseDown } = useContext(HandleContext);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      registerHandle({ nodeId, handleId, type, ref });
    }
  }, [nodeId, handleId, type, ref, registerHandle]);

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        width: 10,
        height: 10,
        background: type === 'source' ? '#1a73e8' : '#e91e63',
        borderRadius: '50%',
        cursor: 'crosshair',
        ...style,
      }}
      onMouseDown={(e) => onHandleMouseDown(e, { nodeId, handleId, type })}
    />
  );
};

const Node = ({ node, onMouseDown }) => {
  const Component = node.component;
  return (
    <div
      className="node"
      style={{
        position: 'absolute',
        left: node.position.x,
        top: node.position.y,
        background: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        cursor: 'move',
      }}
      onMouseDown={(e) => onMouseDown(e, node.id)}
    >
      <Component nodeId={node.id} />
    </div>
  );
};

const FlowDiagram = ({ initialNodes = [], initialEdges = [] }) => {
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [isDraggingNode, setIsDraggingNode] = useState(false);
  const [draggingNodeId, setDraggingNodeId] = useState(null);
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
  const [initialNodePos, setInitialNodePos] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [initialPan, setInitialPan] = useState({ x: 0, y: 0 });
  const [isCreatingEdge, setIsCreatingEdge] = useState(false);
  const [creatingEdgeStart, setCreatingEdgeStart] = useState(null);
  const [tempEdgeEnd, setTempEdgeEnd] = useState({ x: 0, y: 0 });
  const [hoveredEdge, setHoveredEdge] = useState(null);
  const [handles, setHandles] = useState({});
  const canvasRef = useRef(null);

  const registerHandle = useCallback((handle) => {
    setHandles((prev) => ({
      ...prev,
      [handle.nodeId]: {
        ...(prev[handle.nodeId] || {}),
        [handle.handleId]: handle,
      },
    }));
  }, []);

  const getHandlePosition = ({ nodeId, handleId }, handles, nodes) => {
    const node = nodes.find((n) => n.id === nodeId);
    const handle = handles[nodeId]?.[handleId];
    if (!handle || !handle.ref.current) return { x: 0, y: 0 };
    const rect = handle.ref.current.getBoundingClientRect();
    return {
      x: node.position.x + handle.ref.current.offsetLeft + rect.width / 2,
      y: node.position.y + handle.ref.current.offsetTop + rect.height / 2,
    };
  };

  const getBezierPath = (start, end) => {
    const dx = end.x - start.x;
    const controlX1 = start.x + dx * 0.3;
    const controlX2 = end.x - dx * 0.3;
    return `M${start.x},${start.y} C${controlX1},${start.y} ${controlX2},${end.y} ${end.x},${end.y}`;
  };

  const Edge = ({ edge, handles, nodes }) => {
    const sourceNode = nodes.find((n) => n.id === edge.sourceNodeId);
    const targetNode = nodes.find((n) => n.id === edge.targetNodeId);
    const sourceHandle = handles[sourceNode.id]?.[edge.sourceHandleId];
    const targetHandle = handles[targetNode.id]?.[edge.targetHandleId];

    if (!sourceHandle || !targetHandle) return null;

    const sourcePos = getHandlePosition(
      { nodeId: edge.sourceNodeId, handleId: edge.sourceHandleId },
      handles,
      nodes
    );
    const targetPos = getHandlePosition(
      { nodeId: edge.targetNodeId, handleId: edge.targetHandleId },
      handles,
      nodes
    );
    const path = getBezierPath(sourcePos, targetPos);
    const midX = (sourcePos.x + targetPos.x) / 2;
    const midY = (sourcePos.y + targetPos.y) / 2;

    return (
      <g
        onMouseEnter={() => setHoveredEdge(edge.id)}
        onMouseLeave={() => setHoveredEdge(null)}
        pointerEvents="all"
      >
        <path
          d={path}
          stroke="#555"
          strokeWidth="2"
          fill="none"
          pointerEvents="all"
        />
        {hoveredEdge === edge.id && (
          <circle
            cx={midX}
            cy={midY}
            r={8}
            fill="red"
            onClick={() => setEdges((prev) => prev.filter((e) => e.id !== edge.id))}
            style={{ cursor: 'pointer' }}
          />
        )}
      </g>
    );
  };

  const handleNodeMouseDown = (event, nodeId) => {
    event.stopPropagation();
    const node = nodes.find((n) => n.id === nodeId);
    setIsDraggingNode(true);
    setDraggingNodeId(nodeId);
    setInitialMousePos({ x: event.clientX, y: event.clientY });
    setInitialNodePos({ x: node.position.x, y: node.position.y });

    const onMouseMove = (e) => {
      const deltaX = (e.clientX - initialMousePos.x) / zoom;
      const deltaY = (e.clientY - initialMousePos.y) / zoom;
      setNodes((prev) =>
        prev.map((n) =>
          n.id === nodeId
            ? { ...n, position: { x: initialNodePos.x + deltaX, y: initialNodePos.y + deltaY } }
            : n
        )
      );
    };

    const onMouseUp = () => {
      setIsDraggingNode(false);
      setDraggingNodeId(null);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const handleCanvasMouseDown = (event) => {
    if (event.target.closest('.node')) return;
    setIsPanning(true);
    setInitialMousePos({ x: event.clientX, y: event.clientY });
    setInitialPan({ x: pan.x, y: pan.y });

    const onMouseMove = (e) => {
      const deltaX = e.clientX - initialMousePos.x;
      const deltaY = e.clientY - initialMousePos.y;
      setPan({ x: initialPan.x + deltaX, y: initialPan.y + deltaY });
    };

    const onMouseUp = () => {
      setIsPanning(false);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const handleHandleMouseDown = (event, { nodeId, handleId, type }) => {
    if (type !== 'source') return;
    event.stopPropagation();
    setIsCreatingEdge(true);
    setCreatingEdgeStart({ nodeId, handleId });
    const startPos = getHandlePosition({ nodeId, handleId }, handles, nodes);
    setTempEdgeEnd(startPos);

    const onMouseMove = (e) => {
      const canvasX = (e.clientX - pan.x) / zoom;
      const canvasY = (e.clientY - pan.y) / zoom;
      setTempEdgeEnd({ x: canvasX, y: canvasY });
    };

    const onMouseUp = (e) => {
      setIsCreatingEdge(false);
      const endX = (e.clientX - pan.x) / zoom;
      const endY = (e.clientY - pan.y) / zoom;
      let nearestHandle = null;
      let minDist = Infinity;
      Object.entries(handles).forEach(([nId, nodeHandles]) => {
        Object.values(nodeHandles).forEach((h) => {
          if (h.type === 'target') {
            const pos = getHandlePosition({ nodeId: nId, handleId: h.handleId }, handles, nodes);
            const dist = Math.sqrt((pos.x - endX) ** 2 + (pos.y - endY) ** 2);
            if (dist < 20 && dist < minDist) {
              minDist = dist;
              nearestHandle = { nodeId: nId, handleId: h.handleId };
            }
          }
        });
      });
      if (nearestHandle) {
        setEdges((prev) => [
          ...prev,
          {
            id: `${Date.now()}`,
            sourceNodeId: nodeId,
            sourceHandleId: handleId,
            targetNodeId: nearestHandle.nodeId,
            targetHandleId: nearestHandle.handleId,
          },
        ]);
      }
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const delta = event.deltaY > 0 ? 0.9 : 1.1;
    const mx = event.clientX;
    const my = event.clientY;
    const canvasX = (mx - pan.x) / zoom;
    const canvasY = (my - pan.y) / zoom;
    const newZoom = zoom * delta;
    const newPanX = mx - canvasX * newZoom;
    const newPanY = my - canvasY * newZoom;
    setZoom(newZoom);
    setPan({ x: newPanX, y: newPanY });
  };

  const zoomIn = () => {
    const viewportWidth = canvasRef.current.clientWidth;
    const viewportHeight = canvasRef.current.clientHeight;
    const centerX = viewportWidth / 2;
    const centerY = viewportHeight / 2;
    const canvasX = (centerX - pan.x) / zoom;
    const canvasY = (centerY - pan.y) / zoom;
    const newZoom = zoom * 1.2;
    const newPanX = centerX - canvasX * newZoom;
    const newPanY = centerY - canvasY * newZoom;
    setZoom(newZoom);
    setPan({ x: newPanX, y: newPanY });
  };

  const zoomOut = () => {
    const viewportWidth = canvasRef.current.clientWidth;
    const viewportHeight = canvasRef.current.clientHeight;
    const centerX = viewportWidth / 2;
    const centerY = viewportHeight / 2;
    const canvasX = (centerX - pan.x) / zoom;
    const canvasY = (centerY - pan.y) / zoom;
    const newZoom = zoom / 1.2;
    const newPanX = centerX - canvasX * newZoom;
    const newPanY = centerY - canvasY * newZoom;
    setZoom(newZoom);
    setPan({ x: newPanX, y: newPanY });
  };

  return (
    <HandleContext.Provider value={{ registerHandle, onHandleMouseDown: handleHandleMouseDown }}>
      <div
        ref={canvasRef}
        style={{
          width: '100vw',
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          background: '#f0f0f0',
        }}
        onMouseDown={handleCanvasMouseDown}
        onWheel={handleWheel}
      >
        <div
          style={{
            transform: `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`,
            transformOrigin: '0 0',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <svg
            style={{ position: 'absolute', width: '100%', height: '100%' }}
            pointerEvents="none"
          >
            <defs>
              <pattern
                id="grid"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <path
                  d="M 20 0 L 0 0 0 20"
                  fill="none"
                  stroke="rgba(0,0,0,0.1)"
                  strokeWidth="1"
                />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
            {edges.map((edge) => (
              <Edge key={edge.id} edge={edge} handles={handles} nodes={nodes} />
            ))}
            {isCreatingEdge && creatingEdgeStart && (
              <path
                d={getBezierPath(
                  getHandlePosition(creatingEdgeStart, handles, nodes),
                  tempEdgeEnd
                )}
                stroke="#555"
                strokeWidth="2"
                fill="none"
                pointerEvents="none"
              />
            )}
          </svg>
          {nodes.map((node) => (
            <Node
              key={node.id}
              node={node}
              onMouseDown={handleNodeMouseDown}
            />
          ))}
        </div>
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          <button onClick={zoomIn}>+</button>
          <button onClick={zoomOut}>-</button>
        </div>
      </div>
    </HandleContext.Provider>
  );
};

export { FlowDiagram, Handle };