import React, { useState, useEffect } from 'react';
import { Card, Button, message, Table, Space, Tag, Typography, Modal, Input, Form } from 'antd';
import { PlusOutlined, ImportOutlined, ExportOutlined, DeleteOutlined, PlayCircleOutlined, EditOutlined, UploadOutlined, SaveOutlined, HistoryOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CsvImport from './CsvImport';
import ReconciliationView from './ReconciliationView';
import { reconciliationService } from './reconciliationService';
import DefaultPage from '../Shared/DefaultPage';
import AccountSelect from '../Shared/AccountSelect';
import { formatDateShort } from '../../Lib/Helpers';
import Money from '../Shared/Money';
import CurrencyCell from '../Shared/CurrencyCell';
import Link from 'antd/lib/typography/Link';

const { Title, Text } = Typography;

const BankReconciliation = () => {
  const { t } = useTranslation();
  const [reconciliations, setReconciliations] = useState([]);
  const [importedData, setImportedData] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [reconciliationResults, setReconciliationResults] = useState(null);
  const [currentReconciliation, setCurrentReconciliation] = useState(null);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const [saveFormValues, setSaveFormValues] = useState({ name: '', bankAccount: 'Main Account' });
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isExecuted, setIsExecuted] = useState(false);
  const [savedReconciliation, setSavedReconciliation] = useState(null);
  const [currentView, setCurrentView] = useState('list');
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    // Load saved reconciliations when component mounts
    loadReconciliations();
  }, []);

  const loadReconciliations = () => {
    setLoading(true);
    reconciliationService.getAllReconciliations()
      .then(reconciliations => {
        setReconciliations(reconciliations);
        setLoading(false);
      })
      .catch(error => {
        message.error(t('Failed to load reconciliations') + ': ' + error);
        setLoading(false);
      });
  };

  const handleStartImport = () => {
    setIsImporting(true);
    setCurrentView('import');
    setImportedData(null);
    setReconciliationResults(null);
    setCurrentReconciliation(null);
  };

  const handleImportComplete = (data) => {
    setImportedData(data);
    setIsImporting(false);
    setCurrentView('detail');
    
    // Mock reconciliation process with the imported data
    const results = reconciliationService.processReconciliation(data);
    setReconciliationResults(results);
  };

  const handleCancelImport = () => {
    setIsImporting(false);
  };

  const handleReset = () => {
    // Close any open modals first
    setIsSaveModalVisible(false);
    
    // Reset all state
    setCurrentView('list');
    setCurrentReconciliation(null);
    setImportedData(null);
    setReconciliationResults(null);
    setIsSaved(false);
    setIsExecuted(false);
    setSavedReconciliation(null);
    loadReconciliations();
  };

  const showSaveModal = () => {
    // Reset form values 
    form.resetFields();
    // Set initial values
    form.setFieldsValue({
      name: t('Bank Reconciliation') + ' - ' + new Date().toLocaleDateString(),
      bankAccount: 'Main Account'
    });
    setIsSaveModalVisible(true);
  };

  const handleSaveModalOk = () => {
    form.validateFields().then(values => {
      handleSaveReconciliation(values);
      setIsSaveModalVisible(false);
    }).catch(info => {
      // Validation failed
    });
  };

  const handleSaveReconciliation = (values) => {
    setLoading(true);
    
    reconciliationService.saveReconciliation({
      name: values.name,
      bankAccount: values.accountId,
      importedData: importedData,
      reconciliationResults: reconciliationResults
    })
      .then(savedRec => {
        setCurrentReconciliation(savedRec);
        setSavedReconciliation(savedRec);
        setIsSaved(true);
        loadReconciliations();
        setLoading(false);
        message.success(t('Reconciliation saved successfully'));
        setIsSaveModalVisible(false);
      })
      .catch(error => {
        message.error(t('Failed to save reconciliation') + ': ' + error);
        setLoading(false);
      });
  };

  const handleSaveAndExecute = () => {
    form.validateFields().then(values => {
      setLoading(true);
      
      // First save the reconciliation
      const reconciliationData = {
        name: values.name,
        bankAccount: values.accountId,
        importedData: importedData,
        reconciliationResults: reconciliationResults
      };
      
      // Mock API call to save and then execute
      setTimeout(() => {
        // Save the reconciliation
        const savedRec = reconciliationService.saveReconciliation(reconciliationData);
        
        // Then execute the reconciliation
        const executedRec = reconciliationService.executeReconciliation(savedRec.id);
        
        // Update state
        setCurrentReconciliation(executedRec);
        setSavedReconciliation(executedRec);
        setIsSaved(true);
        setIsExecuted(true);
        loadReconciliations();
        
        setLoading(false);
        setIsSaveModalVisible(false);
        message.success(t('Reconciliation saved and executed successfully'));
      }, 1500);
    }).catch(info => {
      // Validation failed
    });
  };
  
  const handleExecuteReconciliation = (recordId) => {
    setLoading(true);
    
    const idToExecute = recordId || (currentReconciliation?.id);
    
    if (!idToExecute) {
      message.error(t('No reconciliation selected for execution'));
      setLoading(false);
      return;
    }
    
    reconciliationService.executeReconciliation(idToExecute)
      .then(executedRec => {
        if (currentView === 'detail') {
          setCurrentReconciliation(executedRec);
          setSavedReconciliation(executedRec);
          setIsExecuted(true);
        } else {
          loadReconciliations();
        }
        setLoading(false);
        message.success(t('Reconciliation executed successfully'));
      })
      .catch(error => {
        message.error(t('Failed to execute reconciliation') + ': ' + error);
        setLoading(false);
      });
  };
  
  const handleDeleteReconciliation = (id) => {
    Modal.confirm({
      title: t('Delete Reconciliation'),
      content: t('Are you sure you want to delete this reconciliation'),
      onOk() {
        setLoading(true);
        reconciliationService.deleteReconciliation(id)
          .then(() => {
            message.success(t('Reconciliation deleted successfully'));
            loadReconciliations();
          })
          .catch(error => {
            message.error(t('Failed to delete reconciliation') + ': ' + error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const viewReconciliation = (reconciliation) => {
    setCurrentReconciliation(reconciliation);
    setReconciliationResults(reconciliation.reconciliationResults);
    setImportedData(reconciliation.importedData);
    setCurrentView('detail');
    
    // Set executed state based on reconciliation status
    setIsExecuted(reconciliation.status === 'Executed');
    setSavedReconciliation(reconciliation);
  };

  const handleViewReconciliation = (record) => {
    setLoading(true);
    reconciliationService.getReconciliationById(record.id)
      .then(reconciliation => {
        viewReconciliation(reconciliation);
        setLoading(false);
      })
      .catch(error => {
        message.error(t('Failed to load reconciliation') + ': ' + error);
        setLoading(false);
      });
  };

  // Add a handler for reconciliation data updates
  const handleUpdateReconciliationResults = (updatedResults) => {
    // Update the reconciliation results state
    setReconciliationResults(updatedResults);
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('Date'),
      dataIndex: 'createdDate',
      key: 'date',
      render: date => date ? formatDateShort(date) : '',
      sorter: (a, b) => new Date(a.createdDate || 0) - new Date(b.createdDate || 0),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: status => {
        // Convert numeric status to text
        let statusText = 'Unknown';
        let color = 'default';
        
        switch(parseInt(status)) {
          case 0:
            statusText = 'Pending';
            color = 'blue';
            break;
          case 1:
            statusText = 'Executed';
            color = 'green';
            break;
          default:
            statusText = `Status: ${status}`;
        }
        
        return (
          <Tag color={color}>
            {t(statusText)}
          </Tag>
        );
      },
      filters: [
        { text: t('Pending'), value: 0 },
        { text: t('Executed'), value: 1 },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: t('Bank Account'),
      dataIndex: ["Account"],
      key: 'bankAccount',
      render: (v, record) => <Link href={`#/account/${v.id}`} copyable>{v.accNo}</Link>,
    },
    {
      title: t('Transactions'),
      dataIndex: 'transactionCount',
      key: 'transactionCount',
      sorter: (a, b) => (a.transactionCount || 0) - (b.transactionCount || 0),
    },
    {
      title: t('Matched'),
      dataIndex: 'matchedCount',
      key: 'matchedCount',
      render: (text, record) => (
        <Text style={{ color: '#52c41a' }}>{text}</Text>
      )
    },
    {
      title: t('Unmatched'),
      dataIndex: 'unmatchedCount',
      key: 'unmatchedCount',
      render: (text, record) => (
        <Text style={{ color: '#ff4d4f' }}>{text}</Text>
      )
    },
    {
      title: t('Total Amount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (amount, record) => {
        return <CurrencyCell value={amount} currency={record.currency} />;
      },
      sorter: (a, b) => {
        const aAmount = parseFloat(a.totalAmount) || 0;
        const bAmount = parseFloat(b.totalAmount) || 0;
        return aAmount - bAmount;
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Button 
            type="link" 
            icon={<EditOutlined />} 
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click
              handleViewReconciliation(record);
            }}
          >
            {t('View')}
          </Button>
          {record.status !== 'Executed' && (
            <Button 
              type="link" 
              icon={<PlayCircleOutlined />} 
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                handleExecuteReconciliation(record.id);
              }}
            >
              {t('Execute')}
            </Button>
          )}
          <Button 
            type="link" 
            danger 
            icon={<DeleteOutlined />} 
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click
              handleDeleteReconciliation(record.id);
            }}
          >
            {t('Delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const renderContent = () => {
    if (currentView === 'import') {
      return (
        <CsvImport 
          onImportComplete={handleImportComplete} 
          onCancel={handleReset} 
          t={t}
        />
      );
    } else if (currentView === 'detail') {
      return (
        <ReconciliationView
          importedData={importedData}
          reconciliationResults={reconciliationResults}
          onReset={handleReset}
          onSave={showSaveModal}
          onExecute={handleExecuteReconciliation}
          isSaved={currentReconciliation !== null}
          savedReconciliation={currentReconciliation}
          t={t}
          onUpdateReconciliationResults={handleUpdateReconciliationResults}
        />
      );
    } else {
      // Default view: list
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
            <Title level={5}>{t('Bank Reconciliations')}</Title>
          </div>
          
          <Table
            loading={loading}
            columns={columns}
            dataSource={reconciliations}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleViewReconciliation(record)
            })}
            locale={{ emptyText: t('No reconciliations found') }}
            pagination={{ pageSize: 10 }}
            bordered
          />
        </div>
      );
    }
  };

  const getExtraButtons = () => {
    if (currentView === 'list') {
      return [
        <Button 
          key="import" 
          type="primary" 
          icon={<ImportOutlined />} 
          onClick={handleStartImport}
        >
          {t('Import Bank Statement')}
        </Button>
      ];
    } else if (currentView === 'detail') {
      const buttons = [];
      
      if (!isSaved) {
        buttons.push(
          <Button 
            key="save" 
            type="primary" 
            icon={<SaveOutlined />} 
            onClick={showSaveModal}
          >
            {t('Save')}
          </Button>
        );
      }
      
      if (isSaved && currentReconciliation?.status !== 'Executed') {
        buttons.push(
          <Button 
            key="execute" 
            type="primary" 
            icon={<PlayCircleOutlined />} 
            onClick={() => handleExecuteReconciliation()}
          >
            {t('Execute')}
          </Button>
        );
      }
      
      buttons.push(
        <Button 
          key="back" 
          icon={<HistoryOutlined />} 
          onClick={handleReset}
        >
          {t('Back to List')}
        </Button>
      );
      
      return buttons;
    }
    
    return null;
  };

  return (
    <DefaultPage
      title={t('Bank Reconciliation')}
      subTitle={t('Reconcile bank statements with accounting records')}
      icon="bank"
      iconStyle={{ backgroundColor: "#1890ff" }}
      extra={getExtraButtons()}
    >
      {renderContent()}

      {/* Save Modal - moved outside renderContent */}
      <Modal
        title={t('Save Reconciliation')}
        open={isSaveModalVisible}
        onCancel={() => setIsSaveModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsSaveModalVisible(false)}>
            {t('Cancel')}
          </Button>,
          <Button key="saveOnly" type="primary" onClick={handleSaveModalOk} loading={loading}>
            {t('Save')}
          </Button>,
          <Button key="saveAndExecute" type="primary" onClick={handleSaveAndExecute} loading={loading}>
            {t('Save & Execute')}
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={t('Reconciliation Name')}
            rules={[{ required: true, message: t('Please enter a name for this reconciliation') }]}
          >
            <Input />
          </Form.Item>
          
          <Form.Item
            name="accountId"
            label={t('Bank Account')}
            rules={[{ required: true, message: t('Please select a bank account') }]}
          >
            <AccountSelect />
          </Form.Item>
        </Form>
      </Modal>
    </DefaultPage>
  );
};

export default BankReconciliation; 