import React, { useState, useEffect } from 'react';
import { 
  Timeline, 
  Card, 
  Typography, 
  Tag, 
  Button, 
  Empty, 
  Spin,
  Tooltip,
  Badge,
  Space,
  Dropdown,
  Menu
} from 'antd';
import {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  UserOutlined,
  EllipsisOutlined,
  PlusOutlined,
  CarOutlined,
  TeamOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { exe } from '../../Lib/Dal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Text, Title, Paragraph } = Typography;

const ActivityFeed = ({ contactId, onAddActivity, onEditActivity, refreshTrigger, onActivitiesLoaded }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (contactId) {
      loadActivities();
    }
  }, [contactId, refreshTrigger]);

  useEffect(() => {
    if (onActivitiesLoaded && activities) {
      onActivitiesLoaded(activities.length);
    }
  }, [activities, onActivitiesLoaded]);

  const loadActivities = () => {
    setLoading(true);
    exe("RepoFunnelContactActivity", {
      operation: "GET",
      filter: `contactId=${contactId}`,
      include: ["Contact"]
    }).then(response => {
      setLoading(false);
      if (response.ok && response.outData) {
        setActivities(response.outData);
        if (onActivitiesLoaded) {
          onActivitiesLoaded(response.outData.length);
        }
      } else {
        console.error("Failed to load activities:", response);
        setActivities([]);
        if (onActivitiesLoaded) {
          onActivitiesLoaded(0);
        }
      }
    }).catch(error => {
      console.error("Error loading activities:", error);
      setLoading(false);
      setActivities([]);
      if (onActivitiesLoaded) {
        onActivitiesLoaded(0);
      }
    });
  };

  const handleMenuClick = (e, activity) => {
    if (e.key === 'edit') {
      onEditActivity(activity);
    } else if (e.key === 'delete') {
      deleteActivity(activity);
    }
  };

  const deleteActivity = (activity) => {
    setLoading(true);
    exe("RepoFunnelContactActivity", {
      operation: "DELETE",
      entity: {
        id: activity.id
      }
    }).then(response => {
      setLoading(false);
      if (response.ok) {
        loadActivities();
      } else {
        console.error("Failed to delete activity:", response);
      }
    }).catch(error => {
      console.error("Error deleting activity:", error);
      setLoading(false);
    });
  };

  const getActivityIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'call':
        return <PhoneOutlined style={{ fontSize: '16px', color: '#1890ff' }} />;
      case 'email':
        return <MailOutlined style={{ fontSize: '16px', color: '#52c41a' }} />;
      case 'meeting':
        return <TeamOutlined style={{ fontSize: '16px', color: '#722ed1' }} />;
      case 'note':
        return <FileTextOutlined style={{ fontSize: '16px', color: '#faad14' }} />;
      case 'task':
        return <CheckCircleOutlined style={{ fontSize: '16px', color: '#f5222d' }} />;
      case 'visit':
        return <CarOutlined style={{ fontSize: '16px', color: '#13c2c2' }} />;
      case 'message':
        return <MessageOutlined style={{ fontSize: '16px', color: '#eb2f96' }} />;
      default:
        return <ClockCircleOutlined style={{ fontSize: '16px', color: '#8c8c8c' }} />;
    }
  };

  const getOutcomeColor = (outcome) => {
    if (!outcome) return '';
    
    switch(outcome.toLowerCase()) {
      case 'positive':
        return 'success';
      case 'negative':
        return 'error';
      case 'neutral':
        return 'warning';
      default:
        return 'default';
    }
  };
  
  const formatDuration = (minutes) => {
    if (!minutes) return '';
    
    if (minutes < 60) {
      return `${minutes}m`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return remainingMinutes > 0 ? `${hours}h ${remainingMinutes}m` : `${hours}h`;
    }
  };

  const activityMenu = (activity) => (
    <Menu onClick={(e) => handleMenuClick(e, activity)}>
      <Menu.Item key="edit" icon={<EditOutlined />}>
        {t('Edit')}
      </Menu.Item>
      <Menu.Item key="delete" danger icon={<DeleteOutlined />}>
        {t('Delete')}
      </Menu.Item>
    </Menu>
  );

  const renderTimelineItem = (activity) => {
    const dateFormatted = moment(activity.date).format('MMM D, YYYY [at] h:mm A');
    const isPastDue = activity.dueDate && !activity.completed && moment(activity.dueDate).isBefore(moment());
    
    return (
      <Timeline.Item 
        key={activity.id} 
        dot={getActivityIcon(activity.type)}
      >
        <Card 
          size="small" 
          style={{ 
            marginBottom: 16,
            borderRight: `3px solid ${
              activity.type === 'call' ? '#1890ff' : 
              activity.type === 'email' ? '#52c41a' : 
              activity.type === 'meeting' ? '#722ed1' : 
              activity.type === 'note' ? '#faad14' : 
              activity.type === 'task' ? '#f5222d' :
              activity.type === 'visit' ? '#13c2c2' :
              '#8c8c8c'
            }`
          }}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>
                {activity.title}
                {activity.dueDate && !activity.completed && (
                  <Badge 
                    status={isPastDue ? "error" : "processing"} 
                    text={isPastDue ? t("Past due") : t("Upcoming")}
                    style={{ marginLeft: 8, fontSize: '12px' }}
                  />
                )}
              </span>
              <Space>
                <Tag color={activity.type === 'call' ? 'blue' : 
                        activity.type === 'email' ? 'green' : 
                        activity.type === 'meeting' ? 'purple' : 
                        activity.type === 'note' ? 'orange' : 
                        activity.type === 'task' ? 'red' : 
                        activity.type === 'visit' ? 'cyan' :
                        'default'}>
                  {activity.type}
                </Tag>
                {activity.outcome && (
                  <Tag color={getOutcomeColor(activity.outcome)}>
                    {activity.outcome}
                  </Tag>
                )}
                {activity.duration && (
                  <Tag>{formatDuration(activity.duration)}</Tag>
                )}
              </Space>
            </div>
          }
          extra={
            <Dropdown overlay={activityMenu(activity)} trigger={['click']}>
              <Button type="text" icon={<EllipsisOutlined />} size="small" />
            </Dropdown>
          }
        >
          <div>
            <Text type="secondary" style={{ fontSize: '12px', display: 'block', marginBottom: 8 }}>
              {dateFormatted} • {activity.createdBy && (
                <Tooltip title={t("Logged by")}>
                  <span>
                    <UserOutlined style={{ marginRight: 4 }} />
                    {activity.createdBy}
                  </span>
                </Tooltip>
              )}
            </Text>
          
            {activity.description && (
              <Paragraph 
                ellipsis={{ rows: 3, expandable: true, symbol: t('more') }}
                style={{ marginBottom: 8 }}
              >
                {activity.description}
              </Paragraph>
            )}
            
            {activity.nextSteps && (
              <div>
                <Text strong>{t('Next steps')}: </Text>
                <Text>{activity.nextSteps}</Text>
              </div>
            )}
            
            {activity.dueDate && (
              <div style={{ marginTop: 8 }}>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  <CalendarOutlined style={{ marginRight: 4 }} />
                  {activity.completed ? 
                    t('Completed') : 
                    `${t('Due')}: ${moment(activity.dueDate).format('MMM D, YYYY')}`
                  }
                </Text>
              </div>
            )}
          </div>
        </Card>
      </Timeline.Item>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={4}>{t('Activity Timeline')}</Title>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={onAddActivity}
        >
          {t('Log Activity')}
        </Button>
      </div>
      
      <Spin spinning={loading}>
        {activities.length > 0 ? (
          <Timeline>
            {activities.map(renderTimelineItem)}
          </Timeline>
        ) : (
          <Empty 
            description={t('No activities recorded yet')} 
            image={Empty.PRESENTED_IMAGE_SIMPLE} 
          />
        )}
      </Spin>
    </div>
  );
};

export default ActivityFeed; 