import React, { useState } from 'react';
import { Card, Button, Typography, Divider, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutlined, CloudSyncOutlined, DownloadOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { exe } from '../../Lib/Dal';


const { Text, Title } = Typography;

const VersionUpdate = () => {
    const { t } = useTranslation();
    const [isChecking, setIsChecking] = useState(false);
    const [latestVersion, setLatestVersion] = useState(null);
    const [currentVersion, setCurrentVersion] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);


    const checkForUpdates = () => {
        setIsChecking(true);
        exe("CheckUpdates").then(r => {
            setIsChecking(false);
            if(r.ok){
                setLatestVersion(r.outData.latest);
                setCurrentVersion(r.outData.current);
            }
        });
    }

    const update = () => {
        setIsUpdating(true);
        exe("VersionUpdate").then(r => {
            setIsUpdating(false);
            if(r.ok){
                message.success(r.msg);
            }else {
                message.error(r.msg);
            }
        });
    }
    return (
        <div>
            <Title level={4}>{t("Version Manager")}</Title>
            <Button type="link" onClick={checkForUpdates} loading={isChecking} icon={<CloudSyncOutlined />}>
                {t("Check for updates")}
            </Button>
            {isChecking && <LoadingOutlined />}
            <br />
            <br />
            {latestVersion &&<div>{t("Latest version")}: <Text code>{latestVersion}</Text></div>}
            <br />
            {currentVersion && <div>{t("Current version")}: <Text code>{currentVersion}</Text></div>}
            <br />
            {latestVersion && currentVersion && <Button type="primary" icon={<DownloadOutlined />} onClick={update} loading={isUpdating}>
                {t("Update")}
            </Button>}
        </div>
    );
};

export default VersionUpdate;