import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Select, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';

const { Option } = Select;

const StageEdit = ({ visible, selected, onCancel, onSave, onDelete }) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [t] = useTranslation();

  // Predefined color options
  const colorOptions = [
    { name: 'Blue', value: '#e6f7ff' },
    { name: 'Green', value: '#d9f7be' },
    { name: 'Yellow', value: '#fffbe6' },
    { name: 'Orange', value: '#fff2e8' },
    { name: 'Red', value: '#fff1f0' },
    { name: 'Purple', value: '#f9f0ff' },
    { name: 'Cyan', value: '#e6fffb' },
    { name: 'Grey', value: '#f0f0f0' },
  ];

  // Status options
  const statusOptions = [
    { name: 'Backlog', value: 0 },
    { name: 'Started', value: 1 },
    { name: 'Won', value: 2 },
    { name: 'Lost', value: 3 },
    { name: 'Cancelled', value: 4 },
  ];

  useEffect(() => {
    if (visible && selected) {
      // Initialize form with selected stage values
      form.setFieldsValue({
        ...selected,
        // Make sure all fields have appropriate values
        name: selected.name || '',
        color: selected.color || '#f0f0f0',
        probability: selected.probability || 0,
        status: selected.status !== undefined ? selected.status : 0,
        active: selected.active !== undefined ? selected.active : true,
        order: selected.order || 0
      });
    }
  }, [visible, selected, form]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        setConfirmLoading(true);
        
        if (!selected.funnelId) {
          setConfirmLoading(false);
          message.error(t('Cannot save stage: Missing funnel ID'));
          return;
        }
        
        // Prepare the stage data - preserve the id if it exists
        const stageData = {
          ...values,
          id: selected.id, // This will be undefined for new stages
          funnelId: selected.funnelId,
          // Ensure required fields have default values if empty
          status: values.status !== undefined ? values.status : 0,
          active: values.active !== undefined ? values.active : true,
          probability: values.probability !== undefined ? values.probability : 0,
          order: values.order !== undefined ? values.order : 10000
        };

        console.log('Saving stage with data:', stageData);

        // Determine if this is a new stage or an update
        const operation = selected.id ? "UPDATE" : "ADD";
        
        // Save to database
        exe("RepoFunnelStage", {
          operation,
          entity: stageData
        }).then(r => {
          setConfirmLoading(false);
          if (r.ok && r.outData && r.outData.length > 0) {
            const savedStage = r.outData[0];
            console.log('Stage saved successfully:', savedStage);
            message.success(selected.id ? t('Stage updated successfully') : t('Stage created successfully'));
            // Pass the saved stage (with id if it was a new stage) back to parent
            onSave(savedStage);
          } else {
            console.error('Failed to save stage:', r);
            message.error(t('Failed to save stage') + (r.msg ? ': ' + r.msg : ''));
          }
        }).catch(err => {
          console.error('Error saving stage:', err);
          setConfirmLoading(false);
          message.error(t('Error saving stage'));
        });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleDelete = () => {
    // Only allow deletion of existing stages (with an ID)
    if (!selected.id) {
      onCancel();
      return;
    }

    // Instead of deleting the stage here, close the modal and pass the stage to the parent's onDelete handler
    setConfirmLoading(true);
    
    // Brief delay to show loading state
    setTimeout(() => {
      setConfirmLoading(false);
      // Let the parent component handle the actual deletion
      onDelete(selected);
    }, 300);
  };

  // Color preview box
  const ColorPreview = ({ color }) => (
    <div
      style={{
        width: 20,
        height: 20,
        background: color,
        borderRadius: 4,
        border: '1px solid #d9d9d9',
        marginRight: 8,
      }}
    />
  );

  return (
    <Modal
      title={selected?.id ? t('Edit Stage') : t('Add Stage')}
      open={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      width={500}
      footer={[
        selected?.id && (
          <Button key="delete" danger onClick={handleDelete}>
            {t('Delete')}
          </Button>
        ),
        <Button key="cancel" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
        <Button key="submit" type="primary" loading={confirmLoading} onClick={handleOk}>
          {t('Save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label={t('Stage Name')}
          rules={[{ required: true, message: t('Please enter stage name') }]}
        >
          <Input placeholder={t('e.g., Lead, Proposal, etc.')} />
        </Form.Item>
        
        <Form.Item
          name="color"
          label={t('Color')}
        >
          <Select
            placeholder={t('Select a color')}
            optionLabelProp="label"
          >
            {colorOptions.map(color => (
              <Option key={color.value} value={color.value} label={color.name}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ColorPreview color={color.value} />
                  {color.name}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        <Form.Item
          name="probability"
          label={t('Probability (%)')}
          rules={[{ required: true, message: t('Please enter probability') }]}
        >
          <InputNumber 
            min={0} 
            max={100}
            style={{ width: '100%' }}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
          />
        </Form.Item>
        
        <Form.Item
          name="order"
          label={t('Display Order')}
          tooltip={t('Lower values will be shown first')}
          rules={[{ required: true, message: t('Please enter display order') }]}
        >
          <InputNumber 
            style={{ width: '100%' }}
            min={0}
          />
        </Form.Item>
        
        <Form.Item
          name="status"
          label={t('Status Type')}
        >
          <Select placeholder={t('Select status type')}>
            {statusOptions.map(status => (
              <Option key={status.value} value={status.value}>
                {status.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        
        <Form.Item
          name="active"
          label={t('Active')}
          valuePropName="checked"
        >
          <Select>
            <Option value={true}>{t('Yes')}</Option>
            <Option value={false}>{t('No')}</Option>
          </Select>
        </Form.Item>
        
        <Form.Item
          name="description"
          label={t('Description')}
        >
          <Input.TextArea rows={3} placeholder={t('Optional description')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StageEdit; 