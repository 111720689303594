import { Input, Dropdown, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import React, { useState, useRef, useEffect } from 'react';
import { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';

function TextUpdaterNode({ data, id }) {
  const [textAreaHeight, setTextAreaHeight] = useState(60); // Default height
  const textAreaRef = useRef(null);
  const nodeRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);

  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const handleDelete = () => {
    // Call the onDelete callback passed via data props with this node's id
    console.log(data,id);
    if (data.onDelete) {
      data.onDelete(id);
    }
  };

  // Use a resize observer to track textarea height changes
  useEffect(() => {
    if (textAreaRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          setTextAreaHeight(entry.target.clientHeight);
        }
      });
      
      resizeObserver.observe(textAreaRef.current.resizableTextArea.textArea);
      
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  // Set up global event listeners to detect resize operations
  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!textAreaRef.current) return;
      
      const textArea = textAreaRef.current.resizableTextArea.textArea;
      const rect = textArea.getBoundingClientRect();
      
      // Check if mouse is in the resize handle area (bottom-right corner)
      if (
        e.target === textArea && 
        e.clientX > rect.right - 16 && 
        e.clientY > rect.bottom - 16
      ) {
        setIsResizing(true);
        e.stopPropagation();
      }
    };
    
    const handleMouseUp = () => {
      setIsResizing(false);
    };
    
    const handleMouseMove = (e) => {
      if (isResizing) {
        e.stopPropagation();
        e.preventDefault();
      }
    };
    
    document.addEventListener('mousedown', handleMouseDown, true);
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
    
    return () => {
      document.removeEventListener('mousedown', handleMouseDown, true);
      document.removeEventListener('mouseup', handleMouseUp, true);
      document.removeEventListener('mousemove', handleMouseMove, true);
    };
  }, [isResizing]);

  const items = [
    {
      key: 'delete',
      label: 'Delete',
      onClick: handleDelete,
    },
  ];

  return (
    <div 
      ref={nodeRef}
      className={isResizing ? 'resizing-node' : ''}
      style={{
        height: 'auto',
        border: '1px solid #eee',
        padding: 5,
        borderRadius: 5,
        background: 'white',
        position: 'relative',
        pointerEvents: isResizing ? 'none' : 'auto'
      }}
    >
      <Handle type="target" position={Position.Left} style={{ pointerEvents: isResizing ? 'none' : 'auto' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <label htmlFor="text" style={{ fontSize: 12, fontWeight: 'bold' }}>Text:</label>
        <Dropdown menu={{ items }} placement="bottomRight">
          <Button type="text" icon={<MoreOutlined />} size="small" />
        </Dropdown>
      </div>
      <div>
        <Input.TextArea 
          id="text" 
          name="text" 
          onChange={onChange} 
          ref={textAreaRef}
          style={{ 
            resize: 'vertical', 
            minHeight: 60,
            height: textAreaHeight,
            pointerEvents: 'auto'
          }}
        />
      </div>
      <Handle type="source" position={Position.Right} id="a" style={{ pointerEvents: isResizing ? 'none' : 'auto' }} />
    </div>
  );
}

export default TextUpdaterNode;