import React, { useState, useEffect } from 'react';
import { Layout, Typography, message, Button } from 'antd';
import DefaultPage  from '../Shared/DefaultPage';
import FixedAssetsStats from './FixedAssetsStats';
import FixedAssetsTable from './FixedAssetsTable';
import FixedAssetForm from './FixedAssetForm';
import { exe } from '../../Lib/Dal';
import { useTranslation } from 'react-i18next';
import { CalculatorOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { Title } = Typography;

const FixedAssets = () => {
  const [assets, setAssets] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    loadAssets();
  }, []);

  const loadAssets = async () => {
    setLoading(true);
    exe('RepoFixedAsset', {operation:"GET"}).then(r=>{
        setLoading(false);
        if(r.ok){
            //calculating the stats
            const totalPurchaseValue = r.outData.reduce((sum, asset) => sum + (asset.purchasePrice || 0), 0);
            const totalBookValue = r.outData.reduce((sum, asset) => sum + (asset.currentBookValue || 0), 0);
            const totalDepreciation = r.outData.reduce((sum, asset) => sum + (asset.depreciationAmount || 0), 0);
            const activeAssets = r.outData.filter(asset => asset.status === 'Active').length;
            const inactiveAssets = r.outData.filter(asset => asset.status !== 'Active').length;
            
            // Get currencies breakdown for both purchase and book values
            const purchaseValueByCurrency = {};
            const bookValueByCurrency = {};
            
            r.outData.forEach(asset => {
              if (asset.currency) {
                // Initialize if not exists
                purchaseValueByCurrency[asset.currency] = purchaseValueByCurrency[asset.currency] || 0;
                bookValueByCurrency[asset.currency] = bookValueByCurrency[asset.currency] || 0;
                
                // Add values
                purchaseValueByCurrency[asset.currency] += asset.purchasePrice || 0;
                bookValueByCurrency[asset.currency] += asset.currentBookValue || 0;
              }
            });
            
            setAssets(r.outData || []);
            setStats({
                totalAssets: r.outData.length || 0,
                totalPurchaseValue: totalPurchaseValue || 0,
                totalBookValue: totalBookValue || 0,
                totalDepreciation: totalDepreciation || 0,
                activeAssets: activeAssets || 0,
                inactiveAssets: inactiveAssets || 0,
                currencies: purchaseValueByCurrency, // This maintains backward compatibility
                purchaseValueByCurrency: purchaseValueByCurrency,
                bookValueByCurrency: bookValueByCurrency
            });
        }else {
            message.error(r.msg);
        }
      });
  };

  const handleAssetSelect = (asset) => {
    setSelectedAsset(asset);
    setShowForm(true);
  };

  const handleCreateNew = () => {
    setSelectedAsset(null);
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setSelectedAsset(null);
  };

  const handleFormSubmit = async (assetData) => {
      const operation = selectedAsset ? 'UPDATE' : 'ADD';
      setLoading(true);
      exe('RepoFixedAsset', {operation:operation, entity:assetData}).then(r=>{
        setLoading(false);
        if(r.ok){
            loadAssets();
            setShowForm(false);
            setSelectedAsset(null);
        }else {
            message.error(r.msg);
        }
      });
  };

  const handleComputeDepreciation = () => {
    setLoading(true);
    exe('ComputeDepreciation').then(r=>{
        setLoading(false);
        if(r.ok){
            message.success(r.msg);
            loadAssets();
        }else {
            message.error(r.msg);
        }
    });
  };

  return (
    <DefaultPage title={t('Fixed Assets Management')} icon="desktop" extra={<Button type="link" icon={<CalculatorOutlined />} onClick={handleComputeDepreciation} loading={loading}>{t('Compute Depreciation')}</Button>}>
      <Content style={{ padding: '0 24px' }}>
        <FixedAssetsStats stats={stats} loading={loading} />
        
        <div style={{ marginTop: 24 }}>
          <FixedAssetsTable 
            assets={assets} 
            loading={loading} 
            onAssetSelect={handleAssetSelect}
            onCreateNew={handleCreateNew}
          />
        </div>

        {showForm && (
          <FixedAssetForm
            asset={selectedAsset}
            onSubmit={handleFormSubmit}
            onClose={handleFormClose}
            visible={showForm}
          />
        )}
      </Content>
    </DefaultPage>
  );
};

export default FixedAssets; 