import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { HolderOutlined } from '@ant-design/icons';

const DraggableModal = ({ title, visible, onOk, onCancel, children, ...props }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if(visible&&position.x === 0 && position.y === 0) {
      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;
      //get the width and height of the modal
      const modalElement = document.querySelector('.ant-modal');
      
        const style = window.getComputedStyle(modalElement);
        console.log({width:style.width,height:style.height})
        const modalWidth = parseInt(style.width, 10);
        const modalHeight =699;

      setPosition({
        x: Math.max(0, viewportWidth / 2 - modalWidth / 2),
        y: Math.max(0, viewportHeight / 2 - modalHeight / 2)
        });
    }
  }, [visible]);
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - dragStart.x,
        y: e.clientY - dragStart.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStart]);

  return (
    <Modal
      title={
        <div
          style={{ 
            cursor: 'move',
            width: '100%',
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            ':hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
          onMouseDown={handleMouseDown}
        >
          <HolderOutlined 
            style={{ 
              cursor: 'move',
              color: '#999',
              fontSize: '16px'
            }} 
          />
          {title}
        </div>
      }
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      style={{ 
        position: 'fixed',
        top: position.y,
        left: position.x,
        margin: 0
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default DraggableModal;
