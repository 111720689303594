import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import { Select } from "antd";
import Currency from "./Currency";
import { useTranslation } from "react-i18next";

const AccountSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.holderId) loadHolderAccounts(props.holderId);
  }, [props.holderId]);

  useEffect(() => {
    if (props.value && !data.find((p) => p.id == props.value)) loadValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.accNo) loadAccNo(props.accNo);
  }, [props.accNo]);

  const loadAccNo = (accNo) => {
    setLoading(true);
    exe("RepoAccount", { operation: "GET", filter: `accNo='${accNo}'` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        if (r.outData.length > 0) props.onChange(r.outData[0].id);
      }
    });
  };
  const loadHolderAccounts = (holderId) => {
    setLoading(true);
    exe("RepoAccount", { operation: "GET", filter: `holderId=${holderId}` }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
    });
  };

  const loadValue = (value) => {
    if (!value) return;
    exe("RepoAccount", { operation: "GET", filter: `id=${value}` }).then((r) => {
      if (r.ok) setData(r.outData);
    });
  };
  const handleSearch = (v) => {
    if (!v) return;
    exe("RepoAccount", { operation: "GET", filter: "accNo LIKE N'%" + v + "%'", size: 10 }).then((r) => {
      if (r.ok) setData(r.outData);
    });
  };
  const Options = data.map((d) => (
    <Select.Option value={d.id} key={d.id} currency={d.currency} record={d}>
      {d.accNo}
      {<Currency.Flag currency={d.currency} />}
    </Select.Option>
  ));

  return (
    <Select
      showSearch
      loading={loading}
      value={props.value}
      placeholder={isNaN(props.holderId) ? t("Type to search account") : t("Select account")}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={!isNaN(props.holderId)}
      filterOption={false}
      onSearch={isNaN(props.holderId) ? handleSearch : undefined}
      onChange={props.onChange}
      onSelect={props.onSelect && props.onSelect}
      notFoundContent={null}>
      {Options}
    </Select>
  );
};

export default AccountSelect;
