import React, { Component } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Row, Col, DatePicker, Button, Checkbox, Tabs, Switch, message } from "antd";
import FormLabel from "../Shared/FormLabel";
import CoverageSelect from "./CoverageSelect";
import Money from "../Shared/Money";
import DateField from "../Shared/DateField";
import moment from "moment";
import PolicyRoleSelect from "../Shared/PolicyRoleSelect";
import Loadings from "./Loadings";
import { equal } from "../../Lib/Dal";
import CoverageClaimList from "./CoverageClaimList";
import { round2 } from "../../Lib/Helpers";
import { withTranslation } from "react-i18next";
import ParentCoverageSelect from "./ParentCoverageSelect";
import ContractCodeSelect from "../Contract/ContractCodeSelect";
import Percentage from "../Shared/Percentage";
import CoContractSelect from "../Coinsurance/CoContractSelect";
import CustomForm from "../Shared/CustomForm";
import PremiumDetail from "./PremiumDetail";

class CoverageDetail extends Component {
  state={
    showPremiumDetail:false
  }
  onSubmit() {
    const values = this.props.form.getFieldsValue();
    //custom form
    const covConfig = this.props.config.Coverages.find((p) => p.code == this.props.value.code);
    if (covConfig.customFormId && window.customCoverageForm && window.customCoverageForm.instanceContainers[0]) {
      if (window.customCoverageForm.instanceContainers[0].reportValidity()) {
          values.jCustom = JSON.stringify(window.customCoverageForm.userData);
      } else {
          message.error(this.props.t("Please check custom form validation errors"));
          return;
      }
  }
    //UI purposes. This is calculated on the backend side
    values.extraPremium = (values.loadingInsuredSum * values.limit) / 100 + (values.loading * values.basePremium) / 100;
    values.extraPremium = Math.round((values.extraPremium + Number.EPSILON) * 100) / 100;
    values.premium = values.basePremium + values.extraPremium;
    console.log(values);
    this.props.onSubmit(values);
    this.props.form.resetFields();
  }
  componentDidMount() {
    this.props.form.setFieldsValue(this.props.value);
    //globally exposing form instance
    window.coverageDetailForm = this.props.form;
  }
  componentWillUnmount() {
    //remove form instance from global scope
    delete window.coverageDetailForm;
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props.value, prevProps.value)) {
      this.props.form.setFieldsValue(this.props.value);
    }
  }
  computeFormula(f, policyContext) {
    try {
      const context = {};
      context.pol = policyContext;
      context.cfg = this.props.config;
      f = f.replace("_", "context.");
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side. Please quote the policy", error);
      return undefined;
    }
  }
  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }
  validateEndDate = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    const start = getFieldValue("start");
    if (start && value) {
      const startDate = moment(start);
      const endDate = moment(value);
      if (endDate < startDate) {
        callback(this.props.t("Can't be before than start date"));
      }
    }

    callback();
  };
  validateStartDate = (rule, value, callback) => {
    const { getFieldValue } = this.props.form;
    const end = getFieldValue("end");
    if (end && value) {
      const endDate = moment(end);
      const startDate = moment(value);
      if (startDate > endDate) {
        callback(this.props.t("Can't be after than end date"));
      }
    }

    callback();
  };
  onChangeLoadings(loadings) {
    const totalLoading = loadings.filter(p => !p.insuredSumBased).reduce((a, p) => p.loading + a, 0);
    const totalLoadingInsuredSum = loadings.filter(p => p.insuredSumBased).reduce((a, p) => p.loading + a, 0);
    this.props.form.setFieldsValue({ loading: totalLoading, loadingInsuredSum: totalLoadingInsuredSum });
  }
  getParameter(parameter) {
    if(!this.props.config||!this.props.value) return undefined;
    const covConfig = this.props.config.Coverages.find((p) => p.code === this.props.value.code);
    if(!covConfig) return undefined;
    return (covConfig[parameter] == undefined || covConfig[parameter] == -1) ? undefined : covConfig[parameter];
  }
  onChangeLimit = (v) => {
    // Only set manualLimit to true if the change was triggered by user input
    if (v !== undefined && v !== null && this.props.form.isFieldTouched('limit')) {
      this.props.form.setFieldsValue({ manualLimit: true, limitFactor: undefined  });
    }
    const baseLimit = this.props.form.getFieldValue("baseLimit");
    if (!baseLimit) {
      const limit = this.props.form.getFieldValue("limit");
      this.props.form.setFieldsValue({ baseLimit: limit });
    }

    if (!this.props.policy) return;
    const covConfig = this.props.config.Coverages.find((p) => p.code == this.props.value.code);
    const manualPremium = this.props.form.getFieldValue("manualPremium");
    if (covConfig.premiumFunctionOfLimit && !manualPremium) {
      const updatedContext = this.props.policy;
      updatedContext.Coverages = updatedContext.Coverages.map((cov) => (cov.code == this.props.value.code ? v : cov));
      const cov = this.props.form.getFieldsValue();
      const formula = covConfig.premiumFunctionOfLimit.replace("_limit", cov.limit);
      const premium = this.computeFormula(formula, updatedContext);
      if (premium) this.props.form.setFieldsValue({ basePremium: round2(premium) });
    }
  };
  onChangePremium = (v) => {
    //only set manualPremium to true if the change was triggered by user input
    if (v !== undefined && v !== null && this.props.form.isFieldTouched('basePremium')) {
      this.props.form.setFieldsValue({ manualPremium: true });
    }
    if (!this.props.policy) return;
    const covConfig = this.props.config.Coverages.find((p) => p.code == this.props.value.code);
    const manualLimit = this.props.form.getFieldValue("manualLimit");
    if (covConfig.limitFunctionOfPremium && !manualLimit) {
      const updatedContext = this.props.policy;
      updatedContext.Coverages = updatedContext.Coverages.map((cov) => (cov.code == this.props.value.code ? v : cov));
      const cov = this.props.form.getFieldsValue();
      const formula = covConfig.limitFunctionOfPremium.replace("_premium", cov.basePremium);
      const limit = this.computeFormula(formula, updatedContext);
      if (limit) this.props.form.setFieldsValue({ limit: round2(limit) });
      console.log({ limit, formula, cov });
    }
  };
  onChangeLimitFactor = v => {
    const values = this.props.form.getFieldsValue();
    if (!v || isNaN(v)) {
      this.props.form.setFieldsValue({ limit: values.baseLimit, manualLimit: false });
      return;
    }
    if (!values.baseLimit) {
      //setting base before changes
      this.props.form.setFieldsValue({ baseLimit: values.limit, limit: round2(values.limit * v), manualLimit: true });
    } else {
      this.props.form.setFieldsValue({ limit: round2(values.baseLimit * v), manualLimit: true })
    }
  }
  render() {
    const { t, i18n } = this.props;
    const field = this.props.form.getFieldDecorator;
    const currency = this.props.currency;
    const covConfig = this.props.config.Coverages.find((p) => p.code == this.props.value.code);
    const values = this.props.form.getFieldsValue();
    return (
      <div>
        <Form>
          <div style={{ display: "none" }}>
            <Form.Item label={t("Id")}>{field("id")(<FormLabel />)}</Form.Item>
            <Form.Item label={t("Basic")}>{field("basic")(<FormLabel />)}</Form.Item>
            {field("Benefits")}
            {field("jPremiumDetail")}
          </div>
          <Tabs>
            <Tabs.TabPane tab={t("Main")} key="main">
            <div style={{display:values.jPremiumDetail?"block":"none"}}>
              <Button type="link" style={{padding:0}} onClick={()=>this.setState({showPremiumDetail:true})}>{t("Premium Detail")}</Button>
              <PremiumDetail premiumData={values.jPremiumDetail} visible={this.state.showPremiumDetail} onClose={()=>this.setState({showPremiumDetail:false})} />
            </div>
              <div className={this.props.readOnly || this.getParameter("restrictUserEdition") ? "disabled" : ""}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<FormLabel paragraph code />)}</Form.Item>
                    <div style={{ display: "flex" }}>
                      <Form.Item label={t("Sum Assured")} style={{ flex: 1 }}>
                        {field("limit", { rules: [{ required: true },
                        { validator: (rule, value, callback) => {
                          const maxLimit = this.getParameter("maxLimit");
                          if (maxLimit !== undefined && value > maxLimit) {
                            callback(t(`Sum Assured must be less than ${maxLimit}`));
                          } else {
                            callback();
                          }
                        }},
                        { validator: (rule, value, callback) => {
                          const minLimit = this.getParameter("minLimit");
                          if (minLimit !== undefined && value < minLimit) {
                            callback(t(`Sum Assured must be greater than ${minLimit}`));
                          } else {
                            callback();
                          }
                        }}
                        ] })(
                          <Money
                            currency={this.props.currency}
                            hideStep
                            onChange={this.onChangeLimit}
                            disabled={this.getParameter("restrictInsuredSumEdition")}
                          />
                        )}
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "flex-end",
                          marginBottom: "5px",
                          marginLeft: "5px",
                        }}>
                        <div>{t("Manual")}</div>
                        {field("manualLimit", { valuePropName: "checked" })(<Switch size="small" disabled={this.getParameter("restrictInsuredSumEdition")} />)}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Form.Item label={t("Base Premium")} style={{ flex: 1 }}>
                        {field("basePremium", { rules: [
                          { required: true },
                        { validator: (rule, value, callback) => {
                          const maxPremium = this.getParameter("maxPremium");
                          if (maxPremium !== undefined && value > maxPremium) {
                            callback(t(`Premium must be less than ${maxPremium}`));
                          } else {
                            callback();
                          }
                        }},
                        { validator: (rule, value, callback) => {
                          const minPremium = this.getParameter("minPremium");
                          if (minPremium !== undefined && value < minPremium) {
                            callback(t(`Premium must be greater than ${minPremium}`));
                          } else {
                            callback();
                          }
                        }}
                        ] })(
                          <Money
                            currency={this.props.currency}
                            hideStep
                            disabled={this.getParameter("restrictPremiumEdition")}
                            onChange={this.onChangePremium}
                          />
                        )}
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "flex-end",
                          marginBottom: "5px",
                          marginLeft: "5px",
                        }}>
                        <div>{t("Manual")}</div>
                        {field("manualPremium", { valuePropName: "checked" })(<Switch size="small" disabled={this.getParameter("restrictPremiumEdition")} />)}
                      </div>
                    </div>
                    <Form.Item label={t("Start")}>{field("start", { rules: [{ validator: this.validateStartDate }] })(<DateField />)}</Form.Item>
                    {field("baseLimit")}
                    <Form.Item label={t("Sum Assured Factor")}>{field("limitFactor")(<InputNumber min={0} onChange={v => this.onChangeLimitFactor(v)} />)}</Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label={t("Name")}>
                      {field("name", { rules: [{ required: true }] })(<FormLabel strong paragraph ellipsis={{ rows: 1 }} />)}
                    </Form.Item>
                    <Form.Item label={t("Deductible")}>
                      {field("deductible", { rules: [{ required: true }] })(<Money currency={this.props.currency} hideStep />)}
                    </Form.Item>
                    <div style={{ display: "flex" }}>

                      <Form.Item label={t("Loadings")}>
                        {field("loading", { rules: [{}] })(
                          <InputNumber
                            disabled
                            defaultValue={100}
                            //min={0}
                            step={10}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            decimalSeparator="."
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={t("Loadings (Insured Sum)")} style={{ marginLeft: 5 }}>
                        {field("loadingInsuredSum", { rules: [{}] })(
                          <InputNumber
                            disabled
                            defaultValue={100}
                            //min={0}
                            step={10}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            decimalSeparator="."
                          />
                        )}
                      </Form.Item>
                    </div>
                    <Form.Item label={t("End")}>{field("end", { rules: [{ validator: this.validateEndDate }] })(<DateField />)}</Form.Item>
                    <Form.Item label={t("Preferred reinsurance contract")}>{field("reinsuranceCode",)(<ContractCodeSelect disabled={covConfig && covConfig.disableReinsuranceSelection} />)}</Form.Item>
                    <Form.Item label={t("Coinsurance contract")}>{field("coContractId", {})(<CoContractSelect disabled={(!this.props.policy||this.props.policy.coinsurance !== 4)} />)}</Form.Item>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Loading")} key="loading">
              <div className={this.props.readOnly || this.getParameter("restrictLoadings") ? "disabled" : ""}>
                {field("Loadings", { initialValue: [] })(<Loadings onChange={(v) => this.onChangeLoadings(v || [])} coverage={values} duration={this.props.policy?.duration} />)}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Other")} key="other">
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label={t("Solvency2 Code")}>{field("solvency2Code", {})(<Input disabled />)}</Form.Item>
                  <Form.Item label={t("Commercial Name")}>{field("commercialName", {})(<Input disabled />)}</Form.Item>
                  <Form.Item label={t("Applies To")}>{field("appliesTo", {})(<PolicyRoleSelect disabled />)}</Form.Item>
                  <Form.Item label={t("Parent Coverage")}>{field("parent", {})(<ParentCoverageSelect disabled={this.props.readOnly} coverages={this.props.coverages} coverageCode={this.props.form.getFieldValue("code")} />)}</Form.Item>
                  <Form.Item label={t("Parent Sum Assured %")}>{field("parentPercentage", {})(<Percentage disabled={this.props.readOnly || !this.props.form.getFieldValue("parent")} />)}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("Internal Premium")}>{field("internalPremium", {})(<Money currency={currency} disabled />)}</Form.Item>
                  <Form.Item label={t("Starting Sum Insured")}>{field("startLimit", {})(<Money currency={currency} disabled />)}</Form.Item>
                  <Form.Item label={t("Starting Premium")}>{field("startPremium", {})(<Money currency={currency} disabled />)}</Form.Item>
                  <Form.Item label={t("Extra Premium")}>{field("extraPremium", {})(<Money currency={currency} disabled />)}</Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("Internal Bonus")}>{field("internalBonus", { valuePropName: "checked" })(<Checkbox disabled />)}</Form.Item>
                  <Form.Item label={t("OFN Group")}>{field("ofnGroup", {})(<InputNumber disabled />)}</Form.Item>
                  <Form.Item label={t("OFN Code")}>{field("ofnCode", {})(<InputNumber disabled />)}</Form.Item>
                  <Form.Item label={t("Number")}>{field("number", {})(<InputNumber disabled />)}</Form.Item>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("Claims")} key="claims">
              <CoverageClaimList coverage={this.props.value} claimId={this.props.claimId} claimType={covConfig.claimType} currency={this.props.currency} config={this.props.config} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={covConfig.customFormLabel?covConfig.customFormLabel:t("Custom")} key="custom" disabled={!covConfig.customFormId}>
              {field("jCustom")(<CustomForm formId={covConfig.customFormId} variable="customCoverageForm"/>)}
            </Tabs.TabPane>
          </Tabs>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={() => this.onSubmit()} disabled={this.props.readOnly || this.hasErrors(this.props.form.getFieldsError())}>
              {t("OK")}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedCoverageDetail = Form.create()(CoverageDetail);
export default withTranslation()(WrappedCoverageDetail);
