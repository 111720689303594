import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Form, 
  Input, 
  Button, 
  Card, 
  Row, 
  Col, 
  Select, 
  Typography, 
  InputNumber, 
  Space, 
  Divider,
  Switch,
  Modal,
  Table,
  Radio,
  message
} from 'antd';
import { 
  SaveOutlined, 
  CalculatorOutlined,
  CheckOutlined,
  CloseOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { exe } from "../../Lib/Dal";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const BudgetLineForm = ({ initialValues, onSave, isEditing, mode = 'item', categories = [], budgetId, scenarioId }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [lineType, setLineType] = useState('expense');
  const [monthlyValues, setMonthlyValues] = useState({
    jan: 0, feb: 0, mar: 0, apr: 0, 
    may: 0, jun: 0, jul: 0, aug: 0, 
    sep: 0, oct: 0, nov: 0, dec: 0
  });
  const [yearTotal, setYearTotal] = useState(0);
  const [isCategory, setIsCategory] = useState(mode === 'category');
  const [isMapped, setIsMapped] = useState(false);
  const [mappingModalVisible, setMappingModalVisible] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [mappingType, setMappingType] = useState('account');
  const [systemEntities, setSystemEntities] = useState({
    accounts: [],
    departments: [],
    costCenters: [],
    chains: []
  });
  const [loading, setLoading] = useState(false);

  // Fetch system entities for mapping
  useEffect(() => {
    fetchSystemEntities();
  }, []);

  const fetchSystemEntities = async () => {
    try {
      setLoading(true);

      const result = await exe("RepoChain", {
        operation: "GET",
        filter:"category='budget'"
      });
      
      setLoading(false);
      if(result.ok){
        setSystemEntities({
          accounts: [],
          departments: [],
          costCenters: [],
          chains: result.outData
        });
      }else {
        message.error(t('Error loading chains'));
      }
     
      
    } catch (error) {
      console.error('Error loading system entities:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    
    if (initialValues) {
      
      // Extract the proper category ID either from categoryId or from the Category relationship
      const categoryId = initialValues.categoryId || 
                         (initialValues.Category ? initialValues.Category.id : null);
      
      // Set form values with all required fields
      form.setFieldsValue({
        id: initialValues.id,
        name: initialValues.name,
        description: initialValues.description,
        lineType: initialValues.lineType || 'expense',
        parentId: categoryId,  // Set parentId to the extracted categoryId
      });
        const months = {
          jan: initialValues.jan || 0,
          feb: initialValues.feb || 0,
          mar: initialValues.mar || 0,
          apr: initialValues.apr || 0,
          may: initialValues.may || 0,
          jun: initialValues.jun || 0,
          jul: initialValues.jul || 0,
          aug: initialValues.aug || 0,
          sep: initialValues.sep || 0,
          oct: initialValues.oct || 0,
          nov: initialValues.nov || 0,
          dec: initialValues.dec || 0
        };
        
        setMonthlyValues(months);
        form.setFieldsValue(months);
        
        // Calculate total
        setYearTotal(Object.values(months).reduce((sum, val) => sum + (val || 0), 0));

        // Set mapping status
        if (initialValues.mappingId) {
          setIsMapped(true);
          setSelectedEntity(+initialValues.mappingId);
          setMappingType("Chain");
        } else {
          setIsMapped(false);
          setSelectedEntity(null);
        }
        
      setLineType(initialValues.lineType || 'expense');
      setIsCategory(initialValues.level === 'category');
    } else {
      // Set default values for new item/category
      form.setFieldsValue({
        lineType: 'expense',
      });
      
      if (mode === 'item' && categories.length > 0) {
        form.setFieldsValue({
          parentId: categories[0].id
        });
      }
      
      setIsCategory(mode === 'category');
      setIsMapped(false);
      setSelectedEntity(null);
    }
  }, [initialValues, form, mode, categories]);

  const handleValuesChange = (changedValues) => {
    if (changedValues.lineType) {
      setLineType(changedValues.lineType);
    }
    
    // Update monthly values if they change
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const changedMonth = Object.keys(changedValues).find(key => months.includes(key));
    
    if (changedMonth) {
      const newMonthlyValues = { ...monthlyValues, [changedMonth]: changedValues[changedMonth] || 0 };
      setMonthlyValues(newMonthlyValues);
      
      // Calculate total
      const total = Object.values(newMonthlyValues).reduce((sum, val) => sum + (val || 0), 0);
      setYearTotal(total);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(values => {
      // Debug the form values
      console.log("Form values on submit:", JSON.stringify(values, null, 2));
      
      // Prepare the data object
      const lineData = {
        ...values,
        budgetId: budgetId,
        scenarioId: scenarioId
      };
      
      const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
      months.forEach(month => {
        lineData[month] = values[month] || 0;
      });

        // Add mapping data if mapped
        if (isMapped && selectedEntity) {
          lineData.mappingId = selectedEntity;
          lineData.mappingType = "Chain";
          lineData.mappingName = systemEntities["chains"].find(c => c.id === selectedEntity).name;
        }else {
          lineData.mappingId = null;
          lineData.mappingType = null;
          lineData.mappingName = null;
        } 
      
      onSave(lineData);
    });
  };



  const resetForm = () => {
    form.resetFields();
    
    if (!isEditing) {
      setMonthlyValues({
        jan: 0, feb: 0, mar: 0, apr: 0, 
        may: 0, jun: 0, jul: 0, aug: 0, 
        sep: 0, oct: 0, nov: 0, dec: 0
      });
      setYearTotal(0);
      setIsMapped(false);
      setSelectedEntity(null);
    } else if (initialValues) {
      // Reset to original values if editing
      const months = {
        jan: initialValues.jan || 0,
        feb: initialValues.feb || 0,
        mar: initialValues.mar || 0,
        apr: initialValues.apr || 0,
        may: initialValues.may || 0,
        jun: initialValues.jun || 0,
        jul: initialValues.jul || 0,
        aug: initialValues.aug || 0,
        sep: initialValues.sep || 0,
        oct: initialValues.oct || 0,
        nov: initialValues.nov || 0,
        dec: initialValues.dec || 0
      };
      
      setMonthlyValues(months);
      form.setFieldsValue({
        ...initialValues,
        ...months
      });
      
      // Calculate total
      setYearTotal(Object.values(months).reduce((sum, val) => sum + (val || 0), 0));

      // Reset mapping data
      if (initialValues.mapping) {
        setIsMapped(true);
        setSelectedEntity(initialValues.mapping.entityId);
        setMappingType(initialValues.mapping.type);
      } else {
        setIsMapped(false);
        setSelectedEntity(null);
      }
    }
  };

  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  
  // Set all months to the same value
  const setAllMonths = (value) => {
    const newValues = {};
    months.forEach(month => {
      newValues[month] = value;
    });
    
    form.setFieldsValue(newValues);
    setMonthlyValues(newValues);
    setYearTotal(value * 12);
  };

  const openMappingModal = () => {
    setMappingModalVisible(true);
  };
  
  const handleMappingOk = () => {
    setMappingModalVisible(false);
  };
  
  const handleMappingCancel = () => {
    setMappingModalVisible(false);
    
    // If no entity was selected, also reset the mapping
    if (!selectedEntity) {
      setIsMapped(false);
    }
  };

  const handleEntitySelect = (entityId) => {
    setSelectedEntity(entityId);
  };
  
  const handleMappingTypeChange = (e) => {
    setMappingType(e.target.value);
    setSelectedEntity(null); // Reset selection when changing type
  };

  const getEntityColumns = () => {
    const baseColumns = [
      {
        title: t('Name'),
        dataIndex: 'name',
        key: 'name',
      }
    ];
    
    return baseColumns;
  };

  const getEntityName = () => {
    if (!selectedEntity || !mappingType) return '';
    
    const entityList = systemEntities["chains"];
    const entity = entityList.find(e => e.id === selectedEntity);
    return entity ? entity.name : '';
  };


  // Render the form
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col span={12}>
          <Form.Item
            name="id"
            label={t('ID')}
            hidden={true}
          >
            <Input placeholder={t('Enter ID')} />
          </Form.Item>
            <Form.Item
              name="name"
              label={t('Name')}
              rules={[{ required: true, message: t('Please enter a name') }]}
            >
              <Input placeholder={t('Enter name')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lineType"
              label={t('Type')}
              rules={[{ required: true, message: t('Please select a type') }]}
            >
              <Select>
                <Option value="income">{t('Income')}</Option>
                <Option value="expense">{t('Expense')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('Description')}
            >
              <TextArea placeholder={t('Enter description')} rows={2} />
            </Form.Item>
          </Col>
        </Row>
        
        {!isCategory && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="categoryId"
                label={t('Category')}
                rules={[{ required: true, message: t('Please select a category') }]}
              >
                <Select placeholder={t('Select category')}>
                  {categories.map(category => (
                    <Option key={category.id} value={category.id}>{category.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        
        {!isCategory && (
          <>
            <Divider>{t('Monthly Budget')}</Divider>
            
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Space align="center">
                  <Text>{t('Quick Setup')}:</Text>
                  <Button onClick={() => setAllMonths(0)}>{t('Set All to 0')}</Button>
                  <InputNumber
                    prefix="$"
                    min={0}
                    step={100}
                    placeholder={t('Enter amount')}
                    style={{ width: 150 }}
                    onChange={(value) => {
                      if (value !== null) setAllMonths(value);
                    }}
                  />
                  <Button onClick={() => {
                    const value = monthlyValues.jan;
                    setAllMonths(value);
                  }}>{t('Copy January to All')}</Button>
                </Space>
              </Col>
            </Row>
            
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={6}>
                <Form.Item
                  name="jan"
                  label={t('January')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="feb"
                  label={t('February')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="mar"
                  label={t('March')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="apr"
                  label={t('April')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  name="may"
                  label={t('May')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="jun"
                  label={t('June')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="jul"
                  label={t('July')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="aug"
                  label={t('August')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Form.Item
                  name="sep"
                  label={t('September')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="oct"
                  label={t('October')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="nov"
                  label={t('November')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="dec"
                  label={t('December')}
                >
                  <InputNumber
                    prefix="$"
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card>
                  <Title level={4}>{t('Year Total')}: ${yearTotal.toLocaleString()}</Title>
                </Card>
              </Col>
            </Row>
            
            <Divider>{t('Mapping')}</Divider>
            
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Space align="center">
                  <Switch 
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={isMapped}
                    onChange={setIsMapped}
                  />
                  <Text>{t('Map to')}: </Text>
                  {isMapped ? (
                    <Button 
                      type="link" 
                      icon={<LinkOutlined />}
                      onClick={openMappingModal}
                    >
                      {getEntityName() || t('Select Entity')}
                    </Button>
                  ) : (
                    <Text type="secondary">{t('No mapping')}</Text>
                  )}
                </Space>
              </Col>
            </Row>
          </>
        )}
        
        <Divider />
        
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button onClick={resetForm}>{t('Reset')}</Button>
              <Button 
                type="primary" 
                icon={<SaveOutlined />}
                onClick={handleSubmit}
              >
                {t('Save')}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      
      <Modal
        title={t('Select Entity for Mapping')}
        open={mappingModalVisible}
        onOk={handleMappingOk}
        onCancel={handleMappingCancel}
        width={700}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
                  
          <Table 
            dataSource={systemEntities["chains"]}
            columns={getEntityColumns()}
            rowKey="id"
            loading={loading}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedEntity ? [selectedEntity] : [],
              onChange: (selectedRowKeys) => {
                handleEntitySelect(selectedRowKeys[0]);
              }
            }}
            pagination={{ pageSize: 10 }}
          />
        </Space>
      </Modal>
    </div>
  );
};

export default BudgetLineForm; 