import React, { useState, useEffect } from "react";
import { CheckCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Steps,
  DatePicker,
  message,
  Result,
  Collapse,
  Popconfirm,
  Select,
  Radio,
  Modal,
  InputNumber,
  Checkbox,
} from "antd";
import Section from "../Shared/Section";
import Billing from "./Bill";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import Bill from "./Bill";
import { exe, safeParse } from "../../Lib/Dal";
import moment from "moment";
import { load } from "js-yaml";
import MultiContactSelect from "../Contact/MultiContactSelect";
import BillUnique from "./BillUnique";
import { formatDate, getEffectiveDateField } from "../../Lib/Helpers";
import EffectiveDate from "./EffectiveDate";
import IndexationPlan from "./IndexationPlan";
import { useTranslation } from "react-i18next";
import ChangeBilling from "./ChangeBilling";
import AmendedChange from "./AmendedChange";
import CommonChangeSection from "./CommonChangeSection";
import IndexationOptionSelect from "./IndexationOptionSelect";
import Percentage from "../Shared/Percentage";
import InputChangeCode from "./InputChangeCode";
const ChangeIndexation = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [oldIndexationPlanVisible, setOldIndexationPlanVisible] = useState(false);
  const [newIndexationPlanVisible, setNewIndexationPlanVisible] = useState(false);

  useEffect(() => setValues(props.value), props.value);
  useEffect(() => {if(props.common.customEffect!==undefined) props.common.customEffect(setStep)}, [props.common.customEffectDeps]);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  const currentIndexation = props.currentIndexation;
  const currentIndexationPeriod = props.currentIndexationPeriod;
  const currentIndexationStart = props.currentIndexationStart;
  const currentIndexationFrequency = props.currentIndexationFrequency;
  const currentJIndexationPlan = props.currentJIndexationPlan;
  const currentIndexationInsuredSum = props.currentIndexationInsuredSum;
  const currentIndexationPremium = props.currentIndexationPremium;
  const policyId = props.policyId;

  const onQuote = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        setLoading(true);
        exe("ChangeIndexation", {
          ...values, policyId: policyId,
          newIndexation: values.newIndexation,
          newIndexationPeriod: values.newIndexationPeriod,
          newIndexationStart: values.newIndexationStart,
          newIndexationFrequency: values.newIndexationFrequency,
          newJIndexationPlan: values.newJIndexationPlan,
          previousAnniversary: values.previousAnniversary,
          effectiveDate: values.effectiveDate,
          ...props.common.toServer,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        if (operation == "UPDATE") {
          onUpdate(values);
          return;
        }
        setLoading(true);
        exe("ChangeIndexation", {
          ...values, policyId: policyId,
          newIndexation: values.newIndexation,
          newIndexationPeriod: values.newIndexationPeriod,
          newIndexationStart: values.newIndexationStart,
          newIndexationFrequency: values.newIndexationFrequency,
          newJIndexationPlan: values.newJIndexationPlan,
          previousAnniversary: values.previousAnniversary,
          effectiveDate: values.effectiveDate,
          operation: "ADD",
          code: values.code,
          note: values.note,
          ...props.common.toServer,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
            setResult({ status: "success", title: t("Successfully Saved Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onUpdate = (values) => {
    setLoading(true);
    exe("ChangeIndexation", { Entity: values, operation: "UPDATE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData);
        setStep(step + 1);
        setResult({ status: "success", title: t("Successfully Updated Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ExeChangeIndexation", { changeId: values.id, operation: "EXECUTE" }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setValues(r.outData);
          setResult({
            status: "success",
            title: r.outData.status == 1 ? t("Successfully Executed Policy Change") : t("Policy Change Scheduled"),
            subTitle:
              t("Change number") +
              ":" +
              r.outData.id +
              (r.outData.status == 1
                ? t(". Please refresh policy or click on 'Change List'")
                : t("Execution date") + ":" + formatDate(r.outData.effectiveDate)),
          });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const setValues = (r) => {
    r.oldIndexation = "oldIndexation" in r ? r.oldIndexation : currentIndexation;
    r.oldIndexationPeriod = "oldIndexationPeriod" in r ? r.oldIndexationPeriod : currentIndexationPeriod;
    r.oldIndexationStart = "oldIndexationStart" in r ? r.oldIndexationStart : currentIndexationStart;
    r.oldIndexationFrequency = "oldIndexationFrequency" in r ? r.oldIndexationFrequency : currentIndexationFrequency;
    r.oldJIndexationPlan = "oldJIndexationPlan" in r ? r.oldJIndexationPlan : currentJIndexationPlan;
    r.oldIndexationInsuredSum = "oldIndexationInsuredSum" in r ? r.oldIndexationInsuredSum : currentIndexationInsuredSum;
    r.oldIndexationPremium = "oldIndexationPremium" in r ? r.oldIndexationPremium : currentIndexationPremium;
    props.form.setFieldsValue(r);
    if (r.status == 0) {
      setResult({ status: "info", title: t("Saved Policy Change Ready To Be Executed"), subTitle: t("Change number") + ":" + r.id });
    } else if (r.status == 1 || r.status == 3) {
      setResult({ status: "success", title: t("Policy Change Executed"), subTitle: t("Change Execution Date") + ":" + r.executionDate });
      setReadOnly(true);
    } else if (r.status === 6) {
      setResult({ status: "warning", title: t("Policy Change Amended"), subTitle: t("This change has been amended by another change") });
      setReadOnly(true);
    }
  };
  const onIndexationPlanOk = () => {
    //checking affected period
    const jPlan = props.form.getFieldValue("newJIndexationPlan");
    const period = props.form.getFieldValue("newIndexationPeriod");
    const plan = JSON.parse(jPlan);
    let lastYear = 0;
    plan.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "year") {
          if (row[key]) lastYear = row.year;
        }
      });
    });
    if (lastYear !== period) props.form.setFieldsValue({ newIndexationPeriod: lastYear });
    setNewIndexationPlanVisible(false);
  };
  const onChangeIndexationPeriod = (indexationPeriod) => {
    const duration = props.duration;
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      props.coverages.forEach((cov) => {
        obj[cov.code] = index + 1 <= indexationPeriod ? true : false;
      });
      obj.year = index + 1;
      return obj;
    });
    props.form.setFieldsValue({ newJIndexationPlan: JSON.stringify(buildPlan) });
  };
  //frequency option
  const onChangeIndexationPeriodWithFrequency=(indexationPeriod)=> {
    const duration = props.duration;
    const start=props.form.getFieldValue("newIndexationStart");
    const frequency=props.form.getFieldValue("newIndexationFrequency");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      props.coverages.forEach((cov) => {
        if(((index + 1 )<=indexationPeriod)&&((index + 1) >= start)){
          if((index+1-start)%frequency===0) obj[cov.code]=true;
        }else  obj[cov.code]=false;
      });
      obj.year = index + 1;
      return obj;
    });
    props.form.setFieldsValue({ newJIndexationPlan: JSON.stringify(buildPlan) });
  }
  const onChangeIndexationStart=(start)=> {
    const duration = props.duration;
    const indexationPeriod=props.form.getFieldValue("newIndexationPeriod");
    const frequency=props.form.getFieldValue("newIndexationFrequency");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      props.coverages.forEach((cov) => {
        if(((index + 1 )<=indexationPeriod)&&((index + 1) >= start)){
          if((index+1-start)%frequency===0) obj[cov.code]=true;
        }else  obj[cov.code]=false;
      });
      obj.year = index + 1;
      return obj;
    });
    props.form.setFieldsValue({ newJIndexationPlan: JSON.stringify(buildPlan) });
  }
  const onChangeIndexationFrequency=(frequency)=> {
    const duration = props.duration;
    const indexationPeriod=props.form.getFieldValue("newIndexationPeriod");
    const start=props.form.getFieldValue("newIndexationStart");
    const buildPlan = new Array(duration || 0).fill().map((p, index) => {
      const obj = {};
      props.coverages.forEach((cov) => {
        if(((index + 1 )<=indexationPeriod)&&((index + 1) >= start)){
          if((index+1-start)%frequency===0) obj[cov.code]=true;
        }else  obj[cov.code]=false;
      });
      obj.year = index + 1;
      return obj;
    });
    props.form.setFieldsValue({ newJIndexationPlan: JSON.stringify(buildPlan) });
  }
  const onSelectIndexationType=(v)=>{
    if(v===2){
      //limited, building initial matrix
      const period=this.props.form.getFieldValue("newIndexationStart");
      onChangeIndexationPeriod(period);
    }
    if(v===3){
      //frequency, building initial matrix
      const start=props.form.getFieldValue("newIndexationStart");
      onChangeIndexationStart(start);
    }
  }

  const oldIsLimited = props.form.getFieldValue("oldIndexation") == 2;
  const newIsLimited = props.form.getFieldValue("newIndexation") == 2;
  const oldIsFrequency = props.form.getFieldValue("oldIndexation") == 3;
  const newIsFrequency = props.form.getFieldValue("newIndexation") == 3;
  const oldOption = props.form.getFieldValue("oldIndexation");
  const newOption = props.form.getFieldValue("newIndexation");
  const values = props.form.getFieldsValue();

  return (
    <div style={{ marginTop: 10 }}>
      <Form layout="vertical">
        <Steps current={step} onChange={(v) => setStep(v)} style={{ margin: 5, marginBottom: 15 }}>
          <Steps.Step title={t("Change")} description={t("Change options")} />
          <Steps.Step title={t("Quote")} description={t("Cost of changes")} />
          <Steps.Step title={t("Result")} description={t("Execution")} />
        </Steps>
        <Row gutter={16} hidden={step !== 0}>
          <Col span={12}>
            <Section text={t("Change Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <div>
                <Form.Item label={t("Old Indexation")} style={{ marginRight: 10 }}>
                  {field("oldIndexation", { initialValue: 0 })(
                    <IndexationOptionSelect disabled={true} />
                  )}
                </Form.Item>
                <div style={{ display: oldIsLimited ? "flex" : "none" }}>
                  <Form.Item label={t("Old Indexation Period")} style={{ marginRight: 10 }}>
                    {field("oldIndexationPeriod", { rules: [{ required: true }] })(<InputNumber disabled />)}
                    <Button type="link" onClick={() => setOldIndexationPlanVisible(true)}>
                      {t("Old Indexation plan")}
                    </Button>
                  </Form.Item>
                </div>
                {/*--- Old Frequency option ---*/}
                <div style={{ display: oldIsFrequency?"flex":"none" }}>
                  <Form.Item label={t("Period")} style={{ marginRight: 10 }}>
                    {field("oldIndexationPeriod", )(
                        <InputNumber
                            min={1}
                            max={props.duration}
                            disabled
                        />
                    )}
                  </Form.Item>
                  <Form.Item label={t("Start")} style={{ marginRight: 10 }}>
                    {field("oldIndexationStart", )(
                        <InputNumber
                            min={1}
                            max={props.duration}
                            disabled
                        />
                    )}
                  </Form.Item>
                  <Form.Item label={t("Frequency")} style={{ marginRight: 10 }}>
                    {field("oldIndexationFrequency", )(
                        <InputNumber
                            min={1}
                            max={props.duration}
                            disabled
                        />
                    )}
                    <Button type="link" onClick={() => setOldIndexationPlanVisible(true)}>
                      {t("Indexation plan")}
                    </Button>
                  </Form.Item>
                </div>
                {/*--- Old Insured Sum option ---*/}
                <div style={{ display: (oldOption == 4 || oldOption == 6) ? "flex" : "none" }}>
                  <Form.Item label={t("Insured Sum Indexation")} style={{ marginRight: 10 }}>
                    {field("oldIndexationInsuredSum", { rules: [{ required: (oldOption == 4 || oldOption == 6) }] })(
                      <Percentage disabled/>
                    )}
                  </Form.Item>    
                </div>
                {/*--- Old Premium option ---*/}
                <div style={{ display: (oldOption == 5 || oldOption == 6) ? "flex" : "none" }}>
                  <Form.Item label={t("Premium Indexation")} style={{ marginRight: 10 }}>
                    {field("oldIndexationPremium", { rules: [{ required: (oldOption == 5 || oldOption == 6) }] })(
                      <Percentage disabled/>
                    )}
                  </Form.Item>
                </div>
                <Modal
                  title={t("Indexation Plan")}
                  width={800}
                  visible={oldIndexationPlanVisible}
                  onCancel={() => setOldIndexationPlanVisible(false)}
                  onOk={() => setOldIndexationPlanVisible(false)}>
                  {field("oldJIndexationPlan")(
                    <IndexationPlan coverages={props.coverages} duration={props.duration} indexationPeriod={props.form.getFieldValue("oldIndexationPeriod")} />
                  )}
                </Modal>
              </div>
              {/*-------- NEW INDEXATION ---------*/}
              <div>
                <Form.Item label={t("New Indexation")} style={{ marginRight: 10 }}>
                  {field("newIndexation", { initialValue: 0 })(
                    <IndexationOptionSelect />
                  )}
                </Form.Item>
                <div style={{  display: newIsLimited ? "flex" : "none" }}>
                  <Form.Item label={t("New Indexation Period")} style={{ marginRight: 10 }}>
                    {field("newIndexationPeriod", { initialValue: 1, rules: [{ required: newIsLimited }] })(
                      <InputNumber min={1} max={props.duration} onChange={(v) => onChangeIndexationPeriod(v)} />
                    )}
                    <Button type="link" onClick={() => setNewIndexationPlanVisible(true)}>
                      {t("New Indexation plan")}
                    </Button>
                  </Form.Item>
                </div>
                {/*--- New Frequency option ---*/}
                <div style={{ display: newIsFrequency?"flex":"none" }}>
                  <Form.Item label={t("Period")} style={{ marginRight: 10 }}>
                    {field("newIndexationPeriod", { initialValue: props.duration, rules: [{ required: newIsFrequency }] })(
                        <InputNumber
                            onChange={(v) => onChangeIndexationPeriodWithFrequency(v)}
                            min={1}
                            max={props.duration}
                        />
                    )}
                  </Form.Item>
                  <Form.Item label={t("Start")} style={{ marginRight: 10 }}>
                    {field("newIndexationStart", { initialValue: 1, rules: [{ required: newIsFrequency }] })(
                        <InputNumber
                            onChange={(v) => onChangeIndexationStart(v)}
                            min={1}
                            max={props.duration}
                        />
                    )}
                  </Form.Item>
                  <Form.Item label={t("Frequency")} style={{ marginRight: 10 }}>
                    {field("newIndexationFrequency", { initialValue: 1, rules: [{ required: newIsFrequency }] })(
                        <InputNumber
                            onChange={(v) => onChangeIndexationFrequency(v)}
                            min={1}
                            max={props.duration}
                        />
                    )}
                    <Button type="link" onClick={() => setNewIndexationPlanVisible(true)}>
                      {t("Indexation plan")}
                    </Button>
                  </Form.Item>
                </div>
                {/*--- New Insured Sum option ---*/}
                <div style={{ display: (newOption == 4 || newOption == 6) ? "flex" : "none" }}>
                  <Form.Item label={t("Insured Sum Indexation")} style={{ marginRight: 10 }}>
                    {field("newIndexationInsuredSum", { rules: [{ required: (newOption == 4 || newOption == 6) }] })(
                      <Percentage />
                    )}
                  </Form.Item>
                </div>
                {/*--- New Premium option ---*/}
                <div style={{ display: (newOption == 5 || newOption == 6) ? "flex" : "none" }}>
                  <Form.Item label={t("Premium Indexation")} style={{ marginRight: 10 }}>
                    {field("newIndexationPremium", { rules: [{ required: (newOption == 5 || newOption == 6) }] })(
                      <Percentage />
                    )}
                  </Form.Item>
                </div>
                <Modal
                  title={t("Indexation Plan")}
                  width={800}
                  visible={newIndexationPlanVisible}
                  onCancel={() => setNewIndexationPlanVisible(false)}
                  onOk={() => onIndexationPlanOk()}>
                  {field("newJIndexationPlan")(
                    <IndexationPlan coverages={props.coverages} duration={props.duration} indexationPeriod={props.form.getFieldValue("newIndexationPeriod")} />
                  )}
                </Modal>
              </div>
              <Form.Item label={t("Effective Date")}>
                {field("effectiveDate", {
                  rules: [
                    {
                      required: true,
                      validator: (rule, v, cb) => (v ? cb(v.validationError) : cb(t("Effective date is required"))),
                    },
                  ],
                })(<EffectiveDate anniversary={props.anniversary} disabled={values.previousAnniversary} allowPastDates={props.common.allowPastEffectDate} />)}
                <Form.Item label={t("Ammend previous anniversary")} style={{ marginRight: 10 }}>
                  {field("previousAnniversary", { valuePropName: "checked" })(
                    <Checkbox onChange={(e) => (e.target.checked ? props.form.setFieldsValue({ effectiveDate: new moment() }) : null)} />
                  )}
                </Form.Item>
              </Form.Item>
              {field("changeIdToBeAmended", { initialValue: props.common.toServer.changeIdToBeAmended })(<AmendedChange />)}
             <CommonChangeSection t={t} form={props.form} common={props.common} change={props.value}  /> 
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
            <Button style={{ marginTop: 5 }} type="primary" onClick={onQuote} loading={loading} disabled={readOnly}>
              {t("Quote")}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 1}>
          <Col span={16}>
            {field("processId")}{field("contractYear")} {field("creationDate")} {field("anniversary")}{field("Bill")}
            {field("BillUnique")}
            {field("jNewPayPlan")}
            <ChangeBilling config={props.config} currency={currency} change={props.form.getFieldsValue()} form={props.form} />
          </Col>
          <Col span={8}>
            <Section text={t("Options")}>
              <Form.Item label={t("Id")}>{field("id", {})(<Input disabled />)}</Form.Item>
              <Form.Item label={t("Code")}>{field("code", {})(<InputChangeCode common={props.common} />)}</Form.Item>
              <Form.Item label={t("Note")}>
                {field("note", {})(<Input.TextArea rows={4} placeholder={t("Optional printable change note or remark")} />)}
              </Form.Item>
              <div style={{ visibility: "hidden" }}>{field("lifePolicyId", {})(<Input disabled />)}</div>
              <div style={{ marginTop: 20 }}>
                <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={() => setStep(step - 1)}>
                  {t("Back")}
                </Button>
                <Button type="primary" icon={<SaveOutlined />} style={{ marginRight: 5 }} onClick={onSave} loading={loading} disabled={readOnly}>
                  {t("Save")}
                </Button>
              </div>
            </Section>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 2}>
          <Col span={24}>
            <Result
              {...result}
              extra={[
                <Button key="console" onClick={() => props.reload()}>
                  {readOnly ? t("Change List") : t("Execute later")}
                </Button>,
                <Popconfirm
                  title={t("Are you sure you want to execute this policy change?")}
                  onConfirm={onExecute}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  disabled={readOnly}>
                  <Button icon={<CheckCircleOutlined />} type="primary" style={{ marginRight: 5 }} disabled={readOnly} loading={loading}>
                    {t("Execute")}
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ChangeIndexation);
