import { Button, message, Modal, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { FilterOutlined} from '@ant-design/icons';

const UserSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [filterByGroupVisible, setFilterByGroupVisible] = useState(false);

  useEffect(() => load(), []);
  const load = () => {
    const includes=props.filterByGroups?["Groups"]:null;

    exe("GetUsers",{include:includes}).then((r) => {
      if (r.ok) {
        const users = r.outData;
        if (props.originating) users.unshift({ email: "@Originating", nombre: "@Originating User" });
        setData(users);
        if(props.filterByGroups){
          const groups = [];
          users.forEach((u) => {
            if (u.Groups) {
              u.Groups.forEach((g) => {
                if (!groups.find((x) => x.usrGroupId == g.usrGroupId)) groups.push(g);
              });
            }
          });
          setGroups(groups);
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <div style={{ display: 'flex', width: '100%' }}>
        <Select
          showSearch
          onChange={(v) => props.onChange && props.onChange(v)}
          value={props.value && props.value}
          style={{ width: "100%",flexGrow:1 }}
          placeholder={t("Select User")}
          allowClear
          key={"email"}
          filterOption={(input, option) => {
            const childText = option.props.children.toString().toLowerCase();
            return childText.indexOf(input.toLowerCase()) >= 0;
          }}>
          {data.filter(u=>selectedGroups.length?(selectedGroups.some(selectedGroupId=>u.Groups.some(usrGroup=>usrGroup.usrGroupId==selectedGroupId)) ):true).map((p) => (
            <Select.Option value={p.email}>
              {p.nombre} <span style={{ color: '#999', fontSize: '11px' }}>{p.email !== '@Originating' ? `(${p.email})` : ''}</span>
            </Select.Option>
          ))}
        </Select>
        <Button style={{flexShrink:0,display:props.filterByGroups?"inline":"none"}} type="link" icon={<FilterOutlined />} onClick={() => setFilterByGroupVisible(true)}/>
      </div>
      <Modal title={t("Filter by Group")} visible={filterByGroupVisible} onOk={()=>setFilterByGroupVisible(false)} onCancel={()=>setFilterByGroupVisible(false)}>
        <Select
          showSearch
          mode="multiple"
          style={{ width: "100%" }}
          placeholder={t("Select Groups")}
          allowClear
          value={selectedGroups}
          onChange={(v) => setSelectedGroups(v)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {groups.map((p) => (
            <Select.Option value={p.usrGroupId}>{p.name}</Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default UserSelect;
