import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Table,
  Typography,
  Row,
  Col,
  InputNumber,
  DatePicker,
  message,
  Space,
  Card,
  Tabs,
  Alert,
  Tooltip,
  Divider
} from 'antd';
import {
  SaveOutlined,
  CloseOutlined,
  PlusOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  EditOutlined,
  CopyOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { exe } from '../../Lib/Dal';
import moment from 'moment';

const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

/**
 * BudgetActualUpdate component
 * Modal for updating budget actual values for multiple months at once
 */
const BudgetActualUpdate = ({ visible, onCancel, onSuccess, budgetId, scenarioId, budgetLineId }) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [actuals, setActuals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(moment().year());
  const [budgetLines, setBudgetLines] = useState([]);
  const [selectedBudgetLine, setSelectedBudgetLine] = useState(null);
  const [selectedBudgetLineData, setSelectedBudgetLineData] = useState(null);

  // Initial data load on modal open
  useEffect(() => {
    if (visible) {
      loadInitialData();
    }
  }, [visible]);

  // Load budget lines when budgetId or scenarioId changes
  useEffect(() => {
    if (budgetId) {
      loadBudgetLines();
    }
  }, [budgetId, scenarioId]);

  // Load actual data when budget line selection changes
  useEffect(() => {
    if (selectedBudgetLine) {
      loadActualData();
      loadBudgetLineDetails();
    }
  }, [selectedBudgetLine, year]);

  // Update form values when actuals change
  useEffect(() => {
    if (actuals.length > 0) {
      // Reset form values for all months based on loaded actuals
      const formValues = {};
      actuals.forEach(actual => {
        formValues[`amount_${actual.month}`] = actual.amount;
      });
      form.setFieldsValue(formValues);
    }
  }, [actuals, form]);

  // Initialize with current month and provided budget line if any
  const loadInitialData = () => {
    setYear(moment().year());
    
    // Set the budget line if provided
    if (budgetLineId) {
      setSelectedBudgetLine(budgetLineId);
      form.setFieldsValue({
        budgetLine: budgetLineId
      });
    }
  };

  // Load budget lines for the selected budget and scenario
  const loadBudgetLines = async () => {
    setLoading(true);
    try {
      // Get budget lines from the specified budget and scenario
      let filter = `budgetId = ${budgetId}`;
      
      // Add scenario filter if applicable
      if (scenarioId) {
        filter += ` AND scenarioId = ${scenarioId}`;
      } else {
        filter += ` AND scenarioId IS NULL`;
      }
      
      const result = await exe('RepoBudgetLine', {
        operation: 'GET',
        filter: filter,
        include: ['Category']
      });
      
      if (result.ok) {
        setBudgetLines(result.outData || []);
      } else {
        console.error('Failed to load budget lines:', result.msg);
        message.error(t('Failed to load budget lines'));
      }
    } catch (error) {
      console.error('Error loading budget lines:', error);
      message.error(t('Error loading budget lines'));
    } finally {
      setLoading(false);
    }
  };

  // Load details for the selected budget line
  const loadBudgetLineDetails = async () => {
    if (!selectedBudgetLine) return;
    
    setLoading(true);
    try {
      const result = await exe('RepoBudgetLine', {
        operation: 'GET',
        filter: `id = ${selectedBudgetLine}`,
        include: ['Category']
      });
      
      if (result.ok && result.outData && result.outData.length > 0) {
        setSelectedBudgetLineData(result.outData[0]);
      } else {
        console.error('Failed to load budget line details:', result.msg);
      }
    } catch (error) {
      console.error('Error loading budget line details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Load actual data for selected budget line
  const loadActualData = async () => {
    if (!selectedBudgetLine) return;
    
    setLoading(true);
    try {
      // Get the selected budget line first to access mapping info
      const lineResult = await exe('RepoBudgetLine', {
        operation: 'GET',
        filter: `id = ${selectedBudgetLine}`
      });
      
      if (!lineResult.ok || !lineResult.outData || lineResult.outData.length === 0) {
        message.error(t('Failed to load budget line'));
        setLoading(false);
        return;
      }
      
      const budgetLine = lineResult.outData[0];
      
      // Now fetch actual data for the budget line
      let entityType, entityId;
      
      // Check if the budget line has mapping data
      if (!budgetLine.mappingType || !budgetLine.mappingId) {
        // For unmapped budget lines, use budget line ID directly
        entityType = 'budgetLine';
        entityId = budgetLine.id.toString();
      } else {
        // For mapped budget lines, use the mapping info
        entityType = budgetLine.mappingType;
        entityId = budgetLine.mappingId;
      }
      
      const result = await exe('RepoBudgetActual', {
        operation: 'GET',
        filter: `entityType = '${entityType}' AND entityId = '${entityId}' AND year = ${year}`
      });

      console.log('Actual data response:', result);
      
      // Map the result to our state structure with month as key
      const actualMap = {};
      
      // Initialize all months with empty data
      for (let month = 1; month <= 12; month++) {
        actualMap[month] = {
          id: 0,
          year: year,
          month: month,
          entityType: entityType,
          entityId: entityId,
          entityName: budgetLine.mappingName || budgetLine.name,
          amount: null // Initialize as null instead of 0
        };
      }
      
      // Override with actual data where it exists
      if (result.ok && result.outData && result.outData.length > 0) {
        result.outData.forEach(actual => {
          actualMap[actual.month] = {
            ...actual,
            // Make sure amount is a number, not a string
            amount: typeof actual.amount === 'string' ? parseFloat(actual.amount) : actual.amount
          };
        });
      }
      
      // Convert to array
      const actualData = Object.values(actualMap);
      console.log('Setting actuals:', actualData);
      setActuals(actualData);
      
      // Set form values directly here, as the useEffect might not catch updates
      const formValues = {};
      actualData.forEach(actual => {
        if (actual.amount !== null && actual.amount !== undefined) {
          formValues[`amount_${actual.month}`] = actual.amount;
        }
      });
      console.log('Setting form values:', formValues);
      form.setFieldsValue(formValues);
      
    } catch (error) {
      console.error('Error loading actual data:', error);
      message.error(t('Error loading actual data'));
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!selectedBudgetLine) {
      message.error(t('Please select a budget line'));
      return;
    }
    
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      // Filter out months with no values
      const dataToSave = [];
      
      // Process all 12 months
      for (let month = 1; month <= 12; month++) {
        const fieldName = `amount_${month}`;
        const amount = values[fieldName];
        
        // Only include months that have a value (not null, undefined, or empty string)
        if (amount !== null && amount !== undefined && amount !== '') {
          const actual = actuals.find(a => a.month === month) || {};
          
          dataToSave.push({
            id: actual.id || 0,
            year: year,
            month: month,
            entityType: actual.entityType || 'budgetLine',
            entityId: actual.entityId || selectedBudgetLine.toString(),
            entityName: actual.entityName || (selectedBudgetLineData ? selectedBudgetLineData.name : ''),
            amount: amount,
            updatedAt: new Date().toISOString()
          });
        }
      }
      
      // Only save if there's at least one month with data
      if (dataToSave.length === 0) {
        message.info(t('No values to save'));
        setLoading(false);
        return;
      }
      
      // Use MERGE operation for bulk update
      const result = await exe('RepoBudgetActual', {
        operation: 'MERGE',
        bulkJson: JSON.stringify(dataToSave)
      });
      
      if (result.ok) {
        message.success(t('Actual values updated successfully'));
        onSuccess && onSuccess();
      } else {
        message.error(t('Failed to update actual values'));
        console.error('Error saving actuals:', result.msg);
      }
    } catch (error) {
      console.error('Form validation or save error:', error);
      message.error(t('Error saving data'));
    } finally {
      setLoading(false);
    }
  };

  // Handle distribution of a single value across all filled months
  const distributeEvenly = (value) => {
    if (!value) return;
    
    // Count how many months have values
    const filledMonths = [];
    for (let month = 1; month <= 12; month++) {
      const fieldName = `amount_${month}`;
      const amount = form.getFieldValue(fieldName);
      if (amount !== null && amount !== undefined && amount !== '') {
        filledMonths.push(month);
      }
    }
    
    // If no months have values, distribute across all months
    if (filledMonths.length === 0) {
      const amountPerMonth = value / 12;
      for (let month = 1; month <= 12; month++) {
        form.setFieldsValue({
          [`amount_${month}`]: parseFloat(amountPerMonth.toFixed(2))
        });
      }
    } else {
      // Distribute only to months that already have values
      const amountPerMonth = value / filledMonths.length;
      filledMonths.forEach(month => {
        form.setFieldsValue({
          [`amount_${month}`]: parseFloat(amountPerMonth.toFixed(2))
        });
      });
    }
  };

  // Month names for display
  const getMonthName = (monthNum) => {
    return moment().month(monthNum - 1).format('MMMM');
  };

  // Get budget amount for a specific month
  const getBudgetAmount = (month) => {
    if (!selectedBudgetLineData) return 0;
    
    const monthMap = {
      1: 'jan', 2: 'feb', 3: 'mar', 4: 'apr',
      5: 'may', 6: 'jun', 7: 'jul', 8: 'aug',
      9: 'sep', 10: 'oct', 11: 'nov', 12: 'dec'
    };
    
    return selectedBudgetLineData[monthMap[month]] || 0;
  };

  // Render form fields for months - now always shows all 12 months
  const renderMonthFields = () => {
    // Group months into quarters for better organization
    const quarters = [
      // Q1
      [1, 2, 3],
      // Q2
      [4, 5, 6],
      // Q3
      [7, 8, 9],
      // Q4
      [10, 11, 12]
    ];
    
    return (
      <>
        {quarters.map((monthsInQuarter, quarterIndex) => (
          <React.Fragment key={`quarter_${quarterIndex}`}>
            <Divider orientation="left">
              {t('Q{{number}}', { number: quarterIndex + 1 })}
            </Divider>
            <Row gutter={[16, 16]}>
              {monthsInQuarter.map(month => {
                // Find the actual for this month
                const actual = actuals.find(a => a.month === month);
                // Get the amount value (could be null)
                const amount = actual ? actual.amount : null;
                
                return (
                  <Col xs={24} sm={12} md={8} key={month}>
                    <Form.Item
                      label={
                        <Space>
                          {getMonthName(month)}
                          <Tooltip title={t('Budgeted amount {{amount}}', { amount: getBudgetAmount(month).toLocaleString() })}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </Space>
                      }
                      name={`amount_${month}`}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        precision={2}
                        step={100}
                        min={0}
                        formatter={value => value ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                        placeholder={t('Enter amount')}
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </React.Fragment>
        ))}
      </>
    );
  };

  // Generate options for year selector
  const yearOptions = Array.from({ length: 5 }, (_, i) => ({
    value: moment().year() - 2 + i,
    label: (moment().year() - 2 + i).toString()
  }));

  // Get budget line display name (with category)
  const getBudgetLineLabel = (line) => {
    if (!line) return '';
    return `${line.name}${line.Category ? ` (${line.Category.name})` : ''}`;
  };

  return (
    <Modal
      title={t('Update Actual Values')}
      visible={visible}
      onCancel={onCancel}
      width={800}
      footer={[
        <Button key="cancel" onClick={onCancel} icon={<CloseOutlined />}>
          {t('Cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
          icon={<SaveOutlined />}
        >
          {t('Save')}
        </Button>
      ]}
      destroyOnClose={false}
    >
      <Form 
        form={form} 
        layout="vertical"
        preserve={false}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              label={t('Budget Line')}
              name="budgetLine"
              initialValue={selectedBudgetLine}
              rules={[
                {
                  required: true,
                  message: t('Please select a budget line')
                }
              ]}
            >
              <Select
                placeholder={t('Select budget line')}
                onChange={value => setSelectedBudgetLine(value)}
                loading={loading}
                disabled={loading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {budgetLines.map(line => (
                  <Option key={line.id} value={line.id}>
                    {getBudgetLineLabel(line)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          
          <Col span={8}>
            <Form.Item
              label={t('Year')}
              name="year"
              initialValue={year}
            >
              <Select 
                onChange={value => setYear(value)}
                disabled={loading}
              >
                {yearOptions.map(option => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        
        {selectedBudgetLine && selectedBudgetLineData && (
          <>
            {(!selectedBudgetLineData.mappingType || !selectedBudgetLineData.mappingId) && (
              <Alert
                message={t('Information')}
                description={t('This budget line is not mapped to an entity. Actual values will be saved using the budget line name.')}
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
            
            <Card 
              title={
                <div>
                  {t('Enter Actual Values')}
                  {loading && <span> (Loading...)</span>}
                </div>
              }
              extra={
                <Row align="middle">
                  <Col>
                    <Space>
                      <InputNumber
                        value={form.getFieldValue('distributeAmount')}
                        onChange={value => form.setFieldsValue({ distributeAmount: value })}
                        precision={2}
                        min={0}
                        step={100}
                        style={{ width: '150px' }}
                        formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                      <Button 
                        type="link"
                        icon={<CopyOutlined />}
                        onClick={() => distributeEvenly(form.getFieldValue('distributeAmount'))}
                      >
                        {t('Distribute Evenly')}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              }
            >
              {renderMonthFields()}
            </Card>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default BudgetActualUpdate; 