import { FileOutlined, FilterOutlined, FolderOpenOutlined, ReloadOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, InputNumber, message, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import ObjectDefinitionDetail from "./ObjectDefinitionDetail";
import FormSelect from './FormSelect';

const ObjectDefinition = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [filterVisible, setFilterVisible] = useState(false);
  const [matches, setMatches] = useState(null);
  const [filterForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoObjectDefinition", { operation: "GET", include: ["Form"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
    setLoading(true);
  };
  const onNew = () => {
    setDetailVisible(true);
    setSelected(undefined);
  };

  const onUpdate = (record) => {
    if (!record) {
      //delete
      setData(data.filter((r) => r.id !== selected.id));
      return;
    }
    if (data.find((r) => r.id == record.id)) {
      //update
      setData(data.map((r) => (r.id == record.id ? record : r)));
    } else {
      //new
      setData([...data, record]);
    }
  };
  const onOpen = (r) => {
    setSelected(r);
    setDetailVisible(true);
  };
  const onFilterSearch = () => {
    filterForm.validateFields().then((values) => {
      let queries = [];

      if (values.user) queries.push(`[user]='${values.user}'`);
      if (values.id) queries.push(`id=${values.id}`);
      if (values.code) queries.push(`code='${values.code}'`);
      if (values.name) queries.push(`name='${values.name}'`);
      if (values.formId) queries.push(`formId=${values.formId}`);
      
      const filterQuery = queries.join(" AND ");

      setLoading(true);
      exe("RepoObjectDefinition", { operation: "GET",filter:filterQuery,include:["Form"]}).then((r) => {
        setLoading(false);
        if (r.ok) {
          setData(r.outData);
          setMatches(r.total);
        } else {
          message.error(r.msg);
        }
      });
    });
  }
  return (
    <div>
      <DefaultPage title={t("Object Definition")} icon="build">
        <div style={{ display: !detailVisible ? "block" : "none" }}>
          <div>
            <Button type="primary" icon={<FileOutlined />} onClick={onNew} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("New")}
            </Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("Reload")}
            </Button>
            <Button type="link" icon={<FilterOutlined />} onClick={()=>setFilterVisible(true)} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("Filter")}
            </Button>
          </div>
          <Table dataSource={data} loading={loading} size="middle">
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Code")} dataIndex="code" />
            <Table.Column title={t("Name")} dataIndex="name" />
            <Table.Column title={t("Form")} dataIndex={["Form","name"]} render={(v, r) => <a href={"#fb/" + r.formId}>{v}</a>} />
            <Table.Column
              title={t("Actions")}
              key="actions"
              render={(v, r) => (
                <div>
                  <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpen(r)} />
                </div>
              )}
            />
          </Table>
        </div>
        <div style={{ display: detailVisible ? "block" : "none" }}>
          <ObjectDefinitionDetail selected={selected} onBack={() => setDetailVisible(false)} onUpdate={(record) => onUpdate(record)} />
        </div>
      </DefaultPage>

      <Drawer title={t("Filter Search")} placement="right" width={512} onClose={()=>setFilterVisible(false)} visible={filterVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => filterForm.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical" form={filterForm}> 
          <Form.Item label={t("Id")} name="id"><InputNumber allowClear /></Form.Item>
          <Form.Item label={t("Code")} name="code"><Input allowClear /></Form.Item>
          <Form.Item label={t("Name")} name="name"><Input allowClear /></Form.Item>
          <Form.Item label={t("Form")} name="formId"><FormSelect /></Form.Item>
        
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onFilterSearch()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ObjectDefinition;
