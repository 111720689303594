import React, { Component } from "react";
import { CloseCircleOutlined, InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select, ConfigProvider, InputNumber, message, Empty, Tooltip } from "antd";
import ContactSelect from "./ContactSelect";
import { safeGetRaw } from "../../Lib/Dal";
import BenefitPaymentMethod from "../Life/BenefitPaymentMethod";
import { withTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";
import FamilyDistribution from "./FamilyDistribution";
import Money from "../Shared/Money";
import { round2 } from "../../Lib/Helpers";

class MultiContactSelect extends Component {
  static contextType = ConfigProvider.ConfigContext;
  state = {
    relationships: [],
    showBeneficiaryAmountField: false,
  };
  componentDidMount() {
    this.loadRelationships();
    this.checkConfig();
  }
  componentDidUpdate(prevProps) {
    //checking if config changed
    if (this.props.config !== prevProps.config) {
      this.checkConfig();
    }
  }
  checkConfig = ()=>{
    const showBeneficiaryAmountField = safeGetRaw(["config", "Main", "showBeneficiaryAmountField"], this.props);
    this.setState({ showBeneficiaryAmountField });
  }
  loadRelationships() {
    getCache("RepoRelationshipCatalog").then((r) => {
      if (r.ok) {
        this.setState({ relationships: r.outData });
      }
    });
  }
  addInsured() {
    let contacts = this.props.value || [];
    if (this.props.maxContacts !== undefined) {
      if (contacts.filter(p=>!p.removed).length >= this.props.maxContacts && this.props.maxContacts !== -1) {
        message.error(this.props.t("Only {{num}} allowed in this product", { num: this.props.maxContacts }));
        return;
      }
    }
    contacts = contacts.concat({ id: 0, contactId: 0, name: undefined, added:true });
    this.props.onChange(contacts);
  }
  removeInsured(removedItem) {
    if (removedItem.mandatory) {
      message.error(this.props.t("This beneficiary is configured as mandatory and cannot be removed."));
      return;
    }
    const contacts = this.props.value;

    if(this.props.renderRemoved){
      //marking for removal, will be rendered as removed
      const removedContact = contacts.find(p=>p.tempId==removedItem.tempId);
      //if not present in DB, just remove it
      if(removedContact.id==0){
        this.props.onChange(contacts.filter((p) => p.tempId !== removedItem.tempId));
        return;
      }
      removedContact.removed=!removedContact.removed;
      const newContacts=contacts.map(p=>p.tempId==removedItem.tempId?removedContact:p);
      this.props.onChange(newContacts);
    }else{
      if (removedItem.id > 0) {
        //present in DB, mark for removal, will not be rendered
        const removedIndex = contacts.findIndex((p) => p.id == removedItem.id);
        contacts[removedIndex] = { ...removedItem, id: removedItem.id * -1,relationship:undefined };
        this.props.onChange(contacts);
      } else {
        //not present in db, just remove it
        this.props.onChange(contacts ? contacts.filter((p) => p.contactId !== removedItem.key) : []);
      }
    }
  }
  onChange(item, value) {
    const contacts = this.props.value;
    console.log({ contacts, item, value }, this.props.maxMinorContacts);
    if (value && this.props.maxMinorContacts !== undefined) {
      if (value.contact.currentAge < 18) {
        //minor. counting
        const numUnderAge = contacts.filter((p) => p.Contact && p.id >= 0 && p.Contact.currentAge < 18).length;
        if (numUnderAge + 1 > this.props.maxMinorContacts) {
          message.error(this.props.t("This plan allows a maximum of {{num}} minor coinsured", { num: this.props.maxMinorContacts }));
          return false;
        }
      }
    }
    if (!contacts) {
      //no contact still selected
      const newContact = { id: item.id, contactId: value.key, name: value.label, tempId: item.tempId, percentage: 100, Contact: value.contact };
      this.props.onChange([newContact]);
      return;
    }
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    if (value) {
      //changed record
      contacts[modifiedIndex] = {
        id: item.id,
        contactId: value.key,
        name: value.label,
        tempId: item.tempId,
        lifeCoverageId: item.lifeCoverageId,
        coverageCode: item.coverageCode,
        Contact: value.contact,
        branchId:value.branchId,
        relationshipId: item.relationshipId,  //if has relationship before, keep it
        percentage: item.percentage,
        type: item.type,
      };
    } else {
      //clicked on reset button
      contacts[modifiedIndex] = { id: item.id, contactId: 0, name: undefined, tempId: item.tempId };
    }
    this.props.onChange(contacts.filter((p) => p));
  }
  onSelectRelationship(r, item) {
    const contacts = this.props.value;
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    contacts[modifiedIndex] = { ...contacts[modifiedIndex], relationship: +r };
    this.props.onChange(contacts);
  }
  onSelectRelationshipToMainInsured(r, item) {
    const contacts = this.props.value;
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    contacts[modifiedIndex] = { ...contacts[modifiedIndex], relationshipId: +r };
    this.props.onChange(contacts);
  }
  onChangePercentage(item, v) {
    const contacts = this.props.value;
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    contacts[modifiedIndex] = { ...contacts[modifiedIndex], percentage: +v, amount: this.getAmount(v) };

    //checking <100%
    // const assigned = contacts.filter((p) => p.id >= 0 && p.coverageCode == item.coverageCode && p.type == item.type).reduce((a, c) => +a + c.percentage, 0);
    // if (assigned > 100) {
    //   message.error("Assigned % must be equal or less than 100%. % Adjusted");
    //   contacts
    //     .filter((p) => p.coverageCode == item.coverageCode && p.type == item.type)
    //     .forEach((p) => {
    //       if (p.tempId != item.tempId) {
    //         p.percentage = 0;
    //       }
    //     });
    // }

    this.props.onChange(contacts);
  }
  onChangeAmount(item, v) {
    const contacts = this.props.value;
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    contacts[modifiedIndex] = { ...contacts[modifiedIndex], amount: +v, percentage: this.props.insuredSum?round2(v/this.props.insuredSum*100):0 };
    this.props.onChange(contacts);
  }
  getAmount=(percentage)=>{
    return this.props.insuredSum?(percentage*this.props.insuredSum/100):0;
  }
  onChangeCoverage(item, code) {
    const contacts = this.props.value;
    const modifiedIndex = contacts.findIndex((p) => p.tempId == item.tempId);
    contacts[modifiedIndex] = { ...contacts[modifiedIndex], lifeCoverageId: item.lifeCoverageId, coverageCode: code };

    this.props.onChange(contacts);
  }
  onChangeType(item, type) {
    const newContacts = this.props.value.map((p) => (p.tempId == item.tempId ? { ...p, type: type } : p));
    this.props.onChange(newContacts);
  }
  onChangeBenefitPaymentMethod(item, paymentMethod) {
    const newContacts = this.props.value.map((p) => (p.tempId == item.tempId ? { ...p, paymentMethod: paymentMethod } : p));
    this.props.onChange(newContacts);
  }
  hasMutirity(coverageCode) {
    return (this.props.coverages || []).some((p) => p.code == coverageCode && p.hasMaturity);
  }
  isSelectable(coverageCode) {
    if (this.props.config) {
      const cov = this.props.config.Coverages.find((p) => p.code == coverageCode);
      if (cov) {
        return cov.beneficiaries && (cov.beneficiaries == "OPTIONAL" || cov.beneficiaries == "MANDATORY");
      }
    }
    return false;
  }
  setMarginRight=(value)=>{
    const direction=this.context.direction;
    return direction=="rtl"?{marginLeft:value}:{marginRight:value}
  }
  setMarginLeft(value){
    const direction=this.context.direction;
    return direction=="rtl"?{marginRight:value}:{marginLeft:value}
  }
  setPaddingLeft(value){
    const direction=this.context.direction;
    return direction=="rtl"?{paddingRight:value}:{paddingLeft:value}
  }
  onFamilyDistribution=(distribution)=>{
    //adding new contacts with the selected relationships
    //selecting the basic coverage as the default
    var cov=(this.props.coverages || []).find(p=>p.basic)||{code:undefined};

    const contacts = [];
    distribution.forEach(r=>{
      const newContact = { id: 0, contactId: 0, name: undefined, tempId: contacts.length, relationshipId: r.relationshipId,coverageCode:cov.code, type:"DEATH", percentage: r.split,};
      contacts.push(newContact);
    })
    this.props.onChange(contacts);
    console.log(contacts);
  }
  render() {
    const { t, i18n } = this.props;
    let contacts = this.props.value || [];
    if (!contacts) contacts = [{ id: 0, key: 0, label: undefined, tempId: 0 }];
    contacts.forEach((p, i) => (p.tempId = i)); //assign temporal ID fore each element
    const excluded = contacts.filter((p) => p.id >= 0).map((p) => +p.contactId);
    const disabled = this.props.disabled;

    if (contacts.length == 0 && disabled) return <Empty />;

    return (
      <div style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
        {(contacts || [])
          .filter((p) => p.id >= 0)
          .map((p) => ({
            id: p.id,
            tempId: p.tempId,
            key: p.contactId,
            label: p.name,
            relationship: p.relationship,
            percentage: p.percentage,
            amount: (p.percentage&&!p.amount)?this.getAmount(p.percentage):p.amount,
            lifeCoverageId: p.lifeCoverageId,
            coverageCode: p.coverageCode,
            type: p.type,
            mandatory: p.mandatory,
            Contact: p.Contact,
            relationshipId: p.relationshipId,
            paymentMethod: p.paymentMethod,
            branchId:p.branchId,
            removed:p.removed,
            added:p.id==0,
          }))
          .map((item, index) => (
            <div key={"contact" + index} style={{ display: "flex" }}>
              {this.props.renderRemoved&&item.removed&&<div style={{fontSize:20}}><CloseCircleOutlined style={{color:"red", margin:5}} /></div>}
              {this.props.renderRemoved&&item.added&&<div style={{fontSize:20}}><PlusCircleOutlined style={{color:"green", margin:5}} /></div>}
              <Form.Item className="noFlex" label={index == 0 && this.props.showPercentage ? t("Beneficiary") : null} style={{ flex: 1,...this.setMarginRight(3) }}>
                <ContactSelect style={{ flex: 1, ...this.setMarginRight(3) }} value={item} contactChange={(v) => this.onChange(item, v)} disabled={item.mandatory} showContactBranch={this.props.showContactBranch} configProfile={this.props.configProfile} />
              </Form.Item>
              {this.props.showRelationship && (
                <Select
                  style={{ width: 150 }}
                  dropdownMatchSelectWidth={false}
                  placeholder={t("Relationship")}
                  value={item.relationship}
                  onSelect={(v) => this.onSelectRelationship(v, item)}>
                  {this.state.relationships
                    .filter((p) => p.principalType == "MAIN")
                    .map((relationship) => (
                      <Select.Option value={relationship.id}>{t(relationship.name)}</Select.Option>
                    ))}
                </Select>
              )}
              {this.props.showPercentage && (
                <div style={{ display: "flex" }}>
                  <Form.Item className="noFlex"
                    label={
                      index == 0 ? (
                        <span>
                          {t("Relationship")}
                          <Tooltip title={t("Relationship of the beneficiary with the main insured")}>
                            <InfoCircleOutlined
                              style={{ verticalAlign: "middle", color: "rgba(0, 0, 0, 0.25)", fontSize: 12,...this.setMarginLeft(3) }} />
                          </Tooltip>
                        </span>
                      ) : (
                        ""
                      )
                    }>
                    <Select
                      style={{ width: 100 }}
                      dropdownMatchSelectWidth={false}
                      placeholder={t("Relationship")}
                      value={item.relationshipId}
                      disabled={item.mandatory || (this.props.incompatible && this.props.incompatible == item.key)}
                      onSelect={(v) => this.onSelectRelationshipToMainInsured(v, item)}>
                      {this.state.relationships
                        .filter((r) => r.principalType == (item.Contact && !item.Contact.isPerson ? "BENEFICIARYLEGAL" : "BENEFICIARY"))
                        .map((relationship) => (
                          <Select.Option value={relationship.id}>{t(relationship.name)}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={index == 0 ? t("Coverage") : null} className="noFlex">
                    <Select
                      value={item.coverageCode}
                      onChange={(code) => this.onChangeCoverage(item, code)}
                      placeholder={t("Coverage")}
                      disabled={item.mandatory}
                      style={{ width: 100, flex: 1,...this.setMarginLeft(3)}}>
                      {(this.props.coverages || []).map((p) => (
                        <Select.Option value={p.code} key={p.code} disabled={!this.isSelectable(p.code)}>
                          {p.code}
                        </Select.Option>
                      ))}
                      {this.props.beneficiaryAllCoveragesAllowed&&<Select.Option value="ALL" key="ALL">
                        {t("All")}
                      </Select.Option>}
                    </Select>
                  </Form.Item>
                  <Form.Item label={index == 0 ? t("Type") : null} className="noFlex">
                    <Select
                      placeholder={t("Type")}
                      style={{ width: 175,...this.setMarginLeft(3) }}
                      dropdownMatchSelectWidth={false}
                      value={this.hasMutirity(item.coverageCode) ? item.type : "DEFAULT"}
                      disabled={!this.hasMutirity(item.coverageCode)}
                      onChange={(v) => this.onChangeType(item, v)}>
                      <Select.Option value="DEATH" disabled={!this.hasMutirity(item.coverageCode)}>
                        {t("Death Beneficiary")}
                      </Select.Option>
                      <Select.Option value="MATURITY" disabled={!this.hasMutirity(item.coverageCode)}>
                        {t("Maturity Beneficiary")}
                      </Select.Option>
                      <Select.Option value="DEFAULT" disabled={this.hasMutirity(item.coverageCode)}>
                        {t("Default Beneficiary")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label={index == 0 ? t("Payment Method") : null} className="noFlex">
                    <BenefitPaymentMethod
                      productCode={this.props.productCode}
                      disabled={item.type !== "MATURITY"}
                      value={this.hasMutirity(item.coverageCode) && item.type == "MATURITY" ? item.paymentMethod : undefined}
                      onChange={(v) => this.onChangeBenefitPaymentMethod(item, v)}
                    />
                  </Form.Item>
                  <Form.Item label={index == 0 ? t("Split") : null} className="noFlex">
                    <InputNumber
                      style={{ width: 75,...this.setMarginLeft(3) }}
                      placeholder="%"
                      value={item.percentage}
                      min={0}
                      max={100}
                      disabled={item.mandatory}
                      onChange={(v) => this.onChangePercentage(item, v)}
                    />
                  </Form.Item>
                  {this.state.showBeneficiaryAmountField && (
                  <Form.Item label={index == 0 ? t("Amount") : null} className="noFlex">
                    <Money
                      style={{ width: 75,...this.setMarginLeft(3) }}
                      min={0}
                      max={this.props.insuredSum}
                      value={item.amount}
                      disabled={item.mandatory}
                      currency={this.props.currency}
                      onChange={(v) => this.onChangeAmount(item, v)}
                    />
                  </Form.Item>
                )}
                </div>
              )}
              <MinusCircleOutlined
                style={{ cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center",...this.setMarginLeft(5) }}
                onClick={() => this.removeInsured(item)} />
            </div>
          ))}
        {!disabled && <div>
          <Button type="link" style={this.setPaddingLeft(0)} icon={<PlusCircleOutlined />} onClick={() => this.addInsured()}>
            {this.props.showPercentage ? t("Add Beneficiary") : t("Add Insured")}
          </Button>
          {this.props.showPercentage&&<FamilyDistribution onOk={v=>this.onFamilyDistribution(v)} />}
        </div>}
      </div>
    );
  }
}

export default withTranslation()(MultiContactSelect);
