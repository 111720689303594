import React, { useState, useEffect } from "react";
import {
  CheckOutlined,
  DeleteOutlined,
  FilterOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SelectOutlined,
  ThunderboltOutlined,ReloadOutlined
} from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  message,
  Button,
  Divider,
  Tag,
  Popconfirm,
  Drawer,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Tooltip,
  Modal,
  Checkbox,
} from "antd";
import { exe } from "../../Lib/Dal";
import useUpdateEffect, {formatDate, formatMoney, getColor} from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import YesNoSelect from "../Shared/YesNoSelect";
import { useTranslation } from "react-i18next";
import FormRenderModal from "../FormRenderModal";
import CustomForm from "../Shared/CustomForm";
import Status from "../Shared/Status";
import DateCell from "../Shared/DateCell";
import ReversalDetail from "../Financial/ReversalDetail";
import IncomeTypeSelect from "./IncomeTypeSelect";
import PaymentTypeSelect from "../Payments/PaymentTypeSelect";
import PaymentMethods from "../Life/PaymentMethods";
import moment from "moment";
import DatePickerW from "../Shared/DatePickerW";
import { load } from 'js-yaml';

const TransferList = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 15, total: 0,current:1, showTotal: (total) => `Total ${total} items` });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formId,setFormId]=useState();
  const [jForm,setJForm]=useState();
  const [reversalRecord,setReversalRecord]=useState();

  useEffect(() => onCompute(), [props.workspaceId]);
  useUpdateEffect(() => {
      if(pagination.current===-1){
          //comes from onCompute
          pagination.current=1; //changing value without setState to avoid effect
      } else onCompute(true)
  }, [pagination.current,props.refresh])

  const doTransfer = (id,transactionCode="") => {
    setLoading(true);
    exe("DoTransfer", { transferId: id }, null, true).then((r) => {
      setLoading(false);
      onCompute(true);
      if(transactionCode==="OTHER"){
        props.onOtherExecuted&&props.onOtherExecuted(id);
      }
    });
  };
  const undoTransfer = (record) => {
    exe("UndoTransfer", { transferId: record.id,reversalCause:record.reversalCause, reversalSubcause:record.reversalSubcause, jReversalFormValues:record.jReversalFormValues }, null, true).then((r) => {
      setLoading(false);
      onCompute(true);
    });
  };
  const deleteTransfer = (id) => {
    setLoading(true);
    exe("RepoTransfer", { operation: "DELETE", entity: { id: id } }).then((r) => {
      setLoading(false);
      onCompute(true);
    });
  };
  const onCompute = (preservePagination=false) => {
    const v = props.form.getFieldsValue();
    if(preservePagination===true){
        v.size = pagination.pageSize;
        v.page = pagination.current-1;
    }else{
        v.size = pagination.pageSize;
        v.page = 0;
    }
    if(v.page<0) v.page=0;
    //converting dates to utc equivalent. Result will be converted in grid back to local time
    if(v.fromDate) v.fromDate = v.fromDate.clone().startOf('day').add((new Date()).getTimezoneOffset(),'m').format();
    if(v.toDate) v.toDate = v.toDate.clone().endOf('day').add((new Date()).getTimezoneOffset(),'m').format();
    window.moment=moment;
    setLoading(true);
    exe("FilterTransfer", v).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setPagination({ ...pagination,current:preservePagination===true?pagination.current:-1, total: r.total });
      } else {
        message.error(r.msg);
      }
    });
  };
  const getStatus = (statusCode, record) => {
    switch (statusCode) {
      case 0:
        return <Tag>{t("Draft")}</Tag>;
        break;
      case 1:
        return <Tag color="green">{t("Executed")}</Tag>;
      case 2:
        return (
          <Tooltip title={record.reversalDate ? formatDate(record.reversalDate) : null}>
            <Tag color="red">{t("Reverted")}</Tag>
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const series=(keys)=> {
    // Create a new empty promise (don't do that with real people ;)
    let sequence = Promise.resolve();
    // Loop over each item, and add on a promise to the
    // end of the 'sequence' promise.
    keys.forEach(key => {
      // Chain one computation onto the sequence
      sequence = sequence
              .then(() => exe("DoTransfer", { transferId: key }).then((r) => {
                if(r.ok) message.success(r.msg); else message.error(r.msg);
              }));
    })
    return sequence; // This will resolve after the entire chain is resolved
  }
  
  const executeSelection = () => {
    //validation
    const records=data.filter(p=>selectedRowKeys.includes(p.id));
    if(records.some(p=>p.executed)){
      message.error(t("Transfer already executed") + "id:" + records.find(p=>p.executed).id);
      return;
    }
    setLoading(true);
    series(selectedRowKeys).then(r => {
      setLoading(false);
      onCompute(true);
    });
  };
  const onPaginationChange = (currentPagination) => {
    setPagination(currentPagination);
  };
  const renderPaymentForm=(jValues,formId)=>{
   setFormId(formId);
   setJForm(jValues);
}
const onReversalDetail=reversalFormValues=>{
    const transferToBeReverted={...reversalRecord,...reversalFormValues};
    onReversal(transferToBeReverted);
    setReversalRecord(undefined);
}
const onReversal=record=>{
    if(record.allocationId){
      exe("UnDoPaymentAllocation", { allocationId: record.allocationId,
          reversalCause:record.reversalCause, 
          reversalSubcause:record.reversalSubcause, 
          jReversalFormValues:record.jReversalFormValues,
          creditFundsToAccountId:record.creditFundsToAccountId,
          workspaceId:record.workspaceId,
      }).then((r) => {
        setLoading(false);
        if(r.ok){
          onCompute(true);
          message.success(r.msg);
        }else {
            message.error(r.msg);
            if(r.opt==="RELOAD") onCompute(true); //wf assigned, needs refresh to show new wf
        }
      });
    }else if(record.claimId){
        exe("UnDoPaymentClaim", { transferId: record.id,reversalCause:record.reversalCause, reversalSubcause:record.reversalSubcause, jReversalFormValues:record.jReversalFormValues }).then((r) => {
            setLoading(false);
            if(r.ok){
                onCompute(true);
                message.success(r.msg);
            }else message.error(r.msg); });
    }
    else{
      undoTransfer(record)
    }
}
  const CustomExpandIcon = (props) => {
    if (!props.record.AllocationMovements || props.record.AllocationMovements.length === 0) return null;
    if (props.expanded) {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <MinusCircleOutlined />
        </a>
      );
    } else {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <PlusCircleOutlined />
        </a>
      );
    }
  };
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
        {t("Filter")}
      </Button>
      {selectedRowKeys.length > 0 && (
        <Button type="link" icon={<SelectOutlined />} onClick={() => executeSelection()}>
          {t("Execute Selection")}
        </Button>
      )}
        <Button type="link" icon={<ReloadOutlined />} onClick={() => {setData([]);onCompute(true);}}>
            {t("Reload")}
        </Button>
      <Table
        dataSource={data}
        rowKey="id"
        loading={loading}
        rowSelection={(props.allocation||props.workspace)?props.rowSelection:{ selectedRowKeys, onChange: (keys) => setSelectedRowKeys(keys) }}
        pagination={{...pagination,current:Math.max(pagination.current,1)}}
        scroll={{ x: true }}
        onChange={(pag) => onPaginationChange(pag)}
        expandIcon={(p) => <CustomExpandIcon {...p} />}
        expandedRowRender={r=><Table dataSource={r.AllocationMovements} >
            {/*----------SUB TABLE------------*/}
            <Table.Column title={t("ID")} dataIndex="id" width={100} />
            <Table.Column title={t("Date")} dataIndex="date" render={(v) => <DateCell value={v} />} />
            <Table.Column title={t("Status")} dataIndex="status" render={(v, r) => getStatus(v, r)} />
            <Table.Column title={t("Source")} dataIndex="sourceName" width={120}/>
            <Table.Column title={t("Destination")} dataIndex="destinationName" width={120}/>
            <Table.Column title={t("Reference")} dataIndex="concept" />
            {props.workspaceId&&<Table.Column title={t("Received")} key="receivedAmount" render={(v,r) => <div  style={{fontSize:12}}>
                {r.SplitPayments.map(d=><div key={d.id} style={{display:"flex"}}>{formatMoney(d.conversion?d.amountEx:d.amount,d.currency)}</div>)}
            </div>} />}
            <Table.Column title={t("Amount")} dataIndex="amount" width={100} />
            <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} width={100} />
            <Table.Column title={t("Type")} dataIndex={["IncomeType","name"]}  />
            {props.workspaceId&&<Table.Column title={t("Detail")} key="openMovement" render={(v,r) => <Button type="link" icon={<LegacyIcon type={"eye"} />} onClick={()=>props.onOpenPayment(r)} />} />}
            <Table.Column
                title={t("Actions")}
                dataIndex="id"
                key="actions"
                fixed1={"right"}
                render={(v, r) => (
                    <div className={props.readOny?"disabled":""} style={{ whiteSpace: "nowrap" }}>
                        <Popconfirm title={t("Are you sure?")} disabled={r.executed} onConfirm={() => doTransfer(v)} okText={t("Yes")} cancelText={t("No")}>
                            <Button type="link" disabled={r.executed||r.reversalDate}>
                                {t("Execute")}
                            </Button>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Button type="link" disabled={!r.executed||r.reversalDate} onClick={() => setReversalRecord(r)}>
                            {<LegacyIcon style={{marginRight:3}} type={r.allocationId?"dollar":"retweet"} />}{t("Revert")}
                        </Button>
                        <Divider type="vertical" />
                        <Popconfirm
                            title={t("Are you sure yo want to delete this record?")}
                            disabled={r.status !== 0}
                            onConfirm={() => deleteTransfer(v)}
                            okText={t("Yes")}
                            cancelText={t("No")}>
                            <Button type="link" disabled={r.status !== 0} icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </div>
                )}
            />
        </Table>}
      >
          {/*----------MAIN TABLE------------*/}
        <Table.Column title={t("ID")} dataIndex="id" />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => <DateCell value={v} />} />
        <Table.Column title={t("Status")} dataIndex="status" render={(v, r) => getStatus(v, r)} sorter={(a,b)=>a.status-b.status}  />
        <Table.Column
          title={t("Source")}
          dataIndex={["SourceAccount","accNo"]}
          render={(v, r) => (r.isExternal ? <span>{r.sourceExternal}</span> : <a href={"#/account/" + r.sourceAccountId}>{v}</a>)}
        />
        <Table.Column
          title={t("Destination")}
          dataIndex={["DestinationAccount","accNo"]}
          render={(v, r) => <a href={"#/account/" + r.destinationAccountId}>{v}</a>}
        />
        <Table.Column title={t("Reference")} dataIndex="concept" />
        {props.workspaceId&&<Table.Column title={t("Received")} key="receivedAmount" render={(v,r) => <div  style={{fontSize:12}}>
          {r.SplitPayments.map(d=><div key={d.id} style={{display:"flex"}}>{formatMoney(d.conversion?d.amountEx:d.amount,d.currency)}</div>)}
        </div>} />}
        <Table.Column title={t("Amount")} dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency)} sorter={(a,b)=>a.amount-b.amount}  />
        <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
        <Table.Column title={t("Type")} dataIndex={["IncomeType","name"]}  />
          <Table.Column title={t("Payment Method")} key="paymentMethods" render={(v,r) => <div  style={{fontSize:10}}>
              {r.SplitPayments.map(d=><div key={d.id} style={{display:"flex"}}>{d.PaymentMethod?d.PaymentMethod.name:d.paymentMethod}</div>)}
          </div>} />
          <Table.Column title={t("Policy")} dataIndex="Allocation" render={v=>v?<div style={{fontSize:10}}>
              {v.InstallmentPremiums.map(d=><div key={d.id} style={{display:"flex"}}>{<a href={"#/lifepolicy/"+d.lifePolicyId}>{d.lifePolicyId}</a> }</div>)}
              {v.SupplementaryPremiums.map(d=><div key={d.id} style={{display:"flex"}}>{d.lifePolicyId?(<a href={"#/lifepolicy/"+d.lifePolicyId}>{d.lifePolicyId}</a>):(<a href={"#/account/"+d.accountId}>{d.accountId}</a>)}</div>)}
          </div>:null}  />
        <Table.Column title={t("Executed")} dataIndex="executed" render={(v) => v && <CheckOutlined />} />
        <Table.Column title={t("Cashier ID")} dataIndex="transferWorkspaceId"  />
        <Table.Column title={t("User")} dataIndex="user"  />
        <Table.Column title={t("Allocation")} dataIndex="allocationId" render={(v) => <a href={"#/allocation?id=" + v}>{v}</a>} />
        {props.workspaceId&&<Table.Column title={t("Detail")} key="openMovement" render={(v,r) => <Button type="link" icon={<LegacyIcon type={"eye"} />} onClick={()=>props.onOpenPayment(r)} />} />}
        {/*{props.workspaceId&&<Table.Column title={t("Detail")} dataIndex="jForm" render={(v,r) => v?<Button type="link" icon={"eye"} onClick={()=>renderPaymentForm(v,r.formId)} />:null} />}*/}
        <Table.Column title={t("Workflow")} dataIndex="Process" fixed1={"right"} render={(v,r) =>  <Status small process={v} color={getColor(v && v.entityState)} reload={() => onCompute(true)} />} />
        <Table.Column
          title={t("Actions")}
          dataIndex="id"
          key="actions"
          fixed1={"right"}
          render={(v, r) => (
            <div className={props.readOny?"disabled":""} style={{ whiteSpace: "nowrap" }}>
              <Popconfirm title={t("Are you sure?")} disabled={r.executed} onConfirm={() => doTransfer(v,r.transactionCode)} okText={t("Yes")} cancelText={t("No")}>
                <Button type="link" disabled={r.executed||r.reversalDate}>
                  {t("Execute")}
                </Button>
              </Popconfirm>
              <Divider type="vertical" />
              <Button type="link" disabled={!r.executed||r.reversalDate} onClick={() => setReversalRecord(r)}>
                {<LegacyIcon style={{marginRight:3}} type={r.allocationId?"dollar":"retweet"} />}{t("Revert")}
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title={t("Are you sure yo want to delete this record?")}
                disabled={r.status !== 0}
                onConfirm={() => deleteTransfer(v)}
                okText={t("Yes")}
                cancelText={t("No")}>
                <Button type="link" disabled={r.status !== 0} icon={<DeleteOutlined />} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <Drawer title={t("Filter")} visible={filterVisible} width={512} onClose={() => setFilterVisible(false)}>
        <Form layout="vertical">
            <div style={{display:"flex"}}>
                <Form.Item label={t("Id")} style={{marginRight:5,width:"100%"}}>{field("id", )(<InputNumber style={{width:"100%"}}/>)}</Form.Item>
                <Form.Item label={t("Allocation Id")} style={{width:"100%"}}>{field("allocationId", )(<InputNumber style={{width:"100%"}}/>)}</Form.Item>
            </div>
            <div style={{display:"flex"}}>
                <Form.Item label={t("From")} style={{marginRight:5,width:"100%"}}>{field("fromDate", )(<DatePickerW style={{width:"100%"}} />)}</Form.Item>
                <Form.Item label={t("To")} style={{width:"100%"}}>{field("toDate", )(<DatePickerW style={{width:"100%"}} />)}</Form.Item>
            </div>
            <div style={{display:"flex"}}>
              <Form.Item label={t("Allocated")} style={{marginRight:5,width:"100%"}}>{field("allocated", { initialValue: props.allocation ? false : undefined })(<YesNoSelect />)}</Form.Item>
              <Form.Item label={t("External")} style={{width:"100%"}}>{field("external", { initialValue: props.allocation ? true : undefined })(<YesNoSelect />)}</Form.Item>
            </div>
          <Form.Item label={t("Executed")}>{field("executed", { initialValue: props.allocation ? true : undefined })(<YesNoSelect />)}</Form.Item>
          <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
          <Form.Item label={t("Concept")}>{field("concept")(<Input allowClear />)}</Form.Item>
            <div style={{display:"flex"}}>
              <Form.Item label={t("Min Amount")} style={{marginRight:5,width:"100%"}}>{field("minAmount")(<InputNumber style={{width:"100%"}} />)}</Form.Item>
              <Form.Item label={t("Max Amount")} style={{width:"100%"}}>{field("maxAmount")(<InputNumber style={{width:"100%"}} />)}</Form.Item>
                
            </div>
            <div style={{display:"flex"}}>
            <Form.Item label={t("Type")} style={{marginRight:5,width:"100%"}}>{field("incomeType")(<IncomeTypeSelect />)}</Form.Item>
            <Form.Item label={t("Payment Method")} style={{width:"100%"}}>{field("paymentMethod")(<PaymentMethods module="INCOME" />)}</Form.Item>
            </div>
          <Form.Item label={t("Month")}>{field("month")(<DatePicker.MonthPicker allowClear />)}</Form.Item>
          <Form.Item label={t("Workspace Id")}>{field("workspaceId",{initialValue:props.workspaceId})(<InputNumber disabled={props.workspaceId} />)}</Form.Item>
          <Form.Item label={t("Payment Request Id")}>{field("claimPaymentId",)(<InputNumber  />)}</Form.Item>
          <Form.Item label={t("Group by allocation")}>{field("groupByAllocation",{valuePropName:"checked",initialValue:props.workspaceId})(<Checkbox />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<ThunderboltOutlined />} onClick={()=>onCompute()}>
          {t("Compute now")}
        </Button>
      </Drawer>
      <Modal
          title={t("Payment Form")}
          visible={formId}
          destroyOnClose
          onCancel={() => setFormId(null)}
          onOk={() => setFormId(null)}>
        <CustomForm variable={"customPaymentForm"} formId={formId} value={jForm}  />
      </Modal>
      <ReversalDetail visible={reversalRecord} onCancel={()=>setReversalRecord(undefined)} onOk={r=>onReversalDetail(r)} selected={reversalRecord} />
    </div>
  );
};

export default Form.create()(TransferList);
