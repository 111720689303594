import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import { ArrowLeftOutlined, FileOutlined, FilterOutlined, ReloadOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Button, message, Form, InputNumber, Input, Drawer, Tag } from "antd";
import HtmlTemplateForm from "./HtmlTemplateForm";
import { useTranslation } from "react-i18next";

const HtmlTemplates = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [matches, setMatches] = useState(null);
  const [filterForm] = Form.useForm();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => load(), []);

  const load = () => {
    exe("RepoHtmlTemplate", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNew = () => {
    setSelected({});
  };
    const onFilterSearch = () => {
      filterForm.validateFields().then((values) => {
        let queries = [];
  
        if (values.name) queries.push(`name='${values.name}'`);
        if (values.description) queries.push(`LOWER(description) LIKE '%${values.description.toLowerCase()}%'`);
        
        const filterQuery = queries.join(" AND ");
  
        setLoading(true);
        exe("RepoHtmlTemplate", { operation: "GET",filter:filterQuery}).then((r) => {
          setLoading(false);
          if (r.ok) {
            setData(r.outData);
            setMatches(r.total);
          } else {
            message.error(r.msg);
          }
        });
      });
    }
  return (
    <div>
      <DefaultPage title={t("HTML Templates")} icon="html5">
        {!selected && (
          <div>
            <Button type="link" icon={<FileOutlined />} onClick={onNew}>
              {t("New")}
            </Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>
              {t("Reload")}
            </Button>
            <Button type="link" icon={<FilterOutlined />} onClick={()=>setFilterVisible(true)} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("Filter")}
            </Button>
            <Table dataSource={data}>
              <Table.Column title={t("Name")} dataIndex="name" />
              <Table.Column title={t("Description")} dataIndex="description" />
              <Table.Column
                title={t("Action")}
                render={(v, r) => (
                  <Button type="link" onClick={() => setSelected(r)}>
                    {t("Open")}
                  </Button>
                )}
              />
            </Table>
          </div>
        )}
        {selected && (
          <div>
            <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => setSelected(null)}>
              {t("Back")}
            </Button>
            <HtmlTemplateForm record={selected} onUpdate={load} />
          </div>
        )}
      </DefaultPage>
      <Drawer title={t("Filter Search")} placement="right" width={512} onClose={()=>setFilterVisible(false)} visible={filterVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => filterForm.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical" form={filterForm}> 
          <Form.Item label={t("Name")} name="name"><Input allowClear /></Form.Item>
          <Form.Item label={t("Description")} name="description"><Input allowClear /></Form.Item>
        
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onFilterSearch()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default HtmlTemplates;
