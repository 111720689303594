import React, { useState, useEffect, useMemo, useCallback } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { CopyOutlined, ExclamationCircleOutlined, FileOutlined, FilterOutlined, ReloadOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Tag, Divider, Button, message, Popover, Input, Form, Drawer } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const InvestmentPlans = (props) => {
  // State
  const [investmentPlans, setInvestmentPlans] = useState([]);
  const [investmentPlanCode, setInvestmentPlanCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [filterVisible, setFilterVisible] = useState(false);
  const [matches, setMatches] = useState(null);
  const [filterForm] = Form.useForm();

  // Load investment plans on component mount
  useEffect(() => {
    load();
  }, []);

  // Function to load data
  const load = () => {
    setLoading(true);
    exe("RepoInvestmentPlan", { operation: "GET" })
      .then((r) => {
        setLoading(false);
        if (r.ok) {
          setInvestmentPlans(r.outData);
        } else {
          message.error(r.msg);
        }
      });
  };

  // Handle cloning an investment plan
  const onClone = (record) => {
    const clonedRecord = {
      ...record,
      code: investmentPlanCode,
      name: `${record.name} (copy ${new Date().toLocaleString()})`
    };

    setLoading(true);
    exe("RepoInvestmentPlan", { operation: "ADD", entity: clonedRecord }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };



  // Redirect to create a new investment plan
  const newInvestmentPlan = () => {
    window.location.href = "#/InvestmentPlans/0";
  };
  const onFilterSearch = () => {
    filterForm.validateFields().then((values) => {
      let queries = [];

      if (values.code) queries.push(`code='${values.code}'`);
      if (values.name) queries.push(`LOWER(name) LIKE '%${values.name.toLowerCase()}%'`);
      if (values.type) queries.push(`type='${values.type}'`);

      const filterQuery = queries.join(" AND ");

      setLoading(true);
      exe("RepoInvestmentPlan", { operation: "GET", filter: filterQuery }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setInvestmentPlans(r.outData);
          setMatches(r.total);
        } else {
          message.error(r.msg);
        }
      });
    });
  }

  return (
    <DefaultPage
      title={t("Financial Products")}
      icon="euro"
      extra={
        <ButtonGroup>
          <Button icon={<FileOutlined />} onClick={newInvestmentPlan}>
            {t("New")}
          </Button>
          <Button onClick={load} icon={<ReloadOutlined />}>{t("Reload")}</Button>
          <Button icon={<FilterOutlined />} onClick={() => setFilterVisible(true)} style={{ marginBottom: 5, marginRight: 5 }}>
            {t("Filter")}
          </Button>
        </ButtonGroup>
      }>
      <Table dataSource={investmentPlans} loading={loading}>
        <Table.Column
          title={t("Code")}
          dataIndex="code"
          key="code"
          render={(text) => <a href={`#/InvestmentPlans/${text}`}>{text}</a>}
        />

        <Table.Column
          title={t("Name")}
          dataIndex="name"
          key="name"
        />

        <Table.Column
          title={t("Type")}
          dataIndex="type"
          key="type"
        />

        <Table.Column
          title={t("Action")}
          key="action"
          render={(text, record) => (
            <span>
              <a href={`#/InvestmentPlans/${record.code}`}>{t("Edit")}</a>
              <Divider type="vertical" />
              <Popover
                content={
                  <div style={{ width: 150 }}>
                    {t("Please specify unique investment plan code for the cloned plan")}
                    <Input
                      style={{ marginTop: 5, marginBottom: 5 }}
                      value={investmentPlanCode}
                      placeholder={t("Please insert code")}
                      onChange={(e) => setInvestmentPlanCode(e.target.value)}
                    />
                    <Button type="primary" icon={<CopyOutlined />} onClick={() => onClone(record)}>
                      {t("Clone")}
                    </Button>
                  </div>
                }
                title={
                  <div>
                    <ExclamationCircleOutlined style={{ marginRight: 5, color: "orange" }} />
                    {t("Financial Product Code")}
                  </div>
                }
                trigger="click"
              >
                <Button type="link">{t("Clone")}</Button>
              </Popover>
            
            </span>
          )}
        />
      </Table>
      <Drawer title={t("Filter Search")} placement="right" width={512} onClose={() => setFilterVisible(false)} visible={filterVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => filterForm.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical" form={filterForm}>
          <Form.Item label={t("Code")} name="code"><Input allowClear /></Form.Item>
          <Form.Item label={t("Name")} name="name"><Input allowClear /></Form.Item>
          <Form.Item label={t("Type")} name="type"><Input allowClear /></Form.Item>

        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onFilterSearch()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </DefaultPage>
  );
};

export default InvestmentPlans;