import config from "../config";
import { message } from "antd";
import i18n from "../i18n";

////////////////////////////////////
//NUEVO REGISTRO////////////////////
export const post = (url, data) => {
  return fetch(config.ssApiUrl + url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: JSON.stringify(data),
  })
    .then(buildResponse)
    .catch((err) => {
      return { ok: false, msg: i18n.t("Not found or connection problem"), error: err };
    });
};

export const exe = (cmd, data, opt, notify) => {
  const dto = { ...opt, cmd: cmd, data: data };
  console.log(String.fromCodePoint(0x1F4E1),dto);
  return fetch(config.ssApiUrl + "/api/cmd", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: JSON.stringify(dto),
  })
    .then((r) => r.json())
    .then((r) => {
      console.log(r.ok?String.fromCodePoint(0x2705):String.fromCodePoint(0x274C),r, r.msg);
      if (notify) message[r.ok ? "success" : "error"](r.msg);
      if(r.hooks&&r.hooks.length>0){
        //executing uiCode for each hook, if defined in a provided context
        r.hooks.forEach(hook=>{
          if(hook.uiCode){
            try {
              const context = {result:r,hook:hook};
              let f = hook.uiCode;
              f = f.replaceAll("_", "context.");
              console.log(f, context, "CONTEXT");
              return eval(f);
            } catch (error) {
              console.log("Unable to evaluate formula client side", error);
              return undefined;
            }
          }
        });
      }
      return r;
    })
    .catch((err) => {
      console.log(err, "catch during exe");
      return { ok: false, msg: i18n.t("Command not found or connection problem"), error: err };
    });
};
//asyncExe executes a command on the event bus via PutMsg and optionally waits for a response via polling
export const exeBackground = (cmd, data,saveDto,interval=5000) => {
  //not allow interval less than 5 seconds
  if(interval<5000) interval=5000;
  const sCmd = { cmd: cmd, data: data };
  
  return new Promise((resolve, reject) => {
    exe("PutMessage", { batch: cmd, value: JSON.stringify(sCmd), saveDto: saveDto })
      .then((r) => {
        if (r.ok) {
          const msgId = r.outData;
          
          const checkResult = () => {
            // if(!window.GLOBAL||!window.GLOBAL.notifications) window.GLOBAL.notifications=[];
            // //checking if result is available in the GLOBAL.notifications array
            // const result = window.GLOBAL.notifications.find((n) => n.msg.indexOf(msgId) !== -1);
            // console.log("Checking result",result);
            // if (result) {
            //   resolve({ ok: true, outData: result });
            // }else{
            //   // If the result isn't ready, check again after 5 seconds
            //   setTimeout(checkResult, interval);
            // }
            exe("GetMessageResult", { msgId: msgId }).then((result) => {
              if (result.ok) {
                if (result.outData.ready) {
                  resolve({ ok: true, outData: result.outData.msgResult });
                } else {
                  // If the result isn't ready, check again after 5 seconds
                  setTimeout(checkResult, interval);
                }
              } else {
                reject({ ok: false, msg: result.msg });
              }
            });
          };
          
          // Start the first check
          checkResult();
          
        } else {
          reject({ ok: false, msg: r.msg });
        }
      })
      .catch((error) => {
        reject({ ok: false, msg: error.message });
      });
  });
};


export const downloadFile = (route) =>
  fetch(config.ssApiUrl + "/proxy" + route, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
  })
    .then((response) => response.blob())
    .then((data) => window.open(URL.createObjectURL(data)))
    .catch((err) => console.log(err));

export const fetchProxy = (route, method, body) => {
  console.log("Proxy request-->",route,method, body);
  return fetch(config.ssApiUrl + "/proxy" + route, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((r) => r.json())
    .then((r) => {
      const output = { proxy: true, ok: true, outData: r };
      console.log(output);
      return output;
    })
    .catch((err) => {
      console.log(err, "catch during proxy fetch");
      return { ok: false, msg: i18n.t("Command not found or connection problem"), error: err };
    });
};
//use this one, the previous need response normalization when error
export const getProxy = (route, method, body) => {
  return fetch(config.ssApiUrl + "/proxy" + route, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((r) => r.json())
    .then((r) => {
      console.log(r);
      return r;
    })
    .catch((err) => {
      console.log(err, "catch during proxy fetch");
      return { ok: false, msg: i18n.t("Command not found or connection problem"), error: err };
    });
};
export const fetchReportProxy = (route, method, body) => {
  return fetch(config.ssApiUrl + "/reportproxy" + route, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((r) => r.json())
    .then((r) => {
      const output = { proxy: true, ok: true, outData: r };
      console.log(output);
      return output;
    })
    .catch((err) => {
      console.log(err, "catch during proxy fetch");
      return { ok: false, msg: i18n.t("Command not found or connection problem"), error: err };
    });
};

///////////////////////////////////
//GET//////////////////////////////
export const get = (url) => {
  return fetch(config.ssApiUrl + url, {
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
  })
    .then((r) => {
      if (!r.ok) throw r.status;
      r.json();
    })
    .catch((err) => {
      return { ok: false, msg: "Error in get " + err };
    });
};
export const getraw = (url) => {
  return fetch(config.ssApiUrl + url, {
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
  });
};
///////////////////////////////////
//DELETE///////////////////////////
export const del = (url, data) => {
  return fetch(config.ssApiUrl + url, {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: "Bearer " + localStorage.token,
      "Content-Type": "application/json",
    },
  }).then((r) => r.json());
};

////////////////////////////////////
//PUT////////////////////
export const put = (url, data) => {
  return fetch(config.ssApiUrl + url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: JSON.stringify(data),
  })
    .then((r) => buildResponse(r))
    .catch((err) => {
      console.log(err, "catch en put");
      return { ok: false, msg: i18n.t("Not found or connection problem"), error: err };
    });
};

////////////////////////////////////
//PATCH////////////////////
export const patch = (url, data) => {
  return fetch(config.ssApiUrl + url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    },
    body: JSON.stringify(buildPatch(data)),
  })
    .then((r) => buildResponse(r))
    .catch((err) => {
      console.log(err, "catch en patch");
      return { ok: false, msg: i18n.t("Not found or connection problem"), error: err };
    });
};

export const getFile = (url) => {
  return fetch(config.ssApiUrl + url, {
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
  }).then((r) => r.blob());
};
//helprs////////////////////////////////////
const isJson = (r) => {
  const contentType = r.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

const buildResponse = (r) => {
  let respuesta = {
    ok: false,
    status: 0,
    msg: "", //string con descripcion del error
  };
  respuesta.ok = r.ok;
  respuesta.status = r.status;
  respuesta.msg = `${r.status} ${r.statusText}`;

  if (isJson(r)) {
    return r.json().then((json) => {
      let ret = { ...respuesta, ...json };
      if (!ret.ok) {
        if (json.Message) ret.msg = json.Message;
      }
      if (json.msg) ret.msg = json.msg;
      return ret;
    });
  } else {
    return r.text().then((text) => {
      respuesta.text = text;
      return respuesta;
    });
  }
};

const buildPatch = (obj) => {
  let res = [];
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      res.push({ op: "replace", path: "/" + key, value: obj[key] });
    }
  }
  return res;
};
export const safe = (obj) => {
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== null && typeof obj[key] !== "object") newObj[key] = obj[key];
    }
  }
  return newObj;
};
export const safeGet = (path, object, nullValue) => path.reduce((xs, x) => (xs && xs[x] ? xs[x] : nullValue), object);
//safely gets value, including falsy values, whenever different than undefined or null
export const safeGetRaw = (path, object, nullValue) => path.reduce((xs, x) => (xs && xs[x]!==undefined&&xs[x]!==null ? xs[x] : nullValue), object);
export const safeValue = (path, parentObject, nullValue) => {
  const keys = path.split(".");
  return safeGet(keys, parentObject, nullValue);
  // let safeValue = parentObject;
  // keys.forEach((element) => {
  //   safeValue = safeValue ? safeValue[element] : undefined;
  //   if (safeValue == undefined) return nullValue;
  // });
  // return safeValue;
};
//safely gets value, including falsy values
export const safeValueRaw = (path, parentObject, nullValue) => {
  const keys = path.split(".");
  return safeGetRaw(keys, parentObject, nullValue);
};
export const setQueryParam = (paramName, paramValue, options = {}) => {
  const { remove = false, debug = false } = options; // Options to remove param or debug
  
  const currentUrl = window.location.href;
  const [baseUrl, hashAndQuery = ''] = currentUrl.split('#');
  const [hashPath = '', existingQuery = ''] = hashAndQuery.split('?');
  
  const params = new URLSearchParams(existingQuery);
  
  if (remove) {
    params.delete(paramName); // Remove the parameter if requested
  } else {
    params.set(paramName, paramValue); // Set or update the parameter
  }
  
  const newUrl = `${baseUrl}#${hashPath}${params.toString() ? `?${params.toString()}` : ''}`;
  
  if (debug) {
    console.log('Old URL:', currentUrl);
    console.log('New URL:', newUrl);
  }
  
  window.history.pushState({}, document.title, newUrl);
}
export const getQueryParam = (paramName, options = {}) => {
  const { debug = false, defaultValue = null } = options; // Optional debug and default
  
  const currentUrl = window.location.href;
  const [, hashAndQuery = ''] = currentUrl.split('#');
  const [, query = ''] = hashAndQuery.split('?');
  
  const params = new URLSearchParams(query);
  const value = params.get(paramName);
  
  if (debug) {
    console.log('URL:', currentUrl);
    console.log(`Value of ${paramName}:`, value);
  }
  
  return value !== null ? value : defaultValue;
}

export const asigna = (dest, values) => {
  let newObj = {};
  for (let key in dest) {
    if (values[key]) newObj[key] = values[key];
  }
  return newObj;
};

export const setValues = (form, values) => {
  form.setFieldsValue(asigna(form.getFieldsValue(), values));
};

export const compare = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};
export const equal = (a, b) => {
  if ((!a && b) || (a && !b)) return false;
  if (!a && !b) return true;

  return JSON.stringify(a) === JSON.stringify(b);
};

export const isValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const safeParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (ex) {
    return null;
  }
};

export const objectToFilter = (values) => {
  let sqFilter = "1=1";
  Object.keys(values).forEach((key, index) => {
    switch (typeof values[key]) {
      case "string":
        if (["=", ">", "<"].includes(values[key].charAt(0))) {
          //comparison field
          sqFilter += ` AND ${key}${values[key]}`;
        } else {
          //string field
          if(key.includes("RLIKE")){
            sqFilter += ` AND ${key.replace("RLIKE","")} LIKE '${values[key]}%'`;  
          }else if(key.includes("LIKE")){
            sqFilter += ` AND ${key.replace("LIKE","")} LIKE '%${values[key]}%'`;
          }else if(values[key]!=="") sqFilter += ` AND ${key}='${values[key]}'`;
        }
        break;
      case "number":
        sqFilter += ` AND ${key}=${values[key]}`;
        break;
    }
  });
  return sqFilter;
};
