import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";

const NewProduct = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RepoProduct", { operation: "ADD", entity: values }).then((r) => {
        if (r.ok) {
          message.success(r.msg);
          props.onOk(r.outData[0]);
        } else {
          message.error(t("Please use an unique product code") + ":" + r.msg);
        }
      });
    });
  };
  return (
    <div>
      <Modal title={t("New Product")} visible={props.visible} onOk={onOk} onCancel={props.onCancel}>
        <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
        <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
        <Form.Item label={t("LoB")}>{field("lobCode", { rules: [{ required: true }] })(<LobSelect />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(NewProduct);
