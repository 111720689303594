import React, { Component } from "react";
import { CodeOutlined, EnterOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { Drawer, Input, Table, message, Modal, Dropdown, Menu, Button, Checkbox, Form, Radio } from "antd";
import { exe } from "Lib/Dal";
import Inspector from "react-inspector";
import { withTranslation } from "react-i18next";
import CommandSelect from './Bpm/CommandSelect';
import CommandArgInput from './CommandArgInput';

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

class Sisos extends Component {
  state = {
    state: [],
    cmdHistory: [],
    searchValue: "",
    loading: false,
    showBuilder: true,
    selectedCommand: '',
    commandData: '',
    commandArgs: [],
    commandValues: {},
  };

  componentDidMount() {
    if (localStorage.cmdHistory) {
      this.setState({ cmdHistory: JSON.parse(localStorage.cmdHistory) });
    }
  }

  handleShowBuilderChange = (e) => {
    this.setState({ 
      showBuilder: e.target.checked,
      selectedCommand: '',
      commandData: '',
      commandArgs: [],
      commandValues: {},
      searchValue: ''
    });
  };

  handleCommandSelect = (command) => {
    if(!command){
      this.setState({ 
        selectedCommand: '',
        commandArgs: [],
        commandValues: {},
        commandData: '',
        searchValue: ''
      });
      return;
    }
    exe("GetCommandArgs", { name: command }).then((r) => {
      if(r.ok){
        const commandStr = this.generateCommandString(command);
        this.setState({ 
          selectedCommand: command, 
          commandArgs: r.outData,
          commandValues: {},
          commandData: commandStr,
          searchValue: commandStr
        });
      }else message.error(r.msg);
    });
  };

  handleArgChange = (name, value) => {
    this.setState(prevState => {
      const newValues = {
        ...prevState.commandValues,
        [name]: value
      };
      
      const commandStr = this.generateCommandString(this.state.selectedCommand, newValues);
      return {
        commandValues: newValues,
        searchValue: commandStr,
        commandData: JSON.stringify(newValues, null, 2)
      };
    });
  };

  handleDataChange = (e) => {
    this.setState({ commandData: e.target.value });
    // Update the command string when data changes
    const commandStr = this.generateCommandString(this.state.selectedCommand, e.target.value);
    console.log("handle data change",commandStr,e.target.value,e);
    this.setState({ searchValue: commandStr });
  };

  generateCommandString = (command, data) => {
    if (!command) return '';
    try {
      return JSON.stringify({ cmd: command, data: data || {} });
    } catch (err) {
      console.warn("error generating command string", err);
      return '';
    }
  };

  sendCommand = (value) => {
    // if (this.state.showBuilder&&this.state.selectedCommand) {
    //   const data = this.state.commandData ? JSON.parse(this.state.commandData) : {};
    //   // if (!this.state.selectedCommand) {
    //   //   message.error(this.props.t("Please select a command"));
    //   //   return;
    //   // }
    //   this.setState({ loading: true });
    //   exe(this.state.selectedCommand, data).then((r) => {
    //     this.setState({ loading: false });
    //     Modal[r.ok ? "success" : "error"]({
    //       title: this.state.selectedCommand + " " + this.props.t("Output"),
    //       maskClosable: true,
    //       width: "60%",
    //       zIndex: 2000,
    //       content: (
    //         <div>
    //           <Inspector data={r} expandLevel={1} />
    //           <div style={{ marginTop: 10 }}>
    //             <Inspector table data={r.outData} />
    //           </div>
    //         </div>
    //       ),
    //       onOk() {},
    //     });
    //     //adding to history
    //     if (!this.state.cmdHistory.includes(value)) {
    //       this.setState({ cmdHistory: [...this.state.cmdHistory, value] });
    //     }
    //   });
    //   return;
    // }

    if (!this.state.cmdHistory.includes(value)) {
      this.setState({ cmdHistory: [...this.state.cmdHistory, value] });
    }
    if(value&&value.startsWith("{")){
      //direct command
      const parsedCmd=JSON.parse(value);
      this.setState({ loading: true });
      exe(parsedCmd.cmd, parsedCmd.data).then((r) => {
        this.setState({ loading: false });
        Modal[r.ok ? "success" : "error"]({
          title: parsedCmd.cmd + " " + this.props.t("Output"),
          maskClosable: true,
          width: "60%",
          zIndex: 2000,
          content: (
              <div>
                <Inspector data={r} expandLevel={1} />
                <div style={{ marginTop: 10 }}>
                  <Inspector table data={r.outData} />
                </div>
              </div>
          ),
          onOk() {},
        });
      });
      return;
}else if (value.slice(0, 1) == ">") {
      //direct command
      try {
        const fullCmd = value.substring(1);
        const splited = fullCmd.split(" ");
        const cmd = splited[0];
        let data, json;
        if (splited.length == 1) {
          //nodata
          data = null;
        } else {
          data = fullCmd.replace(cmd + " ", "");
          //no braces option
          if (data.substring(0, 1) !== "{") data = "{" + data + "}";
          let a;
          json = JSON.stringify(eval("a=" + data));
        }
        //const data = ssv[1] ? ssv[1] : "{}";
        //const opt = ssv.length > 2 ? ssv[2] : "{}";
        this.setState({ loading: true });
        exe(cmd, data ? JSON.parse(json) : {}).then((r) => {
          this.setState({ loading: false });
          console.log(r);

          Modal[r.ok ? "success" : "error"]({
            title: cmd + " " + this.props.t("Output"),
            maskClosable: true,
            width: "60%",
            zIndex: 2000,
            content: (
              <div>
                <Inspector data={r} expandLevel={1} />
                <div style={{ marginTop: 10 }}>
                  <Inspector table data={r.outData} />
                </div>
              </div>
            ),
            onOk() {},
          });
        });
      } catch (err) {
        console.log(err);
        Modal.error({ title: this.props.t("Error parsing command"), zIndex: 2000, maskClosable: true, content: <Inspector data={err.toString()} /> });
      }

      return;
    }
    this.setState({ loading: true });
    exe("Sisos", { cmdText: value }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  };
  historyClick(value) {
    this.setState({ searchValue: value });
    this.searchRef.input.focus();
  }
  saveHistory() {
    let his = this.state.cmdHistory;
    if (this.state.cmdHistory.length > 20) {
      his = his.slice(0, his.length - 20);
    }
    localStorage.cmdHistory = JSON.stringify(his);
  }
  componentDidUpdate(previousProps) {
    if (previousProps.command != this.props.command) {
      //this.searchRef.input.setValue(this.props.command);
      //this.searchRef.input.focus();
      this.sendCommand(this.props.command.trim());
    }
  }

  render() {
    const { t, i18n } = this.props;
    const searchMenu = (
      <Menu>
        {this.state.cmdHistory.map((p, i) => (
          <Menu.Item key={i} onClick={() => this.historyClick(p)}>
            <CodeOutlined />
            {p}
          </Menu.Item>
        ))}
      </Menu>
    );


    return (
      <Drawer
        title={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{t("Command Launcher")}</span>
            <Radio.Group 
              value={this.state.showBuilder}
              onChange={(e) => this.handleShowBuilderChange({ target: { checked: e.target.value }})}
              optionType="button"
            >
              <Radio.Button value={false}>{t("Simple")}</Radio.Button>
              <Radio.Button value={true}>{t("Builder")}</Radio.Button>
            </Radio.Group>
          </div>
        }
        placement="right"
        width={612}
        onClose={() => {
          this.saveHistory();
          this.props.onClose();
        }}
        visible={this.props.visible}
      >
        
        {this.state.showBuilder && (
          <div style={{ marginBottom: 16 }}>
            <CommandSelect
              value={this.state.selectedCommand}
              onChange={this.handleCommandSelect}
              style={{ width: '100%',marginBottom: 4 }}
              size="large"
              placeholder={t("Select a command to start building")}
            />
            
            {this.state.commandArgs.length > 0 && (
              <>
                <style>
                  {`
                    .command-arg-item.ant-form-item {
                      margin-bottom: 6px !important;
                    }
                  `}
                </style>
                <Form 
                  layout="vertical" 
                  style={{ 
                    marginTop: '16px !important',
                    marginBottom: '16px !important',
                    padding: 16,
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: 4
                  }}
                >
                  <div style={{ 
                    marginBottom: 6, 
                    padding: 8,
                    backgroundColor: '#e9ecef',
                    borderRadius: 4,
                    fontSize: '14px',
                    color: '#495057'
                  }}>
                    <CodeOutlined style={{ marginRight: 8 }} />
                    {`${t("Arguments for")}: ${this.state.selectedCommand}`}
                  </div>
                  {this.state.commandArgs.map((arg, index) => (
                    <Form.Item
                      key={arg.name}
                      className="command-arg-item"
                      label={
                        <span style={{ color: '#495057', fontWeight: 500 }}>
                          {arg.name}
                          <span style={{ 
                            fontSize: '12px',
                            color: '#6c757d',
                            marginLeft: 8,
                            fontWeight: 400
                          }}>
                            ({arg.type})
                          </span>
                        </span>
                      }
                      style={{
                        backgroundColor: '#ffffff',
                        padding: '12px 16px',
                        borderRadius: 4,
                        border: '1px solid #dee2e6'
                      }}
                    >
                      <CommandArgInput
                        arg={arg}
                        value={this.state.commandValues[arg.name]}
                        onChange={this.handleArgChange}
                      />
                    </Form.Item>
                  ))}
                </Form>
              </>
            )}

            
          </div>
        )}

        <div style={{ display: 'flex', position: 'relative' }}>
          <Search
            placeholder={t("Input search text or command")}
            size="large"
            ref={(ref) => (this.searchRef = ref)}
            value={this.state.searchValue}
            onChange={(e) => this.setState({ searchValue: e.target.value })}
            onSearch={(value) => this.sendCommand(value)}
            enterButton={<Button type="primary" icon={<EnterOutlined />} loading={this.state.loading} />}
            className="sisos-search-input-group"
            style={{ 
              fontSize: '6px', 
              width: '100%'
            }}
            addonBefore={null}
            addonAfter={null}
          />
          <style>
            {`
              .sisos-search-input-group .ant-input {
                padding-right: 70px;
              }
            `}
          </style>
          <div style={{
            position: 'absolute',
            right: '50px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
            display: 'flex',
          }}>
            <Dropdown overlay={searchMenu} trigger={["click"]}>
              <Button
                icon={<HistoryOutlined style={{ color: '#5F6B7C' }} />}
                style={{
                  border: 'none',
                  background: 'transparent',
                  color: '#5F6B7C',
                  transition: 'color 0.3s'
                }}
                title={t('Command history')}
              />
            </Dropdown>
            <Button
              icon={<CopyOutlined style={{ color: '#5F6B7C' }} />}
              onClick={(e) => {
                navigator.clipboard.writeText(this.state.searchValue);
                message.success(t('Command copied to clipboard'));
              }}
              style={{
                border: 'none',
                background: 'transparent',
                color: '#5F6B7C',
                transition: 'color 0.3s'
              }}
              title={t('Copy to clipboard')}
            />
          </div>
        </div>

        <Table 
          dataSource={this.state.data} 
          size="small" 
          style={{ marginTop: 5 }} 
          rowKey="linkId"
        >
          <Column title={t("Item")} dataIndex="item" />
          <Column title={t("Type")} dataIndex="type" />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <a href={"#/contact/" + record.linkId}>View</a>
              </span>
            )}
          />
        </Table>
      </Drawer>
    );
  }
}
  export default withTranslation()(Sisos);
