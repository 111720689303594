import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Badge, Tabs, Checkbox, Alert, Typography, Space, Row, Col, Statistic, Modal, Result, Form, Input } from 'antd';
import { CheckCircleOutlined, SaveOutlined, ArrowRightOutlined, HistoryOutlined, FileTextOutlined, EditOutlined } from '@ant-design/icons';
import { reconciliationService } from './reconciliationService';
import { useTranslation } from 'react-i18next';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

// Editable cell component for in-place editing
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  // Determine the appropriate input type based on the data
  const getInputNode = () => {
    if (dataIndex === 'amount') {
      return <Input type="number" step="0.01" />;
    }
    return <Input />;
  };
  
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please input ${title}!`,
            },
          ]}
        >
          {getInputNode()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ReconciliationView = ({ 
  importedData, 
  reconciliationResults, 
  onReset, 
  onSave, 
  onExecute,
  isSaved,
  savedReconciliation,
  onUpdateReconciliationResults
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('matched');
  const [selectedItems, setSelectedItems] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [isExecuted, setIsExecuted] = useState(savedReconciliation?.status === 1); // 1 = executed
  
  // Editing related states
  const [editingMode, setEditingMode] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  
  // Local state for reconciliation data
  const [localReconciliationData, setLocalReconciliationData] = useState(reconciliationResults || {
    matched: [], 
    unmatched: []
  });

  // Update local state when props change
  useEffect(() => {
    if (reconciliationResults) {
      // Create a deep copy and ensure all items have keys
      const processedData = {
        matched: reconciliationResults.matched.map(item => ({
          ...item,
          key: item.key || item.id || `matched-${item.date}-${item.amount}`
        })),
        unmatched: reconciliationResults.unmatched.map(item => ({
          ...item,
          key: item.key || item.id || `unmatched-${item.date}-${item.amount}`
        }))
      };
      
      setLocalReconciliationData(processedData);
    }
  }, [reconciliationResults]);

  const { matched, unmatched } = localReconciliationData;

  // Editing related functions
  const isEditing = (record) => record.key === editingKey;

  // Add debug function to print dataset keys
  const printDatasetKeys = (dataset, name) => {
    // Function kept for reference but logging removed
  };

  // Update edit function
  const edit = (record) => {
    // Extract only the editable fields from the record
    const editableFields = {};
    baseColumns.forEach(col => {
      if (col.editable) {
        editableFields[col.dataIndex] = record[col.dataIndex];
      }
    });
    
    // Set form values
    form.setFieldsValue(editableFields);
    setEditingKey(record.key);
    
    // Use the dataset variable to print keys
    let currentDataset;
    let datasetName;
    
    switch(selectedTab) {
      case 'matched':
        currentDataset = matched;
        datasetName = 'Matched';
        break;
      case 'unmatched':
        currentDataset = unmatched;
        datasetName = 'Unmatched';
        break;
      default:
        return;
    }
    
    printDatasetKeys(currentDataset, datasetName);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      // Get all form values
      const row = await form.validateFields();
      
      // Create a deep copy of the current reconciliation data
      const updatedReconciliationData = { ...localReconciliationData };
      
      // Determine which dataset we're editing based on the selected tab
      let dataSet;
      let dataSetKey;
      
      switch(selectedTab) {
        case 'matched':
          dataSet = [...matched];
          dataSetKey = 'matched';
          break;
        case 'unmatched':
          dataSet = [...unmatched];
          dataSetKey = 'unmatched';
          break;
        default:
          return;
      }
      
      // Try to find the item by key first
      let index = dataSet.findIndex((item) => key === item.key);
      
      // If not found by key, try by id as fallback
      if (index === -1) {
        index = dataSet.findIndex((item) => key === item.id);
      }
      
      // Last resort: try matching by composite criteria
      if (index === -1 && row.date && row.amount) {
        index = dataSet.findIndex((item) => 
          item.date === row.date && 
          item.amount === row.amount
        );
      }
      
      if (index > -1) {
        const item = dataSet[index];
        
        // Ensure numeric values are properly parsed
        if (row.amount) {
          row.amount = parseFloat(row.amount);
          if (isNaN(row.amount)) row.amount = 0;
        }
        
        // Create updated item preserving all original fields and MOST IMPORTANTLY the original key
        const updatedItem = {
          ...item,
          ...row,
          key: item.key, // Explicitly preserve the key
          id: item.id     // Preserve the ID
        };
        
        // Make sure we don't lose description/type fields if they were originally used
        if (item.description && !updatedItem.description) {
          updatedItem.description = updatedItem.transaction;
        }
        
        if (item.type && !updatedItem.type) {
          updatedItem.type = updatedItem.transactionCode;
        }
        
        // Replace the item in the dataset
        dataSet.splice(index, 1, updatedItem);
        
        // Update the local state with the new dataset
        updatedReconciliationData[dataSetKey] = dataSet;
        
        setLocalReconciliationData(updatedReconciliationData);
        
        // Propagate the changes to parent component if the callback exists
        if (typeof onUpdateReconciliationResults === 'function') {
          onUpdateReconciliationResults(updatedReconciliationData);
        }
        
        setSuccessMessage(t('Record updated successfully'));
        setEditingKey('');
      } else {
        // Show error message to user
        setSuccessMessage(t('Error: Could not find the record to update'));
        setTimeout(() => setSuccessMessage(null), 3000);
      }
    } catch (errInfo) {
      // Handle validation errors
      console.error('Validation failed:', errInfo);
    }
  };

  const toggleEditMode = () => {
    if (editingMode) {
      setEditingKey('');
      form.resetFields();
    }
    setEditingMode(!editingMode);
  };

  const handleItemSelect = (id, isSelected) => {
    setSelectedItems(prev => ({
      ...prev,
      [id]: isSelected
    }));
  };

  const handleSelectAll = (items, isSelected) => {
    const newSelectedItems = { ...selectedItems };
    
    items.forEach(item => {
      newSelectedItems[item.id] = isSelected;
    });
    
    setSelectedItems(newSelectedItems);
  };

  const handleManualMatch = () => {
    const result = reconciliationService.manualMatch(
      selectedItems, 
      unmatched, 
      []
    );
    
    setSuccessMessage(t('Successfully matched') + ' ' + result.matchedCount + ' ' + t('transactions'));
    
    setSelectedItems({});
    
    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };

  const handleExecute = () => {
    setShowExecuteModal(true);
  };

  const handleConfirmExecute = () => {
    setShowExecuteModal(false);
    setIsExecuted(true);
    onExecute();
  };

  const getStatusBadge = (status) => {
    const statusMap = {
      'Matched': 'success',
      'Unmatched': 'error'
    };
    
    return <Badge status={statusMap[status] || 'default'} text={t(status)} />;
  };

  const isAnySelected = Object.values(selectedItems).some(value => value);

  // Create editable columns for all tables
  const createEditableColumn = (col) => {
    if (!col.editable) {
      return col;
    }
    
    return {
      ...col,
      onCell: (record) => {
        const isCurrentlyEditing = isEditing(record);
        
        return {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isCurrentlyEditing,
        };
      },
    };
  };

  // Base columns shared by all tables - updated to match AccountMov fields
  const baseColumns = [
    { title: t('Date'), dataIndex: 'date', key: 'date', editable: true },
    { 
      title: t('Transaction'), 
      dataIndex: 'transaction', 
      key: 'transaction', 
      editable: true,
      render: (text, record) => {
        // Handle both transaction and description fields
        return text || record.description || '';
      }
    },
    { 
      title: t('Amount'), 
      dataIndex: 'amount', 
      key: 'amount',
      editable: true,
      render: (text) => {
        // Ensure amount is displayed correctly
        const amount = parseFloat(text);
        return (
          <span style={{ color: amount < 0 ? '#ff4d4f' : '#52c41a' }}>
            {isNaN(amount) ? '0.00' : amount.toFixed(2)}
          </span>
        )
      }
    }
  ];

  // Action column for editing (displayed only in edit mode)
  const actionColumn = {
    title: t('Action'),
    key: 'operation',
    fixed: 'right',
    width: 150,
    render: (_, record) => {
      const editable = isEditing(record);
      return editable ? (
        <Space>
          <Button 
            type="link" 
            onClick={() => save(record.key)}
            style={{ marginRight: 8 }}
            icon={<SaveOutlined />}
          >
            {t('Save')}
          </Button>
          <Button type="link" onClick={cancel}>
            {t('Cancel')}
          </Button>
        </Space>
      ) : (
        <Button 
          type="link" 
          disabled={editingKey !== '' || !editingMode} 
          onClick={() => edit(record)}
          icon={<EditOutlined />}
        >
          {t('Edit')}
        </Button>
      );
    }
  };

  // Column definitions for tables with editing support
  const getMatchedColumns = () => {
    let columns = [
      {
        title: t('Exclude'),
        dataIndex: 'exclude',
        key: 'exclude',
        width: 80,
        render: (_, record) => (
          <Button 
            type="link" 
            onClick={() => excludeFromMatched(record)}
            danger
            size="small"
          >
            {t('Exclude')}
          </Button>
        )
      },
      ...baseColumns,
      { 
        title: t('Status'), 
        key: 'status',
        render: () => getStatusBadge('Matched')
      }
    ];
    
    if (editingMode) {
      columns.push(actionColumn);
    }
    
    return columns.map(col => createEditableColumn(col));
  };
  
  const getUnmatchedColumns = () => {
    let columns = [
      {
        title: (
          <Checkbox
            checked={unmatched.length > 0 && unmatched.every(item => selectedItems[item.id])}
            onChange={(e) => handleSelectAll(unmatched, e.target.checked)}
          >
            {t('Select All')}
          </Checkbox>
        ),
        dataIndex: 'select',
        key: 'select',
        width: 60,
        render: (_, record) => (
          <Checkbox
            checked={!!selectedItems[record.id]}
            onChange={(e) => handleItemSelect(record.id, e.target.checked)}
          />
        )
      },
      ...baseColumns,
      { 
        title: t('Status'), 
        key: 'status',
        render: () => getStatusBadge('Unmatched')
      }
    ];
    
    if (editingMode) {
      columns.push(actionColumn);
    }
    
    return columns.map(col => createEditableColumn(col));
  };

  // Create columns for the imported data tab
  const getImportedDataColumns = () => {
    if (!importedData || importedData.length === 0) return [];
    
    const sampleItem = importedData[0];
    return Object.keys(sampleItem).map(key => ({
      title: key,
      dataIndex: key,
      key: key
    }));
  };

  const matchedData = matched.map((item) => ({
    ...item,
    key: item.key || item.id || `matched-${item.date}-${item.amount}`
  }));

  const unmatchedData = unmatched.map((item) => ({
    ...item,
    key: item.key || item.id || `unmatched-${item.date}-${item.amount}`
  }));

  const importedDataWithKeys = importedData ? importedData.map((item, index) => ({
    ...item,
    key: item.id || `imported-${index}`
  })) : [];

  // Inside the component, right after getting matched, unmatched from localReconciliationData
  // Add this useEffect to reset form when editing key changes
  useEffect(() => {
    if (editingKey === '') {
      form.resetFields();
    }
  }, [editingKey, form]);

  // Add function to exclude item from matched
  const excludeFromMatched = (record) => {
    // Create a deep copy of the current reconciliation data
    const updatedReconciliationData = { ...localReconciliationData };
    
    // Find and remove the item from matched
    const matchedIndex = updatedReconciliationData.matched.findIndex(
      item => item.id === record.id || item.key === record.key
    );
    
    if (matchedIndex !== -1) {
      // Remove from matched
      const excludedItem = updatedReconciliationData.matched.splice(matchedIndex, 1)[0];
      
      // Add to unmatched
      updatedReconciliationData.unmatched.push(excludedItem);
      
      // Update local state
      setLocalReconciliationData(updatedReconciliationData);
      
      // Propagate changes to parent if callback exists
      if (typeof onUpdateReconciliationResults === 'function') {
        onUpdateReconciliationResults(updatedReconciliationData);
      }
      
      setSuccessMessage(t('Transaction excluded from matched items'));
      setTimeout(() => setSuccessMessage(null), 3000);
    }
  };

  // Calculate total matched amount
  const calculateTotalMatchedAmount = () => {
    return matched.reduce((sum, item) => {
      const amount = parseFloat(item.amount) || 0;
      return sum + amount;
    }, 0);
  };

  // Create columns for executed reconciliations (read-only)
  const getExecutedMatchedColumns = () => {
    return [
      ...baseColumns,
      { 
        title: t('Status'), 
        key: 'status',
        render: () => getStatusBadge('Matched')
      }
    ];
  };

  const getExecutedUnmatchedColumns = () => {
    return [
      ...baseColumns,
      { 
        title: t('Status'), 
        key: 'status',
        render: () => getStatusBadge('Unmatched')
      }
    ];
  };

  if (isExecuted) {
    return (
      <div>
        <Alert
          message={t('Reconciliation Executed Successfully!')}
          type="success"
          showIcon
          style={{ marginBottom: 16 }}
        />
        
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
          <Title level={5}>{t('Bank Statement Reconciliation')}</Title>
          <Button icon={<HistoryOutlined />} onClick={onReset}>
            {t('Return to List')}
          </Button>
        </div>
        
        <Card style={{ marginBottom: 16 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
            <Title level={5}>{t('Summary')}</Title>
          </div>
          
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Statistic 
                title={t('Total Transactions')} 
                value={importedData?.length || 0} 
              />
            </Col>
            <Col span={6}>
              <Statistic 
                title={t('Matched')} 
                value={matched.length} 
                valueStyle={{ color: '#52c41a' }}
              />
            </Col>
            <Col span={6}>
              <Statistic 
                title={t('Unmatched')} 
                value={unmatched.length} 
                valueStyle={{ color: '#ff4d4f' }}
              />
            </Col>
            <Col span={6}>
              <Statistic 
                title={t('Total Matched Amount')} 
                value={calculateTotalMatchedAmount()} 
                valueStyle={{ color: '#1890ff' }}
                precision={2}
                prefix="$"
              />
            </Col>
          </Row>
        </Card>
        
        <Tabs
          defaultActiveKey="matched"
          type="card"
        >
          <TabPane tab={t('Matched') + ' (' + matched.length + ')'} key="matched">
            <Table 
              columns={getExecutedMatchedColumns()}
              dataSource={matchedData}
              locale={{ emptyText: t('No matched transactions found') }}
              pagination={false}
              bordered
            />
          </TabPane>
          
          <TabPane tab={t('Unmatched') + ' (' + unmatched.length + ')'} key="unmatched">
            <Table 
              columns={getExecutedUnmatchedColumns()}
              dataSource={unmatchedData}
              locale={{ emptyText: t('No unmatched transactions found') }}
              pagination={false}
              bordered
            />
          </TabPane>
          
          <TabPane 
            tab={
              <span>
                <FileTextOutlined />
                {t('Imported Data') + ' (' + (importedData?.length || 0) + ')'}
              </span>
            } 
            key="imported"
          >
            {importedData && importedData.length > 0 ? (
              <Table 
                columns={getImportedDataColumns()} 
                dataSource={importedDataWithKeys}
                locale={{ emptyText: t('No imported data available') }}
                pagination={{ pageSize: 15 }}
                bordered
                scroll={{ x: 'max-content' }}
              />
            ) : (
              <Alert
                message={t('No imported data available')}
                type="info"
                showIcon
              />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }

  return (
    <div>
      {/* Add CSS styles for editable rows */}
      <style>
        {`
          .editable-row > td {
            padding: 8px 12px !important;
          }
          
          .editable-row:hover > td {
            background-color: #f5f5f5;
          }
          
          .editable-row-editing {
            background-color: #e6f7ff !important;
          }
          
          .editable-row-editing:hover > td {
            background-color: #e6f7ff !important;
          }
          
          .ant-form-item-explain {
            font-size: 12px;
          }
        `}
      </style>

      {successMessage && (
        <Alert
          message={successMessage}
          type="success"
          showIcon
          closable
          onClose={() => setSuccessMessage(null)}
          style={{ marginBottom: 16 }}
        />
      )}
      
      {isSaved && (
        <Alert
          message={t('Reconciliation saved as') + ' "' + savedReconciliation?.name + '"'}
          type="info"
          showIcon
          banner
          style={{ marginBottom: 16 }}
          description={
            savedReconciliation?.status === 'Saved' ? 
            t('You can continue to work with this reconciliation or return to the list') : 
            t('This reconciliation has been executed')
          }
        />
      )}
      
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <Title level={5}>{t('Bank Statement Reconciliation')}</Title>
        <Space>
          <Button 
            type={editingMode ? "primary" : "default"}
            icon={<EditOutlined />}
            onClick={toggleEditMode}
            disabled={isSaved}
          >
            {editingMode ? t('Finish Editing') : t('Edit Data')}
          </Button>
        </Space>
      </div>
      
      <Card style={{ marginBottom: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
          <Title level={5}>{t('Summary')}</Title>
          {isAnySelected && (
            <Button 
              type="primary" 
              size="small" 
              onClick={handleManualMatch}
            >
              {t('Match Selected Items')}
            </Button>
          )}
        </div>
        
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Statistic 
              title={t('Total Transactions')} 
              value={importedData?.length || 0} 
            />
          </Col>
          <Col span={6}>
            <Statistic 
              title={t('Matched')} 
              value={matched.length} 
              valueStyle={{ color: '#52c41a' }}
            />
          </Col>
          <Col span={6}>
            <Statistic 
              title={t('Unmatched')} 
              value={unmatched.length} 
              valueStyle={{ color: '#ff4d4f' }}
            />
          </Col>
          <Col span={6}>
            <Statistic 
              title={t('Total Matched Amount')} 
              value={calculateTotalMatchedAmount()} 
              valueStyle={{ color: '#1890ff' }}
              precision={2}
              prefix="$"
            />
          </Col>
        </Row>
      </Card>
      
      <Form form={form} component={false}>
        <Tabs
          activeKey={selectedTab}
          onChange={(tab) => {
            setSelectedTab(tab);
            setEditingKey('');
            form.resetFields();
          }}
          type="card"
        >
          <TabPane tab={t('Matched') + ' (' + matched.length + ')'} key="matched">
            <Table 
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={getMatchedColumns()} 
              dataSource={matchedData}
              locale={{ emptyText: t('No matched transactions found') }}
              pagination={false}
              bordered
              rowClassName={(record) => isEditing(record) ? 'editable-row editable-row-editing' : 'editable-row'}
            />
          </TabPane>
          
          <TabPane tab={t('Unmatched') + ' (' + unmatched.length + ')'} key="unmatched">
            <Table 
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              columns={getUnmatchedColumns()} 
              dataSource={unmatchedData}
              locale={{ emptyText: t('No unmatched transactions found') }}
              pagination={false}
              bordered
              rowClassName={(record) => isEditing(record) ? 'editable-row editable-row-editing' : 'editable-row'}
            />
          </TabPane>
          
          <TabPane 
            tab={
              <span>
                <FileTextOutlined />
                {t('Imported Data') + ' (' + (importedData?.length || 0) + ')'}
              </span>
            } 
            key="imported"
          >
            {importedData && importedData.length > 0 ? (
              <Table 
                columns={getImportedDataColumns()} 
                dataSource={importedDataWithKeys}
                locale={{ emptyText: t('No imported data available') }}
                pagination={{ pageSize: 15 }}
                bordered
                scroll={{ x: 'max-content' }}
              />
            ) : (
              <Alert
                message={t('No imported data available')}
                type="info"
                showIcon
              />
            )}
          </TabPane>
        </Tabs>
      </Form>

      <Modal
        title={t('Execute Reconciliation')}
        open={showExecuteModal}
        onOk={handleConfirmExecute}
        onCancel={() => setShowExecuteModal(false)}
        okText={t('Execute')}
        cancelText={t('Cancel')}
      >
        <Paragraph>
          {t('You are about to execute the bank reconciliation. This will')}:
        </Paragraph>
        <ul>
          <li>{t('Process all matched transactions')}</li>
          <li>{t('Update your accounting records')}</li>
          <li>{t('Mark the reconciliation as completed')}</li>
        </ul>
        <Paragraph strong>
          {t('Do you want to proceed')}?
        </Paragraph>
      </Modal>
    </div>
  );
};

export default ReconciliationView; 