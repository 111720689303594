import React, { useState, useEffect } from 'react';
import { Upload, Input, Button, List, Card, message, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Title } = Typography;

const API_BASE_URL = 'http://localhost:8000';

const defaultHeaders = {
    'Accept': 'application/json',
};

const Brain = () => {
    const { t } = useTranslation();
    const [documents, setDocuments] = useState([]);
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchDocuments = () => {
        fetch(`${API_BASE_URL}/list-documents`, {
            method: 'GET',
            headers: defaultHeaders,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setDocuments(data.files || []);
            })
            .catch(error => {
                console.error('Error fetching documents:', error);
                message.error(t('Error fetching documents'));
            });
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const handleUpload = (file) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(`${API_BASE_URL}/upload`, {
            method: 'POST',
            body: formData,
            // Don't set Content-Type header, let the browser set it with the boundary
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                message.success(t('File uploaded successfully'));
                fetchDocuments();
            })
            .catch(error => {
                console.error('Error uploading file:', error);
                message.error(t('Error uploading file'));
            });

        return false;
    };

    const handleQuery = () => {
        if (!question.trim()) {
            message.warning(t('Please enter a question'));
            return;
        }

        setLoading(true);
        setAnswer('');

        const formData = new FormData();
        formData.append('question', question);

        fetch(`${API_BASE_URL}/query`, {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const reader = response.body.getReader();
                const decoder = new TextDecoder();

                const readStream = () => {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            setLoading(false);
                            return;
                        }

                        const chunk = decoder.decode(value);
                        setAnswer(prev => prev + chunk);
                        readStream();
                    }).catch(error => {
                        console.error('Error reading stream:', error);
                        setLoading(false);
                        message.error(t('Error reading response'));
                    });
                };

                readStream();
            })
            .catch(error => {
                console.error('Error querying system:', error);
                message.error(t('Error querying the system'));
                setLoading(false);
            });
    };

    const handleDelete = (filename) => {
        fetch(`${API_BASE_URL}/delete-document?filename=${encodeURIComponent(filename)}`, {
            method: 'DELETE',
            headers: defaultHeaders,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                message.success(t('Document deleted successfully'));
                fetchDocuments();
            })
            .catch(error => {
                console.error('Error deleting document:', error);
                message.error(t('Error deleting document'));
            });
    };

    return (
        <div style={{ padding: '24px' }}>
            <Title level={2}>{t('AI Brain PoC')}</Title>
            
            <Card title={t('Upload Document')} style={{ marginBottom: '24px' }}>
                <Upload.Dragger
                    beforeUpload={handleUpload}
                    accept=".pdf"
                    multiple={false}
                    showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">{t('Click or drag PDF file to upload')}</p>
                </Upload.Dragger>
            </Card>

            <Card title={t('Documents')} style={{ marginBottom: '24px' }}>
                <List
                    dataSource={documents}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button type="link" onClick={() => handleDelete(item.filename)}>
                                    {t('Delete')}
                                </Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={item.filename}
                                description={`${t('Pages')}: ${item.total_pages}, ${t('Chunks')}: ${item.chunks}`}
                            />
                        </List.Item>
                    )}
                />
            </Card>

            <Card title={t('Ask Question')}>
                <TextArea
                    value={question}
                    onChange={e => setQuestion(e.target.value)}
                    placeholder={t('Enter your question here')}
                    rows={4}
                    style={{ marginBottom: '16px' }}
                />
                <Button
                    type="primary"
                    onClick={handleQuery}
                    loading={loading}
                >
                    {t('Ask')}
                </Button>
                {answer && (
                    <div style={{ marginTop: '16px' }}>
                        <Title level={4}>{t('Answer')}:</Title>
                        <div style={{ whiteSpace: 'pre-wrap' }}>{answer}</div>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default Brain;