import { Avatar, Button, Card, Dropdown, message, Space, Spin, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined, EditOutlined, DownOutlined, PlusOutlined, ColumnWidthOutlined, ReloadOutlined, ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { exe,getQueryParam } from '../../Lib/Dal';
import CardEdit from './CardEdit';
import ColEdit from './ColEdit';
import { useTranslation } from 'react-i18next';

// fake data generator
const getItems = (count, offset = 0) =>
    Array.from({ length: count }, (v, k) => k).map(k => ({
        id: `item-${k + offset}-${new Date().getTime()}`,
        content: `id ${k + offset}`
    }));
const defaultState=[{id:1,name:"col1",Cards:[]},{id:1,name:"col2",Cards:[]}];
const TaskBoard = (props) => {
    const [state, setState] = useState(defaultState);
    const [loading, setLoading] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedCol, setSelectedCol] = useState(null);
    const [t]=useTranslation();


    useEffect(() => {
        if(props.taskId) loadBoard();
        
    }, [props.taskId]);

    useEffect(() => {
        if(state!=defaultState) checkCardInUrl(state); //when user changes the card in url
    }, [window.location.href]);

    const checkCardInUrl = (data) => {
        const cardId = getQueryParam('cardId',{debug:true});
        //checking if there is ?cardId= in the url
        if(+cardId){
            if(selectedCard&&selectedCard.id==cardId){
                return;
            }
            //finding the card in state
            const card = data.map(c=>c.Cards).flat().find(c=>c.id==cardId);
            if(card){
                setSelectedCard(card);
            }else{
                message.error("Card not found");

            } 
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = JSON.parse(JSON.stringify(list));
        const [removed] = result.Cards.splice(startIndex, 1);
        
        //updating order of the moved card using an an in-between ordering strategy
        //1. Identify the order of the card immediately before and after the new position.
        const before = result.Cards[endIndex - 1];
        const after = result.Cards[endIndex];
        //2. Assign a new order that's the midpoint between these two values.
        //if its dragged to the end of the list then the order is the last card order + 1
        if(!after && !before) removed.order = 1000000; //first card
        else if(!after) removed.order = ((before?.order || 0) + 1000000) / 2; //end of list
        else if(!before) removed.order = ((after?.order || 1000000) - 0) / 2; //start of list
        else removed.order = (before.order + after.order) / 2;

        result.Cards.splice(endIndex, 0, removed);
         //saving removed to DB
         exe("RepoProjectTaskCard",{operation:"UPDATE",entity:removed}).then((r) => {
            if(r.ok){
            }else message.error(r.msg);
        });

        return result;
    };

    /**
     * Moves an item from one list to another list.
     */
    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = JSON.parse(JSON.stringify(source));
        const destClone = JSON.parse(JSON.stringify(destination));

        const [removed] = sourceClone.Cards.splice(droppableSource.index, 1);
        removed.projectTaskColId = destClone.id;
        
        //updating order of the moved card using an an in-between ordering strategy
        //1. Identify the order of the card immediately before and after the new position.
        const before = destClone.Cards[droppableDestination.index - 1];
        const after = destClone.Cards[droppableDestination.index ];
        //2. Assign a new order that's the midpoint between these two values.
        //if its dragged to the end of the list then the order is the last card order + 1
        if(!after && !before) removed.order = 1000000; //first card
        else if(!after) removed.order = ((before?.order || 0) + 1000000) / 2; //end of list
        else if(!before) removed.order = ((after?.order || 1000000) - 0) / 2; //start of list
        else removed.order = (before.order + after.order) / 2;
        
        destClone.Cards.splice(droppableDestination.index, 0, removed);

        //saving removed to DB
        exe("RepoProjectTaskCard",{operation:"UPDATE",entity:removed}).then((r) => {
            if(r.ok){
            }else message.error(r.msg);
        });

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };
    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid,
        margin: `0 0 ${grid}px 0`,
        borderRadius: "4px",
        padding: "16px",

        // change background colour if dragging
        background: isDragging ? "#E8F5E9" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });
    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#E3F2FD" : "rgb(248, 249, 251)",
        padding: grid,
        width: 250,
        // Add these properties for column scrolling
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0, // Required for Firefox
        '&:hover': {
            '& > div': {
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                }
            }
        }
    });
    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const items = reorder(state[sInd], source.index, destination.index);
            const newState = [...state];
            newState[sInd] = items;
            setState(newState);
        } else {
            const result = move(state[sInd], state[dInd], source, destination);
            const newState = [...state];
            newState[sInd] = result[sInd];
            newState[dInd] = result[dInd];

            setState(newState);
        }
    }
    
    const loadBoard = () => {
        setLoading(true);
        exe("RepoProjectTaskCol",{operation:"GET",filter:`projectTaskId=${props.taskId}`,include:["Cards"]}).then((r) => {
            setLoading(false);
            if(r.ok){
                //creating an array of columns
                setState(r.outData);
                checkCardInUrl(r.outData);
            }else message.error(r.msg);
        }); }

        const onAddColumn = () => {

            const newColumn = {
                projectId: props.projectId,
                projectTaskId: props.taskId,
                name: "New Column",
                order: state.length * 100000,
            }
            exe("RepoProjectTaskCol",{operation:"ADD",entity:newColumn}).then((r) => {
                if(r.ok){
                    message.success(r.msg);
                    loadBoard();
    
                }else message.error(r.msg);
            });
        }
    const onNewCard = (colId) => {

        //getting order of the new card using an an in-between ordering strategy. dont want to use zero as order, getting the first card order and dividing by 2
        const order = state.find(c => c.id === colId).Cards[0]?.order / 2 || 1000000;


        const newCard = {
            projectId: props.projectId,
            projectTaskId: props.taskId,
            projectTaskColId: colId,
            name: "New Card",
            order: order,
        }
        exe("RepoProjectTaskCard",{operation:"ADD",entity:newCard}).then((r) => {
            if(r.ok){
                message.success(r.msg);
                loadBoard(); //temp should only load column 

            }else message.error(r.msg);
        });
    }

    const onSaveCard = (card) => {
        const newState = [...state];
        const col = newState.find(c => c.id === card.projectTaskColId);
        const cardIndex = col.Cards.findIndex(c => c.id === card.id);
        col.Cards[cardIndex] = card;
        setState(newState);
        setSelectedCard();
    }
    const onDeleteCard = (card) => {
        //removing from state
        const newState = [...state];
        const col = newState.find(c => c.id === card.projectTaskColId);
        col.Cards = col.Cards.filter(c => c.id !== card.id);
        setState(newState);
        setSelectedCard();
    }
    const onSaveCol = (col) => {
        loadBoard(); //temp should only load column
        setSelectedCol();
    }
    const onDeleteCol = (col) => {
        //removing from state
        const newState = state.filter(c => c.id !== col.id);
        setState(newState);
        setSelectedCol();
    }
    const moveCol = (col, dir) => {
        // Sort the state array based on the order property
        const sortedState = [...state].sort((a, b) => a.order - b.order);
        const colIndex = sortedState.findIndex(c => c.id === col.id);
        if (colIndex === -1) return; // Column not found in state
    
        let newOrder;
    
        if (dir === -1) { // Move up
            if (colIndex === 0) return; // Already at the top
            const prevCol = sortedState[colIndex - 1];
            const secondPrevCol = sortedState[colIndex - 2] || { order: prevCol.order - 1 };
            newOrder = (prevCol.order + secondPrevCol.order) / 2;
        } else { // Move down
            if (colIndex === sortedState.length - 1) return; // Already at the bottom
            const nextCol = sortedState[colIndex + 1];
            const secondNextCol = sortedState[colIndex + 2] || { order: nextCol.order + 1 };
            newOrder = (nextCol.order + secondNextCol.order) / 2;
        }
    
        col.order = newOrder;
        console.log(col.order);
    
        exe("RepoProjectTaskCol", { operation: "UPDATE", entity: col }).then((r) => {
            if (r.ok) {
                loadBoard();
            } else {
                message.error(r.msg);
            }
        });
    }
    const colMenu= [
        {
          key: 'edit',
          label: 'Edit',
          icon: <EditOutlined />,
        },
        {
            key: 'left',
            label: 'Move Left',
            icon: <LeftOutlined />,
          },
          {
            key: 'right',
            label: 'Move Right',
            icon: <RightOutlined />,
          }
      ];
      const onColMenuClick = (obj,col) => {
        switch (obj.key) {
            case 'edit':
                setSelectedCol(col);
                break;
            case 'left':
                moveCol(col,-1);
                break;
            case 'right':
                moveCol(col,1);
                break;
            default:
                break;
      }
    }
    const onExport = () => {
        //exporting the board to a json file
        const json = JSON.stringify(state.map(col=>({id:col.id,name:col.name,order:col.order,status:col.status})));
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `board-${props.taskId}.json`; //including the project task id in the file name
        a.click();
    }
    const onImport = () => {
        //importing the board from a json file
        const file = document.createElement("input");
        file.type = "file";
        file.accept = "application/json";
        file.onchange = (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const json = JSON.parse(e.target.result);
                //changing the project task id of the imported board. Only importing cols, not cards
                const json2=json.map(col=>({id:0,projectTaskId:props.taskId,name:col.name,order:col.order,status:col.status}));
                //saving the imported board to the database in a MERGE operation serializing the json
                exe("RepoProjectTaskCol",{operation:"MERGE",bulkJson:JSON.stringify(json2)}).then((r) => {
                    if(r.ok){
                        message.success(r.msg);
                        loadBoard();
                    }else message.error(r.msg);
                });

                
            }
            reader.readAsText(file);
        }
        file.click();
    }

    const getColumnContentStyle = {
        overflowY: 'auto',
        flexGrow: 1,
        minHeight: 0,
        // Default scrollbar styles
        msOverflowStyle: 'none',  // IE and Edge
        scrollbarWidth: 'thin',   // Firefox
        scrollbarColor: 'transparent transparent', // Firefox
        // Webkit scrollbar styles
        '&::-webkit-scrollbar': {
            width: '4px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'transparent',
            borderRadius: '4px'
        },
        '&:hover::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.2)'
        }
    };

    return (
        <div style={{height:"100%"}}>
            <style>
                {`
                    .board-scroll-container {
                        display: flex;
                        overflow-x: auto;
                        overflow-y: hidden;
                        flex-grow: 1;
                        min-height: 0;
                        padding: 8px;
                        gap: 8px;
                        background-color: rgb(248, 249, 251);
                        scroll-behavior: smooth;
                    }
                    .board-scroll-container::-webkit-scrollbar {
                        -webkit-appearance: none;
                        height: 8px;
                    }
                    .board-scroll-container::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        border-radius: 4px;
                    }
                    .board-scroll-container::-webkit-scrollbar-thumb {
                        background: #bfbfbf;
                        border-radius: 4px;
                    }
                    .board-scroll-container::-webkit-scrollbar-thumb:hover {
                        background: #a6a6a6;
                    }
                `}
            </style>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ padding: '8px' }}>
                    <Button type={"link"} icon={<LeftOutlined />} onClick={() => props.onBack()}>{t("Back")}</Button>
                    <Button type={"link"} icon={<ColumnWidthOutlined />} onClick={onAddColumn}>{t("New Column")}</Button>
                    <Button type={"link"} icon={<ReloadOutlined />} onClick={loadBoard}>{t("Reload")}</Button>
                    <Button type={"link"} icon={<ExportOutlined />} onClick={() => onExport()}>{t("Export")}</Button>
                    <Button type={"link"} icon={<ImportOutlined />} onClick={() => onImport()}>{t("Import")}</Button>
                    <Spin spinning={loading} />
                </div>
                <div className="board-scroll-container">
                    <DragDropContext onDragEnd={onDragEnd}>
                        {state
                        .sort((a, b) => a.order - b.order)
                        .map((col, ind) => (
                            <Droppable key={ind} droppableId={`${ind}`}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={{
                                            ...getListStyle(snapshot.isDraggingOver),
                                            flex: '0 0 250px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxHeight: 'calc(100vh - 120px)',
                                        }}
                                        {...provided.droppableProps}
                                    >
                                        <div style={{ 
                                            display: "flex", 
                                            justifyContent: "space-between", 
                                            alignItems: "center",
                                            marginBottom: '8px'
                                        }}>
                                            <h5 style={{ margin: 0 }}>{col.name} ({col.Cards.length})</h5>
                                            <Space>
                                                <Button style={{ padding: 0}} type="link" onClick={() => onNewCard(col.id)} icon={<PlusOutlined />} />
                                                <Dropdown menu={{items:colMenu,onClick:(obj)=>onColMenuClick(obj,col)}}>
                                                    <a onClick={(e) => e.preventDefault()}><DownOutlined /></a>
                                                </Dropdown>
                                            </Space>
                                        </div>
                                        <div 
                                            style={{ 
                                                overflowY: 'auto',
                                                overflowX: 'hidden',
                                                flexGrow: 1,
                                                minHeight: 0,
                                                paddingRight: '4px',
                                                marginRight: '-4px',
                                                // Hide scrollbar by default
                                                msOverflowStyle: 'none',
                                                scrollbarWidth: 'none',
                                                scrollbarColor: 'transparent transparent',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.style.msOverflowStyle = 'auto';
                                                e.currentTarget.style.scrollbarWidth = 'thin';
                                                e.currentTarget.style.scrollbarColor = '#bfbfbf transparent';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.style.msOverflowStyle = 'none';
                                                e.currentTarget.style.scrollbarWidth = 'none';
                                                e.currentTarget.style.scrollbarColor = 'transparent transparent';
                                            }}
                                        >
                                            {col.Cards
                                            .sort((a, b) => a.order - b.order)
                                            .map((card, index) => (
                                                <Draggable
                                                    key={card.id}
                                                    draggableId={`card-${card.id}`}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                            onMouseEnter={(e) => (e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)")}
                                                            onMouseLeave={(e) => (e.currentTarget.style.boxShadow = "")}
                                                            onClick={() => setSelectedCard(card)}
                                                        >
                                                             {/* <Card
                                                                bodyStyle={{  fontSize: "0.7vw",padding:2 }}
                                                                hoverable
                                                                actions={[
                                                                    <CaretRightOutlined key="play" onClick={() => setSelectedCard(card)} />,
                                                                        // <Icon type="edit" key="edit" />,
                                                                        // <Icon type="ellipsis" key="ellipsis" />,
                                                                    ]}>
                                                                    <Card.Meta title={card.name} description={card.content} />
                                                                    </Card> */}
                                                            <div 
                                                         
                                                                style={{
                                                                    display: "flex",
                                                                    //justifyContent: "space-around",
                                                                    borderRadius: "5px",
                                                                    fontSize: "9pt",
                                                                   
                                                                }}
                                                            >
                                                                {card.name}
                                                            </div>
                                                            {card.user&&<Tooltip title={card.user}>
                                                                <Avatar style={{ backgroundColor: '#1890ff' }} size={14}>{card.user.charAt(0).toUpperCase()}</Avatar>
                                                            </Tooltip>}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </DragDropContext>
                </div>
                <CardEdit selected={selectedCard} visible={selectedCard} onCancel={()=>setSelectedCard()} onSave={onSaveCard} onDelete={onDeleteCard} />
                <ColEdit selected={selectedCol} visible={selectedCol} onCancel={()=>setSelectedCol()} onSave={onSaveCol} onDelete={onDeleteCol} />
            </div>
        </div>
    );
}

export default TaskBoard;