import React, { useState, useEffect } from "react";
import { DeleteOutlined, LeftOutlined, PlusOutlined, PullRequestOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Row,
  Col,
  Input,
  Button,
  Empty,
  Statistic,
  message,
  Alert,
  Dropdown,
  Menu,
  Select,
  Spin,
  Checkbox,
} from "antd";
import Money from "../Shared/Money";
import { formatter } from "../Shared/Money";
import { exe, safeGet, safeValue } from "../../Lib/Dal";
import ClaimPaymentList from "./ClaimPaymentList";
import { useTranslation } from "react-i18next";
import PolicyCoverageSelect from "./PolicyCoverageSelect";
import ClaimBeneficiary from "./ClaimBeneficiary";
import BeneficiaryAccountSelect from "./BeneficiaryAccountSelect";
import BeneficiaryTypeSelect from "./BeneficiaryTypeSelect";
import ProviderSelect from "./ProviderSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import BuyAnnuity from "./BuyAnnuity";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import PaymentMethods from "../Life/PaymentMethods";
import { getConfigProfile, hasCurrencyDecimals } from "../../Lib/Helpers";
import ClaimPaymentSchemeSelect from "./ClaimPaymentSchemeSelect";
import ClaimsFundSelect from "../Provider/ClaimsFundSelect";

const ClaimPayment = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([{ num: 1 }]);
  const [mode, setMode] = useState(props.claim.Payments.length == 0 ? "NEW" : "LIST"); //OPEN, NEW, LIST
  const [display, setDisplay] = useState({}); //display values for form combos whn loaded
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buyAnnuityVisible,setBuyAnnuityVisible]=useState(false);
  const [beneficiaryAllAccounts, setBeneficiaryAllAccounts] = useState(false);
  const [defaultValuesConfig, setDefaultValuesConfig] = useState();
  const [accountFilter, setAccountFilter] = useState();
  const values = props.form.getFieldsValue();
  
  useEffect(() => {
    if(values&&values.contactId) setAprovedItems(values.coverageId);
  }, [percentage,values.contactId]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setBeneficiaryAllAccounts(safeGet(["Claim", "beneficiaryAllAccounts"], profile, false));
      setDefaultValuesConfig(safeGet(["Claim", "defaultPaymentValues"], profile, null));
      setAccountFilter(safeGet(["Claim", "accountFilter"], profile, null));
    });
}, []);

useEffect(() => {
  if(mode==="NEW") getDefaultReference();
}, [mode]);

  const claim = props.claim;
  const policy = claim.Policy;
  if (!policy) return <Empty />;

  const coveragePayments = claim.Payouts.map((p) => ({ ...p, code: claim.Policy.Coverages.find((q) => q.id == p.lifeCoverageId).code }));
  const field = props.form.getFieldDecorator;
  const fiatAccounts = policy.Accounts.filter((p) => p.type == "Current Account" && p.currency == policy.currency);
  const fiatBalance = fiatAccounts.reduce((t, item) => t + item.currentBalance, 0);
  const unitBalance = policy.Accounts.filter((p) => p.type !== "Current Account").reduce((t, item) => t + item.currentBalance, 0);
  const currency=policy.currency;

  const getBeneficiaryAmount=(per,payable,approved,lines)=>{
    if(!values.contactId||!per) return 0;
    if(per==100) return approved;
    if(!per) return aprovedForPayment/lines;
    //checking if payable has been paid to other beneficiaries
    const paidToOthers=claim.Payments.filter(p=>p.payoutId==payable.id&&p.contactId!=values.contactId).reduce((t,p)=>t+p.grossAmount,0);
    const paidToSelf=claim.Payments.filter(p=>p.payoutId==payable.id&&p.contactId==values.contactId).reduce((t,p)=>t+p.grossAmount,0);
    //console.log({paidToOthers,paidToSelf});
    if(paidToSelf>0) return 0; //payout already paid to this beneficiary
    if(paidToOthers>0){
      return (approved+paidToOthers)*per/100; //adjusting % to be paid
    }
    else return approved*per/100;

  }
  const setAprovedItems = (selectedCoverageId) => {
    if(mode=="OPEN") return;
    if (coveragePayments.length == 0||!selectedCoverageId){
      setData([]);
      return;
    }
    const coveragePayed = claim.Payments.filter((p) => p.coverageId == selectedCoverageId).reduce((total, item) => total + item.grossAmount, 0);
    //const covApprovedForPayment = coveragePayments.filter((q) => (q.status == 1 || q.status == 3) && q.lifeCoverageId == selectedCoverageId).reduce((total, item) => total + item.payed, 0)-coveragePayed;

    const payables = coveragePayments.filter((p) => p.payed > 0 && p.status == 1).filter(p=>p.lifeCoverageId==selectedCoverageId);
    if (payables.length > 0) {
      const upadtedData = payables.map((p, i) => ({
        num: i + 1,
        payoutId: p.id,
        item: policy.Coverages.find((q) => q.id == p.lifeCoverageId).name,
        description: p.concept,
        amount: getBeneficiaryAmount(percentage,p,aprovedForPayment,payables.length),
        lifeCoverageId:p.lifeCoverageId
      }));
      if (fiatBalance > 0) {
        fiatAccounts.forEach((item) => {
          upadtedData.push({
            num: upadtedData.length + 1,
            payoutId: item.id * -1,
            item: item.accNo,
            description: item.type + "-" + t("Liquidation"),
            amount: item.currentBalance,
          });
        });
      }
      setData(upadtedData);
    } else {
      setData([]);
    }
  };
  const onAddItem = () => {
    setData([...data, { num: data.length + 1 }]);
  };
  const onRemoveItem = (v) => {
    setData(data.filter((p) => p.num !== v).map((q, i) => ({ ...q, num: i + 1 })));
  };

  const changeLine = (line, property, value) => {
    const updatedData = data.map((x) => (x.num === line ? { ...x, [property]: value } : x));
    setData(updatedData);
  };

  const subtotal = data.reduce((total, item) => (total += isNaN(+item.amount) ? 0 : parseFloat(item.amount)), 0);

  if (!claim || !claim.Policy) return <Empty />;

  const coveragePayed = claim.Payments.filter((p) => p.coverageId == values.coverageId).reduce((total, item) => total + item.grossAmount, 0);

  const aprovedForPayment =
    coveragePayments.filter((q) => (q.status == 1 || q.status == 3) && q.lifeCoverageId == values.coverageId).reduce((total, item) => total + item.payed, 0)-coveragePayed;
  const money = formatter(policy.currency);

  const onTransfer = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (aprovedForPayment < subtotal) {
        //for currencies without decimals, we allow a unit difference
        if(!hasCurrencyDecimals(policy.currency)&&Math.abs(aprovedForPayment-subtotal)<1){
          //allowed
        }else{
          message.error(t("Approved amount exceeded"));
          return;
        }
      }
      setLoading(true);
      exe(
        "DoClaimPayment",
        {
          ...values,
          claimId: claim.id,
          reference: values.reference,
          coverageId: values.coverageId,
          contactId: values.contactId,
          currency: claim.Policy.currency,
          accountId: values.accountId,
          total: subtotal,
          jDetail: JSON.stringify(data),
          providerCode: values.providerCode,
          beneficiaryType: values.beneficiaryType,
        },
        { operation: "ADD" }
      ).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(t("Payment requested"));
          setMode("LIST");
          props.reload();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onBuyAnnuity = (annuity) => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("DoClaimPaymentWithAnnuity", {
        ...values,
        claimId: claim.id,
        reference: values.reference,
        coverageId: values.coverageId,
        contactId: values.contactId,
        currency: claim.Policy.currency,
        accountId: values.accountId,
        total: subtotal,
        jDetail: JSON.stringify(data),
        frequency:annuity.frequency,
        start:annuity.start,
        end:annuity.end,
        annuityAmount:annuity.annuityAmount,
        payments:annuity.payments
      }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setBuyAnnuityVisible(false);
          message.success(r.msg);
          setMode("LIST");
          props.reload();
        } else {
          message.error(r.msg);
        }
      });
    });
  };

  const onOpen = (r) => {
    props.form.setFieldsValue(r);
    setData(JSON.parse(r.jDetail));
    setMode("OPEN");
    setDisplay({ beneficiary: r.Beneficiary ? r.Beneficiary.FullName : "" });
  };
  const onRevert = (r) => {
    setLoading(true);
    exe("RevertClaimPayment", { claimPaymentId: r.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Payment reverted"));
        props.reload();
      } else {
        message.error(r.msg);
      }
    }
    );
  }
  const onNew = () => {
    props.form.resetFields();
    setAprovedItems();
    setMode("NEW");
  };
  const onCoverageChange = (covId) => {
    //filtering for only payables for this coverage
    setAprovedItems(covId);
    applyDefaultValues(defaultValuesConfig,covId);

  };
  const onProviderSelect = (value, op) => {
    if (op) {
      props.form.setFieldsValue({ contactId: op.props.record.contactId });
    }
  };
  const onRequiresFiscalNumberChange=e=>{
    if(e.target.checked){
      if(!values.contactId) {
        message.error(t("Please select beneficiary"));
        props.form.setFieldsValue({requiresFiscalNumber:false});
        return false;
      }

      exe("GetContacts",{filter:`id=${values.contactId}`}).then(r=>{
        if(r.ok&&r.total>0){
          const contact=r.outData[0];
          props.form.setFieldsValue({requiresFiscalNumber:true,receiptTypeCode:contact.receiptTypeCode});
          onReceiptTypeSelect(contact.receiptTypeCode)
        }
      })
      
    }else{
      props.form.setFieldsValue({fiscalNumber:undefined,receiptTypeCode:undefined})
    }
  }
  const onReceiptTypeSelect=(v,opt)=>{
    if(v)
    {
      setLoading(true);
      exe("GetFiscalNumber",{receiptTypeCode:v,simulation:true}).then(r=>{
        setLoading(false);
        if(r.ok){
          props.form.setFieldsValue({fiscalNumber:r.outData})
        }else {
          message.error(r.msg);
          props.form.setFieldsValue({fiscalNumber:undefined})
        }
      })
    }else props.form.setFieldsValue({fiscalNumber:undefined})
  }
  const onAccountSelected=(v,opt)=>{
    //defined payment method from config
    if (props.config&&v) {
      if(props.config.Main.claimPaymentMethod){
        setLoading(true);
        exe("GetClaimPaymentMethod",{formula:props.config.Main.claimPaymentMethod,claimId:props.claim.id,accountId:v,contactId:values.contactId}).then(r=>{
          setLoading(false);
          if(r.ok){
            props.form.setFieldsValue({paymentMethodCode:r.outData.paymentMethodCode})
          }else message.error(r.msg);
      })
    }}
  }
  const getDefaultReference=()=>{
    try {
      if(!values.reference&&props.config.Main.claimPaymentReference){
        //computing default reference code
        setLoading(true);
          exe("ExeFormula",{formula:props.config.Main.claimPaymentReference,context:JSON.stringify({cla:props.claim})}).then(r=>{
            setLoading(false);
            if(r.ok){
              props.form.setFieldsValue({reference:r.outData[0]})
            }else console.error("Error computing default reference code (Main.claimPaymentReference): ",r.msg)
          })
        } 
    }
    catch (error) {
      console.error("Error computing default reference code (Main.claimPaymentReference): ",error);
    }
  }
  const applyDefaultValues=(defaultValuesConfig,covId)=>{
    if(!defaultValuesConfig) return;
    //setting context
    const context={cla:claim,payment:{...values,coverageId:covId}};
    setLoading(true);
    console.log(context, "DEFAULT VALUES CONTEXT");
    exe("ExeFormula",{formula:defaultValuesConfig,context:JSON.stringify(context)}).then(r=>{
      setLoading(false);
      if(r.ok){
        props.form.setFieldsValue(r.outData[0]);
      }else console.error("Error computing default values: ",r.msg)
    })
  }

  const paymentSchemes=safeValue("Claims.paymentSchemes",props.config,[]);
  return (
    <div>
      <div style={{ display: mode == "NEW" || mode == "OPEN" ? "block" : "none" }}>
        {claim.Payments.length > 0 && (
          <Button type="link" icon={<LeftOutlined />} onClick={() => setMode("LIST")}>
            {t("Back")}
          </Button>
        )}

        <div style={{ display: "flex" }}>
          <Form style={{ flex: 1, marginRight: 25 }}>
            {claim.closed && (
              <Alert
                message="Warning"
                description={t("This claim is closed. If more operations need to be done, the claim must first be reopened.")}
                type="warning"
                showIcon
              />
            )}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={t("Coverage")}>
                  {field("coverageId", { rules: [{ required: true }] })(
                    <PolicyCoverageSelect
                      elegibleCoverages={coveragePayments.filter((q) => q.status == 1 || q.status == 2).map((p) => p.code)}
                      onChange={onCoverageChange}
                      coverages={safeGet(["Policy", "Coverages"], claim, [])}
                      currency={policy.currency}
                    />
                  )}
                </Form.Item>
                <div style={{ display: values.beneficiaryType == "BEN" ? "block" : "none" }}>
                  <Form.Item label={t("Policy Beneficiary")}>
                    {field("contactId", { rules: [{ required: values.beneficiaryType == "BEN" }] })(
                      <ClaimBeneficiary coverageId={values.coverageId}  onClear={()=>setPercentage(100)} onPercentageChange={per=>setPercentage(per)} />
                    )}
                  </Form.Item>
                </div>
                <div style={{ display: values.beneficiaryType == "PRO" ? "block" : "none" }}>
                  <Form.Item label={t("Provider")}>
                    {field("providerCode", { rules: [{ required: values.beneficiaryType == "PRO" }] })(
                      <ProviderSelect onSelect={(v, o) => onProviderSelect(v, o)} />
                    )}
                  </Form.Item>
                  <Form.Item label={t("Provider Contact")}>
                    {field("contactId", { rules: [{ required: values.beneficiaryType == "PRO" }] })(<SimpleContactSelect disabled />)}
                  </Form.Item>
                </div>
                <div style={{ display: values.beneficiaryType == "OTH" ? "block" : "none" }}>
                  <Form.Item label={t("Beneficiary")}>
                    {field("contactId", { rules: [{ required: values.beneficiaryType == "OTH" }] })(<SimpleContactSelect />)}
                  </Form.Item>
                </div>
                <Form.Item label={t("Reference")}>{field("reference", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                <Form.Item label={t("Claims Fund")}>{field("claimsFundId")(<ClaimsFundSelect claimsFundId={policy?.claimsFundId}  />)}</Form.Item>
                <Form.Item label={t("Requires Fiscal Number")}>
                  {field("requiresFiscalNumber",{valuePropName:"checked"})(<Checkbox onChange={onRequiresFiscalNumberChange} />)}
                </Form.Item>

              
              </Col>
              <Col span={12}>
                <Form.Item label={t("Beneficiary Type")}>
                  {field("beneficiaryType", { rules: [{ required: true }] })(
                    <BeneficiaryTypeSelect disabledBeneficiaryTypes={props.config.Main.disabledBeneficiaryTypes} />
                  )}
                </Form.Item>
                <Form.Item label={t("Beneficiary Account")}>
                  {field("accountId", { rules: [{ required: true }] })(<BeneficiaryAccountSelect onChange={onAccountSelected} filter={accountFilter}
                      contactId={values.contactId} 
                      disabled={!values.contactId} 
                      policyId={(values.beneficiaryType==="BEN"&&policy.holderId===values.contactId&&!beneficiaryAllAccounts)?policy.id:undefined} 
                      currency={currency} />)}
                </Form.Item>
                <div style={{display:values.requiresFiscalNumber?"block":"none"}}>
                  <Form.Item label={t("Fiscal Document Type")}>
                    {field("receiptTypeCode",{rules:[{required:values.requiresFiscalNumber}]})(<ReceiptTypeSelect onSelect={onReceiptTypeSelect} />)}
                  </Form.Item>
                </div>
                <Form.Item label={values.requiresFiscalNumber?t("Fiscal Document Number"):t("Existing Fiscal Number")}>
                  {field("fiscalNumber",{rules:[{required:values.requiresFiscalNumber}]})(<Input disabled={values.requiresFiscalNumber} />)}
                </Form.Item>
                <Form.Item label={t("Payment Method")}>
                  {field("paymentMethodCode",{initialValue:props.claim.paymentMethodCode})(<PaymentMethods module="PAYMENT" disabled={props.claim.paymentMethodCode} context={{payment:values}} showInfo />)}
                </Form.Item>
                <div style={{display:paymentSchemes.length?"block":"none"}}>
                  <Form.Item label={t("Payment Scheme")}>
                    {field("paymentSchemeCode",{})(<ClaimPaymentSchemeSelect claimPaymentSchemes={paymentSchemes} />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
          {mode == "NEW" && (
            <div style={{}}>
              <Statistic title={t("Claim Approved")} value={money.format(aprovedForPayment)} />
              <Statistic title={t("Account Fiat Balance")} value={money.format(fiatBalance)} />
              <Statistic title={t("Account Unit Balance")} value={unitBalance} />
            </div>
          )}
        </div>
        <Table dataSource={data} pagination={false}>
          <Table.Column title={t("Num")} dataIndex="num" />
          <Table.Column title={t("Approval Id")} dataIndex="payoutId" />
          <Table.Column title={t("Item")} dataIndex="item" render={(v, r) => <Input value={v} onChange={(e) => changeLine(r.num, "item", e.target.value)} />} />
          <Table.Column
            title={t("Description")}
            dataIndex="description"
            render={(v, r) => <Input value={v} onChange={(e) => changeLine(r.num, "description", e.target.value)} />}
          />
          <Table.Column
            title={t("Amount")}
            dataIndex="amount"
            key="amountField"
            render={(v, r) => <Money value={v} currency={claim.Policy.currency} onChange={(q) => changeLine(r.num, "amount", q)} />}
          />
          <Table.Column
            key="delete"
            dataIndex="num"
            render={(v, r) => <Button type="danger" icon={<DeleteOutlined />} disabled={mode == "OPEN"} onClick={() => onRemoveItem(v)} />}
          />
        </Table>
        <div style={{ display: "flex", paddingTop: 10, flexDirection: "row-reverse" }}>
          <Button icon={<PlusOutlined />} onClick={onAddItem} disabled={mode == "OPEN"}>
            {t("Add Item")}
          </Button>
        </div>

        <Statistic title={t("Total")} value={money.format(subtotal)} />

        <Dropdown.Button
          loading={loading}
          type="primary"
          onClick={onTransfer}
          disabled={mode == "OPEN"}
          overlay={
            <Menu>
              <Menu.Item onClick={()=>setBuyAnnuityVisible(true)}>
                <PullRequestOutlined />
                {t("Buy Annuity")}
              </Menu.Item>
            </Menu>
          }>
          {t("Request")}
        </Dropdown.Button>
        {loading && <Spin style={{ marginLeft: 15 }} />}
      </div>

      {mode == "LIST" && (
        <div>
          <Button style={{ marginBottom: 5,float:"left" }} type="primary" icon={<PlusOutlined />} onClick={onNew}>
            {t("New")}
          </Button>
          <ClaimPaymentList payments={claim.Payments} onOpen={(r) => onOpen(r)} onRevert={onRevert} claim={claim} />
        </div>
      )}
      <BuyAnnuity visible={buyAnnuityVisible} onCancel={()=>setBuyAnnuityVisible(false)} onOk={onBuyAnnuity} limit={subtotal} />
    </div>
  );
};

export default Form.create()(ClaimPayment);
