import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ObjectRender from "./ObjectRender";

const InsuredObjectsDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dynamicFormId = props.dynamicFormId || "objectForm";

  useEffect(() => {
    if (props.value) {
      props.form.setFieldsValue(props.value);
    } else {
      props.form.resetFields();
    }
  }, [props.value]);

  const emitEvent = (customEvent) => {
    const event = new CustomEvent('insuredObject'+customEvent, { 
      detail: { 
        formId: dynamicFormId,
        bubbles: true,
        cancelable: true
      } 
    });
    window.dispatchEvent(event);
  }
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //validating custom form
      if (window[dynamicFormId]) {
        if (window[dynamicFormId].instanceContainers[0].reportValidity()) {
          values.jValues = JSON.stringify(window[dynamicFormId].userData);
        } else {
          message.error(t("Please check object form validation errors"));
          return;
        }
      }
      setLoading(true);
      exe("RepoInsuredObject", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          emitEvent("Saved");
          message.success(r.msg);
          props.onUpdate(r.outData[0]);
          props.onBack();
          //window[dynamicFormId].instanceContainers[0].reset();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  //used in policy changes
  const onUpdate = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //validating custom form
      if (window[dynamicFormId]) {
        if (window[dynamicFormId].instanceContainers[0].reportValidity()) {
          values.jValues = JSON.stringify(window[dynamicFormId].userData);
        } else {
          message.error(t("Please check object form validation errors"));
          return;
        }
      }
      if (props.value && props.value.ObjectDefinition) values.ObjectDefinition = props.value.ObjectDefinition;
      props.onUpdate(values);
      props.onBack();
      window[dynamicFormId].instanceContainers[0].reset();
    });
  };
  const onDelete = () => {
    if (props.change) {
      props.onUpdate(null);
      props.onBack();

      emitEvent("Deleted");

      window[dynamicFormId].instanceContainers[0].reset();

      return;
    }
    exe("RepoInsuredObject", { operation: "DELETE", entity: { id: values.id, lifePolicyId:values.lifePolicyId } }).then((r) => {
      if (r.ok) {
        emitEvent("Deleted");

        message.success(r.msg);
        props.onUpdate();
        props.onBack();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onMapChange = (valuesObject) => {
    props.form.setFieldsValue({ jMap: JSON.stringify(valuesObject) });
  };
  const onFileUploadChange = (valuesObject) => {
    console.log(valuesObject, "FILEUPLAOD");
    props.form.setFieldsValue({ jFileUpload: JSON.stringify(valuesObject) });
  };
  const field = props.form.getFieldDecorator;
  const values = props.form.getFieldsValue();
  return (
    <div>
      <Button type="link" icon={<LeftOutlined />} onClick={() => props.onBack()}>
        {t("Back")}
      </Button>
      {!props.change && (
        <Button type="link" icon={<SaveOutlined />} onClick={onSave} loading={loading} disabled={props.active}>
          {t("Save")}
        </Button>
      )}
      {props.change && (
        <Button type="link" icon={<SaveOutlined />} onClick={onUpdate} loading={loading} disabled={props.active}>
          {t("Update")}
        </Button>
      )}
      <Popconfirm title={t("Are you sure you want to remove this object?")} onConfirm={onDelete} disabled={props.active}>
        <Button type="link" icon={<DeleteOutlined />} style={{ float: "right" }} disabled={props.active}>
          {t("Remove")}
        </Button>
      </Popconfirm>
      {field("id")}
      {field("lifePolicyId", { initialValue: props.policyId })}
      {field("objectDefinitionId", { initialValue: props.objectDefinitionId })}
      {field("jMap")}
      {field("jFileUpload")}
      <ObjectRender
        value={props.value}
        objectDefinitionId={props.objectDefinitionId}
        onMapChange={onMapChange}
        onFileUploadChange={onFileUploadChange}
        mapValue={values.jMap}
        fileUploadValue={values.jFileUpload}
        dynamicFormId={dynamicFormId}
      />
    </div>
  );
};

export default Form.create()(InsuredObjectsDetail);
