import React from 'react';
import {useTranslation} from "react-i18next";
import {Select} from "antd";

const ReinstatementInstallmentStatus = ({value,onChange,disabled}) => {
    const [t]=useTranslation();
    const data=[{code:0,name:t("Pending")},{code:1,name:t("Paid")},{code:3,name:t("Requested"),disabled:true},{code:2,name:t("Reverted")}];
    return (
        <div>
            <Select value={value} onChange={onChange} disabled={disabled}>
                {data.map(p=><Select.Option value={p.code} disabled={p.disabled}>{p.name}</Select.Option>)}
            </Select>
        </div>
    );
};

export default ReinstatementInstallmentStatus;