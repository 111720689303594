import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";
import { useTranslation } from 'react-i18next';

const CategorySelect = (props) => {
    const [data,setData]=useState([]);
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.categories&&!data.length) load();
    },[props.categories])
    
    const load=()=>{
        //creating distinct array of categories
        let categories=[];
        props.categories.filter(p=>p).forEach(p=>{
            if(!categories.find(c=>c==p)) categories.push(p);
        });
        setData(categories);
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} allowClear onClear={()=>props.onChange()} placeholder={t("Category")} style={{width:200}}>
              {data.map(p=><Select.Option value={p} key={p}>{p}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default CategorySelect;