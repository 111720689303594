import React, { useEffect, useMemo, useState } from "react";
import { FolderOpenOutlined, OrderedListOutlined, PlusOutlined, SaveOutlined, FilterOutlined, CodeOutlined, FolderOutlined, FileOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, message, Typography, Anchor, Tabs, Select, Button, Badge, BackTop, Skeleton } from "antd";
import { exe } from "../../Lib/Dal";
import { HashLink } from "react-router-hash-link";
import DefaultPage from "../Shared/DefaultPage";
import EditableFormField from "../Shared/EditableFormField";
import yaml from "js-yaml";
import NewProduct from "./NewProduct";
import { useTranslation } from "react-i18next";
import productSchema from "./productSchema.json";

const Configurator = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productConfig, setProductConfig] = useState({});
  const [product, setProduct] = useState({});
  const productCode = props.match.params.productCode;
  const [changes, setChanges] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [schemaTree, setSchemaTree] = useState({});
  const [newProductVisible, setNewProductVisible] = useState(false);
  const [hideEmptySections, setHideEmptySections] = useState(false);

  useEffect(() => load(productCode), [productCode]);
  useEffect(() => loadLabels(), []);

  const loadLabels = () => {
    /* exe("RepoLabel", { operation: "GET", filter: "module='PRODUCT'" }).then((r) => {
      const toObject = r.outData.map((p) => ({ path: p.path, label: p.label, help: p.help, type: p.type, options: p.options, validation: p.validation }));
      setMetadata(toObject);
    }); */

    //gettting metadata from productSchema.json.
    const metadata = productSchema.map((p) => ({ section: p.section, sectionName: p.section.split("[")[0], path: p.section + "." + p.fieldName, label: p.label, help: p.help, fieldName: p.fieldName, type: p.type }));
    setMetadata(metadata);
    //creating a schema tree based on metadata.path knowing that metadata.path is a json path.
    const schemaTree = {};
    metadata.forEach((p) => {
      const pathParts = p.path.split(".");
      let current = schemaTree;
      pathParts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = {};
        }
        current = current[part];
      });
      current.label = p.label;
      current.help = p.help;
      current.type = p.type;
      current.options = p.options;
      current.validation = p.validation;
      current.path = p.path;
      current.type = p.type;
    });
    setSchemaTree(schemaTree);
  };

  const load = (productCode) => {
    if (!productCode) return;
    setLoading(true);
    exe("RepoProduct", {
      operation: "GET",
      filter: "code='" + productCode + "'",
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        try{
          const jProduct = JSON.parse(r.outData[0].configJson||"{\"Main\":{}}");
          setProductConfig(jProduct);
          setProduct(r.outData[0]);
        }catch(error){
          message.error(t("Invalid product configuration. Please check console."));
          console.log(r.outData[0].configJson, error);
          const jProduct = JSON.parse("{\"Main\":{}}");
          setProductConfig(jProduct);
          setProduct({...r.outData[0],configJson:JSON.stringify(jProduct)});
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewProduct = (product) => {
    setNewProductVisible(false);
    //redirecting to the new product
    window.location = "#/configurator/" + product.code;
  };
  const onSave = () => {
    console.log("onSave", productConfig, changes);
    changes.forEach((p) => {
      if(p.type=="yaml"){
        try{
          setObjectValue(productConfig, JSON.parse(p.value), p.path);
        }catch(error){
          message.error(t("Yaml field validation error. Please check console."));
          console.log(productConfig, error);
          return;
        }
      }else{
        setObjectValue(productConfig, p.value, p.path);
      }
    });
    const updatedProductConfig = productConfig;
    console.log("updatedProductConfig", updatedProductConfig, changes);
    try {
      product.config = yaml.safeDump(updatedProductConfig, { lineWidth: 600 });
      product.configJson = JSON.stringify(updatedProductConfig);
    } catch (error) {
      message.error(t("Validation error. Please check console."));
      console.log(productConfig, error);
      return;
    }

    setLoading(true);
    exe("RepoProduct", { operation: "UPDATE", entity: product }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setProductConfig(JSON.parse(r.outData[0].configJson));
        setProduct(r.outData[0]);
        setChanges([]);
      } else {
        message.error(r.msg);
      }
    });
  };

  const getObjectValue = (obj, path) => {
    if (!obj || !path) return undefined;

    // Split the path into parts, handling both array notation [...] and dot notation
    const parts = path.split(/\.|\[|\]/).filter(part => part !== '');

    try {
      return parts.reduce((current, part) => {
        // Handle both array indices and object properties
        if (current === undefined || current === null) return undefined;

        // If part is a number, treat as array index
        const index = parseInt(part);
        if (!isNaN(index) && Array.isArray(current)) {
          return current[index];
        }

        return current[part];
      }, obj);
    } catch (error) {
      console.warn(`Error accessing path ${path}:`, error);
      return undefined;
    }
  };
  const setObjectValue = (obj, value, path) => {
    // Split path into parts, filtering out empty strings
    const parts = path.split(/\.|\[|\]/).filter(Boolean);
    
    let current = obj;
    let parent = null;
    let prop = null;
    
    // Walk through all parts except the last one
    for (let i = 0; i < parts.length - 1; i++) {
      let key = parts[i];
      const nextKey = parts[i + 1];
      const isKeyNumeric = /^\d+$/.test(key);
      const isNextNumeric = /^\d+$/.test(nextKey);
      
      // Convert numeric keys to numbers
      if (isKeyNumeric) {
        key = Number(key);
        // If we expect an array but current isn't one, create one.
        if (!Array.isArray(current)) {
          if (parent && prop !== null) {
            parent[prop] = [];
            current = parent[prop];
          } else {
            // For root level numeric paths, override the object
            obj = [];
            current = obj;
          }
        }
      }
      
      // If the property doesn't exist, create it.
      // Create an array if the next key is numeric; otherwise, create an object.
      if (current[key] === undefined) {
        current[key] = isNextNumeric ? [] : {};
      }
      
      // Move one level deeper
      parent = current;
      prop = key;
      current = current[key];
    }
    
    // Handle the final key where the value will be assigned
    let lastKey = parts[parts.length - 1];
    if (/^\d+$/.test(lastKey)) {
      lastKey = Number(lastKey);
    }
    current[lastKey] = value;
  };
  const onChange = (v, path, type) => {
    console.log("onChange", v, path, type);
    const exists = changes.find((p) => p.path == path);
    if (exists) {
      exists.value = v;
      setChanges([...changes.filter((p) => p.path !== path), exists]);
    } else {
      const change = { path: path, value: v, type: type };
      setChanges([...changes, change]);
    }
  };

  const getCurrentValue = (path) => {
    //checking if the path has changed, if so, return the changed value, otherwise return the current value of the productConfig
    const exists = changes.find((p) => p.path == path);
    if (exists) {
      return exists.value;
    } else {
      const objValue= getObjectValue(productConfig, path);
      if(typeof objValue == "object") {
        return JSON.stringify(objValue);
      }
      return objValue;
    }
  };

  const onEditTab = (key, operation, path,tabIndex) => {
    let fullParentPath=path;
    if(tabIndex!==undefined){
      const pathParts=path.split("[]");
      fullParentPath=pathParts[0]+"["+tabIndex+"]"+pathParts[1];
    }
    console.log("onEditTab", operation,key, path,tabIndex,fullParentPath);
    if (operation == "add") {
      const arrayValue=getObjectValue(productConfig,fullParentPath)||[];
      if(arrayValue.length==0){
        //if empty, user has not added index 0 yet to productConfig. Adding 2 objects
        console.log("getting template value for",path);
        arrayValue.push({},{});
      }else{
        const newItem = arrayValue[arrayValue.length-1]; //copying the last item
        arrayValue.push({...newItem});
        console.log("arrayValue",arrayValue);
      }

      //setting value to productConfig
      setObjectValue(productConfig,arrayValue,fullParentPath);
      setProductConfig({ ...productConfig });
    }else if (operation == "remove") {
      if(key.includes(".")){
        const compositeKey=key.split(".");
        //compositeKey is a composite key eg: ['Coverages[]', 'benefits[]', '0', '1']--> Coverages[0].benefits[1]
        //so parentPath is Coverages[0].benefits and fullPath is Coverages[0].benefits[1]
        const fullPath=[];  
        const indexes=compositeKey.filter((p)=>!p.includes("[]")&&!isNaN(p));
        for(let i=0;i<compositeKey.length;i++){
          if(compositeKey[i].includes("[]")){
            fullPath.push(compositeKey[i].replaceAll("[]","["+indexes.shift()+"]"));
          }else if(!isNaN(compositeKey[i])){
            //ignoring
          }else{
            fullPath.push(compositeKey[i]);
          }
        }
        //parentpath is the fullpath with only the last [x] removed
        const parentPath=[...fullPath];
        parentPath[parentPath.length-1]=parentPath[parentPath.length-1].split("[")[0];

        const parent=getObjectValue(productConfig,parentPath.join("."));
        //removing the element at the index of the fullPath
        const indexToRemove=getInteger(fullPath.pop().toString());
        parent.splice(indexToRemove,1);  
        setProductConfig({ ...productConfig });
      }else{
        //back compatibility 
        const pathWithoutIndex = path.replaceAll("[]", "");
        const parent = getObjectValue(productConfig, pathWithoutIndex);
        const index=key.split(".").pop();

        //removing the tab from the productConfig
        parent.splice(index,1);
        setProductConfig({ ...productConfig });
      }
    }
  };
  const getInteger = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0]) : null;
  };
  
  const getArrayItems = (arrayPath) => {
    //getting the array items from the productConfig. arrayPath has the form of Coverages[].Loading[].code
    //const arrayItems = eval("productConfig."+arrayPath.replaceAll("[]",""));
    const arrayItems=getObjectValue(productConfig,arrayPath)||[{}]; //if empty, return an empty object to force tabitem rendering
    return arrayItems;
  }
const getPathWithIndex=(path,index)=>{
  if(index==undefined) return path;
  //if the index contains a dot, it is a composite index (used for subArrays), so we have to replace the first [] with the first index and the second [] with the second index, etc..
  //eg Coverages[].benefits[].eventaTimes 1.0 -> Coverages[1].benefits[0].eventaTimes
  
  if(index&&index.toString().includes(".")){
    const indexParts=index.toString().split(".");
    //if the first index part is undefied, this is an object followed by an array. eg CustomPayPlan.Detail[].order undefined.0 -> CustomPayPlan.Detail[0].order
    if(indexParts[0]==undefined){
      indexParts.shift();
    }
    let currentPath=path;
    indexParts.forEach((p)=>{
      currentPath=currentPath.replace("[]","["+p+"]");
    });
    //console.log("currentPath",currentPath,path,index);
    return currentPath;
  }
  //replacing the last [] with the index
  const lastIndex = path.lastIndexOf("[]"); 
  if(lastIndex==-1) return path;
  const completePath=path.substring(0,lastIndex)+"["+index+"]"+path.substring(lastIndex+2);
  return completePath;
}
  //---------------------------------- NODE RENDERING ----------------------------------
  const renderNode = (node,parentName="",tabIndex=undefined) => {
    //recursively builds a tree of nodes from the schemaTree
    if (!node) return null;
    const keys = Object.keys(node);
    if (keys.length == 0) return null;
    const isLeaf = keys[0] == "label";
    const isArray = parentName.includes("[]");
    const levels=parentName.split("[]").length;
    const isSubArray = isArray&&levels>2;
    const isObjectArray = isArray&&!isSubArray&&parentName.split(".").length>1;

    if (!isLeaf) {
      let arrayItems;
      const tabChildren = [];
      if(isArray) {
        const pathParts=parentName.split("[]");
        if(pathParts.length==2){  
          arrayItems = getArrayItems(parentName);
        }else{
          const parentPath=pathParts[0];
          const arrayPath=pathParts[1];
          const finalPath=parentPath+"["+tabIndex+"]."+arrayPath.replaceAll("[]",""); 
          arrayItems = getArrayItems(finalPath);
        }
      }
      //getting the property name from the node and iterating over it
      const children = [];
      keys.forEach((k) => {
        const isSection = sections.includes(k);
        const name = k.replaceAll("[]", "");
        children.push(<div>
          {isSection&&<Typography.Title level={3} id={name}>{name}</Typography.Title>}  
          {!isArray&&renderNode(node[k],parentName==""?k:parentName+"."+k)}
        </div>);
      });
      if(isArray) {
        (Array.isArray(arrayItems)?arrayItems:[]).forEach((p,index)=>{
          const tIndex=isSubArray?tabIndex+"."+index:index;
          tabChildren.push(<Tabs.TabPane tab={isSubArray?<span style={{fontSize:12}}>{index+1}<FileOutlined style={{fontSize:12,marginLeft:5}} /></span>:<span style={{fontSize:12}}>{index+1}<FolderOutlined style={{fontSize:12,marginLeft:5}} /></span>} key={parentName + "." + tIndex} closable>
            {keys.map((k)=>{
              return <div key={parentName+"."+tIndex+"."+k}>
                {renderNode(node[k],parentName==""?k:parentName+"."+k,tIndex)}
              </div>
            })}
          </Tabs.TabPane>);
        });
      }
      
      return <div>
        {!isArray && children}
        {isArray && <div>
          {isObjectArray&&<Typography.Title level={4}>{parentName.split('.').pop().replace('[]','')}</Typography.Title>} 
          {isSubArray&&<Typography.Title level={4}>{parentName.split('.').pop().replace('[]','')}</Typography.Title>} 
          <Tabs type="editable-card" onEdit={(e, operation) => onEditTab(e, operation,parentName,tabIndex)} style={{padding:10}}  >
            {tabChildren}
          </Tabs>
        </div>}

      </div>;

    } else {
      //leaf, rendering field according to schema
      return <Leaf node={node} tabIndex={tabIndex} />

    }
  }
  //---------------------------------- LEAF RENDERING ----------------------------------
  const Leaf = ({node,tabIndex}) => {
    const currentValue=getCurrentValue(getPathWithIndex(node.path,tabIndex));
    if(hideEmptySections&&!currentValue){
      return null;
    }
    return <div key={node.path+"-"+tabIndex} style={{ marginLeft: 15, marginTop: 10 }}>
      <Typography.Text strong>{formatFieldLabel(node.label)}</Typography.Text>
      <div>
        <Typography.Text type="secondary">{node.help}</Typography.Text>
      </div>
      <EditableFormField
        key={`${node.path}-input`}
        value={currentValue}
        dirty={changes.find((p) => p.path == getPathWithIndex(node.path,tabIndex))}
        type={node.type}
        onChange={(v) => onChange(v, getPathWithIndex(node.path,tabIndex), node.type)}
        />
    </div>
  }
  const formatFieldLabel=(fieldName)=>{
    if(fieldName.includes("[].")) return fieldName.split("[].")[1]; //subArrays
    return fieldName;
  }
 
  //---------------------------------- END OF LEAF RENDERING ----------------------------------
  const sections = Object.keys(schemaTree);
  // Memoize the rendered tree
  const renderedTree = useMemo(() => {
    if(Object.keys(productConfig).length == 0) return null;
    if(Object.keys(schemaTree).length == 0) return null;
    
    console.log("Re-rendering schema tree due to changes");
    console.log("changes", changes);
    console.log("schemaTree", schemaTree);
    console.log("productConfig", productConfig);
    //removing from schemaTree the sections that are not in productConfig 
    if(hideEmptySections){
      const filteredSchemaTree = {};
      Object.keys(schemaTree).forEach((section) => {
        if(productConfig[section.replaceAll("[]", "")]) {
          filteredSchemaTree[section] = schemaTree[section];
        }
      });
      return renderNode(filteredSchemaTree);
    }else{
      return renderNode(schemaTree);
    }
  }, [changes, schemaTree,productConfig,hideEmptySections]); // Dependencies array includes changes and schemaTree

  return (
    <DefaultPage
      title={t("Product Inspector")}
      subTitle={productCode}
      icon="dropbox"
      onBack={() => (window.location = "#/products")}
      routes={{
        routes: [
          { path: "/", breadcrumbName: t("Home") },
          { path: "/products", breadcrumbName: t("Products") },
          { path: "", breadcrumbName: t("Product") },
        ],
      }}
      extra={
        <div>
          <Button type="link" icon={<FilterOutlined />} onClick={() => {setHideEmptySections(!hideEmptySections);}}>{hideEmptySections?t("Show Empty"):t("Hide Empty")}</Button>
          <Button type="link" icon={<CodeOutlined />} onClick={() =>{//redirecting to product editor
            window.location = "#/products/" + productCode;
          }}>{t("Advanced Mode")}</Button>
          <Button icon={<PlusOutlined />} onClick={() => setNewProductVisible(true)} style={{ marginRight: 3 }}>
            {t("New")}
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => onSave()} loading={loading}>
            {t("Save")}
          </Button>
        </div>
      }>
      <div id="configuratorDiv">
      <BackTop 
        target={() => document.getElementById('my-scroll-layout')}
        style={{ right: 24, bottom: 24 }}
      />

        {/* <Row gutter={16}>
        <Col span={4}></Col>
        <Col span={16}>
          <Input.Search placeholder="input search text" onSearch={(value) => console.log(value)} enterButton size="large" style={{ marginBottom: 15 }} />
        </Col>
      </Row> */}
        <Row gutter={16}>
          <Col span={4}>
            <Anchor affix showInkInFixed getContainer={() => document.getElementById("my-scroll-layout")}>
              {sections.filter((section) => !hideEmptySections||productConfig[section.replaceAll("[]", "")]).map((section) => (
                <div>
                  <HashLink to={"#" + section.replaceAll("[]", "")} smooth>
                    <Anchor.Link href={"#" + section.replaceAll("[]", "")} title={<span style={{ fontSize: 12 }}>{section.replaceAll("[]", "")}{section.includes("[]") ? <FolderOpenOutlined style={{ fontSize: 12, margin: 5 }} /> : ""}{productConfig[section.replaceAll("[]", "")]&&<Badge style={{margin:5}} color="blue" />}</span>} />
                  </HashLink>
                </div>
              ))}
            </Anchor>
          </Col>
          <Col span={20}>
            <div id="my-scroll-layout" style={{ height: "80vh", overflowY: "scroll" }}>
              <div>
                {renderedTree || <Skeleton active />}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <NewProduct visible={newProductVisible} onOk={onNewProduct} onCancel={() => setNewProductVisible(false)} />
    </DefaultPage>
  );
};

export default Configurator;
