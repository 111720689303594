import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Usuarios from "./Usuarios";
import Grupos from "./Grupos";
import CatalogEditor from "./CatalogEditor";
import DefaultPage from "../Shared/DefaultPage";
import ConfigProfile from "./ConfigProfile";
import { useTranslation } from "react-i18next";
import UserChangeHistory from "./UserChangeHistory";
import OrganizationStructure from "../Settings/OrganizationStructure";
import VersionUpdate from "./VersionUpdate";
import { safeValueRaw } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";

const { TabPane } = Tabs;

const Configuracion = ({ configProfile, allMenuItems }) => {
  const [enableVersionManager, setEnableVersionManager] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getConfigProfile().then(profile => {
      const enableVersionManager = safeValueRaw("Main.enableVersionManager", profile, false);
      setEnableVersionManager(enableVersionManager);
    });
  }, []);


  return (
    <DefaultPage title={t("Config")} icon="setting">
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("Users")} key="1">
          <Usuarios configProfile={configProfile} />
        </TabPane>
        <TabPane tab={t("Groups")} key="2">
          <Grupos allMenuItems={allMenuItems} />
        </TabPane>
        <TabPane tab={t("Catalogs")} key="3">
          <CatalogEditor />
        </TabPane>
        <TabPane tab={t("Advanced")} key="f">
          <ConfigProfile />
        </TabPane>
        <TabPane tab={t("History")} key="history">
          <UserChangeHistory />
        </TabPane>
        <TabPane tab={t("Organization Structure")} key="orgStructure">
          <OrganizationStructure />
        </TabPane>
        <TabPane tab={t("Version Manager")} key="versionManager" disabled={!enableVersionManager}>
          <VersionUpdate />
        </TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Configuracion;
