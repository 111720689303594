import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Switch, Checkbox, Input, Button, Popconfirm, message, Drawer, Form, Select, DatePicker, Tag } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort, formatDate } from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import ReversalDetail from "../Financial/ReversalDetail";
import UserSelect from "../Bpm/UserSelect";
import { getQueryParam } from "../../Lib/Dal";
const AllocationTaskList = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [reversalRecord, setReversalRecord] = useState();
  const [pagination, setPagination] = useState({pageSize:10, current:1, showTotal: (total) => `Total ${total} items` });
  const [matches, setMatches] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [form] = Form.useForm();

  // Check URL for allocationId on component mount
  useEffect(() => {
    const allocationId = getQueryParam("id");
    if (allocationId) {
      form.setFieldsValue({ id: allocationId });
      submitSearch({ id: allocationId });
    }
  }, []);

  useEffect(() => {
    const allocationId = getQueryParam("id");
    if (allocationId) {
      form.setFieldsValue({ id: allocationId });
      submitSearch({ id: allocationId });
    }else load();
  }, [props.refresh,pagination.current]);

  const load = () => {
    setLoading(true);
    exe("RepoAllocation", { operation: "GET", include: ["InstallmentPremiums", "SupplementaryPremiums"],size:pagination.pageSize,page:pagination.current-1 }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setPagination({...pagination,total:r.total});
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  const onRevert = (allocation) => {
    setLoading(true);
    exe("UnDoPaymentAllocation", { allocationId: allocation.id,
        reversalCause:allocation.reversalCause, 
        reversalSubcause:allocation.reversalSubcause, 
        jReversalFormValues:allocation.jReversalFormValues,
        creditFundsToAccountId:allocation.creditFundsToAccountId,
        workspaceId:allocation.workspaceId
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Allocation reverted");
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
    const onReversalDetail=reversalFormValues=>{
        const allocationToBeReverted={...reversalRecord,...reversalFormValues};
        onRevert(allocationToBeReverted);
        setReversalRecord(undefined);
    }
    const onChange=(pag)=>{
        setPagination({...pagination,current:pag.current});
    }

  const submitSearch = (values) => {
    setLoading(true);
    let filter = [];
    
    if (values.id) filter.push(`id=${values.id}`);
    if (values.currency) filter.push(`currency='${values.currency}'`);
    if (values.user) filter.push(`[user]='${values.user}'`);
    if (values.reverted !== undefined){
      if(values.reverted) filter.push(`reversalDate IS NOT NULL`); 
      else filter.push(`reversalDate IS NULL`); 
    } 
    if (values.automatic !== undefined) filter.push(`automatic=${values.automatic?1:0}`);
    if (values.date) filter.push(`YEAR(date)=${values.date.format('YYYY')} AND MONTH(date)=${values.date.format('M')} AND DAY(date)=${values.date.format('D')}`); //search only day,month,year
    
    const filterString = filter.length > 0 ? filter.join(" AND ") : "";
    
    exe("RepoAllocation", { 
      operation: "GET", 
      include: ["InstallmentPremiums", "SupplementaryPremiums"],
      filter: filterString,
      size: pagination.pageSize,
      page: 0
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setPagination({...pagination, current: 1, total: r.total});
        setMatches(r.total);
        if (props.onClose) props.onClose();
      } else {
        message.error(r.msg);
      }
    });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const resetForm = () => {
    form.resetFields();
  };

  return (
    <div>
        <Button 
          type="link" 
          icon={<SearchOutlined />} 
          onClick={showDrawer}
        >
          {t("Filter Allocations")}
        </Button>
      <Table
        dataSource={data}
        rowKey="id"
        pagination={pagination}
        onChange={onChange}
        loading={loading}
        expandedRowRender={(record) => (
          <div style={{ margin: 0 }}>
            <b>{t("Installment Premiums")}:</b>
            <div>
              {record.InstallmentPremiums.map((p) => (
                <div>
                  <span style={{ marginLeft: 5 }}>
                    {t("Ref")}:{p.payPlanId} {t("Amount")}:{p.dueAmount} ({p.moneyInAmount}+{p.transitAmount}+{p.compensationAmount}) {p.currency} {t("Policy")}
                    :<a href={"#/lifePolicy/" + p.lifePolicyId}>{p.lifePolicyId}</a>
                    {p.compensationType && (
                      <span style={{ marginLeft: 5 }}>
                        {t("Compensation Type")}: {p.compensationType} {t("Compensation Amount")}: {p.compensationAmount}
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
            <b>{t("Supplementary Premiums")}:</b>
            <div>
              {record.SupplementaryPremiums.map((p) => (
                <div>
                  <span style={{ marginLeft: 5 }}>
                    {t("Dest")}:{p.destination} {t("Amount")}:{p.moneyInAmount}+{p.transitAmount} {p.currency} :
                    {p.lifePolicyId && (<span style={{ marginLeft: 5 }}>
                      {t("Policy")}:<a href={"#/lifepolicy/" + p.lifePolicyId}>{p.lifePolicyId}</a>
                    </span>)}
                    {p.accountId && (<span style={{ marginLeft: 5 }}>
                      {t("Account")}:<a href={"#/account/" + p.accountId}>{p.accountId}</a>
                    </span>)}
                    
                  </span>
                </div>
              ))}
            </div>
            {record.reverted && (
              <div>
                <b style={{ color: "red" }}>{t("Reversion Date")}:</b>
                {formatDate(record.reversalDate)}
              </div>
            )}
          </div>
        )}>
        <Table.Column
          title={t("ID")}
          dataIndex="id"
          render={(v) => (props.mark && props.mark == v ? <Text mark>{v}</Text> : v)}
          {...getColumnSearchProps("id")}
          //defaultFilteredValue={props.mark && [props.mark]}
        />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("Automatic")} dataIndex="automatic" render={(v) => <Checkbox checked={v} />} />
        <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
        <Table.Column title={t("Transfer")} dataIndex="transferAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("From Transit")} dataIndex="fromTransitAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Compensation")} dataIndex="compensationAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Premium")} dataIndex="premiumAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Supplementary")} dataIndex="supplementaryAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Difference")} dataIndex="differenceAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("User")} dataIndex="user" />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div>
              {r.reverted ? (
                <span style={{ color: "red" }}>Reverted</span>
              ) : (
                  <Button type="link" icon={<UndoOutlined />} onClick={()=>setReversalRecord(r)}>
                    {t("Revert")}
                  </Button>
              )}
            </div>
          )}
        />
      </Table>
        <ReversalDetail visible={reversalRecord} onCancel={()=>setReversalRecord(undefined)} onOk={r=>onReversalDetail(r)} selected={reversalRecord} allocation />
        <div>
      <Drawer title={t("Allocation Search")} placement="right" width={512} onClose={closeDrawer} visible={drawerVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={resetForm}>
            {t("Reset")}
          </Button>
        </div>

        <Form 
          form={form}
          layout="vertical" 
          onFinish={submitSearch}
        >
          <Form.Item label={t("ID")} name="id">
            <Input placeholder={t("Allocation ID")} />
          </Form.Item>
          
          <Form.Item label={t("Date")} name="date">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          
          <Form.Item label={t("Currency")} name="currency">
           <Currency />
          </Form.Item>
          
          <Form.Item label={t("User")} name="user">
            <UserSelect />
          </Form.Item>
          
          <Form.Item label={t("Reverted")} name="reverted">
            <Select allowClear placeholder={t("Reverted status")}>
              <Select.Option value={true}>{t("Yes")}</Select.Option>
              <Select.Option value={false}>{t("No")}</Select.Option>
            </Select>
          </Form.Item>
          
          <Form.Item label={t("Automatic")} name="automatic">
            <Select allowClear placeholder={t("Automatic status")}>
              <Select.Option value={true}>{t("Yes")}</Select.Option>
              <Select.Option value={false}>{t("No")}</Select.Option>
            </Select>
          </Form.Item>
          
          <Form.Item>
            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={loading}>
              {t("Search")}
            </Button>
          </Form.Item>
        </Form>
        
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
    </div>
  );
};

export default AllocationTaskList;
