import React from "react";

import {
  DollarOutlined,
  EditOutlined,
  FileOutlined,
  FilePdfOutlined,
  FormOutlined,
  MedicineBoxOutlined,
  SaveOutlined,
  ToolOutlined,
  UndoOutlined,
  CheckOutlined,
  FundOutlined
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Switch,
  Typography,
  Select,
  Slider,
  Table,
  Divider,
  Tooltip,
  Modal,
} from "antd";
import { exe } from "Lib/Dal";
import ProviderAgreements from "./ProviderAgreements";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";
import CustomForm from "../Shared/CustomForm";
import { safeGet } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";
import ProviderDocs from "./ProviderDocs";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ProviderTypeSelect from "./ProviderTypeSelect";
import Status from "../Shared/Status";
import ProviderChanges from "./ProviderChanges";
import SpecialtySelect from "./SpecialtySelect";
import NetworkSelect from "./NetworkSelect";
import AreaServedSelect from "./AreaServedSelect";
import ProviderRequirements from "./ProviderRequirements";
import {computeFormula} from "../../Lib/Helpers";
import ProviderClaimsFunds from "../Provider/ProviderClaimsFunds";
import AreaServedDetailSelect from "./AreaServedDetailSelect";
const { Text, Title } = Typography;
const ButtonGroup = Button.Group;
const { Column } = Table;

class ProviderManagement extends React.Component {
  state = {
    loading: false,
    activeTab: "01",
    customFormId: undefined,
    isMedical: true,
    process: null,
    fieldRules: [],
    customValidators: [],
    autoCodeGeneration: false,
    hasSector: false
  };
  componentDidMount() {
    if (this.props.match.params.providerId != "undefined") this.load(this.props.match.params.providerId); //initial load
    getConfigProfile().then((profile) => {
      this.setState({
        customFormId: safeGet(["Providers", "customFormId"], profile, undefined),
        contactRoles: safeGet(["Providers", "contactRoles"], profile, undefined),
        autoCodeGeneration: !!safeGet(["Providers", "providerCode"], profile, false),
        hasSector: !!safeGet(["Contacts", "hasSector"], profile, false)
      });
    });
    this.loadFieldRules();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.providerId !== this.props.match.params.providerId) this.load(this.props.match.params.providerId); //url update
  }

  save = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validating custom form
        if (window.customProviderForm && window.customProviderForm.instanceContainers&&window.customProviderForm.instanceContainers[0]) {
          if (window.customProviderForm.instanceContainers[0].reportValidity()) {
            values.jCustomForm = JSON.stringify(window.customProviderForm.userData);
          } else {
            message.error("Please check custom form validation errors");
            this.setState({ activeTab: "05" });
            return;
          }
        }
        //data transform - ini
        values.specialties = JSON.stringify(values.specialties);
        values.areaServed = JSON.stringify(values.areaServed);
        values.areaServedDetail = JSON.stringify(values.areaServedDetail);
        values.networks = JSON.stringify(values.networks);
        //data transform - end
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        const me = this;
        exe("RepoProvider", {
          entity: values,
          operation: this.state.operation,
        }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            if (this.state.operation == "ADD") window.location.hash = "#/provider/" + r.outData[0].code;
          } else {
            if (r.changeRequest) {
              const before = {};
              const after = {};
              r.unauthorized.forEach((element) => {
                before[element] = r.outData[0][element];
                after[element] = r.data.entity[element];
              });
              this.props.form.setFieldsValue(before); //reverting unauth changes
              Modal.confirm({
                title: this.props.t("Restricted change"),
                content: r.msg,
                onOk() {
                  const change = {
                    providerCode: r.outData[0].code,
                    jBefore: JSON.stringify(before),
                    JAfter: JSON.stringify(after),
                    operation: r.changeOperation,
                  };
                  exe("AddProviderChange", change).then((r) => {
                    if (r.ok) {
                      message.success(r.msg);
                      me.props.form.setFieldsValue({ Changes: r.outData });
                    } else {
                      message.error(r.msg);
                    }
                  });
                },
              });
            } else {
              message.error(r.msg);
            }
          }
        });
      } else {
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fiedls in all tabs"));
      }
    });
  };
  new = () => {
    window.location.hash = "#/provider/0";
    this.setState({ process: null });
  };
  reset = () => {
    this.props.form.resetFields();
  };
  load = (code) => {
    if (code == 0) {
      this.setState({ operation: "ADD" });
      this.props.form.resetFields();
      return;
    }
    this.setState({ loading: true });
    exe("RepoProvider", {
      operation: "GET",
      include: ["Process", "Changes", "Changes.Process"],
      filter: "code='" + code + "'",
    }).then((r) => {
      this.setState({ loading: false, operation: "UPDATE" });
      if (r.ok) {
        const d = r.outData[0];
        //data transform - ini
        d.specialties = d.specialties ? JSON.parse(d.specialties) : undefined;
        d.areaServed = d.areaServed ? JSON.parse(d.areaServed) : undefined;
        d.areaServedDetail = d.areaServedDetail ? JSON.parse(d.areaServedDetail) : undefined;
        d.networks = d.networks ? JSON.parse(d.networks) : undefined;
        //data transform - end
        this.props.form.setFieldsValue(d);
        this.setState({ process: d.Process });
      } else {
        message.error(r.msg);
      }
    });
  };

  onTabChange = (v) => this.setState({ activeTab: v });
  onLocationSearch = (v) => {
    window.open(v ? v : "https://www.google.com/maps", "_blank");
  };
  onSelectContact=(contact,values)=>{
    try {
      //this.setState({loading: true});
      exe("GetContacts", {filter: `id=${contact.id}`, include: ['Addresses', 'Phones']}).then(r => {
        //this.setState({loading: false});
        if (r.ok) {
          //copying same values as contact if no data entered
          const contactData = r.outData[0];
          //location
          if (contactData.Addresses.length) {
            if (!values.address) {
              this.props.form.setFieldsValue({address: `${contactData.Addresses[0].address1} ${contactData.Addresses[0].address2}`});
            }
            if (!values.country && contactData.Addresses[0].country) {
              exe("RepoCountryCatalog", {
                operation: "GET",
                filter: `code='${contactData.Addresses[0].country}'`
              }).then(q => {
                if (q.ok&&q.total>0) {
                  this.props.form.setFieldsValue({country: q.outData[0].name});
                }
              })
            }
            if (!values.state && contactData.Addresses[0].state) {
              exe("RepoStateCatalog", {
                operation: "GET",
                filter: `code='${contactData.Addresses[0].state}'`
              }).then(q => {
                if (q.ok&&q.total>0) {
                  this.props.form.setFieldsValue({state: q.outData[0].name});
                }
              })
            }
            if (!values.city && contactData.Addresses[0].city) {
              //when sector is enabled, in city we expect the city code, when not, we expect the city id
              let filter = `id=${contactData.Addresses[0].city}`;
              if (this.state.hasSector) filter = `code='${contactData.Addresses[0].city}'`;
              exe("RepoCityCatalog", {operation: "GET", filter}).then(q => {
                if (q.ok&&q.total>0) {
                  this.props.form.setFieldsValue({city: q.outData[0].name});
                }
              })
            }
          }
          //contact
          if (!values.email) {
            this.props.form.setFieldsValue({email: contactData.email});
          }
          //phones
          if (contactData.Phones.length) {
            if (!values.phone) {
              this.props.form.setFieldsValue({phone: contactData.Phones[0].num});
            }
          }

        }
      })

    }catch (e) {
      console.error(e)
    }
  }
   loadFieldRules = () => {
      exe("RepoFieldRule", { operation: "GET", filter: "entity='PROVIDER'" }).then((r) => {
        if (r.ok) {
          this.setState({fieldRules:r.outData});
        }
      });
    };
    evalInContext(js, context) {
      return function () {
        return eval(js);
      }.call(context);
    }
    isHidden= (fieldName) => {
      const field = this.state.fieldRules.find((p) => p.field == fieldName);
      if (!field) return false;
      if (field.hidden) return true;
      if(field.hiddenCondition){
        return computeFormula(field.hiddenCondition,{entity:this.props.form.getFieldsValue()})
      }
      return false;
    };
    FormItem = (props) => {
      let componentStyle=props.style||{};
      let hidden=false;
      //safely getting field name
      if(props.children&&props.children.props&&props.children.props.id){
        const fieldName=props.children.props.id;
        hidden=fieldName?this.isHidden(fieldName):false
        if(hidden){
          console.log("Hiding field",fieldName);
          componentStyle={...componentStyle,display:"none"}
        }
      }
      return <Form.Item {...props} style={componentStyle}>{props.children}</Form.Item>
    }

  render() {
    const { t, i18n } = this.props;
    const providerCode = this.props.match.params.providerId;
    const values = this.props.form.getFieldsValue();
    const FormItem=this.FormItem;

    const getFieldDecorator =(fieldName,config)=>{
      const rules=[];
      if(config&&config.rules) rules.push(...config.rules);

      //custom fields catalog rules
      const fieldRule=this.state.fieldRules.find(p=>p.field===fieldName);
      if(fieldRule){
        //required
        if(fieldRule.required){
          if(fieldRule.required.toLowerCase()==="true") rules.push({required:true,message:this.props.t(fieldName)+" "+this.props.t("is required") });
          else if(fieldRule.required.includes("this.")){
            try {
              const result=this.evalInContext(fieldRule.required, {entity:values});
              if(result) rules.push({required:true,message:this.props.t(fieldName)+" "+this.props.t("is required") });
            } catch (error) {
              console.error("Error parsing required rule for " + fieldName, error);
            }
          }
        }

        //front validation
        if(fieldRule.frontValidation){
          try {
            fieldRule.frontValidation=this.evalInContext(fieldRule.frontValidation, {}); //parsing string to function
            const parsed = fieldRule.frontValidation;
            parsed[0].entity=values;//injecting provider values to rule
            const validator = parsed[0].validator;
            if (validator && typeof validator === "string" && validator.slice(0, 1) == "_") {
              //custom validator
              const validatorName = validator.split("_").pop().split('"')[0];
              const customValidator = this.state.customValidators.find((p) => p.name == validatorName);
              if (customValidator) parsed[0].validator = customValidator.function;
            }
            //emsuring that the custom validator is wrapped inside a try-catch and return cb() in csae of error
            parsed[0].validator = (rule, value, callback) => {
              try {
                parsed[0].validator(rule, value, callback);
              } catch (error) {
                callback(`Error in custom validator: ${error.message}`);
              }
            };
            rules.push(...parsed);
          } catch (error) {
            console.error("Error parsing configured rule for " + fieldName, error);
          }
        }
      }
      return this.props.form.getFieldDecorator(fieldName,{...config,rules});
    }
   

    return (
      <div style={{}}>
        <DefaultPage
          title={t("Provider Management")}
          icon="medicine-box"
          loading={this.state.loading}
          tags={<Status process={this.state.process} reload={() => this.load(values.code)} />}
          extra={
            <Row type="flex" justify="end" gutter={12}>
              <Col />
              <Col>
                <ButtonGroup>
                  <Button onClick={this.new} icon={<FileOutlined />}>
                    {t("New")}
                  </Button>
                  <Button onClick={this.save} icon={<SaveOutlined />}>
                    {t("Save")}
                  </Button>
                  <Button onClick={this.reset} icon={<UndoOutlined />}>
                    {t("Reset")}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          }>
          {getFieldDecorator("processId")}
          <Tabs activeKey={this.state.activeTab} onChange={this.onTabChange}>
            <Tabs.TabPane
              tab={
                <span>
                  <MedicineBoxOutlined />
                  {t("Provider")}
                </span>
              }
              key="01">
              {/* ////////////////////////////////TOP MENU ////////////////////////////////////////////////////////////////// */}

              <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Title level={4}>{t("Info")}</Title>
                <Row gutter={16}>
                  {/* ////////////////////////////////ROW1-COL1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <FormItem label={t("Contact")}>
                      {getFieldDecorator("contactId", {
                        rules: [{ required: false }],
                      })(
                        <SimpleContactSelect
                          showLink allowNew onSelectContact={(contact)=>this.onSelectContact(contact,values)}
                          filter={
                            this.state.contactRoles && this.state.contactRoles.length > 0
                              ? `id in (SELECT contactId from ContactRole WHERE role in (${this.state.contactRoles.map((p) => `'${p}'`).join(",")})) AND `
                              : undefined
                          }
                        />
                      )}
                    </FormItem>
                    <FormItem label={t("Display Name")}>
                      {getFieldDecorator("name", {
                        rules: [{ required: true }],
                      })(<Input placeholder={t("Full provider name")} />)}
                    </FormItem>
                    <div style={{ display: values.providerSubType == "MEDICAL" ? "block" : "none" }}>
                      <FormItem label={t("Specialties")}>
                        {getFieldDecorator("specialties", {
                          rules: [{ required: false }],
                        })(<SpecialtySelect />)}
                      </FormItem>
                    </div>
                    <FormItem label={t("Notes")}>
                      {getFieldDecorator("notes", {
                        rules: [{ required: false }],
                      })(<Input.TextArea autosize placeholder={t("Optional notes")} />)}
                    </FormItem>
                  </Col>
                  {/* ////////////////////////////////ROW1-COL2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("Provider Type")}>
                      {getFieldDecorator("providerSubType")}
                      {getFieldDecorator("providerType", { rules: [{ required: true }] })(
                        <ProviderTypeSelect onSelect={(v) => this.props.form.setFieldsValue({ providerSubType: v })} />
                      )}
                    </FormItem>
                    <FormItem label={values.providerSubType == "MEDICAL" ? t("Medical License Code") : t("Provider Code")}>
                      {getFieldDecorator("code", {
                        rules: [{ required: !this.state.autoCodeGeneration }],
                      })(<Input disabled={this.state.autoCodeGeneration} />)}
                    </FormItem>
                    {values.providerSubType == "MEDICAL" && (
                      <FormItem label={t("ID Card")}>
                        {getFieldDecorator("legalId", {
                          rules: [{ required: false }],
                        })(<Input />)}
                      </FormItem>
                    )}
                    <div style={{ display: values.providerSubType == "MEDICAL" ? "block" : "none" }}>
                      <FormItem label={t("Networks")}>
                        {getFieldDecorator("networks", {
                          rules: [{ required: false }],
                        })(<NetworkSelect />)}
                      </FormItem>
                    </div>
                  </Col>
                  {/* ////////////////////////////////ROW1-COL3 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("Active")}>
                      {getFieldDecorator("active", { valuePropName: "checked" })(
                        <Switch disabled={this.state.operation == "ADD" || (this.state.process && this.state.process.entityState !== "APROVED")} />
                      )}
                    </FormItem>
                    <FormItem label={t("Priority")}>
                      {getFieldDecorator("priority", {
                        rules: [{ required: false }],
                      })(
                        <Slider
                          marks={{
                            0: "F",
                            20: "E",
                            40: "D",
                            60: "C",
                            80: "B",
                            100: "A",
                          }}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Title level={4}>{t("Contact Info")}</Title>
                <Row gutter={16}>
                  {/* ////////////////////////////////ROW2-COL1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <FormItem label={t("Address")}>
                      {getFieldDecorator("address", {
                        rules: [{ required: false }],
                      })(<Input.TextArea autosize={{ minRows: 4 }} />)}
                    </FormItem>
                  </Col>
                  {/* ////////////////////////////////ROW2-COL2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("Phone")}>
                      {getFieldDecorator("phone", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                    <FormItem label={t("Cell Phone")}>
                      {getFieldDecorator("cell", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  {/* ////////////////////////////////ROW2-COL3 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("Email")}>
                      {getFieldDecorator("email", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                    <FormItem label={t("Fax")}>
                      {getFieldDecorator("fax", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
                <Title level={4}>{t("Location Info")}</Title>
                <Row gutter={16}>
                  {/* ////////////////////////////////ROW3-COL1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <FormItem label={t("Area Served")}>
                      {getFieldDecorator("areaServed", {
                        rules: [{ required: false }],
                      })(<AreaServedSelect />)}
                    </FormItem>
                    <FormItem label={t("Area Served Detail")}>
                      {getFieldDecorator("areaServedDetail", {
                        rules: [{ required: false }],
                      })(<AreaServedDetailSelect areaServedCode={values.areaServed} />)}
                    </FormItem>
                    <FormItem label={t("Location Map")}>
                      {getFieldDecorator("location", {
                        rules: [{ required: false }],
                      })(<Input.Search onSearch={this.onLocationSearch} />)}
                    </FormItem>
                  </Col>
                  {/* ////////////////////////////////ROW3-COL2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("City")}>
                      {getFieldDecorator("city", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                    <FormItem label={t("State")}>
                      {getFieldDecorator("state", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                  {/* ////////////////////////////////ROW3-COL3 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <FormItem label={t("Country")}>
                      {getFieldDecorator("country", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
            {/* ////////////////////////////////Agreements  ////////////////////////////////////////////////////////////////// */}
            <Tabs.TabPane
              tab={
                <span>
                  <ToolOutlined />
                  {t("Agreements")}
                </span>
              }
              key="02">
              <ProviderAgreements providerCode={providerCode} />
            </Tabs.TabPane>
            {/* ////////////////////////////////Billing  ////////////////////////////////////////////////////////////////// */}
            <Tabs.TabPane
              tab={
                <span>
                  <DollarOutlined />
                  {t("Billing")}
                </span>
              }
              key="04">
              <Table dataSource={[]}>
                <Column title={t("Procedure Code")} dataIndex="procedureCode" />
                <Column title={t("Procedure Name")} dataIndex="agreedPrice" />
                <Column title={t("Date")} dataIndex="publicPrice" />
                <Column title={t("Insured")} dataIndex="publicPrice" />
                <Column title={t("Total")} dataIndex="publicPrice" />
                <Column title={t("Not Covered")} dataIndex="publicPrice" />
                <Column title={t("Co Paid")} dataIndex="publicPrice" />
                <Column title={t("Refunded")} dataIndex="publicPrice" />
                <Column title={t("Notes")} dataIndex="notes" />
                <Column
                  title={t("Action")}
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                        {t("Open")}
                      </Button>
                      <Divider type="vertical" />
                    </span>
                  )}
                />
              </Table>
            </Tabs.TabPane>
            {/* ////////////////////////////////Custom  ////////////////////////////////////////////////////////////////// */}
            <Tabs.TabPane
              forceRender
              tab={
                <span>
                  <FormOutlined />
                  {t("Custom")}
                </span>
              }
              key="05">
              <Row gutter={16}>
                <Col span={12}>{getFieldDecorator("jCustomForm")(<CustomForm formId={this.state.customFormId} variable="customProviderForm" newRecord={!values.code} />)}</Col>
              </Row>
            </Tabs.TabPane>
             {/* ////////////////////////////////Requirements  ////////////////////////////////////////////////////////////////// */}
             <Tabs.TabPane
              tab={
                <span>
                  <CheckOutlined />
                  {t("Requirements")}
                </span>
              }
              key="requirements">
                <ProviderRequirements providerCode={this.props.match.params.providerId} gotoFiles={()=>this.setState({activeTab:"06"})} />
            </Tabs.TabPane>
            {/* ////////////////////////////////Files  ////////////////////////////////////////////////////////////////// */}
            <Tabs.TabPane
              tab={
                <span>
                  <FilePdfOutlined />
                  {t("Files")}
                </span>
              }
              key="06">
              <ProviderDocs providerCode={this.props.match.params.providerId} />
            </Tabs.TabPane>
            {/* ////////////////////////////////Changes  ////////////////////////////////////////////////////////////////// */}
            <Tabs.TabPane
              tab={
                <span>
                  <EditOutlined />
                  {t("Changes")}
                </span>
              }
              key="changes">
              <Title level={4}>{t("Change Requests")}</Title>
              {getFieldDecorator("Changes")(<ProviderChanges onRefresh={() => this.load(values.code)} />)}
            </Tabs.TabPane>
            {/* ////////////////////////////////Claims Funds  ////////////////////////////////////////////////////////////////// */}
                  <Tabs.TabPane
              tab={
                <span>
                  <FundOutlined />
                  {t("Claims Funds")}
                </span>
              }
              key="claimFunds">
                <ProviderClaimsFunds onRefresh={() => this.load(values.code)} providerCode={values.code} />
            </Tabs.TabPane>
          </Tabs>
        </DefaultPage>
      </div>
    );
  }
}

const WrappedForm = Form.create()(ProviderManagement);
export default withTranslation()(WrappedForm);
