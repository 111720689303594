import { DownOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Dropdown, Menu, Select, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PolicySelect from "../Health/PolicySelect";
import Money from "../Shared/Money";
import PremiumSelect from "./PremiumSelect";
import AccountSelect from '../Shared/AccountSelect';

const SupplementaryPremiumAllocation = (props) => {
  const [t] = useTranslation();

  const optionPayNextInstallment=()=>{
      props.onPayNextInstallment();
  }
  //const [rows, setRows] = useState([{ policy: null, destination: null, fromMoneyIn: undefined, fromTransit: undefined }]);
  const initialValue = { lifePolicyId: null, destination: null, moneyInAmount: 0, transitAmount: 0, currency: props.currency };
  const rows = props.value || [];
  const setRows = (v) => props.onChange(v);
  const hasAccountDestinitation = rows.find((r) => r.destination === "ACCOUNT");
  return (
    <div>
      {rows.map((row, index) => (
        <div style={{ display: "flex", width: "100%", marginTop: 5 }}>
          <div style={{ width: "100%" }}>
            {index === 0 && (t("Policy") + (hasAccountDestinitation?(" / "+t("Account")):""))}
            {row.destination!=="ACCOUNT"&&<PolicySelect
              value={row.lifePolicyId}
              proposals
              onSelect={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, lifePolicyId: v } : r)))}
            />}
            {row.destination=="ACCOUNT"&&<AccountSelect value={row.accountId} onSelect={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, accountId: v } : r)))} />}
          </div>
          <div style={{ width: "100%", marginLeft: 5 }}>
            {index === 0 && t("Destination")}
            <Select value={row.destination} onSelect={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, destination: v,transitAmount:v==="TRANSIT"?0:row.transitAmount } : r)))}>
              <Select.Option value="TOPUP">{t("Top-Up Account")}</Select.Option>
              <Select.Option value="TRANSIT">{t("Transit Account")}</Select.Option>
              <Select.Option value="ACCOUNT">{t("Specific Account")}</Select.Option>
            </Select>
          </div>
          <div style={{ width: "100%", marginLeft: 5 }}>
            {index === 0 && t("From Money In")}
            <Money
              value={row.moneyInAmount}
              onChange={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, moneyInAmount: v } : r)))}
              currency={row.currency}
              min={0}
            />
          </div>
          <div style={{ marginLeft: 5, width: "100%" }}>
            {index === 0 && t("From Transit Account")}
            <div style={{ display: "flex" }}>
              <Money
                value={row.transitAmount}
                onChange={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, transitAmount: v } : r)))}
                currency={row.currency}
                disabled={row.destination==="TRANSIT"}
                min={0}
                style={{ width: "100%" }}
              />
              <MinusCircleOutlined
                style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }}
                onClick={() => setRows(rows.filter((r, rIndex) => rIndex !== index))} />
            </div>
          </div>
        </div>
      ))}

      <Button type="link" icon={<PlusCircleOutlined />} style={{ marginTop: 5, marginLeft: 0 }} onClick={() => {
          //setting as default the difference amount to be allocated
          setRows([...rows, {...initialValue,moneyInAmount:props.differenceAmount}]);
      }}>
        {t("Add Row")}
      </Button>
        <Dropdown overlay={<Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" onClick={()=>optionPayNextInstallment()}>
                    {t("Pay next installment")}
                </a>
            </Menu.Item></Menu>}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Space>{t("Options")}<DownOutlined /></Space>
            </a>
        </Dropdown>
    </div>
  );
};

export default SupplementaryPremiumAllocation;
