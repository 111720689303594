import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  InputNumber,
  Checkbox,
  Row,
  Col,
  Divider,
  Modal,
  message
} from 'antd';
import {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  FileTextOutlined,
  ClockCircleOutlined,
  CarOutlined,
  UserOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { exe } from '../../Lib/Dal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

const ACTIVITY_TYPES = [
  { value: 'call', label: 'Call', icon: <PhoneOutlined /> },
  { value: 'email', label: 'Email', icon: <MailOutlined /> },
  { value: 'meeting', label: 'Meeting', icon: <TeamOutlined /> },
  { value: 'note', label: 'Note', icon: <FileTextOutlined /> },
  { value: 'task', label: 'Task', icon: <ClockCircleOutlined /> },
  { value: 'visit', label: 'Visit', icon: <CarOutlined /> },
  { value: 'message', label: 'Message', icon: <MessageOutlined /> }
];

const OUTCOME_OPTIONS = [
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' },
  { value: 'neutral', label: 'Neutral' }
];

const ActivityForm = ({ 
  visible, 
  contactId, 
  activity, 
  onCancel, 
  onSuccess 
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [selectedType, setSelectedType] = useState(activity?.type || 'note');
  const [showDueDate, setShowDueDate] = useState(!!activity?.dueDate);
  const isEditing = !!activity?.id;

  useEffect(() => {
    if (visible) {
      // Reset form when modal opens
      form.resetFields();
      
      if (activity) {
        // For editing, initialize form with activity data
        const formData = {
          ...activity,
          date: activity.date ? moment(activity.date) : moment(),
          dueDate: activity.dueDate ? moment(activity.dueDate) : null
        };
        
        form.setFieldsValue(formData);
        setSelectedType(activity.type || 'note');
        setShowDueDate(!!activity.dueDate);
      } else {
        // For new activity, initialize with defaults
        form.setFieldsValue({
          contactId,
          type: 'note',
          date: moment(),
          completed: false,
          createdBy: sessionStorage.getItem('username') || ''
        });
        setSelectedType('note');
        setShowDueDate(false);
      }
    }
  }, [visible, activity, contactId, form]);

  const handleTypeChange = (value) => {
    setSelectedType(value);
    
    // Pre-fill title based on type
    const currentTitle = form.getFieldValue('title');
    if (!currentTitle || currentTitle === '') {
      form.setFieldsValue({
        title: t(`New ${value}`)
      });
    }
  };

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        setLoading(true);
        
        // Format dates
        const formattedValues = {
          ...values,
          date: values.date ? values.date.format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
          dueDate: showDueDate && values.dueDate ? values.dueDate.format('YYYY-MM-DD HH:mm:ss') : null,
          contactId: contactId
        };
        
        // Add id for updates
        if (isEditing) {
          formattedValues.id = activity.id;
        }
        
        // Remove fields that aren't relevant to the selected type
        if (selectedType !== 'task') {
          delete formattedValues.completed;
        }
        
        if (!showDueDate) {
          delete formattedValues.dueDate;
        }
        
        if (selectedType !== 'call' && selectedType !== 'meeting') {
          delete formattedValues.duration;
        }
        
        // Save the activity
        exe("RepoFunnelContactActivity", {
          operation: isEditing ? "UPDATE" : "ADD",
          entity: formattedValues
        }).then(response => {
          setLoading(false);
          if (response.ok) {
            message.success(
              isEditing ? 
                t('Activity updated successfully') : 
                t('Activity logged successfully')
            );
            onSuccess(response.outData?.[0]);
          } else {
            console.error("Failed to save activity:", response);
            message.error(response.msg || t('Failed to save activity'));
          }
        }).catch(error => {
          console.error("Error saving activity:", error);
          setLoading(false);
          message.error(t('Error saving activity'));
        });
      });
  };

  return (
    <Modal
      title={isEditing ? t('Edit Activity') : t('Log New Activity')}
      open={visible}
      onCancel={onCancel}
      width={600}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {isEditing ? t('Update') : t('Save')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item name="contactId" hidden>
          <Input />
        </Form.Item>
        
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="type"
              label={t('Activity Type')}
              rules={[{ required: true, message: t('Please select activity type') }]}
            >
              <Select onChange={handleTypeChange}>
                {ACTIVITY_TYPES.map(type => (
                  <Option key={type.value} value={type.value}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {type.icon}
                      <span style={{ marginLeft: 8 }}>{t(type.label)}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="date"
              label={t('Date & Time')}
              rules={[{ required: true, message: t('Please select date and time') }]}
            >
              <DatePicker 
                showTime 
                format="YYYY-MM-DD HH:mm" 
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        
        <Form.Item
          name="title"
          label={t('Title')}
          rules={[{ required: true, message: t('Please enter a title') }]}
        >
          <Input placeholder={t('e.g., Call with client about proposal')} />
        </Form.Item>
        
        <Form.Item
          name="description"
          label={t('Description')}
        >
          <TextArea
            rows={4}
            placeholder={t('Enter detailed notes about the activity')}
          />
        </Form.Item>
        
        <Row gutter={16}>
          {(selectedType === 'call' || selectedType === 'meeting') && (
            <Col span={12}>
              <Form.Item
                name="duration"
                label={t('Duration (minutes)')}
              >
                <InputNumber 
                  min={1} 
                  style={{ width: '100%' }} 
                  placeholder={t('e.g., 30')}
                />
              </Form.Item>
            </Col>
          )}
          
          <Col span={12}>
            <Form.Item
              name="outcome"
              label={t('Outcome')}
            >
              <Select placeholder={t('Select outcome')} allowClear>
                {OUTCOME_OPTIONS.map(option => (
                  <Option key={option.value} value={option.value}>
                    {t(option.label)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        
        <Form.Item
          name="nextSteps"
          label={t('Next Steps')}
        >
          <Input placeholder={t('What needs to happen next?')} />
        </Form.Item>
        
        <Divider style={{ margin: '8px 0 16px' }} />
        
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              valuePropName="checked"
            >
              <Checkbox 
                checked={showDueDate} 
                onChange={(e) => setShowDueDate(e.target.checked)}
              >
                {t('Set due date/reminder')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        
        {showDueDate && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="dueDate"
                label={t('Due Date')}
                rules={[{ required: showDueDate, message: t('Please select a due date') }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            
            {selectedType === 'task' && (
              <Col span={12}>
                <Form.Item
                  name="completed"
                  valuePropName="checked"
                  label={t('Status')}
                >
                  <Checkbox>{t('Mark as completed')}</Checkbox>
                </Form.Item>
              </Col>
            )}
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default ActivityForm; 