import React, { useEffect, useState } from 'react';
import { 
  Avatar, 
  Button, 
  Card, 
  Dropdown, 
  message, 
  Space, 
  Spin, 
  Tag, 
  Tooltip, 
  Progress,
  Typography,
  Statistic,
  Select,
  Modal,
  Form,
  Input,
  Divider,
  Alert
} from 'antd';
import { 
  LeftOutlined, 
  RightOutlined, 
  EditOutlined, 
  DownOutlined, 
  PlusOutlined, 
  ReloadOutlined, 
  DollarOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  FilterOutlined,
  SettingOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { exe, getQueryParam } from '../../Lib/Dal';
import DefaultPage from '../Shared/DefaultPage';
import { useTranslation } from 'react-i18next';
import ContactEdit from './ContactEdit';
import StageEdit from './StageEdit';
import FunnelFilter from './FunnelFilter';
import FunnelSettings from './FunnelSettings';
import moment from 'moment';

const { Title, Text } = Typography;
const { Option } = Select;

const FunnelBoard = (props) => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [funnels, setFunnels] = useState([]);
  const [selectedFunnel, setSelectedFunnel] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showStageModal, setShowStageModal] = useState(false);
  const [stageBeingDragged, setStageBeingDragged] = useState(null);
  const [creatingFunnel, setCreatingFunnel] = useState(false);
  const [newFunnelName, setNewFunnelName] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [showSettings, setShowSettings] = useState(false);
  const [t] = useTranslation();
  const [funnelStats, setFunnelStats] = useState({
    totalContacts: 0,
    totalValue: 0,
    avgDealSize: 0,
    conversionRate: 0
  });
  const [newFunnelModalVisible, setNewFunnelModalVisible] = useState(false);
  const [funnelForm] = Form.useForm();
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [settingsForm] = Form.useForm();
  const [filterParams, setFilterParams] = useState(null);
  const [defaultTags, setDefaultTags] = useState([]);

  // Load available funnels on component mount
  useEffect(() => {
    loadFunnels();
  }, []);

  // Load funnel data when a funnel is selected
  useEffect(() => {
    if (selectedFunnel) {
      console.log(`Selected funnel changed to: ${selectedFunnel}`);
      // Reset state when funnel changes to avoid showing wrong data during load
      setState([]);
      loadFunnelData(selectedFunnel);
    }
  }, [selectedFunnel]);

  // Update the settingsModalVisible state handler to initialize the form when opened
  useEffect(() => {
    if (settingsModalVisible && selectedFunnel) {
      const currentFunnel = funnels.find(f => f.id === selectedFunnel);
      if (currentFunnel) {
        settingsForm.setFieldsValue({
          name: currentFunnel.name,
          description: currentFunnel.description,
          contactTag: currentFunnel.contactTag
        });
      }
    }
  }, [settingsModalVisible, selectedFunnel, funnels]);

  // Load available tags for import
  useEffect(() => {
    // Load common tags from contact repository using GetContactTags
    exe("GetContactTags", {}).then(r => {
      if (r.ok && r.outData && r.outData.tags) {
        setDefaultTags(r.outData.tags);
      } else {
        // Fallback to a set of default tags
        setDefaultTags(['Lead', 'Customer', 'Hot', 'Cold', 'Pending', 'VIP', 'Enterprise', 'SMB']);
      }
    }).catch(() => {
      // Fallback to a set of default tags
      setDefaultTags(['Lead', 'Customer', 'Hot', 'Cold', 'Pending', 'VIP', 'Enterprise', 'SMB']);
    });
  }, []);

  const loadFunnels = () => {
    setLoading(true);
    console.log("Loading all funnels...");
    
    // Return the Promise from exe
    return exe("RepoFunnel", { 
      operation: "GET" 
    }).then(r => {
      setLoading(false);
      if (r.ok && r.outData) {
        console.log("Loaded funnels:", r.outData);
        setFunnels(r.outData);
        
        // Select the first funnel if available and none is selected
        if (r.outData.length > 0 && !selectedFunnel) {
          console.log("Selecting first funnel:", r.outData[0].id);
          setSelectedFunnel(r.outData[0].id);
        } else if (r.outData.length === 0) {
          // No funnels available, prompt to create one
          message.info(t('No funnels available. Please create a new funnel.'));
        }
        
        return r.outData; // Return the loaded data
      } else {
        console.error("Failed to load funnels:", r);
        message.error(t('Failed to load funnels'));
        return Promise.reject(new Error('Failed to load funnels'));
      }
    }).catch(err => {
      console.error("Error loading funnels:", err);
      setLoading(false);
      message.error(t('Error loading funnels'));
      return Promise.reject(err);
    });
  };

  const loadFunnelData = (funnelId) => {
    if (!funnelId) {
      console.error("Attempted to load funnel data with no funnel ID");
      return;
    }
    
    // Reset state immediately when switching funnels
    setState([]);
    setLoading(true);
    
    console.log(`Loading funnel data for funnel ID: ${funnelId}`);
    
    // First, load stages for the selected funnel
    exe("RepoFunnelStage", {
      operation: "GET",
      filter: `funnelId=${funnelId}`
    }).then(stagesResponse => {
      if (stagesResponse.ok && stagesResponse.outData) {
        // Process stages data
        const stages = stagesResponse.outData.map(stage => ({
          ...stage,
          contacts: [] // Initialize empty contacts array for each stage
        }));
        
        console.log(`Loaded ${stages.length} stages for funnel ${funnelId}:`, stages);
        
        if (stages.length === 0) {
          setLoading(false);
          setState([]);
          message.info(t('This funnel has no stages. Create stages to get started.'));
          return;
        }
        
        // Now, load contacts in a separate request
        exe("RepoFunnelContact", {
          operation: "GET",
          filter: `funnelId=${funnelId}`,
          include: ["Contact"]
        }).then(contactsResponse => {
          setLoading(false);
          
          if (contactsResponse.ok && contactsResponse.outData) {
            const contacts = contactsResponse.outData;
            console.log(`Loaded ${contacts.length} contacts for funnel ${funnelId}`);
            
            // Create a new copy of stages with contacts assigned to correct stages
            const stagesWithContacts = [...stages];
            
            // Group contacts by stage
            contacts.forEach(contact => {
              const stageIndex = stagesWithContacts.findIndex(s => s.id === contact.stageId);
              if (stageIndex !== -1) {
                stagesWithContacts[stageIndex].contacts.push(contact);
              } else {
                console.warn(`Contact ${contact.id} has stageId ${contact.stageId} which doesn't exist in funnel ${funnelId}`);
              }
            });
            
            console.log(`Final stages with contacts for funnel ${funnelId}:`, stagesWithContacts);
            setState(stagesWithContacts);
            calculateFunnelStats(stagesWithContacts);
          } else {
            // Even if contacts fail to load, still show the stages
            setState(stages);
            calculateFunnelStats(stages);
            console.warn("Failed to load contacts:", contactsResponse);
            message.error(t('Failed to load contacts'));
          }
        }).catch(err => {
          console.error("Error loading contacts:", err);
          setLoading(false);
          // Still show stages even if contacts fail to load
          setState(stages);
          calculateFunnelStats(stages);
          message.error(t('Error loading contacts'));
        });
      } else {
        console.error("Failed to load stages:", stagesResponse);
        setLoading(false);
        setState([]);
        message.error(t('Failed to load funnel stages'));
      }
    }).catch(err => {
      console.error("Error loading stages:", err);
      setLoading(false);
      setState([]);
      message.error(t('Error loading funnel stages'));
    });
  };

  const handleCreateFunnel = () => {
    funnelForm.validateFields().then(values => {
      setLoading(true);
      
      // Create new funnel without contactTag
      exe("RepoFunnel", {
        operation: "ADD",
        entity: {
          name: values.name,
          description: values.description || ''
        }
      }).then(response => {
        if (response.ok && response.outData && response.outData.length > 0) {
          const newFunnel = response.outData[0];
          
          // First create the stages, then import contacts
          createDefaultStages(newFunnel.id)
            .then(() => {
              console.log("Stages created successfully, now importing contacts if needed");
              
              // Now that stages exist, we can import contacts if a tag was specified
              const importTag = values.importTag;
              if (importTag) {
                console.log(`Importing contacts with tag: ${importTag}`);
                return exe("ImportContacts", {
                  funnelId: newFunnel.id,
                  tag: importTag
                });
              }
              return Promise.resolve();
            })
            .then(() => {
              console.log("Import complete, loading funnels");
              // Refresh funnels list
              return loadFunnels();
            })
            .then(() => {
              // Set the newly created funnel as selected
              setSelectedFunnel(newFunnel.id);
              message.success(t('Funnel created successfully'));
              setNewFunnelModalVisible(false);
              funnelForm.resetFields();
              setLoading(false);
            })
            .catch(error => {
              console.error("Error in funnel creation process:", error);
              // Still mark as successful if the funnel was created, even if stages failed
              message.warning(t('Funnel created but there was an issue setting up stages.'));
              loadFunnels();
              setSelectedFunnel(newFunnel.id);
              setNewFunnelModalVisible(false);
              funnelForm.resetFields();
              setLoading(false);
            });
        } else {
          message.error(response.msg || t('Failed to create funnel'));
          setLoading(false);
        }
      }).catch(error => {
        console.error("Error creating funnel:", error);
        message.error(t('Error creating funnel'));
        setLoading(false);
      });
    });
  };

  const createDefaultStages = (funnelId) => {
    if (!funnelId) {
      console.error("Cannot create default stages: Missing funnel ID");
      return Promise.reject(new Error("Missing funnel ID"));
    }

    console.log(`Creating default stages for funnel ${funnelId}`);
    
    const defaultStages = [
      { name: "Lead", color: "#e6f7ff", order: 10000, probability: 20, status: 0, active: true },
      { name: "Qualified", color: "#f0f0f0", order: 20000, probability: 40, status: 0, active: true },
      { name: "Proposal", color: "#fffbe6", order: 30000, probability: 60, status: 1, active: true },
      { name: "Negotiation", color: "#fff2e8", order: 40000, probability: 80, status: 1, active: true },
      { name: "Closed Won", color: "#f6ffed", order: 50000, probability: 100, status: 2, active: true },
      { name: "Closed Lost", color: "#fff1f0", order: 60000, probability: 0, status: 3, active: true }
    ];
    
    // Add funnelId to each stage
    const stages = defaultStages.map(stage => ({
      ...stage,
      funnelId
    }));
    
    console.log(`Preparing to create ${stages.length} default stages for funnel ${funnelId}`);
    
    // Make this function return a Promise
    return new Promise((resolve, reject) => {
      // Create a function to add stages one by one
      const addStage = (index) => {
        if (index >= stages.length) {
          // All stages added
          message.success(t('Default stages created'));
          resolve(); // Resolve the Promise when all stages are created
          return;
        }
        
        // Add the current stage
        exe("RepoFunnelStage", {
          operation: "ADD",
          entity: stages[index]
        }).then(r => {
          if (r.ok) {
            console.log(`Created stage ${index+1}/${stages.length} for funnel ${funnelId}`);
            // Continue with the next stage
            addStage(index + 1);
          } else {
            console.error(`Failed to create stage ${index+1}/${stages.length}:`, r);
            message.error(t(`Failed to create stage ${stages[index].name}`));
            // Continue with the next stage even if this one failed
            addStage(index + 1);
          }
        }).catch(err => {
          console.error(`Error creating stage ${index+1}/${stages.length}:`, err);
          // Continue with the next stage even if this one failed
          addStage(index + 1);
        });
      };
      
      setLoading(true);
      // Start adding stages
      addStage(0);
    });
  };

  const calculateFunnelStats = (stagesData = state) => {
    const totalContacts = stagesData.reduce((acc, stage) => acc + stage.contacts.length, 0);
    const totalValue = stagesData.reduce(
      (acc, stage) => acc + stage.contacts.reduce((sum, contact) => sum + (+contact.value || 0), 0), 
      0
    );
    const wonStages = stagesData.filter(s => s.status === 2); // Status 2 is for 'win'
    const wonContacts = wonStages.reduce((acc, stage) => acc + stage.contacts.length, 0);
    
    setFunnelStats({
      totalContacts,
      totalValue,
      avgDealSize: totalContacts > 0 ? totalValue / totalContacts : 0,
      conversionRate: totalContacts > 0 ? (wonContacts / totalContacts) * 100 : 0
    });
  };

  // Handle drag end event
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      // Reordering within the same column
      const stagesCopy = [...state];
      const stage = stagesCopy[sInd];
      const [removed] = stage.contacts.splice(source.index, 1);
      stage.contacts.splice(destination.index, 0, removed);
      
      setState(stagesCopy);
    } else {
      // Moving between columns
      const stagesCopy = [...state];
      const sourceStage = stagesCopy[sInd];
      const destStage = stagesCopy[dInd];
      const [removed] = sourceStage.contacts.splice(source.index, 1);
      
      // Update contact with new stage id
      const updatedContact = {
        ...removed,
        stageId: destStage.id,
        // Update probability based on destination stage
        probability: destStage.probability
      };
      
      destStage.contacts.splice(destination.index, 0, updatedContact);
      setState(stagesCopy);
      
      // Save the updated contact to the database
      exe("RepoFunnelContact", {
        operation: "UPDATE",
        entity: updatedContact
      }).then(r => {
        if (!r.ok) {
          message.error(t('Failed to update contact stage'));
          // Revert the UI change if save fails
          loadFunnelData(selectedFunnel);
        }
      });
    }
    
    // Recalculate funnel stats after drag
    calculateFunnelStats();
  };

  const addNewStage = () => {
    if (!selectedFunnel) {
      message.warning(t('Please select a funnel first'));
      return;
    }
    
    // Create a new stage
    const newStage = {
      funnelId: selectedFunnel,
      name: t("New Stage"),
      color: "#f0f0f0",
      order: Math.max(...state.map(s => +s.order || 0), 0) + 10000,
      probability: 50,
      active: true,
      status: 0,
      contacts: []
    };
    
    // Open the stage edit modal with the new stage
    setSelectedStage(newStage);
  };

  const addNewContact = (stageId) => {
    if (!selectedFunnel) {
      message.warning(t('Please select a funnel first'));
      return;
    }
    
    // Find the stage
    const stage = state.find(s => s.id === stageId);
    
    if (!stage || !stage.id) {
      message.error(t('Cannot add contact: Invalid stage. Please refresh the page and try again.'));
      return;
    }
    
    // Validate that the stage exists in the database
    setLoading(true);
    exe("RepoFunnelStage", {
      operation: "GET",
      filter: `id=${stageId}`
    }).then(r => {
      setLoading(false);
      
      if (r.ok && r.outData && r.outData.length > 0) {
        // Stage exists, create the contact
        const validatedStage = r.outData[0];
        
        // Create a new contact
        const newContact = {
          name: t("New Contact"),
          company: t("New Company"),
          value: 0,
          email: "",
          phone: "",
          probability: validatedStage.probability || 0,
          lastActivity: moment().format('YYYY-MM-DD'),
          tags: "",
          assignedTo: "",
          funnelId: selectedFunnel,
          stageId: validatedStage.id  // Use the validated stage ID from the database
        };
        
        setSelectedContact(newContact);
      } else {
        message.error(t('Cannot add contact: Stage not found in database. Please refresh and try again.'));
      }
    }).catch(err => {
      setLoading(false);
      console.error("Error validating stage:", err);
      message.error(t('Error validating stage'));
    });
  };

  const moveStage = (stage, direction) => {
    const sortedStages = [...state].sort((a, b) => a.order - b.order);
    const stageIndex = sortedStages.findIndex(s => s.id === stage.id);
    
    if (
      (direction === -1 && stageIndex === 0) || 
      (direction === 1 && stageIndex === sortedStages.length - 1)
    ) {
      return; // Already at the edge
    }
    
    const targetIndex = stageIndex + direction;
    const targetStage = sortedStages[targetIndex];
    
    // Swap orders
    const stagesCopy = [...state];
    const stageToMove = stagesCopy.find(s => s.id === stage.id);
    const targetStageInCopy = stagesCopy.find(s => s.id === targetStage.id);
    
    const tempOrder = stageToMove.order;
    stageToMove.order = targetStageInCopy.order;
    targetStageInCopy.order = tempOrder;
    
    // Update UI immediately
    setState(stagesCopy);
    
    // Save updated stages to the database one by one using UPDATE instead of MERGE
    setLoading(true);
    
    // First update the stage being moved
    exe("RepoFunnelStage", {
      operation: "UPDATE",
      entity: {
        id: stageToMove.id,
        order: stageToMove.order,
        funnelId: stageToMove.funnelId
      }
    }).then(r => {
      if (r.ok) {
        console.log(`Updated order for stage ${stageToMove.id} to ${stageToMove.order}`);
        
        // Then update the target stage
        return exe("RepoFunnelStage", {
          operation: "UPDATE",
          entity: {
            id: targetStageInCopy.id,
            order: targetStageInCopy.order,
            funnelId: targetStageInCopy.funnelId
          }
        });
      } else {
        setLoading(false);
        message.error(t('Failed to update stage order'));
        loadFunnelData(selectedFunnel);
        return { ok: false };
      }
    }).then(r => {
      setLoading(false);
      if (!r || !r.ok) {
        message.error(t('Failed to update target stage order'));
        loadFunnelData(selectedFunnel);
      } else {
        console.log(`Updated order for stage ${targetStageInCopy.id} to ${targetStageInCopy.order}`);
      }
    }).catch(err => {
      console.error("Error updating stage order:", err);
      setLoading(false);
      message.error(t('Error updating stage order'));
      loadFunnelData(selectedFunnel);
    });
  };

  const onStageMenuClick = (option, stage) => {
    switch (option.key) {
      case 'edit':
        setSelectedStage(stage);
        break;
      case 'left':
        moveStage(stage, -1);
        break;
      case 'right':
        moveStage(stage, 1);
        break;
      default:
        break;
    }
  };

  const handleSaveContact = (contact) => {
    loadFunnelData(selectedFunnel);
    setSelectedContact(null);
  };

  const handleDeleteContact = (contact) => {
    loadFunnelData(selectedFunnel);
    setSelectedContact(null);
  };

  const handleSaveStage = (stage) => {
    message.success(stage.id ? t('Stage updated successfully') : t('Stage created successfully'));
    loadFunnelData(selectedFunnel);
    setSelectedStage(null);
  };

  const handleDeleteStage = (stage) => {
    // Check if there are contacts in the stage
    if (stage.contacts && stage.contacts.length > 0) {
      message.error(t('Cannot delete stage with contacts. Move contacts to another stage first.'));
      return;
    }
    
    // Close the modal first to prevent double deletion
    setSelectedStage(null);
    
    // Delete stage from the database
    setLoading(true);
    exe("RepoFunnelStage", {
      operation: "DELETE",
      entity: {
        id: stage.id
      }
    }).then(r => {
      setLoading(false);
      if (r.ok) {
        message.success(t('Stage deleted successfully'));
        loadFunnelData(selectedFunnel);
      } else {
        console.error("Failed to delete stage:", r);
        message.error(t('Failed to delete stage'));
      }
    }).catch(err => {
      console.error("Error deleting stage:", err);
      setLoading(false);
      message.error(t('Error deleting stage'));
    });
  };

  // Fix the stageMenu to use the correct 'items' format for Ant Design v4+
  const stageMenu = (stage) => {
    return {
      items: [
        {
          key: 'edit',
          label: t('Edit'),
          icon: <EditOutlined />,
        },
        {
          key: 'left',
          label: t('Move Left'),
          icon: <LeftOutlined />,
        },
        {
          key: 'right',
          label: t('Move Right'),
          icon: <RightOutlined />,
        }
      ],
      onClick: (obj) => onStageMenuClick(obj, stage)
    };
  };

  // Drag and drop styles
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    borderRadius: "8px",
    boxShadow: isDragging ? "0 5px 15px rgba(0,0,0,0.1)" : "0 2px 6px rgba(0,0,0,0.05)",
    background: isDragging ? "#f0f8ff" : "white",
    ...draggableStyle
  });
  
  const getListStyle = (isDraggingOver, stageColor) => ({
    background: isDraggingOver ? "#f0f7ff" : stageColor || "#f9f9f9",
    padding: grid,
    width: 280,
    borderRadius: "8px",
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  });

  // Formatter for currency
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });

  const handleApplyFilter = (filterValues) => {
    console.log('Applying filters:', filterValues);
    setLoading(true);
    setFilterParams(filterValues);
    setFilterCriteria(filterValues); // Store filter values
    
    // Apply filters to the current funnel data
    if (selectedFunnel) {
      // Build filter string for backend filtering
      let filterString = `funnelId=${selectedFunnel}`;
      
      // Add assignedTo filter if specified
      if (filterValues.assignedTo) {
        filterString += ` AND assignedTo='${filterValues.assignedTo}'`;
      }
      
      // Load stages for the selected funnel
      exe("RepoFunnelStage", {
        operation: "GET",
        filter: `funnelId=${selectedFunnel}`
      }).then(stagesResponse => {
        if (stagesResponse.ok && stagesResponse.outData) {
          // Process stages data
          const stages = stagesResponse.outData.map(stage => ({
            ...stage,
            contacts: [] // Initialize empty contacts array for each stage
          }));
          
          console.log(`Loaded ${stages.length} stages for funnel ${selectedFunnel} with filter`);
          
          if (stages.length === 0) {
            setLoading(false);
            setState([]);
            message.info(t('This funnel has no stages. Create stages to get started.'));
            return;
          }
          
          // Now, load contacts with filters
          exe("RepoFunnelContact", {
            operation: "GET",
            filter: filterString,
            include: ["Contact"]
          }).then(contactsResponse => {
            setLoading(false);
            
            if (contactsResponse.ok && contactsResponse.outData) {
              let contacts = contactsResponse.outData;
              console.log(`Loaded ${contacts.length} contacts for funnel ${selectedFunnel} with filter`);
              
              // Log the structure of the first contact to help debugging
              if (contacts.length > 0) {
                console.log('First contact structure:', JSON.stringify(contacts[0]));
              }
              
              // Apply client-side filtering for searchText since it's not easily done in a SQL filter string
              if (filterValues.searchText) {
                const searchText = filterValues.searchText.toLowerCase();
                contacts = contacts.filter(contact => {
                  // Access properties directly if available, or through Contact relationship if not
                  const contactData = contact.Contact || contact;
                  return (
                    (contactData.name && contactData.name.toLowerCase().includes(searchText)) ||
                    (contactData.company && contactData.company.toLowerCase().includes(searchText)) ||
                    (contactData.email && contactData.email.toLowerCase().includes(searchText)) ||
                    (contactData.phone && contactData.phone.toLowerCase().includes(searchText)) ||
                    (contactData.notes && contactData.notes.toLowerCase().includes(searchText))
                  );
                });
                console.log(`Filtered to ${contacts.length} contacts after applying searchText`);
              }
              
              // Apply stage filter if specified
              if (filterValues.stages && filterValues.stages.length > 0) {
                contacts = contacts.filter(contact => 
                  filterValues.stages.includes(contact.stageId)
                );
                console.log(`Filtered to ${contacts.length} contacts after applying stage filter`);
              }
              
              // Apply tag filter if specified
              if (filterValues.tags && filterValues.tags.length > 0) {
                contacts = contacts.filter(contact => {
                  if (!contact.tags) return false;
                  const contactTags = contact.tags.split(',').map(tag => tag.trim());
                  return filterValues.tags.some(tag => contactTags.includes(tag));
                });
                console.log(`Filtered to ${contacts.length} contacts after applying tag filter`);
              }
              
              // Apply value range filter if specified
              if (filterValues.valueRange) {
                if (filterValues.valueRange.min) {
                  contacts = contacts.filter(contact => 
                    (contact.value || 0) >= filterValues.valueRange.min
                  );
                }
                if (filterValues.valueRange.max) {
                  contacts = contacts.filter(contact => 
                    (contact.value || 0) <= filterValues.valueRange.max
                  );
                }
                console.log(`Filtered to ${contacts.length} contacts after applying value range filter`);
              }
              
              // Apply probability range filter if specified
              if (filterValues.probabilityRange) {
                if (filterValues.probabilityRange.min) {
                  contacts = contacts.filter(contact => 
                    (contact.probability || 0) >= filterValues.probabilityRange.min
                  );
                }
                if (filterValues.probabilityRange.max) {
                  contacts = contacts.filter(contact => 
                    (contact.probability || 0) <= filterValues.probabilityRange.max
                  );
                }
                console.log(`Filtered to ${contacts.length} contacts after applying probability range filter`);
              }
              
              // Group contacts by stage
              const stagesMap = new Map(stages.map(stage => [stage.id, { ...stage, contacts: [] }]));
              
              contacts.forEach(contact => {
                if (stagesMap.has(contact.stageId)) {
                  const stage = stagesMap.get(contact.stageId);
                  stage.contacts.push(contact);
                } else {
                  console.warn(`Contact ${contact.id} has stageId ${contact.stageId} which doesn't exist in funnel ${selectedFunnel}`);
                }
              });
              
              // Convert map back to array
              const stagesWithContacts = Array.from(stagesMap.values());
              console.log(`Final filtered stages with contacts for funnel ${selectedFunnel}:`, stagesWithContacts);
              setState(stagesWithContacts);
              calculateFunnelStats(stagesWithContacts);
            } else {
              // Even if contacts fail to load, still show the stages
              setState(stages);
              calculateFunnelStats(stages);
              console.warn("Failed to load contacts:", contactsResponse);
              message.error(t('Failed to load contacts'));
            }
          }).catch(err => {
            console.error("Error loading contacts:", err);
            setLoading(false);
            // Still show stages even if contacts fail to load
            setState(stages);
            calculateFunnelStats(stages);
            message.error(t('Error loading contacts'));
          });
        } else {
          console.error("Failed to load stages:", stagesResponse);
          setLoading(false);
          setState([]);
          message.error(t('Failed to load funnel stages'));
        }
      }).catch(err => {
        console.error("Error loading stages:", err);
        setLoading(false);
        setState([]);
        message.error(t('Error loading funnel stages'));
      });
    } else {
      setLoading(false);
      message.warning(t('Please select a funnel first'));
    }
  };

  const clearFilters = () => {
    setLoading(true);
    setFilterParams(null);
    setFilterCriteria(null);
    
    // Reload original data
    loadFunnelData(selectedFunnel);
  };

  // Calculate avatar initials from name
  const getInitials = (name) => {
    if (!name) return '';
    return name.split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  // Add function to handle funnel deletion
  const handleDeleteFunnel = () => {
    if (!selectedFunnel) {
      message.warning(t('No funnel selected'));
      return;
    }
    
    setLoading(true);
    exe("RepoFunnel", {
      operation: "DELETE",
      entity: {
        id: selectedFunnel
      }
    }).then(r => {
      setLoading(false);
      if (r.ok) {
        message.success(t('Funnel deleted successfully'));
        
        // Remove the deleted funnel from the list
        const updatedFunnels = funnels.filter(funnel => funnel.id !== selectedFunnel);
        setFunnels(updatedFunnels);
        
        // Clear the selected funnel
        setSelectedFunnel(updatedFunnels.length > 0 ? updatedFunnels[0].id : null);
        
        // Close the modals
        setDeleteConfirmVisible(false);
        setSettingsModalVisible(false);
      } else {
        console.error("Failed to delete funnel:", r);
        message.error(t('Failed to delete funnel'));
      }
    }).catch(err => {
      console.error("Error deleting funnel:", err);
      setLoading(false);
      message.error(t('Error deleting funnel'));
    });
  };

  // Add function to handle funnel update
  const handleUpdateFunnel = () => {
    if (!selectedFunnel) {
      message.warning(t('No funnel selected'));
      return;
    }
    
    settingsForm.validateFields().then(values => {
      setLoading(true);
      
      // Get the current funnel to preserve all fields
      const currentFunnel = funnels.find(f => f.id === selectedFunnel);
      
      exe("RepoFunnel", {
        operation: "UPDATE",
        entity: {
          ...currentFunnel,
          ...values,
          id: selectedFunnel
        }
      }).then(r => {
        setLoading(false);
        if (r.ok && r.outData && r.outData.length > 0) {
          const updatedFunnel = r.outData[0];
          message.success(t('Funnel updated successfully'));
          
          // Update the funnel in the list
          const updatedFunnels = funnels.map(f => 
            f.id === selectedFunnel ? updatedFunnel : f
          );
          setFunnels(updatedFunnels);
          
          setSettingsModalVisible(false);
        } else {
          console.error("Failed to update funnel:", r);
          message.error(t('Failed to update funnel'));
        }
      }).catch(err => {
        console.error("Error updating funnel:", err);
        setLoading(false);
        message.error(t('Error updating funnel'));
      });
    });
  };

  // Add function to handle adding a contact to the first stage
  const addContactToFirstStage = () => {
    if (!selectedFunnel) {
      message.warning(t('Please select a funnel first'));
      return;
    }
    
    // Sort stages by order and get the first stage
    const sortedStages = [...state].sort((a, b) => a.order - b.order);
    
    if (sortedStages.length === 0) {
      message.warning(t('No stages available. Please create a stage first.'));
      return;
    }
    
    const firstStage = sortedStages[0];
    
    // Use the existing addNewContact function with the first stage's ID
    addNewContact(firstStage.id);
  };

  // Add a function to handle showing the settings modal
  const handleShowSettings = () => {
    if (!selectedFunnel) {
      message.error(t('Please select a funnel first'));
      return;
    }
    
    const currentFunnel = funnels.find(f => f.id === selectedFunnel);
    if (currentFunnel) {
      setShowSettings(true);
    }
  };
  
  // Add a function to handle saving settings
  const handleSaveSettings = (updatedFunnel) => {
    setShowSettings(false);
    
    // If funnel was deleted (updatedFunnel is null)
    if (!updatedFunnel) {
      // Reset state and reload funnels
      setState([]);
      setSelectedFunnel(null);
      loadFunnels();
      return;
    }
    
    // Update the funnel in the list
    const updatedFunnels = funnels.map(f => 
      f.id === updatedFunnel.id ? updatedFunnel : f
    );
    setFunnels(updatedFunnels);
    
    // Reload funnel data to reflect any imported contacts
    loadFunnelData(selectedFunnel);
  };
  
  // Update the main actions above the board to include settings
  const renderActions = () => {
    return (
      <Space size="middle">
        <Button 
          onClick={() => setShowFilter(true)}
          icon={<FilterOutlined />}
        >
          {t('Filter')}
        </Button>
        
        <Button 
          onClick={handleShowSettings}
          icon={<SettingOutlined />}
        >
          {t('Settings')}
        </Button>
        
        <Button 
          type="primary" 
          onClick={addNewStage}
          icon={<PlusOutlined />}
        >
          {t('Add Stage')}
        </Button>
      </Space>
    );
  };

  return (
    <DefaultPage
      title={t("Sales Funnel")}
      icon="funnel-plot"
      loading={loading}
      extra={
        <Space size="middle">
          <Select
            style={{ width: 250 }}
            placeholder={t("Select funnel")}
            value={selectedFunnel}
            onChange={(value) => {
              console.log(`Changing selected funnel to: ${value}`);
              setSelectedFunnel(value);
            }}
          >
            {funnels.map(funnel => (
              <Option key={funnel.id} value={funnel.id}>{funnel.name}</Option>
            ))}
          </Select>
          
          <Button
            icon={<PlusOutlined />}
            onClick={() => setNewFunnelModalVisible(true)}
          >
            {t("New Funnel")}
          </Button>
          
          <Button
            icon={<ReloadOutlined />}
            onClick={() => selectedFunnel && loadFunnelData(selectedFunnel)}
            disabled={!selectedFunnel}
          >
            {t("Refresh")}
          </Button>
          
          <Button
            icon={<FilterOutlined />}
            onClick={() => setShowFilter(true)}
          >
            {t("Filter")}
          </Button>
          
          <Button
            icon={<SettingOutlined />}
            onClick={handleShowSettings}
            disabled={!selectedFunnel}
          >
            {t("Settings")}
          </Button>
        </Space>
      }
    >
      <div style={{ marginBottom: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
          <div>
            <Title level={4}>{t("Pipeline Overview")}</Title>
            {filterParams && (
              <div style={{ fontSize: '12px', color: '#888' }}>
                {t("Filtered view - showing")} {state.reduce((acc, stage) => acc + stage.contacts.length, 0)} {t("contacts")}
              </div>
            )}
              <Button 
            type="primary" 
            icon={<UserOutlined />} 
            onClick={addContactToFirstStage}
            style={{ marginRight: 8 }}
          >
            {t("New Contact")}
          </Button>
               <Button 
            style={{ marginLeft: 8 }} 
            icon={<FilterOutlined />} 
            onClick={() => setShowFilter(true)}
          >
            {t("Filter")}
          </Button>
          {filterParams && (
            <Button style={{ marginLeft: 8 }} onClick={clearFilters}>
              {t("Clear Filters")}
            </Button>
          )}
          </div>
          <div style={{ display: 'flex', gap: 24 }}>
            <Statistic 
              title={t("Total Contacts")} 
              value={funnelStats.totalContacts} 
              prefix={<UserOutlined />} 
            />
            <Statistic 
              title={t("Total Pipeline Value")} 
              value={currencyFormatter.format(funnelStats.totalValue)} 
              prefix={<DollarOutlined />} 
            />
            <Statistic 
              title={t("Avg Deal Size")} 
              value={currencyFormatter.format(funnelStats.avgDealSize)} 
              precision={0} 
              prefix={<DollarOutlined />} 
            />
            <Statistic 
              title={t("Conversion Rate")} 
              value={funnelStats.conversionRate} 
              precision={1} 
              suffix="%" 
            />
          </div>
        </div>
      </div>

      <style>
        {`
          .funnel-scroll-container {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            flex-grow: 1;
            min-height: 0;
            padding: 8px;
            gap: 16px;
            background-color: rgb(248, 249, 251);
            scroll-behavior: smooth;
            border-radius: 8px;
          }
          .funnel-scroll-container::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 8px;
          }
          .funnel-scroll-container::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
          }
          .funnel-scroll-container::-webkit-scrollbar-thumb {
            background: #bfbfbf;
            border-radius: 4px;
          }
          .funnel-scroll-container::-webkit-scrollbar-thumb:hover {
            background: #a6a6a6;
          }
          .contact-card:hover {
            transform: translateY(-2px);
            transition: all 0.2s;
          }
        `}
      </style>

      {selectedFunnel ? (
        <div className="funnel-scroll-container" style={{ height: 'calc(100vh - 250px)' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {state
              .sort((a, b) => a.order - b.order)
              .map((stage, ind) => (
                <Droppable key={ind} droppableId={`${ind}`}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        ...getListStyle(snapshot.isDraggingOver, stage.color),
                        flex: '0 0 280px',
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '100%',
                        border: '1px solid #f0f0f0',
                      }}
                      {...provided.droppableProps}
                    >
                      <div style={{ 
                        display: "flex", 
                        justifyContent: "space-between", 
                        alignItems: "center",
                        marginBottom: '8px',
                        borderBottom: '1px solid #f0f0f0',
                        padding: '0 0 8px 0'
                      }}>
                        <Title level={5} style={{ margin: 0 }}>
                          {stage.name} 
                          <Text type="secondary" style={{ fontSize: '14px', marginLeft: '8px' }}>
                            ({stage.contacts.length})
                          </Text>
                        </Title>
                        <Space>
                          <Button 
                            size="small" 
                            type="text" 
                            onClick={() => addNewContact(stage.id)} 
                            icon={<PlusOutlined />} 
                          />
                          <Dropdown menu={stageMenu(stage)}>
                            <Button size="small" type="text" icon={<DownOutlined />} />
                          </Dropdown>
                        </Space>
                      </div>
                      
                      <div style={{ 
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        flexGrow: 1,
                        minHeight: 0,
                        paddingRight: '4px',
                      }}>
                        {stage.contacts.map((contact, index) => (
                          <Draggable
                            key={contact.id || `new-${index}`}
                            draggableId={`contact-${contact.id || `new-${index}`}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className="contact-card"
                                onClick={() => setSelectedContact(contact)}
                              >
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar style={{ backgroundColor: '#1890ff', marginRight: 8 }}>
                                      {getInitials(contact.name)}
                                    </Avatar>
                                    <div>
                                      <div style={{ fontWeight: 'bold' }}>{contact.name}</div>
                                      <div style={{ fontSize: '12px', color: '#888' }}>{contact.company}</div>
                                    </div>
                                  </div>
                                  <div>
                                    <Text strong style={{ color: '#1890ff' }}>
                                      {currencyFormatter.format(contact.value || 0)}
                                    </Text>
                                  </div>
                                </div>
                                
                                <div style={{ fontSize: '12px', marginBottom: 8 }}>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
                                    <MailOutlined style={{ fontSize: '12px', marginRight: 4, color: '#888' }} />
                                    <Text ellipsis style={{ width: '100%' }}>{contact.email}</Text>
                                  </div>
                                  {contact.phone && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <PhoneOutlined style={{ fontSize: '12px', marginRight: 4, color: '#888' }} />
                                      <Text>{contact.phone}</Text>
                                    </div>
                                  )}
                                </div>
                                
                                <div>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                                    <Text type="secondary" style={{ fontSize: '12px' }}>Probability</Text>
                                    <Text strong style={{ fontSize: '12px' }}>{contact.probability}%</Text>
                                  </div>
                                  <Progress 
                                    percent={contact.probability} 
                                    size="small" 
                                    showInfo={false}
                                    strokeColor={{
                                      '0%': '#1890ff',
                                      '100%': '#52c41a',
                                    }}
                                  />
                                </div>
                                
                                {contact.tags && (
                                  <div style={{ marginTop: 8 }}>
                                    {contact.tags.split(',').filter(Boolean).map(tag => (
                                      <Tag key={tag} color="blue" style={{ marginRight: 4 }}>{tag.trim()}</Tag>
                                    ))}
                                  </div>
                                )}
                                
                                {contact.assignedTo && (
                                  <div style={{ marginTop: 8, textAlign: 'right', fontSize: '12px' }}>
                                    <Text type="secondary">Assigned to: {contact.assignedTo}</Text>
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              ))}
          </DragDropContext>
        </div>
      ) : (
        <div style={{ textAlign: 'center', padding: '50px 0' }}>
          <Title level={4}>{t('No funnel selected')}</Title>
          <Button 
            type="primary" 
            icon={<PlusOutlined />} 
            onClick={() => setNewFunnelModalVisible(true)}
            style={{ marginTop: 16 }}
          >
            {t('Create your first funnel')}
          </Button>
        </div>
      )}
      
      {/* Modals for editing contacts and stages */}
      <ContactEdit 
        visible={!!selectedContact} 
        selected={selectedContact} 
        onCancel={() => setSelectedContact(null)} 
        onSave={handleSaveContact} 
        onDelete={handleDeleteContact} 
      />
      
      <StageEdit 
        visible={!!selectedStage} 
        selected={!!selectedStage ? {...selectedStage, funnelId: selectedFunnel} : null}
        onCancel={() => setSelectedStage(null)} 
        onSave={handleSaveStage} 
        onDelete={handleDeleteStage} 
      />
      
      <FunnelFilter
        visible={showFilter}
        onClose={() => setShowFilter(false)}
        onSearch={handleApplyFilter}
      />

      {/* New Funnel Modal */}
      <Modal
        title={t("Create New Funnel")}
        open={newFunnelModalVisible}
        onOk={handleCreateFunnel}
        onCancel={() => {
          setNewFunnelModalVisible(false);
          funnelForm.resetFields();
        }}
        confirmLoading={loading}
      >
        <Form
          form={funnelForm}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={t("Funnel Name")}
            rules={[{ required: true, message: t("Please enter funnel name") }]}
          >
            <Input placeholder={t("Enter funnel name")} />
          </Form.Item>
          
          <Form.Item
            name="description"
            label={t("Description")}
          >
            <Input.TextArea rows={3} placeholder={t("Enter funnel description (optional)")} />
          </Form.Item>
          
          <Divider orientation="left">{t('Import Contacts')}</Divider>
          
          <Form.Item
            name="importTag"
            label={t("Import by Tag")}
            tooltip={t("Contacts with this tag will be automatically added to this funnel")}
          >
            <Select
              placeholder={t("Select a tag (optional)")}
              allowClear
              style={{ width: '100%' }}
            >
              {defaultTags.map(tag => (
                <Option key={tag} value={tag}>
                  <Tag color="blue">
                    <TagsOutlined /> {tag}
                  </Tag>
                </Option>
              ))}
            </Select>
          </Form.Item>
          
          <Alert
            message={t("Tip")}
            description={t("Contacts with the selected tag will be automatically added to the first stage of your funnel.")}
            type="info"
            showIcon
          />
        </Form>
      </Modal>

      {/* Add settings and delete confirmation modals */}
      <Modal
        title={t('Funnel Settings')}
        open={settingsModalVisible}
        onCancel={() => setSettingsModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setSettingsModalVisible(false)}>
            {t('Cancel')}
          </Button>,
          <Button 
            key="save" 
            type="primary" 
            onClick={handleUpdateFunnel}
            loading={loading}
            disabled={!selectedFunnel}
          >
            {t('Save Changes')}
          </Button>
        ]}
      >
        <Form 
          form={settingsForm} 
          layout="vertical"
          disabled={!selectedFunnel}
        >
          <Form.Item
            name="name"
            label={t('Funnel Name')}
            rules={[{ required: true, message: t('Please enter a name for the funnel') }]}
          >
            <Input placeholder={t('e.g., Sales Pipeline')} />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('Description')}
          >
            <Input.TextArea placeholder={t('Optional description')} />
          </Form.Item>
          <Form.Item
            name="contactTag"
            label={t('Contact Tag')}
            tooltip={t('Contacts with this tag will be automatically added to this funnel')}
          >
            <Input placeholder={t('e.g., lead, prospect')} />
          </Form.Item>
        </Form>
        
        <Divider />
        
        {/* Add Stages section */}
        <div style={{ marginBottom: 16 }}>
          <Title level={5}>{t('Stages')}</Title>
          <div style={{ marginBottom: 16 }}>
            <Text>{t('Manage the stages in your sales funnel.')}</Text>
          </div>
          <Button 
            type="primary" 
            icon={<PlusOutlined />} 
            onClick={addNewStage}
            disabled={!selectedFunnel}
          >
            {t("Add New Stage")}
          </Button>
        </div>
        
        <Divider />
        
        <div style={{ marginBottom: 16 }}>
          <Title level={5}>{t('Danger Zone')}</Title>
          <div style={{ 
            padding: 16, 
            border: '1px solid #ff4d4f', 
            borderRadius: 4,
            backgroundColor: '#fff1f0' 
          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Text strong>{t('Delete this funnel')}</Text>
                <div>
                  <Text type="secondary">{t('Once deleted, all stages and contacts in this funnel will be permanently removed.')}</Text>
                </div>
              </div>
              <Button 
                danger 
                onClick={() => setDeleteConfirmVisible(true)}
                disabled={!selectedFunnel}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t('Delete Funnel')}
        open={deleteConfirmVisible}
        onCancel={() => setDeleteConfirmVisible(false)}
        okText={t('Delete')}
        okButtonProps={{ danger: true }}
        onOk={handleDeleteFunnel}
      >
        <div>
          <p>{t('Are you sure you want to delete this funnel?')}</p>
          <p style={{ fontWeight: 'bold', color: '#ff4d4f' }}>
            {t('This will permanently delete all stages and contacts in this funnel. This action cannot be undone.')}
          </p>
          {selectedFunnel && (
            <p>
              {t('Deleting funnel')}: <strong>{funnels.find(f => f.id === selectedFunnel)?.name}</strong>
            </p>
          )}
        </div>
      </Modal>

      {/* Add the FunnelSettings component */}
      <FunnelSettings
        visible={showSettings}
        funnel={funnels.find(f => f.id === selectedFunnel)}
        onCancel={() => setShowSettings(false)}
        onSave={handleSaveSettings}
        onAddStage={() => {
          setShowSettings(false); // Close settings first
          addNewStage(); // Open the stage creation modal
        }}
      />
    </DefaultPage>
  );
};

export default FunnelBoard; 