import React, { useState, useRef } from 'react';
import { Form, Button, Card, Alert, Table, Row, Col, Spin, Upload, Select, Typography, Space, Divider } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { Dragger } = Upload;

const CsvImport = ({ onImportComplete, onCancel }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [mappings, setMappings] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  // Required fields for reconciliation
  const requiredFields = [
    { id: 'date', label: t('Date') },
    { id: 'transaction', label: t('Transaction') },
    { id: 'amount', label: t('Amount') }
  ];

  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      const selectedFile = info.file.originFileObj;
      
      if (!selectedFile.name.endsWith('.csv')) {
        setError(t('Please upload a valid CSV file'));
        setFile(null);
        setLoading(false);
        return;
      }

      setError(null);
      setFile(selectedFile);
      parseCSV(selectedFile);
    }

    if (info.file.status === 'error') {
      setError(t('File upload failed'));
      setLoading(false);
    }
  };

  const parseCSV = (file) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data.length === 0) {
          setError(t('The CSV file appears to be empty'));
          setLoading(false);
          return;
        }

        setCsvData(results.data);
        // Add keys to previewData for display
        const dataWithKeys = results.data.slice(0, 5).map((item, index) => ({
          ...item,
          key: `row-${index}`
        }));
        setPreviewData(dataWithKeys);
        
        // Initialize mappings with empty values
        const initialMappings = {};
        requiredFields.forEach(field => {
          initialMappings[field.id] = '';
        });
        setMappings(initialMappings);
        
        setLoading(false);
      },
      error: (error) => {
        setError(t('Error parsing CSV') + ': ' + error.message);
        setLoading(false);
      }
    });
  };

  const handleMappingChange = (fieldId, csvColumn) => {
    setMappings({
      ...mappings,
      [fieldId]: csvColumn
    });
  };

  const handleSubmit = () => {
    // Check which fields are required for matching (only date, amount, and transaction)
    const matchRequiredFields = requiredFields.filter(field => 
      ['date', 'amount', 'transaction'].includes(field.id)
    );
    
    // Validate required matching fields are mapped
    const unmappedFields = matchRequiredFields.filter(field => !mappings[field.id]);
    if (unmappedFields.length > 0) {
      const missingFields = unmappedFields.map(f => f.label);
      setError(t('Please map all required fields for matching. Missing') + ': ' + missingFields.join(', '));
      return;
    }

    // Transform data based on mappings
    const transformedData = csvData.map((row, index) => {
      const transformedRow = {
        id: `imported-${index}`,  // Add an ID for each row
      };
      
      // Apply mappings for the required fields
      for (const field of requiredFields) {
        const fieldId = field.id;
        const csvColumn = mappings[fieldId];
        
        // Only map if we have a mapping for this field
        if (csvColumn) {
          // Special handling for amount field
          if (fieldId === 'amount') {
            // Clean up amount string - remove currency symbols, commas, etc.
            const rawValue = row[csvColumn] || '0';
            const cleanAmountStr = String(rawValue).replace(/[^0-9.-]/g, '');
            const parsedAmount = parseFloat(cleanAmountStr);
            
            // Set amount to parsed value or 0 if NaN
            transformedRow[fieldId] = isNaN(parsedAmount) ? 0 : parsedAmount;
          } else {
            transformedRow[fieldId] = row[csvColumn] || '';
          }
        }
      }
      
      // Make sure transaction field exists (even if empty) to avoid undefined errors
      if (!transformedRow.transaction) {
        transformedRow.transaction = '';
      }
      
      return transformedRow;
    });

    onImportComplete(transformedData);
  };

  const getHeaders = () => {
    if (!previewData || previewData.length === 0) return [];
    return Object.keys(previewData[0]).filter(key => key !== 'key');
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: handleFileChange,
  };

  const getDataTableColumns = () => {
    const headers = getHeaders();
    return headers.map(header => ({
      title: header,
      dataIndex: header,
      key: header,
    }));
  };

  return (
    <div>
      <Title level={5} style={{ marginBottom: 16 }}>{t('Import Bank Statement')}</Title>
      
      {error && <Alert message={error} type="error" style={{ marginBottom: 16 }} />}
      
      {!csvData ? (
        <div>
          <Dragger {...uploadProps} style={{ marginBottom: 16 }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('Click or drag CSV file to this area to upload')}</p>
            <p className="ant-upload-hint">
              {t('Please select a CSV file containing your bank statement data.')}
            </p>
          </Dragger>
          
          {loading && (
            <div style={{ textAlign: 'center', margin: '16px 0' }}>
              <Spin size="large" />
              <p style={{ marginTop: 8 }}>{t('Parsing CSV file...')}</p>
            </div>
          )}
          
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
            <Button onClick={onCancel}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div style={{ marginBottom: 24 }}>
            <Title level={5} style={{ marginBottom: 16 }}>{t('Map CSV Fields')}</Title>
            <Text type="secondary" style={{ display: 'block', marginBottom: 16 }}>
              {t('Please map each required field to the corresponding column in your CSV file.')}
            </Text>
            
            <Row gutter={[16, 16]}>
              {requiredFields.map((field) => (
                <Col span={12} key={field.id}>
                  <Form.Item
                    label={field.label}
                    required
                  >
                    <Select
                      placeholder={t('-- Select CSV Column --')}
                      value={mappings[field.id] || undefined}
                      onChange={(value) => handleMappingChange(field.id, value)}
                      style={{ width: '100%' }}
                    >
                      {getHeaders().map((header) => (
                        <Option key={header} value={header}>
                          {header}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </div>
          
          <Divider />
          
          <div style={{ marginBottom: 24 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
              <Title level={5}>{t('Data Preview')}</Title>
            </div>

            <Table 
              columns={getDataTableColumns()} 
              dataSource={previewData} 
              size="small" 
              pagination={false}
              bordered
              scroll={{ x: true }}
            />
            <Text type="secondary" style={{ display: 'block', marginTop: 8 }}>
              {t('Showing first 5 rows. You can edit the data after import in the reconciliation view')}
            </Text>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              {t('Import Data')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default CsvImport; 