export const REFRESH_TIMING_BUFFER_MS = 5000; // refresh guest token early to avoid failed superset requests
export const MIN_REFRESH_WAIT_MS = 10000; // avoid blasting requests as fast as the cpu can handle
export const DEFAULT_TOKEN_EXP_MS = 300000; // (5 min) used only when parsing guest token exp fails

export function getGuestTokenRefreshTiming(currentGuestToken) {
  //const parsedJwt = jwtDecode(currentGuestToken);
  const parsedJwt = 5000;
  const exp = new Date(/[^0-9\.]/g.test(parsedJwt.exp) ? parsedJwt.exp : parseFloat(parsedJwt.exp) * 1000);
  const isValidDate = exp.toString() !== 'Invalid Date';
  const ttl = isValidDate ? Math.max(MIN_REFRESH_WAIT_MS, exp.getTime() - Date.now()) : DEFAULT_TOKEN_EXP_MS;
  return ttl - REFRESH_TIMING_BUFFER_MS;
}

const Actions = {
  GET: 'get',
  REPLY: 'reply',
  EMIT: 'emit',
  ERROR: 'error',
};

function isGet(message) {
  return message.switchboardAction === Actions.GET;
}

function isReply(message) {
  return message.switchboardAction === Actions.REPLY;
}

function isEmit(message) {
  return message.switchboardAction === Actions.EMIT;
}

function isError(message) {
  return message.switchboardAction === Actions.ERROR;
}

class Switchboard {
  constructor(params) {
    if (!params) {
      return;
    }
    this.init(params);
  }

  init(params) {
    if (this.isInitialised) {
      this.logError('already initialized');
      return;
    }
    const { port, name = 'switchboard', debug = false } = params;
    this.port = port;
    this.name = name;
    this.debugMode = debug;
    this.methods = {};
    this.incrementor = 1;
    this.isInitialised = true;

    port.addEventListener('message', async event => {
      this.log('message received', event);
      const message = event.data;
      if (isGet(message)) {
        this.port.postMessage(await this.getMethodResult(message));
      } else if (isEmit(message)) {
        const { method, args } = message;
        const executor = this.methods[method];
        if (executor) {
          executor(args);
        }
      }
    });
  }

  async getMethodResult({ messageId, method, args }) {
    const executor = this.methods[method];
    if (!executor) {
      return { switchboardAction: Actions.ERROR, messageId, error: `[${this.name}] Method "${method}" is not defined` };
    }
    try {
      const result = await executor(args);
      return { switchboardAction: Actions.REPLY, messageId, result };
    } catch (err) {
      this.logError(err);
      return { switchboardAction: Actions.ERROR, messageId, error: `[${this.name}] Method "${method}" threw an error` };
    }
  }

  defineMethod(methodName, executor) {
    this.methods[methodName] = executor;
  }

  get(method, args = undefined) {
    return new Promise((resolve, reject) => {
      if (!this.isInitialised) {
        reject(new Error('Switchboard not initialised'));
        return;
      }
      const messageId = this.getNewMessageId();
      const listener = (event) => {
        const message = event.data;
        if (message.messageId !== messageId) return;
        this.port.removeEventListener('message', listener);
        if (isReply(message)) {
          resolve(message.result);
        } else {
          const errStr = isError(message) ? message.error : 'Unexpected response message';
          reject(new Error(errStr));
        }
      };
      this.port.addEventListener('message', listener);
      this.port.start();
      this.port.postMessage({ switchboardAction: Actions.GET, method, messageId, args });
    });
  }

  emit(method, args = undefined) {
    if (!this.isInitialised) {
      this.logError('Switchboard not initialised');
      return;
    }
    this.port.postMessage({ switchboardAction: Actions.EMIT, method, args });
  }

  start() {
    if (!this.isInitialised) {
      this.logError('Switchboard not initialised');
      return;
    }
    this.port.start();
  }

  log(...args) {
    if (this.debugMode) {
      console.debug(`[${this.name}]`, ...args);
    }
  }

  logError(...args) {
    console.error(`[${this.name}]`, ...args);
  }

  getNewMessageId() {
    return `m_${this.name}_${this.incrementor++}`;
  }
}

export { Switchboard };
export default new Switchboard();
