import React from "react";
import Heatmap from "./Heatmap";
import HeatmapPolicies from "./HeatmapPolicies";
import Events from "./Events";
import MyTasks from "./MyTasks";
import { CalendarOutlined, DownOutlined, ReloadOutlined, SafetyOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, Input, Skeleton, Tooltip } from "antd";
import { FaAdjust } from "react-icons/fa";
import Operations from "./Financial/Operations";
import Search from "./Search";
import { exe, safeGet } from "../Lib/Dal";
import { withTranslation } from "react-i18next";
import { setMarginRight } from "../Lib/Helpers";
import View from "./Bpm/View";
import { local } from "d3";
class Main extends React.Component {
  state = {
    mode: "policies",
    configProfile: undefined,
    loadingHeatmap: true,
    loadingEvents: true,
    loadingOperations: true,
    homeViewId: undefined,
    doRefresh: 0,
    loadingEventsRefresh: false,
  };
  componentDidMount() {
    this.setState({ configProfile: this.props.configProfile });
    this.checkCustomLandingPage();
  }
  componentDidUpdate(prevPro, prevState) {
    if (!prevPro.configProfile && this.props.configProfile) {
      //configprops arrived
      this.setState({ configProfile: this.props.configProfile });
      this.checkCustomLandingPage();
    }
  }
  checkCustomLandingPage = () => {
    if(!localStorage.organizationCode) return;
    const orgs = safeGet(["configProfile","UInsurer","Organizations"], this.props,[]);
    const org = orgs.find((o) => o.code == localStorage.organizationCode);
    if(org && org.homeViewId){
      this.setState({homeViewId:org.homeViewId})
    }
  }

  getOnPlay(r) {
    switch (r.type) {
      case "POLICY":
        return () => (window.location = "/#/lifepolicy/0?d=0&oneclick=" + r.code);
        break;
      default:
        return () => {};
    }
  }

  render() {
    const { t, i18n } = this.props;
    const currentYear = new Date().getFullYear();
    
    if(this.state.homeViewId) return <View match={{params:{id:this.state.homeViewId}}} />
    if(localStorage.organizationCode && !this.state.configProfile) return <Skeleton active loading={true} />
    return (
      <div>
        <Search />
        <Skeleton active loading={this.state.loadingHeatmap||!this.props.localeLoaded} />
        {this.state.mode == "claims" &&this.props.localeLoaded&& <Heatmap year={this.state.year} onLoad={() => this.setState({ loadingHeatmap: false })} />}
        {this.state.mode == "policies" &&this.props.localeLoaded&& <HeatmapPolicies year={this.state.year} onLoad={() => this.setState({ loadingHeatmap: false })} />}

        <Dropdown
          overlay={
            <Menu>
              <Menu.Item onClick={() => this.setState({ mode: "policies" })}>
                <SafetyOutlined />
                {t("Policies")}
              </Menu.Item>
              <Menu.Item onClick={() => this.setState({ mode: "claims" })}>
                <WarningOutlined />
                {t("Claims")}
              </Menu.Item>

              <Menu.SubMenu
                title={
                  <span>
                    <CalendarOutlined  />
                    {t("Year")}
                  </span>
                }
                onClick={(item) => this.setState({ year: +item.key })}>
                <Menu.Item key={currentYear}>{currentYear}</Menu.Item>
                <Menu.Item key={currentYear - 1}>{currentYear - 1}</Menu.Item>
                <Menu.Item key={currentYear - 2}>{currentYear - 2}</Menu.Item>
                <Menu.Item key={currentYear - 3}>{currentYear - 3}</Menu.Item>
                <Menu.Item key={currentYear - 4}>{currentYear - 4}</Menu.Item>
                {/* <Menu.Item key={currentYear - 5}>{currentYear - 5}</Menu.Item> */}
              </Menu.SubMenu>
            </Menu>
          }
          trigger={["click"]}>
          <Button type="link" style={{ marginTop: 5, paddingLeft: 0 }}>
            <FaAdjust style={{ marginBottom: 2, verticalAlign: "middle",...setMarginRight(5,this.props.direction) }} />
            {t("Mode")}
            <DownOutlined />
          </Button>
        </Dropdown>
        <Skeleton active loading={this.state.loadingEvents||!this.props.localeLoaded} />
          {this.props.localeLoaded&&<div>
            <Events onLoad={() => this.setState({ loadingEvents: false,loadingEventsRefresh:false })} direction={this.props.direction} doRefresh={this.state.doRefresh} />
              <Tooltip title={t("Refresh Event Drops")}>
                <Button style={{marginBottom:15}} type="link" icon={<ReloadOutlined />} loading={this.state.loadingEventsRefresh} onClick={() => this.setState({ doRefresh: this.state.doRefresh + 1,loadingEventsRefresh:true})}  />
            </Tooltip>
            </div>}

        <Skeleton active loading={this.state.loadingOperations} />
        <Operations
          value={
            this.state.configProfile
              ? this.state.configProfile.OneClick
                ? this.state.configProfile.OneClick.map((p) => ({ ...p, onPlay: this.getOnPlay(p) }))
                : []
              : []
          }
          onLoad={() => this.setState({ loadingOperations: false })}
        />

        {/* <h3 style={{ color: "#999" }}>My Pending Work</h3>
        <MyTasks /> */}
      </div>
    );
  }
}
export default withTranslation()(Main);
