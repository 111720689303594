import React, { useState, useEffect } from 'react';
import { Button, Table, Progress, Menu, Select, message, Space, Tooltip, Modal } from 'antd';
import { EditOutlined, PlusOutlined, ProjectOutlined,FileOutlined, ReloadOutlined, DeleteOutlined, SaveOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TaskEdit from './TaskEdit';
import ProjectOpen from './ProjectOpen';
import ProjectEdit from './ProjectEdit';
import { exe } from '../../Lib/Dal';
import TaskBoard from './TaskBoard';

const Gantt = (props) => {
  const { t } = useTranslation();
  const dayWidth = 20;
  const taskHeight = 30;
  const leftPaneWidth = 200;
  const headerHeight = 90; 
  const [taskEdit, setTaskEdit] = useState(null);
  const [projectOpenVisible, setProjectOpenVisible] = useState(false);
  const [newProjectVisible, setNewProjectVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandLevel, setExpandLevel] = useState(1);
  const [currentDate, setCurrentDate] = useState(moment());
  const [tableRef, setTableRef] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskIdBoard, setTaskIdBoard] = useState();
  const [editProject, setEditProject] = useState(false);

  const [timeRange, setTimeRange] = useState({
    start: moment().subtract(5, 'days').startOf('day'),
    end: moment().add(5, 'days').endOf('day')
  });

  //if the project id is passed in the url, open the project
  useEffect(() => {
    if (props.match.params.projectId) {
      loadProject(props.match.params.projectId);
      
      // Check if there's a taskId in the URL
      if (props.match.params.taskId) {
        setTaskIdBoard(props.match.params.taskId);
      }
    } else {
      // offering the user to select a project
      setProjectOpenVisible(true);
    }
    props.noScroll(true);

    // Cleanup function that runs when component unmounts
    return () => {
      props.noScroll(false);
    };
  }, [props.match.params.projectId, props.match.params.taskId]);

  useEffect(() => {
    if (tasks.length > 0) {
      const allDates = tasks.flatMap(task => [moment(task.start), moment(task.end)]);
      const minDate = moment.min(allDates).subtract(5, 'days').startOf('day');
      let maxDate = moment.max(allDates).add(5, 'days').endOf('day');
      
      const today = moment().endOf('day');
      if (maxDate.isBefore(today)) {
        maxDate = today.add(5, 'days').endOf('day');
      }

      setTimeRange({
        start: minDate,
        end: maxDate,
      });
    }
  }, [tasks]);

const loadProject=(projectId)=>{
  setLoading(true);
  exe("RepoProject",{operation:"GET",filter:`id=${projectId}`}).then(r=>{
    setLoading(false);
    if(r.ok){
      if(r.total){
        setProject(r.outData[0]);
        onProjectOpen(r.outData[0]);
      }else{
        message.error("Project not found");
      }
    }else{
      message.error(r.msg);
    }
  }
  )
}

  const totalDays = timeRange.end.diff(timeRange.start, 'days') + 1;
  const round = (value) => Math.round(value * 10) / 10;

  const getCardsDone = (task) => {
    if(task.children){
      const children = task.children;
      const totalCardsDone = children.reduce((acc, child) => acc + getCardsDone(child), 0);
      return totalCardsDone;
  }
  else{
    return task.cardsDone;
  }
  }
  const getCardsCount = (task) => {
    if(task.children){
      const children = task.children;
      const totalCards = children.reduce((acc, child) => acc + getCardsCount(child), 0);
      return totalCards;
  }else{
    return task.cardsCount;
  }
  }
  const getExpected = (task) => {
    //calculating the expected progress today taking into accound the end date
    const today = moment();
    const totalDays = task.end.diff(task.start, 'days');
    const daysPassed = today.diff(task.start, 'days');
    const expected = Math.min(daysPassed / totalDays * 100, 100);
    return isNaN(expected)?0:round(expected);
  }
  const getWorkProgress = (task) => {
    //the work progress is the sum of children cardsDone / sum of children cardsCount, when there are children. If not, its cardsDone / cardsCount
    if(task.children){
      const children = task.children;
      const totalCards = children.reduce((acc, child) => acc + getCardsCount(child), 0);
      if(totalCards === 0) return 0;
      const totalCardsDone = children.reduce((acc, child) => acc + getCardsDone(child), 0);
      //rounding to one decimal
      return round(totalCardsDone / totalCards * 100);
    }else{
      return round(task.cardsDone / task.cardsCount * 100);
    }
}
  

  const processTasksToTreeData = (tasks) => {
    const taskMap = new Map();
    const rootTasks = [];
    // First pass: create all nodes
    tasks.forEach(task => {
      const { id, code, name, start, end, progress } = task;
      const level = code.split('.').length;
      const treeNode = {
        ...task,
        key: id.toString(),
        id,
        code,
        name,
        start: moment(start),
        end: moment(end),
        progress: progress || 0,
        children: null,
        level,  // Add level property
      };
      taskMap.set(code, treeNode);
    });

    // Second pass: build the tree structure
    tasks.forEach(task => {
      const treeNode = taskMap.get(task.code);
      const parentCode = task.code.split('.').slice(0, -1).join('.');
      
      if (parentCode && taskMap.has(parentCode)) {
        const parentNode = taskMap.get(parentCode);
        if (parentNode.children === null) {
          parentNode.children = [];
        }
        parentNode.children.push(treeNode);
      } else {
        rootTasks.push(treeNode);
      }
    });
    const getDeficit = (task) => {
      if(task.children){
        const children = task.children;
        const deficit = children.reduce((acc, child) => acc + getDeficit(child), 0);
        return deficit;
      }else{
        return task.cardsCount * task.expected / 100 - task.cardsDone;
      }
    }

    //trasversing the tree to calculate the stats
    const calculateStats = (task) => {
      if(task.children){
        const children = task.children;
        task.cardsDone = children.reduce((acc, child) => acc + getCardsDone(child), 0);
        task.cardsCount = children.reduce((acc, child) => acc + getCardsCount(child), 0);
        task.wProgress = getWorkProgress(task);
        task.expected = getExpected(task);
        task.done = task.cardsDone;
        task.count = task.cardsCount;
        task.diff = round(task.wProgress-task.expected);
        //if diff is NaN, set it to 0
        task.diff = isNaN(task.diff)?0:task.diff;
        children.forEach(child => calculateStats(child));
      }else{
        task.cardsDone = task.cardsDone;
        task.cardsCount = task.cardsCount;
        task.wProgress = getWorkProgress(task);
        task.expected = getExpected(task);
        task.done = task.cardsDone;
        task.count = task.cardsCount;
        task.diff = round(task.wProgress-task.expected);
        //task.deficit = task.cardsCount * task.expected / 100 - task.cardsDone;
        task.diff = isNaN(task.diff)?0:task.diff;
      }
      //deficit is an integer
      task.deficit = Math.round(task.cardsCount * task.expected / 100 - task.cardsDone);
    }
    rootTasks.forEach(task => calculateStats(task));

    
    return rootTasks;
  };

  const treeData = processTasksToTreeData(tasks);

  const renderGanttChart = (task) => {
    const startDiff = Math.max(0, task.start.diff(timeRange.start, 'days'));
    const endDiff = Math.min(totalDays, task.end.diff(timeRange.start, 'days') + 1);
    const x = startDiff * dayWidth;
    const width = (endDiff - startDiff) * dayWidth;

    const currentDayDiff = currentDate.diff(timeRange.start, 'days');
    const currentDayX = currentDayDiff * dayWidth;

    // Use the level property instead of calculating depth
    const taskColor = `hsl(195, 80%, ${30 + task.level * 20}%)`;
    const progressColor = `hsl(141, 71%, 80%)`;

    return (
      <div style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
        <div style={{
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: `repeating-linear-gradient(
    to right,
    ${Array.from({ length: 7 }).map((_, i) => {
      const isWeekend = i % 7 === 6 || i % 7 === 0; // Saturday or Sunday
      return `
        ${isWeekend ? '#f9f9f9' : 'transparent'} ${i * dayWidth}px,
        ${isWeekend ? '#f9f9f9' : 'transparent'} ${(i + 1) * dayWidth}px
      `;
    }).join(',')},
    transparent ${7 * dayWidth}px
  ),
  repeating-linear-gradient(
    to right,
    #f0f0f0,
    #f0f0f0 1px,
    transparent 1px,
    transparent ${dayWidth}px
  )`,
  backgroundSize: `${7 * dayWidth}px 100%, ${dayWidth}px 100%`,
  backgroundPositionX: `${-timeRange.start.day() * dayWidth}px, 0`,
}} />
    
        <svg width={totalDays * dayWidth} height={taskHeight} style={{ display: 'block', position: 'relative', marginTop: -1 }}>
          <rect
            x={x}
            y={2}
            width={width}
            height={taskHeight - 4}
            fill={taskColor}
            rx="3"
            ry="3"
          />
          <rect
            x={x}
            y={2}
            width={width * (task.wProgress / 100)}
            height={5}
            fill={progressColor}
            rx="3"
            ry="3"
          />
          <text x={x - 5} y={taskHeight / 2} textAnchor="end" dominantBaseline="middle" fontSize="10">
            {task.start.format('DD/MM')}
          </text>
          <text x={x + width + 5} y={taskHeight / 2} textAnchor="start" dominantBaseline="middle" fontSize="10">
            {task.end.format('DD/MM')}
          </text>
          {currentDayX >= 0 && currentDayX <= totalDays * dayWidth && (
            <line
              x1={currentDayX}
              y1={0}
              x2={currentDayX}
              y2={taskHeight}
              stroke="red"
              strokeWidth="2"
              style={{ }}
            />
          )}
        </svg>
      </div>
    );
  };

  const generateTimeHeader = () => {
    const months = [];
    const days = [];
    let currentDate = timeRange.start.clone();

    while (currentDate.isSameOrBefore(timeRange.end)) {
      // Month
      if (currentDate.date() === 1) {
        const monthStart = currentDate.clone();
        const monthEnd = currentDate.clone().endOf('month');
        const daysInMonth = monthEnd.diff(monthStart, 'days') + 1;
        months.push({
          title: currentDate.format('MMMM YYYY'),
          width: daysInMonth * dayWidth,
        });
      }

      // Day
      days.push({
        title: currentDate.format('D'),
        width: dayWidth,
      });

      currentDate.add(1, 'day');
    }

    return { months, days };
  };

  const { months, days } = generateTimeHeader();


const onOpenBoard=(task)=>{
  //updating the url with board view
  setTaskIdBoard(task.id);
  props.history.push(`/gantt/${project.id}/board/${task.id}`);
}
const onBackFromBoard=()=>{
  setTaskIdBoard(undefined);
  props.history.push(`/gantt/${project.id}`);
}

  const columns = [
    {
      title: 'WBS',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
      fixed: 'left',
    },
    {
      title: 'Task',
      dataIndex: 'name',
      key: 'name',
      width: leftPaneWidth,
      fixed: 'left',
      // bold if has children, normal if not
      render: (text, record) => <span style={{ fontWeight: record.children ? 'bold' : 'normal' }}>{text}</span>,
    },
  /*   {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      fixed: 'left',
      width: 100,
      render: (date) => date.format('YYYY-MM-DD'),
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      fixed: 'left',
      width: 100,
      render: (date) => date.format('YYYY-MM-DD'),
    }, */
    {
      //delta symbol
      title:<Tooltip title={"Deviation (%)"}> δ</Tooltip>,
      key: 'expected',
      fixed: 'left',
      width: 30,
      //red if negative
      render: (_,r) =><span style={{fontSize:8, color: r.diff<0?'red':'black'}}>{r.diff}</span>,
    },
    //deficit
    {
      //error symbol, red if positive
      //title: 'Σ',
      title:<Tooltip title={"Card Delta"}>Δ</Tooltip>,
      key: 'deficit',
      fixed: 'left',
      width: 30,
      render: (_,r) =><span style={{fontSize:8, color: r.deficit>0?'red':'black'}}>{r.deficit*-1}</span>,
    },
    {
      title:<Tooltip title={"Cards Done / Total Cards (%)"}>Progress</Tooltip>,
      key: 'workProgress',
      fixed: 'left',
      width: 100,
      render: (_,r) => (
        <Progress size={"small"} percent={r.wProgress} format={p=>p}  width={30} style={{paddingRight: 10, padding:3}} />
      ),
    },
    {
      title:<Tooltip title={"Cards Done / Total Cards"}>W</Tooltip>,
      key: 'cardsCount',
      fixed: 'left',
      width: 30,
      render: (_,r) =><span style={{fontSize:8}}>{r.done}/{r.count}</span>,  
    },
    // {
    //   title: 'Progress',
    //   dataIndex: 'progress',
    //   key: 'progress',
    //   fixed: 'left',
    //   width: 100,
    //   render: (progress) => (
    //     <Progress percent={progress} size="small" style={{paddingRight: 10}} />
    //   ),
    // },
    {
      fixed: 'left',
      dataIndex: 'actions',
      width:50,
      key: 'actions',
      render: (v,r) => <Space>
        <Button type="link" icon={<EditOutlined />} style={{padding: 0, margin: 0, height: 20, width: 20, minWidth: 20, fontSize: 10}} onClick={() => setTaskEdit(r)} />
        <Button type="link" icon={<ProjectOutlined />} style={{padding: 0, margin: 0, height: 20, width: 20, minWidth: 20, fontSize: 10}} onClick={() =>onOpenBoard(r)} disabled={r.children} />
        </Space>,
    },
    
    {
      title: () => (
        <div style={{ height: headerHeight - 30 }}> 
          <div style={{ display: 'flex', height: '40px' }}> 
            {months.map((month, index) => (
              <div key={index} style={{ width: month.width, borderRight: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {month.title}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', height: '20px' }}> 
            {days.map((day, index) => (
              <div key={index} style={{ width: day.width, borderRight: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'normal', fontSize:5 }}> 
                {day.title}
              </div>
            ))}
          </div>
        </div>
      ),
      key: 'gantt',
      render: (_, record) => renderGanttChart(record),
      width: totalDays * dayWidth,
    },
   
  ];

  const scrollToToday = () => {
    if (tableRef) {
      const currentDayDiff = currentDate.diff(timeRange.start, 'days');
      const currentDayX = currentDayDiff * dayWidth;
      const scrollContainer = tableRef.querySelector('.ant-table-body');
      const containerWidth = scrollContainer.clientWidth;
      const scrollLeft = currentDayX - containerWidth / 2 + leftPaneWidth;
      scrollContainer.scrollLeft = Math.max(0, scrollLeft);
    }
  };



  // Update getAllKeys function
  const getAllKeys = (data, targetDepth, currentDepth = 0) => {
    return data.reduce((keys, item) => {
      if (currentDepth < targetDepth - 1) {
        keys.push(item.key);
        if (item.children) {
          keys.push(...getAllKeys(item.children, targetDepth, currentDepth + 1));
        }
      }
      return keys;
    }, []);
  };

  // Update this effect to set initial expanded keys
  useEffect(() => {
    setExpandedRowKeys(expandLevel > 1 ? getAllKeys(treeData, expandLevel) : []);
  }, [expandLevel]);

  // Update handleLevelChange
  const handleLevelChange = (value) => {
    setExpandLevel(value);
    //setExpandedRowKeys(value > 1 ? getAllKeys(treeData, value) : []);
  };


  // Add this function to get the maximum depth of the task tree
  const getMaxDepth = (tasks, currentDepth = 0) => {
    return tasks.reduce((maxDepth, task) => {
      if (task.children) {
        return Math.max(maxDepth, getMaxDepth(task.children, currentDepth + 1));
      }
      return Math.max(maxDepth, currentDepth);
    }, currentDepth);
  };

  const maxDepth = getMaxDepth(treeData);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(moment());
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(timer);
  }, []);

  const onMenuClick = (e) => {
    console.log('menu clicked', e);
    if(e.key === 'openProject'){
      setProjectOpenVisible(true);
    }
    if(e.key === 'newProject'){
      setNewProjectVisible(true);
    }
    if(e.key === 'deleteProject'){
      //asking for confirmation
      Modal.confirm({
        title: t("Delete Project"),
        content: t("Are you sure you want to delete this record?"),
        onOk: () => onProjectDelete(project),
        okText: t("Yes"),
        cancelText: t("No"),
      });
    }
    if(e.key === 'editProject'){
      setEditProject(true);
      setNewProjectVisible(true);
    }
  };
  const projectMenuItem = project ? [
    {
      type: 'divider'  // Adds a vertical line separator
    },
    {
      label: project.name,
      key: 'projectName',
      disabled: true,
      style: { 
        fontWeight: 'bold',
        marginLeft: 'auto'
      }
    }
  ] : [];
  const menuItems = [
    {
      label: t("Projects"),
      key: 'projects',
      icon: <FileOutlined />,
      children: [
           {
          type: 'group',
          label: 'Projects',
          children: [
            {
              label: 'New',
              key: 'newProject',
              icon: <PlusOutlined />,
            },
            {
              label: 'Open',
              key: 'openProject',
              icon: <ProjectOutlined />,
            },
            {
              label: 'Edit',
              key: 'editProject',
              icon: <EditOutlined />,
            },
            {
              label: 'Save',
              key: 'saveProject',
              icon: <SaveOutlined />,
            },
            {
              label: 'Delete',
              key: 'deleteProject',
              icon: <DeleteOutlined />,
            },
          ],
        },
      ],
    },
    ...projectMenuItem,
  ];
 const handleSave = (values) => {
  values.projectId=project.id;
  //assign parentId (search for the parent id)
  const codeParts = values.code.split('.');
  const codePrefix = codeParts.slice(0, -1).join('.');
  const parentId = tasks.find(task => task.code === codePrefix);
  if(parentId) values.parentId = parentId.id;
  // Check if there's another task with the same code
  const existingTask = tasks.find(task => task.code === values.code && task.id !== values.id);
  if (existingTask) {
    const isTopLevel = codeParts.length === 1;

    if (isTopLevel) {
      // Handle top-level codes
      const topLevelCodes = tasks
        .filter(task => !task.code.includes('.'))
        .map(task => parseInt(task.code));
      const nextCode = Math.max(...topLevelCodes, 0) + 1;
      values.code = nextCode.toString();
    } else {
      // Handle nested codes
      const existingCodes = tasks
        .filter(task => task.code.startsWith(codePrefix + '.'))
        .map(task => {
          const lastPart = task.code.split('.').pop();
          return isNaN(lastPart) ? 0 : parseInt(lastPart);
        });
      const nextCode = existingCodes.length > 0 ? Math.max(...existingCodes) + 1 : 1;
      values.code = `${codePrefix}.${nextCode}`;
    }
  }

  const taskIndex = tasks.findIndex(task => task.id === values.id);
  const operation = taskIndex === -1 ? "ADD" : "UPDATE";
  if (operation === "ADD") values.id = 0;
  exe("RepoProjectTask",{operation:operation,entity:values}).then(r=>{
    if(r.ok){
      loadProject(project.id);
    }else{
      message.error(r.msg);
    }
  })

  setTaskEdit(null);
 }
 const onTaskDelete = (id) => {
  setLoading(true);
  exe("RepoProjectTask",{operation:"DELETE",entity:{id:id}}).then(r=>{
    setLoading(false);
    if(r.ok){
      setTasks(tasks.filter(task => task.id !== id));
      setTaskEdit(null);
    }else{
      message.error(r.msg);
    }
  })
 }
const onUserSelectedProject = (selectedProject) => {
  //changing the url to the selected project
  props.history.push(`/gantt/${selectedProject.id}`);
}
 const onProjectOpen = (selectedProject) => {
  console.log('project', selectedProject);
  setProject(selectedProject)
  setProjectOpenVisible(false);
   //loading project tasks
   setLoading(true);
   exe("RepoProjectTask",{operation:"GET",filter:`projectId=${selectedProject.id}`}).then(r=>{
     setLoading(false);
     if(r.ok){
       setTasks(r.outData);
       setExpandLevel(2);
     }else{
       message.error(r.msg);
     }
   })
 }
 const onProjectDelete = (proj) => {
  setLoading(true);
  exe("RepoProject",{operation:"DELETE",entity:{id:proj.id}}).then(r=>{
    setLoading(false);
    if(r.ok){
      setProject(null);
      props.history.push("/gantt");
    }else{
      message.error(r.msg);
    }
  })
}
 const onProjectSave = (selectedProject) => {
  console.log('project', selectedProject);
  setNewProjectVisible(false);
  if(editProject){
    setEditProject(false);
    //reloading the project
    loadProject(selectedProject[0].id);
  }else{
    //changing the url to the selected project
    props.history.push(`/gantt/${selectedProject[0].id}`);
  }

 }
  return (
    <div>
       <style>
            {`
                .ant-progress-text {
                    font-size: 8px !important; 
                } 
            `}
        </style>
      <Menu onClick={onMenuClick} selectable= {false} mode="horizontal" items={menuItems} />
      <div style={{display:taskIdBoard?"none":"block"}}>
        <div style={{padding: '5px 0px 5px 0px'}}>
          <Button type="link" icon={<PlusOutlined />} onClick={() => {
            if(!project) {
              message.error(t("Please open a project first"));
              return;
            }
            setTaskEdit({
              id: tasks.length > 0 ? Math.max(...tasks.map(t => t.id)) + 1 : 1, 
              code: tasks.length > 0 
                ? `${Math.max(...tasks.filter(task => !task.code.includes('.')).map(task => parseInt(task.code||0))) + 1}`
                : '1',
              name: '',
              start: moment(),
              end: moment(),
              progress: 0,
              projectId: project.id,
            });
          }}>{t("New Task")}</Button>
          <Button type="link" icon={<CalendarOutlined />} onClick={() => {
            setCurrentDate(moment());
            scrollToToday();
          }}>{t("Today")}</Button>
          <Select
            style={{ width: 120, marginLeft: 8 }}
            placeholder={t("Select level")}
            onChange={handleLevelChange}
            value={expandLevel}
          >
            {[...Array(maxDepth + 1)].map((_, index) => (
              <Select.Option key={index + 1} value={index + 1}>
                {t("Level")} {index + 1}
              </Select.Option>
            ))}
          </Select>
          <Button type="link" icon={<ReloadOutlined />} onClick={() => loadProject(props.match.params.projectId)} >{t("Reload")}</Button>
        </div>
        <Table
          ref={setTableRef}
          expandedRowKeys={expandedRowKeys}
          onExpandedRowsChange={setExpandedRowKeys}
          columns={columns}
          dataSource={treeData}
          loading={loading}
          pagination={false}
          size="small"
          scroll={{ x: leftPaneWidth + totalDays * dayWidth, y: 'calc(100vh - 8px)' }}
          components={{
            body: {
              row: ({ children, ...props }) => (
                <tr {...props} style={{ height: `${taskHeight}px`, padding: 0, margin: 0 }}>
                  {children}
                </tr>
              ),
              cell: ({ children, ...props }) => (
                <td {...props} style={{ ...props.style, padding: 0 }}>
                  {children}
                </td>
              ),
            },
          }}
          style={{ height: '100vh' }}
        />
        <TaskEdit visible={taskEdit !== null} onCancel={() => setTaskEdit(null)} onSave={handleSave} initialValues={taskEdit} tasks={tasks} onDelete={onTaskDelete} />
        <ProjectOpen visible={projectOpenVisible} onCancel={() => setProjectOpenVisible(false)} onOpen={onUserSelectedProject}/>
        <ProjectEdit visible={newProjectVisible} onCancel={() => setNewProjectVisible(false)} onSave={onProjectSave} initialValues={editProject?project:null}  />
      </div>
      <div style={{display:taskIdBoard?"block":"none"}}>
        <TaskBoard projectId={project&&project.id} taskId={taskIdBoard} onBack={onBackFromBoard} />
      </div>
    </div>
  );
};

export default Gantt;