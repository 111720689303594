import React, { useCallback, useMemo, useState, useEffect } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  getBezierPath,
  getMarkerEnd,
  ConnectionLineComponent
} from 'react-flow-renderer';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';
import TextUpdaterNode from './TextUpdaterNode';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

// Custom edge component with delete button
const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  // Calculate a simple bezier curve
  const midX = (sourceX + targetX) / 2;
  const midY = (sourceY + targetY) / 2;
  
  // Create a simple path with a control point
  const directPath = `M${sourceX},${sourceY} Q${midX},${sourceY} ${midX},${midY} Q${midX},${targetY} ${targetX},${targetY}`;
  
  // Position the button in the middle of the edge
  const buttonX = midX;
  const buttonY = midY;

  // Show elements if either the edge or button is hovered
  const showElements = isHovered || isButtonHovered;

  return (
    <>
      {/* Invisible wider path for better hover detection */}
      <path
        d={directPath}
        fill="none"
        stroke="transparent"
        strokeWidth={20}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ cursor: 'pointer' }}
      />
      
      {/* Visible path that changes color on hover */}
      <path
        id={id}
        style={{
          ...style,
          stroke: showElements ? '#ff0072' : '#b1b1b7',
          strokeWidth: showElements ? 2 : 1,
          pointerEvents: 'none', // This ensures the hover detection works on the wider path
        }}
        className="react-flow__edge-path"
        d={directPath}
        markerEnd={markerEndId ? `url(#${markerEndId})` : undefined}
      />
      
      {/* Always render the foreignObject but conditionally show its content */}
      <foreignObject
        width={20}
        height={20}
        x={buttonX - 10}
        y={buttonY - 10}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
        style={{ overflow: 'visible' }} // This helps with hover detection
      >
        <div
          style={{
            background: 'white',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            border: '1px solid #ff0072',
            display: showElements ? 'flex' : 'none', // Hide or show based on hover state
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 10,
          }}
          onClick={(event) => {
            event.stopPropagation();
            console.log('Edge removed:', id);
            document.dispatchEvent(new CustomEvent('edge:remove', { detail: { id } }));
          }}
        >
          <DeleteOutlined style={{ fontSize: '12px', color: '#ff0072' }} />
        </div>
      </foreignObject>
    </>
  );
};


const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: { label: 'Input Node' },
    position: { x: 250, y: 25 },
  },

  {
    id: '2',
    data: { label: <div style={{ fontSize: "12px" }}>Default Node</div> },
    position: { x: 100, y: 125 },
  },
  {
    id: '3',
    type: 'output',
    data: { label: 'Output Node' },
    position: { x: 250, y: 250 },
  },
  {
    id: '4',
    type: 'textUpdater',
    data: { label: 'Text Updater Node' },
    position: { x: 500, y: 250 },
  },
];
const initialEdges = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e2-3', source: '2', target: '3', animated: true },
];

const VisualChain = (props) => {
  const [t] = useTranslation();
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  // Add this new function to handle node deletion
  const onDeleteNode = useCallback((nodeId) => {
    console.log('Deleting node:', nodeId);
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    // Also remove any connected edges
    setEdges((eds) => eds.filter((edge) => 
      edge.source !== nodeId && edge.target !== nodeId
    ));
  }, []);

  // Setup event listener for edge removal
  useEffect(() => {
    const handleEdgeRemove = (event) => {
      const { id } = event.detail;
      setEdges((eds) => eds.filter((e) => e.id !== id));
    };

    document.addEventListener('edge:remove', handleEdgeRemove);
    return () => {
      document.removeEventListener('edge:remove', handleEdgeRemove);
    };
  }, []);

  // Add this effect to update existing nodes with the onDelete handler
  useEffect(() => {
    setNodes((prevNodes) => 
      prevNodes.map((node) => {
        if (node.type === 'textUpdater') {
          return {
            ...node,
            data: {
              ...node.data,
              onDelete: onDeleteNode
            }
          };
        }
        return node;
      })
    );
  }, [onDeleteNode]);

  // Add styling for handles directly in the component
  useEffect(() => {
    // Create a style element
    const styleEl = document.createElement('style');
    
    // Define styles for connection handles
    const cssRules = `
      .react-flow__handle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #c2c2c2;
        border: 1px solid white;
        transition: all 0.2s ease;
      }

      .react-flow__handle:hover {
        width: 10px;
        height: 10px;
        background-color: #43b883;
      }

      .react-flow__handle.connectable {
        cursor: crosshair;
      }

      /* Enhanced connection line styling */
      .react-flow__connection-line {
        stroke: #43b883;
        stroke-width: 3;
        stroke-dasharray: none;
      }

      /* Path for edges */
      .react-flow__edge-path {
        stroke: #b1b1b7;
        stroke-width: 1;
      }

      /* When connection is valid */
      .react-flow__connection.valid .react-flow__connection-line {
        stroke: #43b883;
        stroke-width: 3;
      }
    `;
    
    styleEl.innerHTML = cssRules;
    document.head.appendChild(styleEl);
    
    // Cleanup on component unmount
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );
  
  const onEdgesChange = useCallback(
    (changes) => {
      console.log(changes);
      setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges]
  );

  // Define both node types and edge types
  const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode }), []);
  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  // When initializing edges, set them to use the custom edge type
  useEffect(() => {
    setEdges((eds) => 
      eds.map((edge) => ({
        ...edge,
        type: 'custom',
      }))
    );
  }, []);

  // Update the connection line styling and behavior
  const connectionLineStyle = { 
    stroke: '#43b883', 
    strokeWidth: 3,
  };

  const menuClick = ({ key }) => {
    if (key === "1") {
      // Generate a unique ID based on timestamp
      const newId = `node_${Date.now()}`;

      // Calculate center of the screen
      const offsetX = window.innerWidth / 2;
      const offsetY = window.innerHeight / 2;

      // Create the new node with unique properties
      const newNode = {
        id: newId,
        type: 'textUpdater',
        data: { 
          label: `New Node`,
          // Add onDelete handler for textUpdater nodes
          ...({ onDelete: onDeleteNode })
        },
        position: { x: offsetX, y: offsetY }
      };

      // Update nodes using the callback form to ensure we have the latest state
      setNodes(prevNodes => [...prevNodes, newNode]);
    }
  }
  const onConnect = useCallback(
    (connection) => {
      console.log(connection);
      // Set the type of new connections to 'custom' and enforce smoothstep
      setEdges((eds) => 
        addEdge({
          ...connection,
          type: 'custom',
          animated: false,
          style: { stroke: '#b1b1b7' }
        }, eds)
      );
    },
    [setEdges]
  );

  return (<div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>

    <Menu mode="horizontal" onClick={menuClick} >
      <Menu.Item key="1">
        <FileOutlined />
        {t("New")}
      </Menu.Item>
    </Menu>

    <ReactFlow style={{ flex: 1 }}
      nodes={nodes}
      edges={edges}
      onConnect={onConnect}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      connectionLineStyle={connectionLineStyle}
      connectionRadius={30}
      defaultMarkerColor="#43b883">
      <MiniMap />
      <Controls />
      <Background />
    </ReactFlow>
  </div>
  );
}

export default VisualChain;