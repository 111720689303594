import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { AutoComplete, Button, Checkbox, Col, Input, message, Modal, Row, Slider } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import PolicyAccountSelect from "../Financial/PolicyAccountSelect";
import PaymentMethods from "../Life/PaymentMethods";
import AccountSelect from "../Shared/AccountSelect";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import TaxField from "./TaxField";
import {getConfigProfile, round2} from "../../Lib/Helpers";
import PaymentTypeSelect from "./PaymentTypeSelect";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import CostCenterManagement from "../Shared/CostCenterManagement";
import BranchSelect from "../Financial/BranchSelect";

const ManualPaymentRequest = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedContact,setSelectedContact]=useState();
  const [conceptData,setConceptData]=useState([]);
  const [allConceptData,setAllConceptData]=useState([]);
  const [costCentersVisible,setCostCentersVisible]=useState(false);
  const [triggerSelect,doTriggerSelect]=useState(0);
  const [readOnlyBeneficiary,setReadOnlyBeneficiary]=useState(false);

  useEffect(() => {
    if (props.record) {
      props.form.setFieldsValue(props.record);
      console.log("Loading Record",props.record);
      doTriggerSelect(c=>c+1); //trigger onSelectPaymentType event 
    }
    else props.form.resetFields();
    loadConcepts();
  }, [props.visible]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setReadOnlyBeneficiary(safeGet(["Payment", "readOnlyBeneficiary"], profile, false));
    });
}, []);

  const doRequest = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (props.record) {
        //record editional
        props.onOk(values);
      } else {
        setLoading(true);
        exe("DoManualPaymentRequest", values).then((r) => {
          setLoading(false);
          if (r.ok) {
            message.success(r.msg);
            props.onOk && props.onOk();
          } else message.error(r.msg);
        });
      }
    });
  };
  const onReceiptTypeSelect=(v,opt)=>{
    if(v){
      setLoading(true);
      exe("GetFiscalNumber",{receiptTypeCode:v,simulation:true}).then(r=>{
        setLoading(false);
        if(r.ok){
          props.form.setFieldsValue({fiscalNumber:r.outData})
        }else {
          message.error(r.msg);
          props.form.setFieldsValue({fiscalNumber:undefined})
        }
      })
    }else props.form.setFieldsValue({fiscalNumber:undefined})
  }
  const onRequiresFiscalNumberChange=e=>{
    if(e.target.checked){
      if(!values.contactId) {
        message.error(t("Please select beneficiary"));
        props.form.setFieldsValue({requiresFiscalNumber:false});
        return false;
      }
      if(selectedContact){
        if(selectedContact.receiptTypeCode){
          props.form.setFieldsValue({requiresFiscalNumber:true,receiptTypeCode:selectedContact.receiptTypeCode});
          onReceiptTypeSelect(selectedContact.receiptTypeCode)
        }
      }else{
        exe("GetContacts",{filter:`id=${values.contactId}`}).then(r=>{
          if(r.ok&&r.total>0){
            setSelectedContact(r.outData[0]);
            const contact=r.outData[0];
            props.form.setFieldsValue({requiresFiscalNumber:true,receiptTypeCode:contact.receiptTypeCode});
            onReceiptTypeSelect(contact.receiptTypeCode)
          }
        })        
      }
    }else{
      props.form.setFieldsValue({fiscalNumber:undefined,receiptTypeCode:undefined})
    }
  }
  const onSelectContact=contact=>{
    setSelectedContact(contact)
  }
  const loadConcepts=()=>{
    setLoading(true);
    exe("RepoPaymentConceptCatalog",{operation:"GET"}).then(r=>{
      setLoading(false);
      if(r.ok){
        setConceptData(r.outData.map(p=>p.concept));
        setAllConceptData(r.outData.map(p=>p.concept));
      }else message.error(r.msg);
    })
  }
  const onSearchConcept=v=>{
    if(v){
      setConceptData(allConceptData.filter(p=>p.toLowerCase().includes(v.toLowerCase())))
    }else setConceptData(allConceptData)
  }
  const onSelectPaymentType=record=>{
    setCostCentersVisible(record?record.showCostCenters:false);
  }

  const field = props.form.getFieldDecorator;
  const values = props.form.getFieldsValue();
  return (
    <div>
      {/*caution: all entity fields must be listed or will be overwritten when user saves*/}
      {field("id")}
      {field("claimId")}
      {field("lifePolicyId")}
      {field("liquidationId")}
      {field("producer")}
      {field("coverageId")}
      {field("date")}
      {field("user")}
      {field("jDetail")}
      {field("processId")}
      {field("entityState")}
      {field("payoutId")}
      {field("providerCode")}
      {field("beneficiaryType")}
      {field("CostCenters")}
      {field("claimsFundId")}

      <Modal 
          style={{ top: 20 }}
        visible={props.visible}
        title={t("Payment Request")}
        onOk={doRequest}
        onCancel={props.onCancel}
        okButtonProps={{ loading: loading, disabled: props.readOnly }}
        okText={props.record ? t("Save") : t("Request")}>
        <CostCenterManagement form={props.form} visible={costCentersVisible} />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={t("Beneficiary")}>{field("contactId", { rules: [{ required: true }] })(<SimpleContactSelect disabled={readOnlyBeneficiary&&values.id} onSelectContact={onSelectContact} />)}</Form.Item>
            {/*<Form.Item label={t("Concept")}>{field("concept", { rules: [{ required: false }] })(<Input />)}</Form.Item>*/}
            <Form.Item label={t("Concept")}>{field("concept", { rules: [{ required: false }] })(<AutoComplete
                dataSource={conceptData}
                onSearch={onSearchConcept}
                style={{ width: "100%" }}
                placeholder={t("Type to search")}
            />)}</Form.Item>
            <Form.Item label={t("Requires Fiscal Number")}>
              {field("requiresFiscalNumber",{valuePropName:"checked"})(<Checkbox onChange={onRequiresFiscalNumberChange} />)}
            </Form.Item>
            <Form.Item label={t("Currency")}>{field("currency", { rules: [{ required: true }] })(<Currency style={{ width: "100%" }} />)}</Form.Item>
            <Form.Item label={t("Gross Amount")}>{field("grossAmount", {normalize:(v,p,all)=>!v&&all.total?all.total:v, rules: [{ required: true }] })(<Money currency={values.currency} disabled={!values.currency} />)}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("Method of Payment")}>
              {field("paymentMethodCode", { rules: [{ required: true }] })(<PaymentMethods module="PAYMENT" style={{ width: "100%" }} showInfo small context={{payment:values}} />)}
            </Form.Item>
            <Form.Item label={t("Branch")}>{field("branch", { rules: [{ required: false }] })(<BranchSelect />)}</Form.Item>
            
            <div style={{display:values.requiresFiscalNumber?"block":"none"}}>
              <Form.Item label={t("Fiscal Document Type")}>
                {field("receiptTypeCode",{rules:[{required:values.requiresFiscalNumber}]})(<ReceiptTypeSelect onSelect={onReceiptTypeSelect} />)}
              </Form.Item>
            </div>
            <Form.Item label={values.requiresFiscalNumber?t("Fiscal Document Number"):t("Existing Fiscal Number")}>
              {field("fiscalNumber",{rules:[{required:values.requiresFiscalNumber}]})(<Input disabled={values.requiresFiscalNumber} />)}
            </Form.Item>
            <Form.Item label={t("Reference")}>{field("reference", { rules: [{ required: true }] })(<Input />)}</Form.Item>
            <Form.Item label={t("Payment Type")}>{field("paymentType", { rules: [{ required: false }] })(<PaymentTypeSelect triggerSelect={triggerSelect} onSelect={onSelectPaymentType} />)}</Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            {field("Taxes")}
            <Form.Item label={t("Taxes")}>{field("taxes",{ initialValue:0 })(<TaxField taxType={"TAX"} currency={values.currency} grossAmount={values.grossAmount} detail={values.Taxes} onDetailChange={v=>props.form.setFieldsValue({Taxes:v})} />)}</Form.Item>
            <Form.Item label={t("Retentions")}>{field("retentions", { initialValue:0 })(<TaxField taxType={"RET"} currency={values.currency} grossAmount={values.grossAmount} detail={values.Taxes} onDetailChange={v=>props.form.setFieldsValue({Taxes:v})} />)}</Form.Item>
            <Form.Item label={t("Deductions")}>{field("deductions", { initialValue:0 })(<TaxField taxType={"DED"} currency={values.currency} grossAmount={values.grossAmount} detail={values.Taxes} onDetailChange={v=>props.form.setFieldsValue({Taxes:v})} />)}</Form.Item>
            <Form.Item label={t("Total Amount")}>
              {field("total", { normalize:(v,p,all)=>!p&&v?v:round2((all.grossAmount||0)+all.taxes-all.retentions-all.deductions), rules: [{ required: true }]})(<Money currency={values.currency} disabled />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {field("lifePolicyId", { initialValue: props.policyId })}
            <Form.Item label={t("Source Account")}>
              {field("sourceAccountId", { rules: [{ required: true }] })(<AccountSelect style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            
            <Form.Item label={t("Destination Account")}>
              {field("accountId", { rules: [{ required: true }] })(<AccountSelect holderId={values.contactId} style={{ width: "100%" }} />)}
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Form.create()(ManualPaymentRequest);
