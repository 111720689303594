import { InfoCircleOutlined } from '@ant-design/icons';
import { message, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { round2 } from "../../Lib/Helpers";
import Money, { formatter } from "../Shared/Money";
import Percentage from "../Shared/Percentage";
const AccountsTable = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [navs, setNavs] = useState([]);

  useEffect(() => {
    if (props.value && navs.length == 0) {
      getAccountNavs(props.value);
    }
  }, props.value);

  const onChange = (accId, rescueAmount) => {
    if (props.onChange) {
      props.onChange(props.value.map((p) => (p.id == accId ? { ...p, rescueAmount: rescueAmount, rescuePercentage: round2(rescueAmount / p.currentValue * 100) } : p)));
    }
  };
  const onChangePercentage = (accId, rescuePercentage) => {
    if (props.onChange) {
      props.onChange(props.value.map((p) => (p.id == accId ? { ...p, rescuePercentage: rescuePercentage, rescueAmount: round2(p.currentValue * rescuePercentage / 100) } : p)));
    }
  };
  const getAccountNavs = (accounts) => {
    setLoading(true);
    exe("GetNavs", { assets: accounts.map((p) => p.investmentPlanCode) }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setNavs(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const getNav = (assetCode) => {
    const nav = navs.find((p) => p.assetCode == assetCode);
    if (nav) return nav.value;
    else return 0;
  };
  const getValue = (assetCode, units) => {
    return round2(getNav(assetCode) * units);
  };
  return (
    <div>
      <Table dataSource={props.value} size="small" pagination={false} loading={loading}>
        <Table.Column title={t("Name")} dataIndex="name"></Table.Column>
        <Table.Column title={t("Type")} dataIndex="type" render={(v) => <Tag>{v}</Tag>}></Table.Column>
        <Table.Column title={t("Product")} dataIndex="investmentPlanCode"></Table.Column>
        <Table.Column title={t("Currency")} dataIndex="currency"></Table.Column>
        <Table.Column title={t("Acc No")} dataIndex="accNo"></Table.Column>
        <Table.Column title={t("Units")} dataIndex="currentBalance" render={(v, r) => (r.isInvestmentAccount ? round2(v) : "-")}></Table.Column>
        <Table.Column
          title={
            <span>
              {t("Value")}
              <Tooltip title={t("Based on today's NAV or 0 if not available")}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Tooltip>
            </span>
          }
          dataIndex="currentValue"
          render={(v, r) => formatter(r.currency).format(v ? v : getValue(r.investmentPlanCode, r.currentBalance))}></Table.Column>
        <Table.Column
          title={t("Percentage")}
          dataIndex="rescuePercentage"
          key="surrenderPercentage"
          render={(v, r) => (
            <Percentage value={v} onChange={(value) => onChangePercentage(r.id, value)} disabled={["TRANSIT", "DAILY", "INI"].includes(r.type)} />
          )}
        />
        <Table.Column
          title={t("Amount")}
          dataIndex="rescueAmount"
          key="surrenderAmount"
          render={(v, r) => (
            <Money currency={r.currency} value={v} onChange={(value) => onChange(r.id, value)} disabled={["TRANSIT", "DAILY", "INI"].includes(r.type)} />
          )}
        />
      </Table>
    </div>
  );
};

export default AccountsTable;
