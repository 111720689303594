import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Table,
  Button,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  Radio,
  Tooltip,
  Tag,
  Row,
  Col,
  InputNumber,
  Select,
  Divider,
  message,
  Checkbox
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CheckCircleOutlined,
  LineChartOutlined,
  BarChartOutlined,
  ArrowLeftOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import BudgetLineForm from './BudgetLineForm';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const ScenarioManager = ({ budgetId, loading: parentLoading, onRefresh, budget }) => {
  const [t] = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isVarianceModalVisible, setIsVarianceModalVisible] = useState(false);
  const [editingScenario, setEditingScenario] = useState(null);
  const [form] = Form.useForm();
  const [createScenarioForm] = Form.useForm();
  const [adjustmentForm] = Form.useForm();
  const [varianceType, setVarianceType] = useState('percentage');
  const [varianceScope, setVarianceScope] = useState('all');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [percentageValue, setPercentageValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [budgetLines, setBudgetLines] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState(null);
  const [scenarioLines, setScenarioLines] = useState([]);
  const [isLineModalVisible, setIsLineModalVisible] = useState(false);
  const [editingLine, setEditingLine] = useState(null);
  const [scenarioLinesLoading, setScenarioLinesLoading] = useState(false);
  
  // Fetch scenarios and budget lines when the component loads
  useEffect(() => {
    if (!budgetId) return;
    
    fetchScenarios();
    fetchBudgetLines();
    fetchCategories();
  }, [budgetId]);

  const fetchScenarios = ()=>{
    const filteredScenarios = (budget?.Scenarios || []).filter(s => s.budgetId === budgetId);
    setScenarios(filteredScenarios);
  }
  
  const reloadScenarios = async () => {
    try {
      setLoading(true);
      
      const result = await exe('RepoBudgetScenario', {
        operation: 'GET',
        filter: '',
        include: null,
        size: 0
      });
      
      if (result.ok) {
        const filteredScenarios = (result.outData || []).filter(s => s.budgetId === budgetId);
        setScenarios(filteredScenarios);
        
        // If refresh callback is provided, call it to update parent components
        if (onRefresh) {
          onRefresh();
        }
      } else {
        message.error(t('Failed to load scenarios: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading scenarios:', error);
      message.error(t('Failed to load scenarios'));
      setLoading(false);
    }
  };
  const fetchBudgetLines = ()=>{
    const filteredLines = (budget?.BudgetLines || []).filter(line => line.scenarioId === selectedScenario?.id);
    setBudgetLines(filteredLines);
  }
  const reloadBudgetLines = async (scenarioId = null) => {
    try {
      const result = await exe('RepoBudgetLine', {
        operation: 'GET',
        filter: `budgetId=${budgetId}`,
        include: ['Category'],
      });
      
      if (result.ok) {
        if(scenarioId){
          setScenarioLines(result.outData.filter(line => line.scenarioId === scenarioId));
        }else{
          setBudgetLines(result.outData);
        }
      } else {
        message.error(t('Failed to load budget lines: ') + result.msg);
      }
    } catch (error) {
      console.error('Error loading budget lines:', error);
      message.error(t('Failed to load budget lines'));
    }
  };
  const fetchCategories = ()=>{
    const activeCategories = (budget?.Categories || []).filter(cat => cat.isActive) || [];
    setCategories(activeCategories);
  }
   
  const showModal = (scenario = null) => {
    setEditingScenario(scenario);
    
    if (scenario) {
      form.setFieldsValue({
        name: scenario.name,
        description: scenario.description,
        isDefault: scenario.isDefault
      });
    } else {
      form.resetFields();
      form.setFieldsValue({
        isDefault: false
      });
    }
    
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      if (editingScenario) {
        // Edit existing scenario
        const result = await exe('RepoBudgetScenario', {
          operation: 'UPDATE',
          entity: {
            ...editingScenario,
            ...values,
            budgetId: budgetId,
            updatedAt: new Date().toISOString()
          }
        });
        
        if (result.ok) {
          // If this is being set as default, update the default scenario
          if (values.isDefault) {
            await setAsDefaultScenario(editingScenario.id);
          }
          
          await reloadScenarios();
          message.success(t('Scenario updated'));
        } else {
          message.error(t('Failed to update scenario: ') + result.msg);
        }
      } else {
        // Add new scenario
        console.log("Creating new scenario:", values);
        const result = await exe('CreateScenario', {
          budgetId: budgetId,
          scenario: {
            name: values.name,
            description: values.description,
            isDefault: values.isDefault,
            budgetId: budgetId
          },
          defaultAdjustmentType: varianceType,
          defaultAdjustmentValue: percentageValue
        });
        
        if (result.ok) {
          await reloadScenarios();
          message.success(t('Scenario created'));
        } else {
          message.error(t('Failed to create scenario: ') + result.msg);
        }
      }
      
      setLoading(false);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error saving scenario:', error);
      message.error(t('Failed to save scenario'));
      setLoading(false);
    }
  };

  const handleCreateScenario = async () => {
    try {
      const values = await createScenarioForm.validateFields();
      setLoading(true);
      const result = await exe('CreateScenario', {
        budgetId: budgetId,
        scenario: {
          name: values.name,
          description: values.description,
          isDefault: values.isDefault || false,
          budgetId: budgetId
        }
      });
      
      if (result.ok) {
        await reloadScenarios();
        message.success(t('Scenario created'));
        setIsCreateModalVisible(false);
        createScenarioForm.resetFields();
      } else {
        message.error(t('Failed to create scenario: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error creating scenario:', error);
      message.error(t('Failed to create scenario'));
      setLoading(false);
    }
  };

 
  const deleteScenario = async (id) => {
    try {
      setLoading(true);
      
      // Don't allow deleting the default scenario
      const scenarioToDelete = scenarios.find(s => s.id === id);
      if (scenarioToDelete.isDefault) {
        message.error(t('Cannot delete the default scenario'));
        setLoading(false);
        return;
      }
      
      const result = await exe('RepoBudgetScenario', {
        operation: 'DELETE',
        entity: { id: id }
      });
      
      if (result.ok) {
        await reloadScenarios();
        message.success(t('Scenario deleted'));
      } else {
        message.error(t('Failed to delete scenario: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error deleting scenario:', error);
      message.error(t('Failed to delete scenario'));
      setLoading(false);
    }
  };
  
  const setAsDefaultScenario = async (id) => {
    try {
      setLoading(true);
      
      const result = await exe('SetDefaultScenario', {
        scenarioId: id,
        budgetId: budgetId
      });
      
      if (result.ok) {
        await fetchScenarios();
        message.success(t('Default scenario set'));
      } else {
        message.error(t('Failed to set default scenario: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error setting default scenario:', error);
      message.error(t('Failed to set default scenario'));
      setLoading(false);
    }
  };

  const duplicateScenario = async (scenario) => {
    try {
      setLoading(true);
      
      // Create a new scenario with the same settings but a new name
      const result = await exe('CreateScenario', {
        budgetId: budgetId,
        scenario: {
          name: `${scenario.name} (${t('Copy')})`,
          description: scenario.description,
          isDefault: false,
          budgetId: budgetId
        },
        defaultAdjustmentType: "",
        defaultAdjustmentValue: 0
      });
      
      if (result.ok) {
        await fetchScenarios();
        message.success(t('Scenario duplicated'));
      } else {
        message.error(t('Failed to duplicate scenario: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error duplicating scenario:', error);
      message.error(t('Failed to duplicate scenario'));
      setLoading(false);
    }
  };
  
  const createScenarioFromVariance = async () => {
    try {
      const scenarioName = `${t('Scenario')} - ${varianceType === 'percentage' ? percentageValue + '%' : '$' + percentageValue}`;
      
      setLoading(true);
      
      const result = await exe('CreateScenario', {
        budgetId: budgetId,
        scenario: {
          name: scenarioName,
          description: `${t('Created with')} ${varianceType === 'percentage' ? t('percentage') : t('fixed amount')} ${t('adjustment')}`,
          isDefault: false,
          budgetId: budgetId
        },
        defaultAdjustmentType: varianceType,
        defaultAdjustmentValue: percentageValue,
        defaultAdjustmentScope: varianceScope,
        defaultAdjustmentCategories: selectedCategories
      });
      
      if (result.ok) {
        // Reset the form state
        setPercentageValue(0);
        setVarianceType('percentage');
        setVarianceScope('all');
        setSelectedCategories([]);
        
        // Fetch updated scenarios
        await reloadScenarios();
        await reloadBudgetLines();
        //close the modal
        setIsVarianceModalVisible(false);
        
        message.success(t('Scenario created with adjustments'));
        
        // If there's a new scenario ID in the result, select it
        if (result.outData && result.outData.id) {
          // You could add logic here to select the new scenario if needed
          // For example, if there's a setSelectedScenario prop passed from parent
          if (onRefresh) {
            onRefresh(result.outData.id);
          }
        }
      } else {
        message.error(t('Failed to create scenario: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error creating scenario with variance:', error);
      message.error(t('Failed to create scenario'));
      setLoading(false);
    }
  };

  const fetchScenarioLines = async (scenarioId) => {
    if (!scenarioId) return;
    
    // Filter budget lines for the selected scenario
    const filteredLines = (budget?.BudgetLines || [])
      .filter(line => line.budgetId === budgetId && line.scenarioId === scenarioId);
    
    setScenarioLines(filteredLines);
    
  };
  
  const handleScenarioSelect = (scenarioId) => {
    const scenario = scenarios.find(s => s.id === scenarioId);
    setSelectedScenario(scenario);
    reloadBudgetLines(scenarioId);
  };
  
  const handleEditLine = (line) => {
    console.log("Editing line:", JSON.stringify(line, null, 2));
    
    // Create a clean copy with just the required properties
    const cleanLine = {
      id: line.id,
      name: line.name,
      description: line.description,
      lineType: line.lineType || 'expense',
      categoryId: line.categoryId,
      level: line.level || 'item',
      mapping: line.mapping,
      scenarioId: line.scenarioId,
      // Include Category if available
      Category: line.Category,
      // Include all monthly values
      jan: line.jan || 0,
      feb: line.feb || 0,
      mar: line.mar || 0,
      apr: line.apr || 0,
      may: line.may || 0,
      jun: line.jun || 0,
      jul: line.jul || 0,
      aug: line.aug || 0,
      sep: line.sep || 0,
      oct: line.oct || 0,
      nov: line.nov || 0,
      dec: line.dec || 0
    };
    
    console.log("Clean line for editing:", JSON.stringify(cleanLine, null, 2));
    setEditingLine(cleanLine);
    setIsLineModalVisible(true);
  };
  
  const handleAddLine = () => {
    setEditingLine(null);
    setIsLineModalVisible(true);
  };
  
  const handleSaveLine = async (lineData) => {
    try {
      setScenarioLinesLoading(true);
      
      // Set the scenario ID on the line data
      lineData.scenarioId = selectedScenario.id;
      
      // Determine if this is an update or add operation
      const operation = lineData.id ? 'UPDATE' : 'ADD';
      
      console.log("Original line data:", JSON.stringify(lineData, null, 2));
      
      // Create a proper data structure for API request
      const apiLineData = {
        // Include necessary properties
        id: operation === 'UPDATE' ? lineData.id : undefined,
        name: lineData.name,
        description: lineData.description,
        lineType: lineData.lineType || 'expense',
        level: lineData.level || 'item',
        scenarioId: selectedScenario.id,
        budgetId: budgetId,
        // Make sure categoryId is properly set from parentId if needed
        categoryId: lineData.categoryId || lineData.parentId,
        // Include monthly budget values
        jan: lineData.jan || 0,
        feb: lineData.feb || 0,
        mar: lineData.mar || 0,
        apr: lineData.apr || 0,
        may: lineData.may || 0,
        jun: lineData.jun || 0,
        jul: lineData.jul || 0,
        aug: lineData.aug || 0,
        sep: lineData.sep || 0,
        oct: lineData.oct || 0,
        nov: lineData.nov || 0,
        dec: lineData.dec || 0,
        // Include mapping if present
        mapping: lineData.mapping
      };
      
      const requestData = {
        operation: operation,
        entity: apiLineData
      };
      
      console.log("API request data:", JSON.stringify(requestData, null, 2));
      
      const result = await exe('RepoBudgetLine', requestData);
      
      if (result.ok) {
        message.success(t(operation === 'ADD' ? 'Budget line added' : 'Budget line updated'));
        setIsLineModalVisible(false);
        
        // Refresh scenario lines
        if(operation === 'ADD'){  
          setScenarioLines([...scenarioLines, result.outData[0]]);
        }else{
          setScenarioLines(scenarioLines.map(line => line.id === lineData.id ? result.outData[0] : line));
        }
      } else {
        message.error(t('Failed to save budget line: ') + result.msg);
      }
      
      setScenarioLinesLoading(false);
    } catch (error) {
      console.error('Error saving budget line:', error);
      message.error(t('Failed to save budget line'));
      setScenarioLinesLoading(false);
    }
  };
  
  const handleDeleteLine = async (lineId) => {
    try {
      setScenarioLinesLoading(true);
      
      const result = await exe('RepoBudgetLine', {
        operation: 'DELETE',
        entity: { id: lineId }
      });
      
      if (result.ok) {
        message.success(t('Budget line deleted'));
        
        // Refresh scenario lines
        await reloadBudgetLines(selectedScenario.id);
      } else {
        message.error(t('Failed to delete budget line: ') + result.msg);
      }
      
      setScenarioLinesLoading(false);
    } catch (error) {
      console.error('Error deleting budget line:', error);
      message.error(t('Failed to delete budget line'));
      setScenarioLinesLoading(false);
    }
  };
  
  const calculateScenarioTotal = (month = null) => {
    if (!scenarioLines || scenarioLines.length === 0) return 0;
    
    if (month) {
      return scenarioLines.reduce((total, line) => {
        const amount = line[month] || 0;
        return line.lineType === 'income' ? total + amount : total - amount;
      }, 0);
    } else {
      // Calculate yearly total
      const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
      return scenarioLines.reduce((total, line) => {
        const lineTotal = months.reduce((sum, month) => sum + (line[month] || 0), 0);
        return line.lineType === 'income' ? total + lineTotal : total - lineTotal;
      }, 0);
    }
  };
  const getCategoryNameById = (categoryId) => {
    const category = categories.find(c => c.id === categoryId);
    return category ? category.name : '';
  };
  
  const scenarioLinesColumns = [
    {
      title: t('Budget Line'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Text strong>{text}</Text>
          <Text type="secondary">{record.categoryId?getCategoryNameById(record.categoryId):''}</Text>
          {record.mappingType && (
            <Tag color="blue" icon={<LinkOutlined />}>
              {record.mappingId || ''}
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'lineType',
      key: 'lineType',
      width: 120,
      render: (lineType) => {
        let color = lineType === 'income' ? 'green' : 'volcano';
        return <Tag color={color}>{lineType === 'income' ? t('Income') : t('Expense')}</Tag>;
      },
    },
    {
      title: t('Category'),
      key: 'category',
      width: 150,
      render: (_, record) => {
        return record.Category?.name || (record.categoryId ? `Category ID: ${record.categoryId}` : t('No Category'));
      },
    },
    {
      title: t('Jan'),
      dataIndex: 'jan',
      key: 'jan',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Feb'),
      dataIndex: 'feb',
      key: 'feb',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Mar'),
      dataIndex: 'mar',
      key: 'mar',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Apr'),
      dataIndex: 'apr',
      key: 'apr',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('May'),
      dataIndex: 'may',
      key: 'may',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Jun'),
      dataIndex: 'jun',
      key: 'jun',
      width: 100,
      render: value => `$${(value || 0).toLocaleString()}`,
    },
    {
      title: t('Total'),
      key: 'total',
      width: 120,
      render: (_, record) => {
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const total = months.reduce((sum, month) => sum + (record[month] || 0), 0);
        return `$${total.toLocaleString()}`;
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      width: 120,
      render: (_, record) => (
        <Space>
          <Button 
            type="text" 
            icon={<EditOutlined />} 
            onClick={() => handleEditLine(record)} 
          />
          <Button 
            type="text" 
            danger 
            icon={<DeleteOutlined />} 
            onClick={() => handleDeleteLine(record.id)} 
          />
        </Space>
      ),
    },
  ];

  const scenarioColumns = [
    {
      title: t('Scenario Name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          {text} {record.isDefault && <Tag color="green">{t('Default')}</Tag>}
        </Space>
      ),
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('Created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('Last Updated'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text ? new Date(text).toLocaleDateString() : '-',
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            type="link" 
            icon={<EditOutlined />} 
            onClick={() => showModal(record)}
          />
          <Button 
            type="link" 
            icon={<CopyOutlined />} 
            onClick={() => duplicateScenario(record)}
          />
          <Button 
            type="link" 
            icon={<DeleteOutlined />} 
            onClick={() => deleteScenario(record.id)}
            disabled={record.isDefault}
          />
          {!record.isDefault && (
            <Button 
              type="link" 
              icon={<CheckCircleOutlined />} 
              onClick={() => setAsDefaultScenario(record.id)}
            />
          )}
          <Button
            type="link"
            onClick={() => handleScenarioSelect(record.id)}
          >
            {t('Open Lines')}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="scenario-manager">
      <Card 
        title={selectedScenario ? 
          <Space>
            <Button 
              icon={<ArrowLeftOutlined />} 
              onClick={() => setSelectedScenario(null)}
            >
              {t('Back to Scenarios')}
            </Button>
            <Text>{selectedScenario.name} {selectedScenario.isDefault && <Tag color="green">{t('Default')}</Tag>}</Text>
          </Space> 
          : t('Budget Scenarios')}
        extra={
          !selectedScenario ? 
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreateModalVisible(true)}
            >
              {t('Create New Scenario')}
            </Button>
            <Button
              icon={<BarChartOutlined />}
              onClick={() => setIsVarianceModalVisible(true)}
            >
              {t('Create Scenario with Adjustments')}
            </Button>
          </Space>
          :
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddLine}
            >
              {t('Add Budget Line')}
            </Button>
          </Space>
        }
      >
        {!selectedScenario ? (
          <Table
            dataSource={scenarios}
            columns={scenarioColumns}
            rowKey="id"
            loading={loading || parentLoading}
          />
        ) : (
          <Card>
            <Table
              columns={scenarioLinesColumns}
              dataSource={scenarioLines}
              rowKey="id"
              loading={scenarioLinesLoading}
              pagination={false}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <Text strong>{t('Total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Text strong>${calculateScenarioTotal('jan').toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Text strong>${calculateScenarioTotal('feb').toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <Text strong>${calculateScenarioTotal('mar').toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <Text strong>${(calculateScenarioTotal('jan') + calculateScenarioTotal('feb') + calculateScenarioTotal('mar')).toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <Text strong>${calculateScenarioTotal().toLocaleString()}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} />
                </Table.Summary.Row>
              )}
            />
          </Card>
        )}
      </Card>
      
      <Modal
        title={t("Create New Scenario")}
        open={isCreateModalVisible}
        onCancel={() => setIsCreateModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsCreateModalVisible(false)}>
            {t('Cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateScenario} loading={loading}>
            {t('Create')}
          </Button>
        ]}
      >
        <Form
          form={createScenarioForm}
          layout="vertical"
        >
          <Form.Item 
            name="name"
            label={t('Scenario Name')}
            rules={[{ required: true, message: t('Please enter a scenario name') }]}
          >
            <Input placeholder={t('Enter scenario name')} />
          </Form.Item>
          
          <Form.Item 
            name="description"
            label={t('Description')}
          >
            <TextArea rows={4} placeholder={t('Enter scenario description')} />
          </Form.Item>
          
          <Form.Item 
            name="isDefault"
            valuePropName="checked"
          >
            <Checkbox>{t('Set as default scenario')}</Checkbox>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Create Scenario with Adjustments")}
        open={isVarianceModalVisible}
        onCancel={() => setIsVarianceModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsVarianceModalVisible(false)}>
            {t('Cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={createScenarioFromVariance} loading={loading}>
            {t('Create')}
          </Button>
        ]}
      >
        <Form
          form={adjustmentForm}
          layout="vertical"
        >
          <Form.Item label={t('Adjustment Type')}>
            <Radio.Group 
              value={varianceType} 
              onChange={e => setVarianceType(e.target.value)}
            >
              <Radio value="percentage">{t('Percentage')}</Radio>
              <Radio value="fixed">{t('Fixed Amount')}</Radio>
            </Radio.Group>
          </Form.Item>
          
          <Form.Item 
            label={varianceType === 'percentage' ? t('Percentage Value') : t('Fixed Amount')}
            extra={varianceType === 'percentage' ? t('Positive values increase budget, negative values decrease') : t('Amount to add/subtract from each line')}
          >
            <InputNumber
              value={percentageValue}
              onChange={value => setPercentageValue(value)}
              style={{ width: '100%' }}
              min={-100}
              max={100}
              formatter={varianceType === 'percentage' ? value => `${value}%` : value => `$${value}`}
              parser={varianceType === 'percentage' ? value => value.replace('%', '') : value => value.replace('$', '')}
            />
          </Form.Item>
          
          <Form.Item label={t('Apply To')}>
            <Radio.Group 
              value={varianceScope} 
              onChange={e => setVarianceScope(e.target.value)}
            >
              <Radio value="all">{t('All Budget Lines')}</Radio>
              <Radio value="selected">{t('Selected Categories')}</Radio>
            </Radio.Group>
          </Form.Item>
          
          {varianceScope === 'selected' && (
            <Form.Item label={t('Select Categories')}>
              <Select
                mode="multiple"
                placeholder={t('Select categories')}
                value={selectedCategories}
                onChange={setSelectedCategories}
                style={{ width: '100%' }}
              >
                {categories.map(category => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
      
      <Modal
        title={editingScenario ? t('Edit Scenario') : t('Create Scenario')}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            {t('Cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
            {t('Save')}
          </Button>
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={editingScenario || {}}
        >
          <Form.Item
            name="name"
            label={t('Scenario Name')}
            rules={[{ required: true, message: t('Please enter a scenario name') }]}
          >
            <Input placeholder={t('Enter scenario name')} />
          </Form.Item>
          <Form.Item
            name="description"
            label={t('Description')}
          >
            <TextArea
              rows={4}
              placeholder={t('Enter scenario description')}
            />
          </Form.Item>
          <Form.Item 
            name="isDefault"
            valuePropName="checked"
          >
            <Checkbox>{t('Set as default scenario')}</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      
      <Modal
        title={editingLine ? t('Edit Budget Line') : t('Add Budget Line')}
        open={isLineModalVisible}
        onCancel={() => setIsLineModalVisible(false)}
        footer={null}
        width={800}
      >
        <BudgetLineForm 
          initialValues={editingLine}
          categories={categories}
          onSave={handleSaveLine}
          onCancel={() => setIsLineModalVisible(false)}
          budgetId={budgetId}
          scenarioId={selectedScenario?.id}
        />
      </Modal>
    </div>
  );
};

export default ScenarioManager; 