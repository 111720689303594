import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";
import { formatMoney } from '../../Lib/Helpers';

const ClaimsFundSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.claimsFundId) load(props.claimsFundId);
        else if(props.all) loadAll();
    },[props.claimsFundId,props.value])
    
    const load=claimsFundId=>{
        setLoading(true);
        exe("RepoClaimsFund",{operation:"GET",filter:`id=${claimsFundId}`,include:["Account","Account.Movements"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
                //selecting first
                if(!props.value&&r.outData.length>0){
                    props.onChange(r.outData[0].id);
                }
            }else message.error(r.msg)
        })
    }
    const loadAll=()=>{
        setLoading(true);
        exe("RepoClaimsFund",{operation:"GET",include:["Account","Account.Movements"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading}>
              {data.map(p=><Select.Option value={p.id} key={p.id}>{p.name} {formatMoney(p.balance,p.currency)}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default ClaimsFundSelect;