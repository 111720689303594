import React from 'react';
import { FlowDiagram, Handle } from './FlowDiagram';

const CustomNode = ({ nodeId }) => (
  <div style={{ width: 100, height: 50, padding: 10 }}>
    Node {nodeId}
    <Handle
      nodeId={nodeId}
      handleId="source"
      type="source"
      style={{ right: -5, top: '50%' }}
    />
    <Handle
      nodeId={nodeId}
      handleId="target"
      type="target"
      style={{ left: -5, top: '50%' }}
    />
  </div>
);

const VisualChain2 = () => (
  <FlowDiagram
    initialNodes={[
      { id: '1', position: { x: 100, y: 100 }, component: CustomNode },
      { id: '2', position: { x: 300, y: 200 }, component: CustomNode },
    ]}
    initialEdges={[]}
  />
);

export default VisualChain2;