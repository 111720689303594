import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import SimpleContactSelect from '../Contact/SimpleContactSelect';


const AmendmentPolicyholder = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) {
        props.form.setFieldsValue(props.value);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const currentPolicyholder = props.currentPolicyholder;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
        <Section text={t("Additional Amendment Options")}>
                {/* CHANGE OPTIONS ***********************************************************************/}
                <Form.Item label={t("Old Policyholder")}>
                {field("oldPolicyholder", { initialValue: currentPolicyholder })(<SimpleContactSelect disabled />)}
              </Form.Item>
              <Form.Item label={t("New Policyholder")}>{field("newPolicyholder", { rules: [{ required: true }] })(<SimpleContactSelect allowNew />)}</Form.Item>
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentPolicyholder);
