import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Table, 
  Card, 
  Input, 
  Button, 
  Dropdown, 
  Menu, 
  Tag, 
  Space, 
  Tooltip, 
  Popconfirm,
  Modal
} from 'antd';
import { 
  SearchOutlined, 
  FilterOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  CopyOutlined, 
  MoreOutlined, 
  CheckCircleOutlined, 
  ClockCircleOutlined,
  StopOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { formatDateShortUtc } from "../../Lib/Helpers";

const { Search } = Input;
const { confirm } = Modal;

const BudgetList = ({ budgets, onSelect, onDelete, loading }) => {
  const [t] = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(budgets);

  React.useEffect(() => {
    setFilteredData(budgets);
  }, [budgets]);

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = budgets.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      (item.description && item.description.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
  };

  const showDeleteConfirm = (budgetId, budgetName) => {
    confirm({
      title: t('Delete budget'),
      icon: <ExclamationCircleOutlined />,
      content: t('Are you sure you want to delete the budget "{name}"? This action cannot be undone.', { name: budgetName }),
      okText: t('Yes, delete'),
      okType: 'danger',
      cancelText: t('No, cancel'),
      onOk() {
        onDelete(budgetId);
      }
    });
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'active':
        return <Tag icon={<CheckCircleOutlined />} color="success">{t("Active")}</Tag>;
      case 'draft':
        return <Tag icon={<ClockCircleOutlined />} color="processing">{t("Draft")}</Tag>;
      case 'closed':
        return <Tag icon={<StopOutlined />} color="default">{t("Closed")}</Tag>;
      default:
        return <Tag color="default">{status}</Tag>;
    }
  };

  const renderActions = (record) => {
    const menu = (
      <Menu>
        <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => onSelect(record)}>
          {t("Edit")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item 
          key="delete" 
          icon={<DeleteOutlined />} 
          danger
          onClick={() => showDeleteConfirm(record.id, record.name)}
        >
          {t("Delete")}
        </Menu.Item>
      </Menu>
    );

    return (
      <Space size="small">
        <Tooltip title={t("Edit Budget")}>
          <Button 
            type="text" 
            icon={<EditOutlined />}
            onClick={() => onSelect(record)}
          />
        </Tooltip>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </Space>
    );
  };

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <a onClick={() => onSelect(record)}>{text}</a>
      )
    },
    {
      title: t('Year'),
      dataIndex: 'year',
      key: 'year',
      sorter: (a, b) => a.year - b.year,
      width: 100,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: t('Default Scenario'),
      dataIndex: 'defaultScenarioName',
      key: 'defaultScenarioName',
      width: 150,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: renderStatus,
      filters: [
        { text: t('Active'), value: 'active' },
        { text: t('Draft'), value: 'draft' },
        { text: t('Closed'), value: 'closed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: t('Created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (date) => formatDateShortUtc(date),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: t('Last Updated'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 120,
      render: (date) => formatDateShortUtc(date),
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
    },
    {
      title: t('Actions'),
      key: 'actions',
      width: 100,
      render: renderActions,
    },
  ];

  return (
    <Card>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <Search
          placeholder={t("Search budgets")}
          onSearch={handleSearch}
          onChange={e => handleSearch(e.target.value)}
          style={{ width: 300 }}
          allowClear
        />
      
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        rowKey="id"
        loading={loading}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        }}
      />
    </Card>
  );
};

export default BudgetList; 