import React, { useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import CommonChangeSurcharge from "./CommonChangeSurcharge";
import CommonChangeAdditional from "./CommonChangeAdditional";
import Amendments from './Amendments';

const CommonChangeSection = (props) => {
    const jAmendments = props.form.getFieldValue("jAmendments");
    useEffect(() => {
        if (jAmendments) {
            //checking if Amendments has been set
            const amendments=props.form.getFieldValue("Amendments");
            if(!amendments){
                props.form.setFieldsValue({
                    Amendments: JSON.parse(jAmendments)
                });
        }
        }
    }, [jAmendments]);
    const t=props.t;
    const field=props.form.getFieldDecorator;
    const change=props.change;
    const common=props.common;
    const canHaveAmendments=["ClauseChange","ExclusionChange","LoadingChange","IntermediaryChange","InsuredObjectChange",
        "TemporalStatusChange","AddCoverageChange","RemoveCoverageChange","CessionBeneficiaryChange","CapitalChange","TermChange","CoverageChange","CoverageChangeTechData","PolicyholderChange"]
    .includes(common.changeName);
    console.log("canHaveAmendments",canHaveAmendments,common.changeName);
    return (
        <div>
            <div style={{display:canHaveAmendments?"block":"none"}}>
                {field("jAmendments")(<input type="hidden" />)}
                <Form.Item label={t("Additional Amendments")}>{field("Amendments")(<Amendments common={common} changeForm={props.form} />)}</Form.Item>
            </div>
            <Form.Item label={t("Surcharges")}>{field("Surcharges")(<CommonChangeSurcharge changeId={change&&props.change.id} currency={common.currency} />)}</Form.Item>
            {field("jAdditional")(<CommonChangeAdditional config={common.config} />)}
        </div>
    );
};

export default CommonChangeSection;