import React from 'react';
import { Input, InputNumber, Switch, DatePicker, Form, Select } from 'antd';
import moment from 'moment';

const CommandArgInput = ({ arg, value, onChange }) => {
  const handleChange = (val) => {
    onChange(arg.name, val);
  };
  // Convert C# types to appropriate input components. Include nullable types (with a question mark)
  switch (arg.type.toLowerCase()) {
    case 'string[]':
      return (
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder={arg.name}
          value={Array.isArray(value) ? value : []}
          onChange={handleChange}
        />
      );

    case 'string':
    case 'string?':
      return (
        <Input 
          placeholder={arg.name}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
        />
      );

    case 'int32':
    case 'int64':
    case 'decimal':
    case 'double':
    case 'int32?':
    case 'int64?':
    case 'decimal?':
    case 'double?':
      return (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={arg.name}
          value={value}
          onChange={handleChange}
        />
      );

    case 'boolean':
    case 'boolean?':
      return (
        <Switch
          checked={value}
          onChange={handleChange}
        />
      );

    case 'datetime':
    case 'datetime?':
      return (
        <DatePicker
          style={{ width: '100%' }}
          value={value ? moment(value) : null}
          onChange={(date) => handleChange(date ? date.toISOString() : null)}
        />
      );

    default:
      // For complex types, use a JSON input
      return (
        <Input.TextArea
          placeholder={`Enter ${arg.name} as JSON object`}
          value={typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
          onChange={(e) => {
            try {
              const parsed = JSON.parse(e.target.value);
              handleChange(parsed);
            } catch {
              handleChange(e.target.value);
            }
          }}
        />
      );
  }
};

export default CommandArgInput; 