import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import { 
  Tabs, 
  Button, 
  message, 
  Tooltip, 
  Space
} from "antd";
import { 
  PlusOutlined, 
  FileExcelOutlined, 
  BarChartOutlined, 
  SwapOutlined,
  ArrowLeftOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import BudgetList from "./BudgetList";
import BudgetDetail from "./BudgetDetail";

const Budget = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [showBudgetDetail, setShowBudgetDetail] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    loadBudgets();
  }, [refreshTrigger]);

  const loadBudgets = async () => {
    try {
      setLoading(true);
      
      const result = await exe('RepoBudget', {
        operation: 'GET',
        include:["Categories","Scenarios","BudgetLines"],
        size: 0
      });
      
      if (result.ok) {
        setBudgets(result.outData || []);
      } else {
        message.error(t('Failed to load budgets: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading budgets:', error);
      message.error(t('Error loading budgets'));
      setLoading(false);
    }
  };

  const onCreateBudget = () => {
    // Create a new empty budget with default values
    const newBudget = {
      id: 0,
      name: "",
      description: "",
      year: new Date().getFullYear(),
      status: "draft",
      defaultScenarioId: null,
      createdAt: new Date().toISOString().split('T')[0],
      updatedAt: new Date().toISOString().split('T')[0]
    };
    setSelectedBudget(newBudget);
    setShowBudgetDetail(true);
  };

  const onSelectBudget = (budget) => {
    setSelectedBudget(budget);
    setShowBudgetDetail(true);
  };

  const onSaveBudget = async (budgetData) => {
    try {
      setLoading(true);
      
      // Budget data is already saved by BudgetDetail component
      // We just need to refresh the list and update our UI
      message.success(t('Budget saved successfully'));
      setRefreshTrigger(refreshTrigger + 1);
      
      // If it was a new budget that was just created
      if (budgetData && !selectedBudget?.id) {
        setSelectedBudget(budgetData);
      } else {
        setShowBudgetDetail(false);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error handling budget save:', error);
      message.error(t('Error processing budget'));
      setLoading(false);
    }
  };

  const onDeleteBudget = async (budgetId) => {
    try {
      setLoading(true);
      
      const result = await exe('RepoBudget', {
        operation: 'DELETE',
        entity: { id: budgetId }
      });
      
      if (result.ok) {
        message.success(t('Budget deleted successfully'));
        setRefreshTrigger(refreshTrigger + 1);
      } else {
        message.error(t('Failed to delete budget: ') + result.msg);
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error deleting budget:', error);
      message.error(t('Error deleting budget'));
      setLoading(false);
    }
  };



  return (
    <DefaultPage
      title={t("Budget Management")}
      loading={loading}
      icon="fund"
      iconStyle={{ backgroundColor: "#1890ff" }}
      extra={<div> <Button type="link" icon={<ReloadOutlined />} onClick={() => setRefreshTrigger(refreshTrigger + 1)}>
      {t("Reload")}
    </Button>
    <Button type="primary" icon={<PlusOutlined />}onClick={onCreateBudget}>
      {t("New Budget")}
    </Button>
    </div>
      }
    >
      {!showBudgetDetail &&  <>
        <BudgetList 
          budgets={budgets}
          onSelect={onSelectBudget}
          onDelete={onDeleteBudget}
          loading={loading}
        />
      </>}
     {showBudgetDetail && <>
          <div style={{ marginBottom: 16 }}>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => setShowBudgetDetail(false)}
              style={{ marginRight: 8 }}
            >
              {t("Back to Budgets")}
            </Button>
          </div>
          
          <BudgetDetail 
            budget={selectedBudget}
            onSave={onSaveBudget}
            onBack={() => setShowBudgetDetail(false)}
            loading={loading}
          />
        </>}
        

    </DefaultPage>
  );
};

export default Budget; 