import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, message, Modal } from "antd";
import {useTranslation} from "react-i18next";
import TransferReversalCauseSelect from "./TransferReversalCauseSelect";
import TransferReversalSubCauseSelect from "./TransferReversalSubcauseSelect";
import CustomForm from "../Shared/CustomForm";
import AccountSelect from "../Shared/AccountSelect";
import WorkspaceSelect from "./WorkspaceSelect";
import {exe} from "../../Lib/Dal";

const ReversalDetail = (props) => {
    const [formId,setFormId]=useState();
    const [subCauseRequired,setSubCauseRequired]=useState(false);
    const [workspaceClosed,setWorkspaceClosed]=useState(false);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    useEffect(()=>{
        if(props.selected&&props.selected.reversalCause){
            console.log("reloading values",props.selected);
            //setting saved values
            props.form.setFieldsValue({
                reversalCause:props.selected.reversalCause,
                reversalSubcause:props.selected.reversalSubcause,
                jReversalFormValues:props.selected.jReversalFormValues,
            });
            
        }else{
          props.form.resetFields();
          setFormId(undefined);//clear form
        }
        //checking workspace status of the selected transfer. If closed, user must select another workspace
        if(props.selected){
            getTransferWorkspaceStatus(props.selected.id);
        }
    },[props.selected&&props.selected.id])
    
    const getTransferWorkspaceStatus=(entityId)=> {
        //if this is an allocation, we pass allocationId instead of transferId
        const data=props.allocation?{allocationId:entityId}:{transferId:entityId};
        exe("GetTransferWorkspaceStatus",data).then(r=>{
            if(r.ok){
                setWorkspaceClosed(r.outData.closed)
            }else{
                message.error(r.msg);
            }
        })
    }
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            //validating income type form
            if (window.reversalForm &&window.reversalForm.instanceContainers&& window.reversalForm.instanceContainers[0]) {
                if (window.reversalForm.instanceContainers[0].reportValidity()) {
                    values.jReversalFormValues = JSON.stringify(window.reversalForm.userData);
                } else {
                    message.error(t("Please check reversal form validation errors"));
                    return;
                }
            }
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    const changeSubcauseRequired=v=>{
        setSubCauseRequired(v);
        if(!v) props.form.setFieldsValue({reversalSubcause:undefined}) //refreshing validator
    }
    const onSelectCause=r=> {
        console.log("onSelectCause",r);
        if(r) setFormId(r.formId);else {
            setFormId(undefined);
            props.form.setFieldsValue({jReversalFormValues:undefined});
        }
    }
    const values=props.form.getFieldsValue();
    return (
        <div>
            <Modal title={t("Reversal Detail")} visible={props.visible} onOk={onSave} onCancel={props.onCancel} okText={t("Revert")} okButtonProps={{type:"danger"}}>
                
                <Form.Item label={t("Cause")}>
                    {field("reversalCause",{rules:[{required:true}]})(<TransferReversalCauseSelect style={{width:"100%"}} onSelect={onSelectCause} />)}
                </Form.Item>
                <Form.Item label={t("Subcause")}>
                    {field("reversalSubcause",{rules:[{required:subCauseRequired}]})(<TransferReversalSubCauseSelect causeCode={values.reversalCause} style={{width:"100%"}} disabled={!values.reversalCause} required={changeSubcauseRequired} />)}
                </Form.Item>
                <div style={{display:workspaceClosed?"block":"none"}}>
                    <Form.Item label={t("Workspace")}>
                        {field("workspaceId",{rules:[{required:workspaceClosed}]})(<WorkspaceSelect style={{width:"100%"}} />)}
                    </Form.Item>
                </div>
                {formId&&<CustomForm formId={formId} variable="reversalForm" value={props.selected&&props.selected.jReversalFormValues}  />}
                <Form.Item label={t("Options")}>
                    {field("creditFundsToAccount",{valuePropName:"checked"})(<Checkbox>{t("Credit funds to account")}</Checkbox>)}
                </Form.Item>
                <div style={{display:values.creditFundsToAccount?"block":"none"}}>
                    <Form.Item label={t("Account")}>
                        {field("creditFundsToAccountId",{rules:[{required:values.creditFundsToAccount}]})(<AccountSelect style={{width:"100%"}} />)}
                    </Form.Item>
                </div>
            </Modal>
        </div>
    );
};

export default Form.create()(ReversalDetail);