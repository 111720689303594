import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";
import Link from 'antd/lib/typography/Link';

const CreditSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.value) load(props.value);
    },[props.value])
    
    const load=creditId=>{
        setLoading(true);
        exe("RepoCredit",{operation:"GET",filter:`id=${creditId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading}>
              {data.map(p=><Select.Option value={p.id} key={p.id}><Link href={`#/credit/${p.id}`}>{p.code}</Link></Select.Option> )}
          </Select>  
        </div>
    );
};

export default CreditSelect;