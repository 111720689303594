import React, { useState, useEffect } from "react";
import { DeleteOutlined, SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Typography,
  Input,
  Button,
  Row,
  Col,
  Switch,
  Descriptions,
  Tabs,
  Popconfirm,
  Space,
  Card,
  Tooltip,
  Select,
} from "antd";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import { formatDate } from "../../Lib/Helpers";
import Inspector from "react-inspector";
import moment from "moment";
import TriggerLog from "./TriggerLog";
import { useTranslation } from "react-i18next";
import ChainSelect from "../Bpm/ChainSelect";
import CommandSelect from "../Bpm/CommandSelect";
import CodeEditorW from "../Shared/CodeEditorW";
const TriggerForm = (props) => {
  const [t] = useTranslation();

  const [updateMode, setUpdateMode] = useState(false);
  const [result, setResult] = useState(null);
  const field = props.form.getFieldDecorator;

  useEffect(() => {
    loadTrigger(props.value);
  }, props.value);

  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RepoTrigger", { operation: updateMode ? "UPDATE" : "ADD", entity: { ...props.value, ...values } }, undefined, true).then((r) => {
        props.form.setFieldsValue(r.outData[0]);
        if (props.onExit) props.onExit();
      });
    });
  };
  const onExecute = (job) => {
    const jobCmd = eval("window.a=" + job.command);
    exe(jobCmd.cmd, jobCmd.data, null, true).then((r) => {
      props.form.setFieldsValue({ lastExecution: moment(), lastResultOk: r.ok, lastResultDto: JSON.stringify(r) });
      setResult(r);
    });
  };
  const loadTrigger = (trigger) => {
    if (trigger.id) setUpdateMode(true);
    props.form.setFieldsValue(trigger);
  };
  const onDelete = () => {
    exe("RepoTrigger", { operation: "DELETE", entity: props.value }, null, true).then((r) => {
      if (props.onExit) props.onExit();
    });
  };
  const values = props.form.getFieldsValue();

  return (
    <div className="trigger-form-container">
      <Tabs>
        <Tabs.TabPane key="trigger" tab={t("Trigger")}>
          <Form layout="vertical">
            <Row gutter={24}>
              <Col span={12}>
                <Card title={t("Settings")} style={{ height: '100%' }} size="small">
                  <Form.Item label={t("Name")} required>
                    {field("name", { rules: [{ required: true }] })(<Input />)}
                  </Form.Item>
                  <Form.Item label={t("Listened Command")} required>
                    {field("listensTo", { rules: [{ required: true }] })(<CommandSelect />)}
                  </Form.Item>
                  <Form.Item 
                    label={
                      <span>
                        {t("Condition")}{' '}
                        <Tooltip title={"Boolean expression. Available context: _origin -> Original command dto"}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    } 
                    required
                  >
                    {field("condition", { rules: [{ required: true }] })(<CodeEditorW formField javascript divId="condition" />)}
                  </Form.Item>
                  <Form.Item label={t("Code")} required>
                    {field("code", { rules: [{ required: true }] })(<Input />)}
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label={t("Toggles")}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Form.Item label={t("Enabled")} style={{ marginBottom: 8 }}>
                            {field("enabled", { valuePropName: "checked" })(<Switch />)}
                          </Form.Item>
                          <Form.Item label={t("Interceptor")} style={{ marginBottom: 8 }}>
                            {field("interceptor", { valuePropName: "checked" })(<Switch />)}
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("Logging")}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          <Form.Item label={t("Log Full DTO")} style={{ marginBottom: 8 }}>
                            {field("logDto", { valuePropName: "checked" })(<Switch />)}
                          </Form.Item>
                          <Form.Item label={t("Log To Command")} style={{ marginBottom: 8 }}>
                            {field("logToCommand", { valuePropName: "checked" })(<Switch />)}
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={12}>
                <Card title={t("Action")} style={{ height: '100%' }} size="small">
                  <Form.Item
                    label={t("Action Type")}
                    required
                  >
                    {field("actionType", { 
                      rules: [{ required: true }],
                      initialValue: values.actionType 
                    })(
                      <Select onChange={(value)=>{
                        //reset action field because its used for all action types
                        props.form.setFieldsValue({action:null})
                      }}>
                        <Select.Option value={0}>{t("Execute Command")}</Select.Option>
                        <Select.Option value={1}>{t("Execute Chain")}</Select.Option>
                        <Select.Option value={2}>{t("Execute Code")}</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                  
                  <div style={{ display: values.actionType === 1 ? "block" : "none" }}>
                    <Form.Item label={t("Chain")} required>
                      {field("action", { rules: [{ required: values.actionType === 1 }] })(<ChainSelect valueField="name" filter="1=1" />)}
                    </Form.Item>
                  </div>
                  
                  <div style={{ display: values.actionType === 0 ? "block" : "none" }}>
                    <Form.Item label={t("Command")} required>
                      {field("action", { rules: [{ required: values.actionType === 0 }] })(<CodeEditorW formField javascript divId="commandCode" />)}
                    </Form.Item>
                    <Form.Item label={t("Context")} required>
                      {field("context", { rules: [{ required: values.actionType === 0 }] })(<CodeEditorW formField javascript divId="commandContext" />)}
                    </Form.Item>
                  </div>
                  
                  <div style={{ display: values.actionType === 2 ? "block" : "none" }}>
                    <Form.Item 
                      label={t("Custom Code")} 
                      required
                      help={t("Write custom JavaScript code to be executed")}
                    >
                      {field("action", { 
                        rules: [{ required: values.actionType === 2 }] 
                      })( <CodeEditorW formField javascript divId="triggerCode" />)}
                    </Form.Item>
                  </div>
                  <div style={{ display: values.logToCommand ? "block" : "none" }}>
                    <Form.Item label={t("Client Code")}>
                      {field("uiCode", { rules: [{ required: false }] })(<CodeEditorW formField javascript divId="uiCode" />)}
                    </Form.Item>
                  </div>
                </Card>
              </Col>
            </Row>
            
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
              <Button type="primary" icon={<SaveOutlined />} onClick={onSave} style={{ marginRight: 'auto' }}>
                {t("Save")}
              </Button>
              <Popconfirm title={t("Are you sure delete this trigger?")} onConfirm={onDelete} okText={t("Yes")} cancelText={t("No")}>
                <Button type="danger" icon={<DeleteOutlined />}>
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key="log" tab={t("Log")}>
          <TriggerLog triggerId={props.triggerId} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Form.create()(TriggerForm);
