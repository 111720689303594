import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Form, 
  Input, 
  Button, 
  Row, 
  Col, 
  Select, 
  DatePicker, 
  InputNumber, 
  Typography 
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Currency from '../Shared/Currency';
const { TextArea } = Input;
const { Option } = Select;

const FixedAssetForm = ({ asset, onSubmit, onClose, visible }) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  useEffect(() => {
    if (asset) {
      // Format dates for form inputs
      const formattedValues = {
        ...asset,
        purchaseDate: asset.purchaseDate ? moment(asset.purchaseDate) : null,
        disposalDate: asset.disposalDate ? moment(asset.disposalDate) : null,
        lastDepreciationDate: asset.lastDepreciationDate ? moment(asset.lastDepreciationDate) : null
      };
      
      form.setFieldsValue(formattedValues);
    } else {
      form.resetFields();
      // Set default values
      form.setFieldsValue({
        status: 'Active',
        depMethod: 'STL',
        currency: 'USD'
      });
    }
  }, [asset, form, visible]);

  // Add function to calculate depreciation amount
  const calculateDepreciationAmount = () => {
    const purchasePrice = form.getFieldValue('purchasePrice') || 0;
    const currentBookValue = form.getFieldValue('currentBookValue') || 0;
    return purchasePrice - currentBookValue;
  };

  // Add effect to update depreciation amount when purchase price or book value changes
  useEffect(() => {
    const { getFieldValue, setFieldsValue } = form;
    
    // Set up a form watcher for the relevant fields
    const unsubscribe = form.getFieldsValue(['purchasePrice', 'currentBookValue']);
    
    // Calculate and set the depreciation amount
    setFieldsValue({
      depreciationAmount: calculateDepreciationAmount()
    });
    
    return () => {
      // Cleanup if needed
    };
  }, [form]);

  const handleSubmit = () => {
    form.validateFields()
      .then(values => {
        // Format dates for submission
        const submitData = {
          ...values,
          purchaseDate: values.purchaseDate ? values.purchaseDate.format('YYYY-MM-DD') : null,
          disposalDate: values.disposalDate ? values.disposalDate.format('YYYY-MM-DD') : null,
          lastDepreciationDate: values.lastDepreciationDate ? values.lastDepreciationDate.format('YYYY-MM-DD') : null
        };
        
        // If we're editing an existing asset, include the id
        if (asset?.id) {
          submitData.id = asset.id;
        }
        
        onSubmit(submitData);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={asset ? t('Edit Asset') : t('Add New Asset')}
      visible={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button key="back" onClick={onClose}>
          {t('Cancel')}
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          {asset ? t('Update Asset') : t('Create Asset')}
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="assetForm"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t('Asset Name')}
              rules={[{ required: true, message: t('Please enter asset name') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="assetTag"
              label={t('Asset Tag/Barcode')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="category"
              label={t('Category')}
              rules={[{ required: true, message: t('Please select a category') }]}
            >
              <Select>
                <Option value="Equipment">{t('Equipment')}</Option>
                <Option value="Furniture">{t('Furniture')}</Option>
                <Option value="Vehicle">{t('Vehicle')}</Option>
                <Option value="Building">{t('Building')}</Option>
                <Option value="Land">{t('Land')}</Option>
                <Option value="Other">{t('Other')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label={t('Status')}
              rules={[{ required: true, message: t('Please select status') }]}
            >
              <Select>
                <Option value="Active">{t('Active')}</Option>
                <Option value="Inactive">{t('Inactive')}</Option>
                <Option value="Sold">{t('Sold')}</Option>
                <Option value="Damaged">{t('Damaged')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="description"
          label={t('Description')}
        >
          <TextArea rows={2} />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="purchaseDate"
              label={t('Purchase Date')}
              rules={[{ required: true, message: t('Please select purchase date') }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="purchasePrice"
              label={t('Purchase Price')}
              rules={[{ required: true, message: t('Please enter purchase price') }]}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/(,*)/g, '')}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="currency"
              label={t('Currency')}
              rules={[{ required: true, message: t('Please select currency') }]}
            >
             <Currency />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="currentBookValue"
              label={t('Current Book Value')}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/(,*)/g, '')}
                min={0}
                step={0.01}
                onChange={() => {
                  form.setFieldsValue({
                    depreciationAmount: calculateDepreciationAmount()
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="depreciationAmount"
              label={t('Depreciation Amount')}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/(,*)/g, '')}
                min={0}
                step={0.01}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="location"
              label={t('Location')}
              rules={[{ required: true, message: t('Please enter location') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="supplier"
              label={t('Supplier')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="serialNumber"
              label={t('Serial Number')}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="model"
              label={t('Model')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="depMethod"
              label={t('Depreciation Method')}
            >
              <Select>
                <Option value="STL">{t('Straight-line')}</Option>
                <Option value="DBL">{t('Double-declining')}</Option>
                <Option value="SYD">{t('Sum-of-years-digits')}</Option>
                <Option value="UOP">{t('Units-of-production')}</Option>
                <Option value="NON">{t('None')}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="usefulLife"
              label={t('Useful Life (Years)')}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="depreciationRate"
              label={t('Depreciation Rate (%)')}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                max={100}
                step={0.01}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="salvageValue"
              label={t('Salvage Value')}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/(,*)/g, '')}
                min={0}
                step={0.01}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="warranty"
              label={t('Warranty Information')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="disposalDate"
              label={t('Disposal Date')}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastDepreciationDate"
              label={t('Last Depreciation Date')}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="notes"
          label={t('Notes')}
        >
          <TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FixedAssetForm; 