import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const AreaServedSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), [props.areaServedCode]);

  const load = () => {
    if (!props.areaServedCode) return;
    if (props.areaServedCode.length === 0){
      setData([]);
      props.onChange([]);
      return;
    }
    setLoading(true);
    exe("RepoAreaServedDetailCatalog", { operation: "GET",filter:`areaServedCode IN (${props.areaServedCode})` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        //remove selected values that are not in the data. Value is an array of codes
        if (props.value) {
          const filteredValue = props.value.filter(p => r.outData.find(q => q.code === p));
          props.onChange(filteredValue);
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        mode="multiple"
        showArrow
        placeholder={t("Please select details of areas served")}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        disabled={!props.areaServedCode}
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default AreaServedSelect;
