import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import {
  CheckOutlined,
  CloseOutlined,
  FilterOutlined,
  LeftOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  SyncOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Tabs, Table, Button, Row, Col, Tag, Switch, message, Form, Drawer, Input, InputNumber } from "antd";
import Job from "./Job";
import { exe } from "../../Lib/Dal";
import { formatDateFromNow } from "../../Lib/Helpers";
import Log from "./Log";
import { useTranslation } from "react-i18next";
import StatusSelect from "../Shared/StatusSelect";

const Supervisor = () => {
  const [t] = useTranslation();

  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [matches, setMatches] = useState(null);
  const [filterForm] = Form.useForm();

  useEffect(() => {
    loadJobs();
  }, []);

  const loadJobs = () => {
    setLoading(true);
    exe("RepoJob", { operation: "GET", nextExecution: true }).then((r) => {
      setLoading(false);
      setJobs(r.outData);
    });
  };
  const onJobExit = () => {
    setSelectedJob(null);
    loadJobs();
  };
  const onChangeActive = (active,record) => {
    //updating record to disabled=!active
    setLoading(true);
    exe("RepoJob", { operation: "UPDATE", entity: {...record,disabled:!active} }).then((r) => {
      setLoading(false);
      if(r.ok){
        message.success(r.msg)
        loadJobs();
      }else {
        message.error(r.msg);
      }
    });
  }
  const now=new Date();
 const onFilterSearch = () => {
      filterForm.validateFields().then((values) => {
        let queries = [];
  
        if (values.id) queries.push(`id=${values.id}`);
        if (values.name) queries.push(`LOWER(name) LIKE '%${values.name.toLowerCase()}%'`);
        if (values.status!==undefined&&values.status!==null) queries.push(`disabled=${values.status?0:1}`);
        console.log(values.status);
        
        const filterQuery = queries.join(" AND ");
  
        setLoading(true);
        exe("RepoJob", { operation: "GET",filter:filterQuery}).then((r) => {
          setLoading(false);
          if (r.ok) {
            setJobs(r.outData);
            setMatches(r.total);
          } else {
            message.error(r.msg);
          }
        });
      });
    }
  return (
    <DefaultPage title={t("Supervisor")} icon="clock-circle">
      <Tabs>
        <Tabs.TabPane key="list" tab={t("Recurring Jobs")}>
          {!selectedJob && (
            <div>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setSelectedJob({})}>
                {t("New")}
              </Button>
              <Button type="link" icon={<SyncOutlined />} onClick={() => loadJobs()}>
                {t("Refresh")}
              </Button>
              <Button type="link" icon={<FilterOutlined />} onClick={()=>setFilterVisible(true)} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("Filter")}
            </Button>
              <Table style={{ marginTop: 5 }} dataSource={jobs} rowKey="id" loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Cron")} dataIndex="cron" />
                <Table.Column title={t("Job")} dataIndex="name" />
                <Table.Column title={t("Next Run")} dataIndex="nextExecution" render={(v) => v ? formatDateFromNow(v) : t("Never")} />
                <Table.Column
                  title={t("Last Launched")}
                  dataIndex="lastExecution"
                  render={(v, r) => <Tag color={r.lastResultOk ? "green" : "red"}>{v ? formatDateFromNow(v) : t("Never")}</Tag>}
                />
                <Table.Column
                  title={t("Last Finished")}
                  dataIndex="lastFinish"
                  render={(v, r) => <Tag color={r.lastResultOk ? "green" : "red"}>{v ? formatDateFromNow(v) : t("Never")}</Tag>}
                />
                <Table.Column
                  title={t("Status")}
                  dataIndex="disabled"
                  render={(v,r) => <Switch checked={!v} onChange={v=>onChangeActive(v,r)} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />}
                />
                {/* <Table.Column title="Created" dataIndex="created" render={(v) => formatDateFromNow(v)} /> */}
                <Table.Column
                  title={t("Actions")}
                  render={(v, r) => (
                    <Button type="link" onClick={() => setSelectedJob(r)}>
                      {t("Open")}
                    </Button>
                  )}
                />
              </Table>
            </div>
          )}
          {selectedJob && (
            <div>
              <Button type="link" icon={<LeftOutlined />} onClick={() => setSelectedJob(null)}>
                {t("Back")}
              </Button>
              <Job value={selectedJob} onExit={onJobExit} />
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key="job" tab={t("Log")}>
          <Button type="link" icon={<ReloadOutlined />} onClick={() => doRefresh(refresh + 1)}>
            {t("Refresh")}
          </Button>
          <Log refresh={refresh} />
        </Tabs.TabPane>
      </Tabs>
      <Drawer title={t("Filter Search")} placement="right" width={512} onClose={()=>setFilterVisible(false)} visible={filterVisible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => filterForm.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical" form={filterForm}> 
          <Form.Item label={t("Id")} name="id"><InputNumber allowClear /></Form.Item>
          <Form.Item label={t("Job Name")} name="name"><Input allowClear /></Form.Item>
          <Form.Item label={t("Status")} name="status"><StatusSelect /></Form.Item>
        
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onFilterSearch()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </DefaultPage>
  );
};

export default Supervisor;
