import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Input,
  Space,
  Typography,
  Select,
  Tag,
  Popconfirm,
  Tooltip,
  Switch,
  Row,
  Col,
  Divider,
  message,
  Table
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  FolderOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const BudgetCategorySetup = ({ initialCategories = [], onSave, onCancel, loading }) => {
  const [t] = useTranslation();
  const [categories, setCategories] = useState([]);
  const [editForm] = Form.useForm();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  
  // Initialize with provided categories or load defaults
  useEffect(() => {
    if (initialCategories && initialCategories.length > 0) {
      // Add order property if not present
      const withOrder = initialCategories.map((cat, index) => ({
        ...cat,
        order: cat.order || index + 1
      }));
      setCategories(withOrder);
    } else {
      // Default categories if none provided
      const defaultCategories = [
        { 
          id: -1, // Temporary negative id that will be replaced by backend
          name: 'Revenue', 
          description: 'All income sources',
          categoryType: 'income',
          isActive: true,
          order: 1
        },
        { 
          id: -2, // Temporary negative id that will be replaced by backend
          name: 'Operational Expenses', 
          description: 'Day-to-day operational costs',
          categoryType: 'expense',
          isActive: true,
          order: 2
        },
        { 
          id: -3, // Temporary negative id that will be replaced by backend
          name: 'Personnel', 
          description: 'All employee-related costs',
          categoryType: 'expense',
          isActive: true,
          order: 3
        }
      ];
      setCategories(defaultCategories);
    }
  }, [initialCategories]);
  
  const handleAddCategory = () => {
    setEditingCategory(null);
    editForm.resetFields();
    editForm.setFieldsValue({
      categoryType: 'expense',
      isActive: true
    });
    setIsEditModalVisible(true);
  };
  
  const handleEditCategory = (category) => {
    setEditingCategory(category);
    editForm.setFieldsValue({
      name: category.name,
      description: category.description,
      categoryType: category.categoryType,
      isActive: category.isActive
    });
    setIsEditModalVisible(true);
  };
  
  const handleDeleteCategory = (categoryId) => {
    setCategories(categories.filter(c => c.id !== categoryId));
  };
  
  const handleEditFormSubmit = () => {
    editForm.validateFields().then(values => {
      if (editingCategory) {
        // Update existing category
        const updatedCategories = categories.map(c => 
          c.id === editingCategory.id 
            ? { ...c, ...values }
            : c
        );
        setCategories(updatedCategories);
      } else {
        // Add new category with the highest order + 1
        const maxOrder = categories.length > 0 
          ? Math.max(...categories.map(c => c.order || 0))
          : 0;
        
        // Find the lowest available negative ID
        const minId = categories.length > 0
          ? Math.min(...categories.map(c => c.id)) - 1
          : -1;
        
        const newCategory = {
          id: minId, // Use negative ID temporarily, will be replaced by backend
          ...values,
          order: maxOrder + 1
        };
        setCategories([...categories, newCategory]);
      }
      
      setIsEditModalVisible(false);
    });
  };
  
  const handleSaveCategories = () => {
    onSave(categories);
  };
  
  const moveUp = (categoryId) => {
    const categoryIndex = categories.findIndex(cat => cat.id === categoryId);
    if (categoryIndex <= 0) return; // Already at the top
    
    const newCategories = [...categories];
    const temp = newCategories[categoryIndex].order;
    newCategories[categoryIndex].order = newCategories[categoryIndex - 1].order;
    newCategories[categoryIndex - 1].order = temp;
    
    setCategories([...newCategories].sort((a, b) => a.order - b.order));
  };
  
  const moveDown = (categoryId) => {
    const categoryIndex = categories.findIndex(cat => cat.id === categoryId);
    if (categoryIndex >= categories.length - 1) return; // Already at the bottom
    
    const newCategories = [...categories];
    const temp = newCategories[categoryIndex].order;
    newCategories[categoryIndex].order = newCategories[categoryIndex + 1].order;
    newCategories[categoryIndex + 1].order = temp;
    
    setCategories([...newCategories].sort((a, b) => a.order - b.order));
  };
  
  const columns = [
    {
      title: '',
      key: 'icon',
      width: 50,
      render: () => <FolderOutlined style={{ color: '#1890ff' }} />
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Type'),
      dataIndex: 'categoryType',
      key: 'categoryType',
      width: 120,
      render: (categoryType) => {
        let color = categoryType === 'income' ? 'green' : 'volcano';
        return <Tag color={color}>{categoryType === 'income' ? t('Income') : t('Expense')}</Tag>;
      },
    },
    {
      title: t('Status'),
      dataIndex: 'isActive',
      key: 'status',
      width: 100,
      render: (isActive) => {
        return isActive ? 
          <Tag color="green">{t('Active')}</Tag> : 
          <Tag color="red">{t('Inactive')}</Tag>;
      },
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: t('Actions'),
      key: 'actions',
      width: 180,
      render: (_, record) => (
        <Space size="small">
          <Tooltip title={t('Move Up')}>
            <Button 
              type="text" 
              icon={<ArrowUpOutlined />} 
              onClick={() => moveUp(record.id)} 
            />
          </Tooltip>
          <Tooltip title={t('Move Down')}>
            <Button 
              type="text" 
              icon={<ArrowDownOutlined />} 
              onClick={() => moveDown(record.id)} 
            />
          </Tooltip>
          <Tooltip title={t('Edit')}>
            <Button 
              type="text" 
              icon={<EditOutlined />} 
              onClick={() => handleEditCategory(record)} 
            />
          </Tooltip>
          <Tooltip title={t('Delete')}>
            <Popconfirm
              title={t('Are you sure you want to delete this category?')}
              onConfirm={() => handleDeleteCategory(record.id)}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button 
                type="text" 
                danger
                icon={<DeleteOutlined />} 
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  
  // Get sorted categories
  const getSortedCategories = () => {
    return [...categories].sort((a, b) => (a.order || 0) - (b.order || 0));
  };
  
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <div>
          <Text>{t('Organize your budget lines into categories for better structure and reporting')}</Text>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddCategory}
        >
          {t('Add Category')}
        </Button>
      </div>
      
      <Table 
        columns={columns}
        dataSource={getSortedCategories()}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      
      <Divider />
      
      <div style={{ textAlign: 'right', marginTop: 16 }}>
        <Space>
          <Button onClick={onCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={handleSaveCategories}>
            {t('Save Categories')}
          </Button>
        </Space>
      </div>
      
      {/* Edit Category Form Modal */}
      {isEditModalVisible && (
        <Form
          form={editForm}
          layout="vertical"
          visible={isEditModalVisible}
          onFinish={handleEditFormSubmit}
          onCancel={() => setIsEditModalVisible(false)}
        >
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                name="name"
                label={t('Category Name')}
                rules={[{ required: true, message: t('Please enter a category name') }]}
              >
                <Input placeholder={t('Enter category name')} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="categoryType"
                label={t('Type')}
                rules={[{ required: true, message: t('Please select a type') }]}
                initialValue="expense"
              >
                <Select>
                  <Option value="income">{t('Income')}</Option>
                  <Option value="expense">{t('Expense')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          <Form.Item
            name="description"
            label={t('Description')}
          >
            <TextArea 
              rows={3}
              placeholder={t('Enter category description')}
            />
          </Form.Item>
          
          <Form.Item
            name="isActive"
            valuePropName="checked"
            initialValue={true}
          >
            <Switch 
              checkedChildren={t('Active')}
              unCheckedChildren={t('Inactive')}
            />
          </Form.Item>
          
          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
            <Space>
              <Button onClick={() => setIsEditModalVisible(false)}>
                {t('Cancel')}
              </Button>
              <Button type="primary" htmlType="submit">
                {editingCategory ? t('Update') : t('Create')}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default BudgetCategorySetup; 