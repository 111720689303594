import React, { useState, useEffect } from 'react';
import { 
  Modal, 
  Form, 
  Input, 
  Button, 
  message, 
  Divider,
  Select,
  Space,
  Alert,
  Spin,
  Typography,
  Tag,
  Row,
  Col
} from 'antd';
import { 
  TagsOutlined, 
  ImportOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';

const { Option } = Select;
const { Text } = Typography;

const FunnelSettings = ({ visible, funnel, onCancel, onSave, onAddStage }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [t] = useTranslation();
  const [availableTags, setAvailableTags] = useState([]);
  const [importLoading, setImportLoading] = useState(false);
  const [importSuccess, setImportSuccess] = useState(null);
  
  // Load available tags when modal opens
  useEffect(() => {
    if (visible) {
      setLoading(true);
      // Load all unique tags from contacts using GetContactTags
      exe("GetContactTags", {}).then(r => {
        if (r.ok && r.outData && r.outData.tags) {
          setAvailableTags(r.outData.tags);
        } else {
          // Fallback to some default tags
          setAvailableTags(['Lead', 'Customer', 'Hot', 'Cold', 'VIP']);
        }
        setLoading(false);
      }).catch(() => {
        setAvailableTags(['Lead', 'Customer', 'Hot', 'Cold', 'VIP']);
        setLoading(false);
      });
      
      // Initialize form with funnel data
      if (funnel) {
        form.setFieldsValue({
          name: funnel.name,
          description: funnel.description
        });
      }
    }
  }, [visible, funnel, form]);
  
  const handleImportByTag = () => {
    const tag = form.getFieldValue('importTag');
    if (!tag) {
      message.error(t('Please select a tag to import contacts'));
      return;
    }
    
    setImportLoading(true);
    setImportSuccess(null);
    
    // Use ImportContacts command instead of operation in RepoFunnel
    exe("ImportContacts", {
      funnelId: funnel.id,
      tag: tag
    }).then(r => {
      setImportLoading(false);
      if (r.ok) {
        setImportSuccess({
          status: 'success',
          message: r.msg || t('Contacts imported successfully')
        });
        message.success(r.msg || t('Contacts imported successfully'));
      } else {
        setImportSuccess({
          status: 'error',
          message: r.msg || t('Failed to import contacts')
        });
        message.error(r.msg || t('Failed to import contacts'));
      }
    }).catch(error => {
      setImportLoading(false);
      setImportSuccess({
        status: 'error',
        message: t('Error importing contacts') + ': ' + error.message
      });
      message.error(t('Error importing contacts'));
    });
  };
  
  const handleCancel = () => {
    form.resetFields();
    setImportSuccess(null);
    onCancel();
  };
  
  const handleSave = () => {
    form.validateFields()
      .then(values => {
        setSubmitting(true);
        
        const updatedFunnel = {
          ...funnel,
          name: values.name,
          description: values.description
        };
        
        exe("RepoFunnel", {
          operation: "UPDATE",
          entity: updatedFunnel
        }).then(r => {
          setSubmitting(false);
          if (r.ok && r.outData && r.outData.length > 0) {
            message.success(t('Funnel settings updated successfully'));
            onSave(r.outData[0]);
          } else {
            message.error(r.msg || t('Failed to update funnel settings'));
          }
        }).catch(error => {
          setSubmitting(false);
          message.error(t('Error updating funnel settings'));
        });
      });
  };
  
  const handleDelete = () => {
    Modal.confirm({
      title: t('Delete Funnel'),
      content: t('Are you sure you want to delete this funnel? This cannot be undone.'),
      okText: t('Yes, delete'),
      okType: 'danger',
      cancelText: t('Cancel'),
      onOk() {
        setSubmitting(true);
        exe("RepoFunnel", {
          operation: "DELETE",
          entity: { id: funnel.id }
        }).then(r => {
          setSubmitting(false);
          if (r.ok) {
            message.success(t('Funnel deleted successfully'));
            handleCancel();
            // Signal that the funnel was deleted
            onSave(null);
          } else {
            message.error(r.msg || t('Failed to delete funnel'));
          }
        }).catch(error => {
          setSubmitting(false);
          message.error(t('Error deleting funnel'));
        });
      }
    });
  };
  
  return (
    <Modal
      title={t('Funnel Settings')}
      open={visible}
      onCancel={handleCancel}
      width={700}
      footer={[
        <Button key="delete" danger onClick={handleDelete}>
          {t('Delete Funnel')}
        </Button>,
        <Button key="cancel" onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} loading={submitting}>
          {t('Save')}
        </Button>
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label={t('Funnel Name')}
            rules={[{ required: true, message: t('Please enter funnel name') }]}
          >
            <Input placeholder={t('Enter funnel name')} />
          </Form.Item>
          
          <Form.Item
            name="description"
            label={t('Description')}
          >
            <Input.TextArea 
              rows={3} 
              placeholder={t('Enter funnel description (optional)')} 
            />
          </Form.Item>
          
          <Divider orientation="left">{t('Stages')}</Divider>
          
          <Button 
            icon={<PlusOutlined />} 
            onClick={() => onAddStage(funnel.id)}
            style={{ marginBottom: 16 }}
          >
            {t('Add New Stage')}
          </Button>
          
          <Divider orientation="left">{t('Import Contacts')}</Divider>
          
              <Form.Item
                name="importTag"
                label={t('Import by Tag')}
                extra={t('Import all contacts with a specific tag into this funnel')}
              >
                <Select
                  placeholder={t('Select a tag')}
                  style={{ width: '100%' }}
                  loading={loading}
                >
                  {availableTags.map(tag => (
                    <Option key={tag} value={tag}>
                      <Tag color="blue" style={{ marginRight: 0 }}>
                        <TagsOutlined /> {tag}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Button 
                icon={<ImportOutlined />} 
                onClick={handleImportByTag}
                loading={importLoading}
              >
                {t('Import Contacts')}
              </Button>
          
          {importSuccess && (
            <Alert
              message={importSuccess.message}
              type={importSuccess.status}
              showIcon
              style={{ marginBottom: 16 }}
            />
          )}
          
          <Space direction="vertical" style={{ width: '100%', marginTop: 8 }}>
            <Alert
              message={t('Import Information')}
              description={t('Imported contacts will be added to the first stage of your funnel. If a contact is already in the funnel, it will not be imported again.')}
              type="info"
              showIcon
              icon={<InfoCircleOutlined />}
            />
          </Space>
          
        </Form>
      </Spin>
    </Modal>
  );
};

export default FunnelSettings; 