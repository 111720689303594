import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDateShort} from "../../Lib/Helpers";

const WorkspaceSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("GetUserWorkspaces",).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData.records);
        if(r.outData.records.length===1){
          //selection the only record available
            props.onChange(r.outData.records[0].id);
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        placeholder={t("Please select workspace")}
        style={props.style}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear>
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {formatDateShort(p.date)}-{p.Branch?.name}-{p.id}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default WorkspaceSelect;
