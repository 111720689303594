import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";

const CommandSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[])
    
    const load=()=>{
        setLoading(true);
        exe("GetCommands",{}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select 
            value={props.value || undefined} 
            onChange={props.onChange} 
            disabled={props.disabled} 
            placeholder={props.placeholder}
            loading={loading} 
            style={props.style}
            size={props.size}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
              {data.map(p=><Select.Option value={p.name} key={p.name}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default CommandSelect;