import React, { useEffect } from 'react';
import { exe } from '../../Lib/Dal';
import { embedDashboard } from './embedDasboard';

// Add a style tag for the BI container and its iframe


const DashBoardRender = (props) => {
  useEffect(() => {
    //getting dashboard id from param or props
    load(dashboardId);
  }, []);
  const dashboardId=props.match?.params?.dashboardId||props.dashboardId;
  const styles = `
    #my-bi-container-${dashboardId} {
      width: 100%;
      height: 100vh;
      border: none;
      margin-left: -24px;
      margin-top: -12px;
      position: relative;
      overflow: hidden;
    }
    
    #my-bi-container-${dashboardId} iframe {
      width: 100%;
      height: 100%;
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
    }
  `;


  const load=(id)=>{
    exe("GetGuestToken",{resources:[{type:"dashboard",id:id}]}).then((res) => {
      const token = res.outData.token;
      const domain = res.outData.domain;
      embedDashboard({
        id: id, // given by the Superset embedding UI
        supersetDomain: domain,
        mountPoint: document.getElementById(`my-bi-container-${id}`), // any html element that can contain an iframe
        fetchGuestToken: () => token,
        dashboardUiConfig: { 
          hideTitle: true,
          filters: {
              expanded: false,
          },
          urlParams: {
              foo: 'value1',
              bar: 'value2',
          },
          iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox']
      }
      });
    });
  }
  return (
    <>
      <style>{styles}</style>
      <div id={`my-bi-container-${dashboardId}`}></div>
    </>
  );
};

export default DashBoardRender;