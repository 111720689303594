import React, { useRef, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import ButtonGroup from "antd/lib/button/button-group";
import { FileOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Tabs, Table, Typography, Row, Col, Select, Input } from "antd";
import Section from "../Shared/Section";
import ManualTransfer from "./ManualTransfer";
import TransferList from "./TransferList";
import BulkTransfer from "./BulkTransfer";
import MT940Parser from "./MT940Parser";
import { useTranslation } from "react-i18next";

const Transfers = (props) => {
  const [t] = useTranslation();
  const [refreshList, doRefreshList] = useState(0);

  return (
    <DefaultPage
      title={t("Transfers")}
      icon="retweet"
      extra={
        <ButtonGroup>
          <Button icon={<FileOutlined />}>{t("New")}</Button>
          <Button icon={<ReloadOutlined />} onClick={() => doRefreshList(refreshList + 1)}>
            {t("Reload")}
          </Button>
          <Button>{t("Import")}</Button>
          <Button>{t("Export")}</Button>
        </ButtonGroup>
      }>
      <Tabs>
        <Tabs.TabPane tab={t("Transfers")} key="transfers">
          <TransferList refresh={refreshList} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Manual")} key="manual">
          <ManualTransfer onNewManualTransfer={() => doRefreshList(refreshList + 1)} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Import")} key="import">
          <BulkTransfer onNewImport={() => doRefreshList(refreshList + 1)} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Parser")} key="parser">
          <MT940Parser />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Transfers;
