import React, { useState } from 'react';
import { 
  Card, 
  Table, 
  Input, 
  Button, 
  Tag, 
  Select, 
  Space, 
  Typography 
} from 'antd';
import { 
  SearchOutlined, 
  PlusOutlined, 
  FilterOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const { Title, Text } = Typography;
const { Option } = Select;

const FixedAssetsTable = ({ assets, loading, onAssetSelect, onCreateNew }) => {
  const {t} = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'name',
    order: 'ascend'
  });
  const [filters, setFilters] = useState({
    status: 'all',
    category: 'all',
    location: 'all',
    currency: 'all'
  });

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const handleFilterChange = (filter, value) => {
    setFilters({
      ...filters,
      [filter]: value
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatCurrency = (value, currencyCode = 'USD') => {
    if (value === undefined || value === null) return '';
    return new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: currencyCode 
    }).format(value);
  };

  const getStatusTag = (status) => {
    let color = 'default';
    
    switch(status?.toLowerCase()) {
      case 'active':
        color = 'success';
        break;
      case 'inactive':
        color = 'error';
        break;
      case 'sold':
        color = 'warning';
        break;
      case 'damaged':
        color = 'default';
        break;
      default:
        color = 'default';
    }
    
    return <Tag color={color}>{status}</Tag>;
  };

  // Get unique values for filters
  const categories = ['all', ...new Set(assets.map(asset => asset.category).filter(Boolean))];
  const locations = ['all', ...new Set(assets.map(asset => asset.location).filter(Boolean))];
  const statuses = ['all', ...new Set(assets.map(asset => asset.status).filter(Boolean))];
  const currencies = ['all', ...new Set(assets.map(asset => asset.currency).filter(Boolean))];

  // Calculate asset value distribution by currency
  const assetValueByCurrency = assets.reduce((acc, asset) => {
    const currency = asset.currency || 'Unknown';
    if (!acc[currency]) {
      acc[currency] = {
        count: 0,
        totalValue: 0
      };
    }
    acc[currency].count += 1;
    acc[currency].totalValue += Number(asset.currentBookValue || 0);
    return acc;
  }, {});
  
  // Filter assets
  const filteredAssets = assets.filter(asset => {
    // Search term filter
    const searchMatch = 
      asset.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      asset.assetTag?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      asset.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      asset.description?.toLowerCase().includes(searchTerm.toLowerCase());
    
    // Category, location, status, currency filters
    const categoryMatch = filters.category === 'all' || asset.category === filters.category;
    const locationMatch = filters.location === 'all' || asset.location === filters.location;
    const statusMatch = filters.status === 'all' || asset.status === filters.status;
    const currencyMatch = filters.currency === 'all' || asset.currency === filters.currency;
    
    return searchMatch && categoryMatch && locationMatch && statusMatch && currencyMatch;
  });

  const columns = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
    },
    {
      title: t('Asset Tag'),
      dataIndex: 'assetTag',
      key: 'assetTag',
      sorter: (a, b) => (a.assetTag || '').localeCompare(b.assetTag || ''),
      sortOrder: sortedInfo.columnKey === 'assetTag' && sortedInfo.order,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => (a.name || '').localeCompare(b.name || ''),
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    },
    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => (a.category || '').localeCompare(b.category || ''),
      sortOrder: sortedInfo.columnKey === 'category' && sortedInfo.order,
    },
    {
      title: t('Purchase Date'),
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.purchaseDate || 0) - new Date(b.purchaseDate || 0),
      sortOrder: sortedInfo.columnKey === 'purchaseDate' && sortedInfo.order,
    },
    {
      title: t('Purchase Price'),
      dataIndex: 'purchasePrice',
      key: 'purchasePrice',
      render: (text, record) => formatCurrency(text, record.currency),
      sorter: (a, b) => (a.purchasePrice || 0) - (b.purchasePrice || 0),
      sortOrder: sortedInfo.columnKey === 'purchasePrice' && sortedInfo.order,
    },
    {
      title: t('Current Book Value'),
      dataIndex: 'currentBookValue',
      key: 'currentBookValue',
      render: (text, record) => formatCurrency(text, record.currency),
      sorter: (a, b) => (a.currentBookValue || 0) - (b.currentBookValue || 0),
      sortOrder: sortedInfo.columnKey === 'currentBookValue' && sortedInfo.order,
    },
    {
      title: t('Location'),
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => (a.location || '').localeCompare(b.location || ''),
      sortOrder: sortedInfo.columnKey === 'location' && sortedInfo.order,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusTag(text),
      sorter: (a, b) => (a.status || '').localeCompare(b.status || ''),
      sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
    },
  ];

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Title level={5} style={{ margin: 0 }}>Asset Inventory</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={onCreateNew}>
          {t('Add New Asset')}
        </Button>
      </div>
      
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 16, gap: 8 }}>
        <Input
          placeholder={t('Search assets...')}
          prefix={<SearchOutlined />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 250 }}
        />
        
        <Space>
          <Select
            value={filters.category}
            onChange={(value) => handleFilterChange('category', value)}
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
          >
            <Option value="all">{t('All Categories')}</Option>
            {categories.filter(c => c !== 'all').map(category => (
              <Option key={category} value={category}>{category}</Option>
            ))}
          </Select>
          
          <Select
            value={filters.location}
            onChange={(value) => handleFilterChange('location', value)}
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
          >
            <Option value="all">{t('All Locations')}</Option>
            {locations.filter(l => l !== 'all').map(location => (
              <Option key={location} value={location}>{location}</Option>
            ))}
          </Select>
          
          <Select
            value={filters.status}
            onChange={(value) => handleFilterChange('status', value)}
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
          >
            <Option value="all">{t('All Statuses')}</Option>
            {statuses.filter(s => s !== 'all').map(status => (
              <Option key={status} value={status}>{status}</Option>
            ))}
          </Select>
          
          <Select
            value={filters.currency}
            onChange={(value) => handleFilterChange('currency', value)}
            style={{ width: 180 }}
            dropdownMatchSelectWidth={false}
          >
            <Option value="all">{t('All Currencies')}</Option>
            {currencies.filter(c => c !== 'all').map(currency => {
              const stats = assetValueByCurrency[currency] || { count: 0, totalValue: 0 };
              return (
                <Option key={currency} value={currency}>
                  {currency} ({stats.count} {t('assets')}, {formatCurrency(stats.totalValue, currency)})
                </Option>
              );
            })}
          </Select>
        </Space>
      </div>
      
      <Table 
        columns={columns} 
        dataSource={filteredAssets}
        rowKey="id"
        loading={loading}
        onChange={handleChange}
        onRow={(record) => ({
          onClick: () => {
            onAssetSelect(record);
          },
          style: { cursor: 'pointer' }
        })}
        pagination={{ 
          pageSize: 10,
        showTotal: (total) => t('Total {{total}} items', { total })
        }}
        locale={{
          emptyText: t('No assets found matching your criteria')
        }}
      />
    </Card>
  );
};

export default FixedAssetsTable; 