import {
  DeleteOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  FileOutlined,
  LeftOutlined,
  SaveOutlined,
  SearchOutlined,
  UploadOutlined,FolderOpenOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, message, Popconfirm, Table, Tag, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormulaSimulator from "../Products/FormulaSimulator";
import CodeEditorW from '../Shared/CodeEditorW';
import RecentSelect from '../Shared/RecentSelect';

const CommandChains = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [code, setCode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [codeSearchText, setCodeSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [simulatorVisible,setSimulatorVisible]=useState(false);
  // Add column filters state
  const [columnFilters, setColumnFilters] = useState({});

  useEffect(() => load(props.match.params.chainId ? props.match.params.chainId : 0), []);
  
  // Filter data when any filters change
  useEffect(() => {
    let filtered = [...data];
    
    // Apply code search filter
    if (codeSearchText && codeSearchText.trim() !== "") {
      filtered = filtered.filter(item => 
        item.code && item.code.toLowerCase().includes(codeSearchText.toLowerCase())
      );
    }
    
    // Apply column filters
    Object.keys(columnFilters).forEach(column => {
      const value = columnFilters[column];
      if (value && value.trim() !== "") {
        filtered = filtered.filter(item => 
          item[column] && item[column].toString().toLowerCase().includes(value.toLowerCase())
        );
      }
    });
    
    setFilteredData(filtered);
  }, [codeSearchText, columnFilters, data]);

  const load = (chainId) => {
    exe("RepoChain", { operation: "GET" }).then((r) => {
      if (r.ok) {
        const chainData = r.outData.map(item => ({
          ...item,
          code: item.code || "" // Ensure code property exists
        }));
        setData(chainData);
        setFilteredData(chainData);
        if (chainId) {
          //if chainId is not a number, we search by name 
          if (isNaN(chainId)) {
            doSetSelected(chainData.find((p) => p.name == chainId))
          } else {
            doSetSelected(chainData.find((p) => p.id == chainId))
          }
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const save = (r) => {
    exe("RepoChain", { operation: selected.id ? "UPDATE" : "ADD", entity: {...selected,code:code} }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        setSelected({...selected,id:r.data.entity.id}); //if not set, the next save without going back will be an add
        load();
        // Reset code search when saving to show all updated items
        setCodeSearchText("");
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 12, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', width: 250 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const value = e.target.value || '';
            setSelectedKeys(value ? [value] : []);
            // Update column filters state immediately
            setColumnFilters(prev => ({
              ...prev,
              [dataIndex]: value
            }));
            if (value) {
              setSearchedColumn(dataIndex);
              setSearchText(value);
            }
            // Don't auto confirm as we type - this keeps the dropdown open
          }}
          onClear={() => {
            setColumnFilters(prev => ({
              ...prev,
              [dataIndex]: ''
            }));
            setSelectedKeys([]);
            clearFilters(); // Call clearFilters to properly reset the filter state
            confirm(); // OK to close on clear
          }}
          onBlur={() => {
            // Only confirm/close when focus leaves the input
            confirm();
          }}
          style={{ width: '100%' }}
          allowClear
          onPressEnter={() => confirm()}
        />
      </div>
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select(), 100);
      }
    },
    render: (text, record) => {
      if (dataIndex === 'name') {
        return (
          <div>
            <Tag color="blue">{text}</Tag>
            {codeSearchText && record.code && record.code.toLowerCase().includes(codeSearchText.toLowerCase()) && (
              <div style={{ fontSize: '0.8em', color: '#1890ff', marginTop: 4 }}>
                <FilterOutlined /> {t("Code match")}
              </div>
            )}
          </div>
        );
      }
      
      // Highlight the matching text in other columns
      const filterValue = columnFilters[dataIndex];
      if (filterValue && text) {
        return (
          <div>
            {text.toString().split(new RegExp(`(${filterValue})`, 'gi')).map((fragment, i) => 
              fragment.toLowerCase() === filterValue.toLowerCase() 
                ? <span key={i} style={{ backgroundColor: '#ffc069', padding: '0 2px' }}>{fragment}</span> 
                : fragment
            )}
          </div>
        );
      }
      return text;
    }
  });

  // table search end
  const onDelete = (record) => {
    setLoading(true);
    exe("RepoChain", { operation: "DELETE", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
        // Reset code search when deleting to show all updated items
        setCodeSearchText("");
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExport = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify({ ...selected,code:code, id: 0 }), selected.name + ".json", "text/json");
  };
  const onImport = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const myForm = JSON.parse(e.target.result);
        doSetSelected(null);
        doSetSelected(myForm);
      } catch (error) {
        message.error("Invalid file");
      }
    };
    reader.readAsText(file);
    return false;
  };
  const openSimulator=()=>{
    setSimulatorVisible(true);
  }
  const doSetSelected=(r)=>{
    if(r&&r.code) {
      setCode(r.code);
    }else setCode("");

    setSelected(r);
    //inserting in localsotrage history. Limiting to 10 items
    if(r&&r.id){
      let arr=JSON.parse(localStorage.getItem("chainHistory"));
      if(!arr) arr=[];
      arr=arr.filter(item=>item.key!=r.id);
      arr.unshift({key:r.id,label:r.name});
      arr=arr.slice(0,10);
      localStorage.setItem("chainHistory",JSON.stringify(arr));
    }
  }
  const onRecentKeySelect=(v)=>{
    doSetSelected(data.find(p=>p.id==+v));
  }

  // Get unique categories for filter dropdown
  const getCategoryFilters = () => {
    const uniqueCategories = [...new Set(data.filter(item => item.category).map(item => item.category))];
    return uniqueCategories.map(category => ({ text: category, value: category }));
  };

  // Update category filter
  const handleCategoryFilter = (category) => {
    setColumnFilters(prev => ({
      ...prev,
      category: category || ''
    }));
  };

  // Add a helper to highlight the matching code content
  const highlightCodeMatch = (name, id) => {
    if (!codeSearchText || codeSearchText.trim() === "") return null;
    
    const item = data.find(d => d.id === id);
    if (!item || !item.code) return null;
    
    const codeContent = item.code;
    const searchIndex = codeContent.toLowerCase().indexOf(codeSearchText.toLowerCase());
    
    if (searchIndex === -1) return null;
    
    // Get a snippet of code around the match
    const startPos = Math.max(0, searchIndex - 20);
    const endPos = Math.min(codeContent.length, searchIndex + codeSearchText.length + 20);
    const snippet = codeContent.substring(startPos, endPos);
    
    return (
      <div style={{ fontSize: '0.85em', color: '#666', marginTop: 4 }}>
        {startPos > 0 ? '...' : ''}
        {snippet.split(new RegExp(`(${codeSearchText})`, 'i')).map((part, i) => 
          part.toLowerCase() === codeSearchText.toLowerCase() 
            ? <span key={i} style={{ backgroundColor: '#ffe58f', padding: '0 2px' }}>{part}</span> 
            : part
        )}
        {endPos < codeContent.length ? '...' : ''}
      </div>
    );
  };

  // Update category filter dropdown to be simpler too
  const updateCategoryFilter = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 12, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', width: 250 }}>
        <Input
          prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
          placeholder={`${t("Search")} ${t("Category")}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const value = e.target.value || '';
            handleCategoryFilter(value);
            setSelectedKeys(value ? [value] : []);
            // Don't auto confirm as we type - this keeps the dropdown open
          }}
          onClear={() => {
            handleCategoryFilter('');
            setSelectedKeys([]);
            clearFilters(); // Call clearFilters to properly reset the filter state
            confirm(); // OK to close on clear
          }}
          style={{ width: '100%', marginBottom: 8 }}
          allowClear
          onBlur={() => confirm()}
          onPressEnter={() => confirm()}
        />
        <div style={{ maxHeight: 250, overflow: 'auto' }}>
          {getCategoryFilters().map(category => (
            <div 
              key={category.value} 
              style={{ 
                padding: '4px 8px', 
                cursor: 'pointer',
                backgroundColor: columnFilters.category === category.value ? '#e6f7ff' : 'transparent',
                borderRadius: 2
              }}
              onClick={() => {
                handleCategoryFilter(category.value);
                setSelectedKeys([category.value]);
                confirm();
              }}
            >
              {category.text}
            </div>
          ))}
        </div>
      </div>
    )
  });

  // Handler for clearing all filters, including internal state
  const clearAllFilters = () => {
    setColumnFilters({});
    setSearchText('');
    setSearchedColumn('');
    setCodeSearchText('');
  };

  return (
    <div>
      <DefaultPage
        title={t("Command Chains")}
        icon="link"
        extra={
          <div>
            <Button.Group>
              <Button icon={<ExperimentOutlined />} onClick={openSimulator}>
                {t("Simulator")}
              </Button>
              <Button icon={<DownloadOutlined />} onClick={onExport} disabled={!selected}>
                {t("Export")}
              </Button>
              <Upload showUploadList={false} beforeUpload={(f) => onImport(f)}>
                <Button icon={<UploadOutlined />}>{t("Import")}</Button>
              </Upload>
              {selected && (
                <Button type="primary" icon={<SaveOutlined />} onClick={save}>
                  {t("Save")}
                </Button>
              )}
            </Button.Group>
          </div>
        }>
        {!selected && (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
              <div>
                <Button type="link" icon={<FileOutlined />} onClick={() => doSetSelected({})}>
                  {t("New")}
                </Button>
                <RecentSelect 
                  variable="chainHistory" 
                  onSelect={v=>onRecentKeySelect(v)} 
                />
              </div>
            
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input 
                  prefix={<FilterOutlined style={{ color: '#bfbfbf' }} />}
                  placeholder={t("Filter by code content")}
                  value={codeSearchText}
                  onChange={e => setCodeSearchText(e.target.value)}
                  onClear={() => setCodeSearchText('')}
                  style={{ width: 250 }}
                  allowClear
                />
                {codeSearchText && (
                  <Tag color="blue" style={{ marginLeft: 8 }}>
                    {filteredData.length} {t("results found")}
                  </Tag>
                )}
              </div>
            </div>

            {/* Filter status bar - only show for column filters, not code content filter */}
            {Object.keys(columnFilters).some(key => columnFilters[key] && columnFilters[key].trim() !== '') && (
              <div style={{ marginBottom: 16, display: 'flex', flexWrap: 'wrap', gap: 8, alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>{t("Active filters")}:</span>
                {Object.keys(columnFilters).map(column => 
                  columnFilters[column] && columnFilters[column].trim() !== '' ? (
                    <Tag 
                      color="blue" 
                      key={column}
                      closable
                      onClose={() => {
                        setColumnFilters(prev => ({...prev, [column]: ''}));
                      }}
                    >
                      {t(column.charAt(0).toUpperCase() + column.slice(1))}: {columnFilters[column]}
                    </Tag>
                  ) : null
                )}
                
                <Button 
                  type="link" 
                  size="small"
                  onClick={clearAllFilters}
                >
                  {t("Clear all")}
                </Button>
              </div>
            )}
    
            <Table 
              dataSource={filteredData} 
              size="small" 
              rowKey="id"
              pagination={{ 
                defaultPageSize: 15,
                showSizeChanger: true,
                pageSizeOptions: ['15', '30', '50', '100'],
                showTotal: (total, range) => `${range[0]}-${range[1]} ${t("of")} ${total} ${t("items")}`
              }}
              loading={loading}
              expandable={{
                expandedRowRender: record => codeSearchText && record.code ? (
                  <div style={{ padding: '0 20px' }}>
                    <div><strong>{t("Code Match")}:</strong></div>
                    {highlightCodeMatch(record.name, record.id)}
                  </div>
                ) : null,
                rowExpandable: record => codeSearchText && record.code && record.code.toLowerCase().includes(codeSearchText.toLowerCase())
              }}
            >
              <Table.Column title={t("ID")} dataIndex="id" {...getColumnSearchProps("id")} sorter={(a, b) => a.id - b.id} />
              <Table.Column 
                title={t("Name")} 
                dataIndex="name" 
                {...getColumnSearchProps("name")}
                sorter={(a, b) => (a.name || '').localeCompare(b.name || '')}
              />
              <Table.Column 
                title={t("Operation")} 
                dataIndex="operation" 
                {...getColumnSearchProps("operation")}
                sorter={(a, b) => (a.operation || '').localeCompare(b.operation || '')}
              />
              <Table.Column 
                title={t("Category")} 
                dataIndex="category"
                {...updateCategoryFilter()}
                sorter={(a, b) => (a.category || '').localeCompare(b.category || '')}
                render={(text) => (
                  text ? <Tag color="green">{text}</Tag> : null
                )}
              />
              <Table.Column
                title={t("Actions")}
                render={(v, r) => (
                  <span style={{whiteSpace:"nowrap"}}>
                    <Button type="link" onClick={() => doSetSelected(r)} icon={<FolderOpenOutlined />} />
                    <Divider type="vertical" />
                    <Popconfirm title={t("Are you sure you want to delete this item?")} onConfirm={() => onDelete(r)}>
                      <Button type="link" icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
          </div>
        )}

        {selected && (
          <div>
            <Button type="link" icon={<LeftOutlined />} onClick={() => doSetSelected(null)}>
              {t("Back")}
            </Button>
            <div style={{ display: "flex" }}>
              <Form.Item label={t("Name")} style={{ flex: 1 }}>
                <Input value={selected.name} onChange={(v) => setSelected({ ...selected, name: v.target.value })} />
              </Form.Item>
              <Form.Item label={t("Operation Description")} style={{ flex: 1, marginLeft: 5 }}>
                <Input value={selected.operation} onChange={(v) => setSelected({ ...selected, operation: v.target.value })} />
              </Form.Item>
              <Form.Item label={t("Category")} style={{ flex: 1, marginLeft: 5 }}>
                <Input value={selected.category} onChange={(v) => setSelected({ ...selected, category: v.target.value })} />
              </Form.Item>
            </div>
            <CodeEditorW javascript value={code} onChange={(v) => setCode(v)} />
          </div>
        )}
        <FormulaSimulator visible={simulatorVisible} onCancel={()=>setSimulatorVisible(false)} formula={code} />
      </DefaultPage>
    </div>
  );
};

export default CommandChains;
