import React, { useEffect, useState } from 'react';
import { 
  Modal, 
  Form, 
  Input, 
  InputNumber, 
  Select, 
  DatePicker, 
  Button, 
  Divider, 
  Row, 
  Col, 
  Avatar,
  Tag,
  message,
  Tabs,
  Badge
} from 'antd';
import { 
  UserOutlined, 
  DollarOutlined, 
  MailOutlined, 
  PhoneOutlined, 
  TeamOutlined,
  TagsOutlined,
  FileTextOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import SimpleContactSelect from '../Contact/SimpleContactSelect';
import { exe } from '../../Lib/Dal';
import UserSelect from '../Bpm/UserSelect';
import ActivityFeed from './ActivityFeed';
import ActivityForm from './ActivityForm';

const { Option } = Select;
const { TabPane } = Tabs;

// Default mock data for users and tags
const defaultUsers = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Jane Smith' },
  { id: 3, name: 'Robert Johnson' },
  { id: 4, name: 'Emily Williams' }
];

const defaultTags = [
  'Lead', 'Customer', 'Hot', 'Cold', 'Pending', 
  'Follow-up', 'VIP', 'Enterprise', 'SMB'
];

const ContactEdit = ({ visible, selected, onCancel, onSave, onDelete }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [t] = useTranslation();
  const [contactData, setContactData] = useState(null);
  const [stages, setStages] = useState([]);
  const [users, setUsers] = useState(defaultUsers);
  const [tags, setTags] = useState(defaultTags);
  const [activeTab, setActiveTab] = useState("1");
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [refreshActivities, setRefreshActivities] = useState(0);
  const [activitiesCount, setActivitiesCount] = useState(0);

  // Clean the selected contact data when it changes
  useEffect(() => {
    if (selected) {
      // Create a clean copy of the contact without date objects
      const cleanContact = { ...selected };
      
      // Ensure tags is initialized properly for new contacts
      if (!cleanContact.id && !cleanContact.tags) {
        cleanContact.tags = null;
      }
      
      // Set initial contact data
      setContactData(cleanContact);
    }
  }, [selected]);

  // Initialize form values when contact data or visibility changes
  useEffect(() => {
    if (visible && contactData) {
      // Initialize the form with contact data
      // Make a copy to avoid directly modifying the contactData object
      const formValues = { ...contactData };
      
      try {
        // Handle the lastActivity date conversion to Moment object if it exists
        if (formValues.lastActivity) {
          const date = moment(formValues.lastActivity);
          if (date.isValid()) {
            formValues.lastActivity = date;
          } else {
            formValues.lastActivity = null;
          }
        } else {
          formValues.lastActivity = null;
        }
        
        // Convert tags from comma-separated string to array if needed
        if (formValues.tags && typeof formValues.tags === 'string') {
          // Split and filter out any empty strings
          const tagArray = formValues.tags.split(',')
            .filter(tag => tag && tag.trim())
            .map(tag => tag.trim());
            
          formValues.tags = tagArray.length > 0 ? tagArray : undefined;
        } else if (!formValues.tags || (Array.isArray(formValues.tags) && formValues.tags.length === 0)) {
          // Explicitly set empty tags to undefined (will clear the field)
          formValues.tags = undefined;
        }
      } catch (error) {
        console.error("Error converting values:", error);
        formValues.lastActivity = null;
      }
      
      console.log('Setting form values with tags:', formValues.tags);
      
      // Set the form values
      form.setFieldsValue(formValues);
    }
  }, [visible, contactData, form]);

  // Load stages, users and other data when modal opens
  useEffect(() => {
    if (visible && contactData?.funnelId) {
      // Load stages for the current funnel
      exe("RepoFunnelStage", {
        operation: "GET",
        filter: `funnelId=${contactData.funnelId}`
      }).then(r => {
        if (r.ok) {
          setStages(r.outData || []);
        }
      });
      
      // Load users - assuming there's a command to get users
      exe("GetUsers", {}).then(r => {
        if (r.ok && r.outData && r.outData.length > 0) {
          setUsers(r.outData);
        }
      }).catch(() => {
        // Fallback to default users if API fails
        setUsers(defaultUsers);
      });
      
      // Load available tags
      exe("GetContactTags", {}).then(r => {
        if (r.ok && r.outData && r.outData.tags) {
          setTags(r.outData.tags);
        }
      }).catch(() => {
        // Fallback to default tags if API fails
        setTags(defaultTags);
      });
    }
  }, [visible, contactData?.funnelId]);

  const handleCancel = () => {
    // Reset the form data when cancelling
    form.resetFields();
    setActiveTab("1");
    onCancel();
  };

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        setConfirmLoading(true);
        
        // Debug the tags coming from the form
        console.log('Original tags value:', values.tags);
        
        // Validate required foreign keys
        if (!contactData.funnelId) {
          setConfirmLoading(false);
          message.error(t('Missing funnel ID. Please try again.'));
          return;
        }
        
        if (!values.stageId) {
          setConfirmLoading(false);
          message.error(t('Please select a stage for this contact.'));
          return;
        }
        
        // Remove any empty tags and ensure proper string conversion
        let tagsValue = null;
        if (Array.isArray(values.tags) && values.tags.length > 0) {
          // Get non-empty tags
          const nonEmptyTags = values.tags.filter(tag => tag && tag.trim());
          // Only set if we have any
          if (nonEmptyTags.length > 0) {
            tagsValue = nonEmptyTags.join(',');
          }
        }
        values.tags = tagsValue;
        
        console.log('Processed tags value:', values.tags);

        const processedValues = {
          ...values,
          lastActivity: values.lastActivity ? 
            values.lastActivity.format('YYYY-MM-DD') : null,
          funnelId: contactData.funnelId
        };

        // Print debug info
        console.log('Saving contact with data:', {
          operation: contactData.id ? "UPDATE" : "ADD",
          funnelId: processedValues.funnelId,
          stageId: processedValues.stageId,
          tags: processedValues.tags, // Log tags for debugging
          data: {...contactData, ...processedValues}
        });

        // Determine if this is a new contact or an update
        const operation = contactData.id ? "UPDATE" : "ADD";
        
        exe("RepoFunnelContact", {
          operation,
          entity: {
            ...contactData,
            ...processedValues
          }
        }).then(r => {
          setConfirmLoading(false);
          if (r.ok && r.outData && r.outData.length > 0) {
            message.success(contactData.id ? t('Contact updated successfully') : t('Contact created successfully'));
            
            // If new contact, add a "note" activity automatically
            if (!contactData.id) {
              const newContact = r.outData[0];
              
              // Add a "Created" note
              exe("RepoFunnelContactActivity", {
                operation: "ADD",
                entity: {
                  contactId: newContact.id,
                  type: "note",
                  title: t("Contact created"),
                  description: t("Contact added to funnel"),
                  date: moment().format('YYYY-MM-DD HH:mm:ss'),
                  created: moment().format('YYYY-MM-DD HH:mm:ss'),
                  createdBy: sessionStorage.getItem('username') || ''
                }
              });
            }
            
            onSave(r.outData[0]);
          } else {
            console.error('Failed to save contact:', r);
            message.error(r.msg || t('Failed to save contact'));
          }
        }).catch(err => {
          console.error("Error saving contact:", err);
          setConfirmLoading(false);
          message.error(t('Error saving contact'));
        });
      });
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    
    // Call the API to delete the contact
    exe("RepoFunnelContact", {
      operation: "DELETE",
      entity: {
        id: contactData.id
      }
    }).then(r => {
      if (r.ok) {
        setConfirmLoading(false);
        message.success(t('Contact deleted successfully'));
        onDelete(contactData);
      } else {
        setConfirmLoading(false);
        message.error(r.msg || t('Failed to delete contact'));
      }
    });
  };

  // Calculate avatar initials from name
  const getInitials = (name) => {
    if (!name) return '';
    return name.split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleAddActivity = () => {
    setSelectedActivity(null);
    setShowActivityForm(true);
  };

  const handleEditActivity = (activity) => {
    setSelectedActivity(activity);
    setShowActivityForm(true);
  };

  const handleActivitySuccess = () => {
    setShowActivityForm(false);
    setSelectedActivity(null);
    setRefreshActivities(prev => prev + 1);
    
    // Update lastActivity date in the contact if it's a new activity
    if (contactData?.id) {
      exe("RepoFunnelContact", {
        operation: "GET",
        filter: `id=${contactData.id}`
      }).then(r => {
        if (r.ok && r.outData && r.outData.length > 0) {
          setContactData(r.outData[0]);
        }
      });
    }
  };

  // Callback function to update activities count
  const handleActivitiesLoaded = (count) => {
    setActivitiesCount(count);
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar 
            size={32} 
            style={{ backgroundColor: '#1890ff', marginRight: 12 }}
          >
            {contactData ? getInitials(contactData.name) : ''}
          </Avatar>
          <span>{t('Edit Contact')}</span>
        </div>
      }
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      footer={[
        <Button key="delete" danger onClick={handleDelete}>
          {t('Delete')}
        </Button>,
        <Button key="cancel" onClick={handleCancel}>
          {t('Cancel')}
        </Button>,
        <Button key="save" type="primary" onClick={handleOk} loading={confirmLoading}>
          {t('Save')}
        </Button>
      ]}
    >
      {contactData && (
        <Tabs activeKey={activeTab} onChange={handleTabChange} style={{marginTop: -20}}>
          <TabPane 
            tab={
              <span>
                <UserOutlined />
                {t('Contact Details')}
              </span>
            } 
            key="1"
          >
            <Form
              form={form}
              layout="vertical"
              preserve={false}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label={t('Name')}
                    rules={[{ required: true, message: t('Please enter contact name') }]}
                  >
                    <Input prefix={<UserOutlined />} placeholder={t('Contact name')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="company"
                    label={t('Company')}
                    rules={[{ required: false, message: t('Please enter company name') }]}
                  >
                    <Input prefix={<TeamOutlined />} placeholder={t('Company name')} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label={t('Email')}
                    rules={[
                      { required: false, message: t('Please enter email') },
                      { type: 'email', message: t('Please enter valid email') }
                    ]}
                  >
                    <Input prefix={<MailOutlined />} placeholder="example@company.com" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone"
                    label={t('Phone')}
                  >
                    <Input prefix={<PhoneOutlined />} placeholder="+1 (555) 000-0000" />
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left">{t('Deal Information')}</Divider>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="value"
                    label={t('Deal Value')}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                      prefix={<DollarOutlined />}
                      placeholder="0"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="stageId"
                    label={t('Stage')}
                  >
                    <Select placeholder={t('Select stage')}>
                      {stages.map(stage => (
                        <Option key={stage.id} value={stage.id}>
                          {stage.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="probability"
                    label={t('Probability (%)')}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      max={100}
                      formatter={value => `${value}%`}
                      parser={value => value.replace('%', '')}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastActivity"
                    label={t('Last Activity Date')}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="assignedTo"
                    label={t('Assigned To')}
                  >
                    <UserSelect 
                      value={form.getFieldValue('assignedTo')}
                      onChange={value => form.setFieldsValue({ assignedTo: value })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="tags"
                    label={t('Tags')}
                  >
                    <Select
                      mode="tags"
                      tagRender={tagRender}
                      placeholder={t('Select or enter tags')}
                      style={{ width: '100%' }}
                      options={tags.map(tag => ({ label: tag, value: tag }))}
                      allowClear
                      tokenSeparators={[',']}
                      onChange={(values) => {
                        // Filter out any empty tags
                        if (Array.isArray(values)) {
                          const filteredValues = values.filter(tag => tag && tag.trim());
                          
                          // Only update if there's been a change
                          if (filteredValues.length !== values.length) {
                            form.setFieldsValue({ tags: filteredValues.length > 0 ? filteredValues : undefined });
                          }
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="notes"
                label={t('Notes')}
              >
                <Input.TextArea rows={4} placeholder={t('Enter any additional notes...')} />
              </Form.Item>

              <Form.Item
                name="contactId"
                label={t('Link to Contact')}
              >
                <SimpleContactSelect
                  showLink={true}
                  onChange={value => form.setFieldsValue({ contactId: value })}
                  value={form.getFieldValue('contactId')}
                  allowNew
                />
              </Form.Item>
            </Form>
          </TabPane>
          
          <TabPane 
            tab={
              <span>
                <ClockCircleOutlined />
                {t('Activities')}
                {activitiesCount > 0 && <Badge count={activitiesCount} style={{ marginLeft: 8 }} />}
              </span>
            } 
            key="2"
            forceRender
            disabled={!contactData.id}
          >
            <ActivityFeed 
              contactId={contactData.id}
              onAddActivity={handleAddActivity}
              onEditActivity={handleEditActivity}
              refreshTrigger={refreshActivities}
              onActivitiesLoaded={handleActivitiesLoaded}
            />
          </TabPane>
        </Tabs>
      )}
      
      <ActivityForm
        visible={showActivityForm}
        contactId={contactData?.id}
        activity={selectedActivity}
        onCancel={() => setShowActivityForm(false)}
        onSuccess={handleActivitySuccess}
      />
    </Modal>
  );
};

export default ContactEdit; 